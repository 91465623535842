import React, { ReactNode, createContext, useContext, useState } from 'react';
import { optionsFields } from 'src/Pages/BrokerPages/AnalyticalReports/data';

// Define Context Type
interface LeverageContextType {
  totalEquity: string[];
  setTotalEquity: React.Dispatch<React.SetStateAction<string[]>>;
  inTangibleAssets: string[];
  setInTangibleAssets: React.Dispatch<React.SetStateAction<string[]>>;
  totalLongTermAssets: string[];
  setTotalLongTermAssets: React.Dispatch<React.SetStateAction<string[]>>;
  tangibleNetworth: number;
  setTangibleNetworth: React.Dispatch<React.SetStateAction<number>>;
  totalAssets: string[];
  setTotalAssets: React.Dispatch<React.SetStateAction<string[]>>;
  totalLiabilities: string[];
  setTotalLiabilities: React.Dispatch<React.SetStateAction<string[]>>;
  reportReferenceData: {
    profitAndLossData: { [key: string]: optionsFields[] };
    balanceSheetData: { [key: string]: optionsFields[] };
    balanceSheetPreviewLink: string;
    profitAndLossPreviewLink: string;
  };
  setReportReferenceData: React.Dispatch<
    React.SetStateAction<{
      profitAndLossData: { [key: string]: optionsFields[] };
      balanceSheetData: { [key: string]: optionsFields[] };
      balanceSheetPreviewLink: string;
      profitAndLossPreviewLink: string;
    }>
  >;
  historyData: {
    [key: string]: { value: number; updatedBy: string };
  };
  setHistoryData: React.Dispatch<
    React.SetStateAction<{
      [key: string]: { value: number; updatedBy: string };
    }>
  >;
}

// Create Context with Default Values
const LeverageContext = createContext<LeverageContextType | undefined>(
  undefined
);

// Define Props for Provider
interface LeverageContextProviderProps {
  children: ReactNode;
}

// Create Provider Component
export const LeverageProvider: React.FC<LeverageContextProviderProps> = ({
  children,
}) => {
  // Total Equity
  // Intangible Assets
  // Long Term Assets
  // Tangible Networth
  // Total Liabilities
  // Liabilities Tangible Networth
  const [totalEquity, setTotalEquity] = useState<string[]>([]);
  const [inTangibleAssets, setInTangibleAssets] = useState<string[]>([]);
  const [totalAssets, setTotalAssets] = useState<string[]>([]);
  const [totalLongTermAssets, setTotalLongTermAssets] = useState<string[]>([]);
  const [tangibleNetworth, setTangibleNetworth] = useState<number>(0);
  const [totalLiabilities, setTotalLiabilities] = useState<string[]>([]);
  const [reportReferenceData, setReportReferenceData] = useState<{
    profitAndLossData: { [key: string]: optionsFields[] };
    balanceSheetData: { [key: string]: optionsFields[] };
    balanceSheetPreviewLink: string;
    profitAndLossPreviewLink: string;
  }>({
    profitAndLossData: {},
    balanceSheetData: {},
    balanceSheetPreviewLink: '',
    profitAndLossPreviewLink: '',
  });
  const [historyData, setHistoryData] = useState<{
    [key: string]: { value: number; updatedBy: string };
  }>({});

  return (
    <LeverageContext.Provider
      value={{
        totalEquity,
        setTotalEquity,
        inTangibleAssets,
        setInTangibleAssets,
        totalLongTermAssets,
        setTotalLongTermAssets,
        tangibleNetworth,
        setTangibleNetworth,
        totalAssets,
        setTotalAssets,
        totalLiabilities,
        setTotalLiabilities,
        reportReferenceData,
        setReportReferenceData,
        historyData,
        setHistoryData,
      }}
    >
      {children}
    </LeverageContext.Provider>
  );
};

// Custom Hook for Context Access
export const useLeverageContext = (): LeverageContextType => {
  const context = useContext(LeverageContext);
  if (!context) {
    throw new Error(
      'useLeverageContext must be used within an LeverageProvider'
    );
  }
  return context;
};
