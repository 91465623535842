import { Radio, RadioGroup, Stack, useToast } from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { MdEmail, MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { RiLockPasswordFill } from 'react-icons/ri';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { adminLogin, loginUser } from '../../Redux/Auth/Auth';
import { UserLoginSchema } from '../../ValidationSchema/Auth/Index';

import {
  Box,
  Button,
  Checkbox,
  Container,
  Flex,
  IconButton,
  Image,
  Input,
  Link,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import Cookies from 'js-cookie';
import { useAppSelector } from 'src/Redux/Store';
import { useAppToast } from 'src/hooks';

function Login() {
  const navigate = useNavigate();
  const userTypeLatest = localStorage.getItem('userType');
  const tokenLatest = Cookies.get('Token');
  const auth = useAppSelector((state) => state.auth);
  const toast = useAppToast();
  useEffect(() => {
    if (tokenLatest) {
      if (userTypeLatest === 'applicant') {
        navigate('/');
      } else if (userTypeLatest === 'broker') {
        navigate('/');
      } else if (userTypeLatest === 'brokerage') {
        navigate('/');
      } else if (userTypeLatest === null) {
        localStorage.removeItem('Token');
        Cookies.remove('Token');
      }
    }
  }, [tokenLatest]);

  const [cookies, setCookie, removeCookie] = useCookies([
    'userType',
    'email',
    'password',
    'remember',
  ]);
  useEffect(() => {
    if (
      cookies.userType &&
      cookies.email &&
      cookies.password &&
      cookies.remember === 'true'
    ) {
      formik.setValues({
        userType: cookies.userType,
        email: cookies.email,
        password: cookies.password,
        remember: true,
      });
    }
  }, []);

  const handleRememberMe = (e: any) => {
    formik.setFieldValue('remember', e.target.checked);
  };
  const formWidth = useBreakpointValue({ base: '96%', sm: '57%' });

  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();
  const Toast = useToast();
  const location = useLocation();
  const [isAdminLogin, setIsAdminLogin] = useState<boolean>(false);
  interface FormValues {
    userType: string;
    email: string;
    password: string;
    remember: boolean;
  }

  const initialValues: FormValues = {
    userType: isAdminLogin ? 'admin' : cookies.userType || 'broker',
    email: cookies.email || '',
    password: cookies.password || '',
    remember: cookies?.remember?.toString() === 'true' || false,
  };
  useEffect(() => {
    const pathName = location?.pathname;
    setIsAdminLogin(pathName === '/admin-login');
    formik.setFieldValue(
      'userType',
      pathName === '/admin-login' ? 'admin' : cookies.userType || 'broker'
    );
  }, [location.pathname]);

  useEffect(() => {
    const { status, type, user } = auth;
    switch (status) {
      case 'loading':
        break;
      case 'succeed': {
        if (type == 'GET_ISLOGIN') {
          const response = user?.data;
          const token = response?.token;
          const userType = response?.userType;
          const userID = response?.id;
          try {
            userType && localStorage.setItem('userType', userType);
            localStorage.setItem('userID', JSON.stringify(userID));
          } catch (error: any) {
            console.log(error.message);
          }

          if (!!token) {
            localStorage.setItem('Token', token);

            // Toast({
            //   // title: response.msg,
            //   title: 'Welcome to dashboard',

            //   status: 'success',
            //   isClosable: true,
            //   duration: 3000,
            //   position: 'top-right',
            // });
            if (user.msg.toLowerCase().includes('your request')) {
              toast({
                title: user.msg,
                status: 'info',
              });
            }

            if (userType === 'applicant') {
              if (!user.data.onboarding) {
                navigate('/applicant/profile');
              } else navigate('/');
              return;
            } else if (userType === 'broker') {
              navigate('/');
              return;
            } else if (userType === 'brokerage') {
              navigate('/');
              return;
            }
          }

          const { values } = formik;

          if (!!values?.remember) {
            setCookie('userType', values?.userType, { path: '/' });
            setCookie('email', values?.email, { path: '/' });
            setCookie('password', values?.password, { path: '/' });
            setCookie('remember', values?.remember.toString(), { path: '/' });
          } else {
            removeCookie('userType', { path: '/' });
            removeCookie('email', { path: '/' });
            removeCookie('password', { path: '/' });
            removeCookie('remember', { path: '/' });
          }
        }
        if (type === 'GET_ISLOGIN_ADMIN') {
          const response = user?.data;
          const token = response?.token;
          const userType = response?.userType;
          const userID = response?.id;
          try {
            userType && localStorage.setItem('userType', userType);
            localStorage.setItem('userID', JSON.stringify(userID));
          } catch (error: any) {
            console.log(error.message);
          }

          if (!!token) {
            localStorage.setItem('Token', token);
            const naviagteURL = userType === 'support' ? '/report-issues' : '/';
            navigate(naviagteURL);
          }

          const { values } = formik;

          if (!!values?.remember) {
            setCookie('userType', values?.userType, { path: '/' });
            setCookie('email', values?.email, { path: '/' });
            setCookie('password', values?.password, { path: '/' });
            setCookie('remember', values?.remember.toString(), { path: '/' });
          } else {
            removeCookie('userType', { path: '/' });
            removeCookie('email', { path: '/' });
            removeCookie('password', { path: '/' });
            removeCookie('remember', { path: '/' });
          }
        }
        break;
      }
      case 'failed':
        if (type == 'GET_ISLOGIN' || type === 'GET_ISLOGIN_ADMIN') {
          Toast({
            title: auth?.error?.msg || auth?.error?.message || 'Error occured!',
            status: 'error',
            isClosable: true,
            duration: 3000,
            position: 'top-right',
          });
        }
        break;

      default:
        break;
    }
  }, [auth.status]);

  const formik = useFormik({
    initialValues: initialValues,

    validationSchema: UserLoginSchema,
    onSubmit: async (values: FormValues) => {
      let res = '';
      // console.log('values', values);
      if (values?.userType === 'admin') {
        const payload = {
          email: values?.email,
          password: values?.password,
        };
        dispatch(adminLogin(payload) as any);
      } else {
        dispatch(loginUser(values) as any);
      }
      // if (!!values?.remember) {
      //   setCookie('userType', values?.userType, { path: '/' });
      //   setCookie('email', values?.email, { path: '/' });
      //   setCookie('password', values?.password, { path: '/' });
      //   setCookie('remember', values?.remember.toString(), { path: '/' });
      // } else {
      //   removeCookie('userType', { path: '/' });
      //   removeCookie('email', { path: '/' });
      //   removeCookie('password', { path: '/' });
      //   removeCookie('remember', { path: '/' });
      // }
    },
  });

  const OnHandelUser = () => {
    // isAdminLogin ? navigate('/admin-signup') : navigate('/applicant-account');
    navigate('/applicant-account');
  };
  const OnHandelForget = () => {
    navigate('/request-email');
  };

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  console.log('formik', formik);

  return (
    <>
      {!tokenLatest && (
        <Box h="100%">
          <Flex>
            <Container maxW="100%" p={['0px', '0px', '0px']}>
              <Flex flexWrap="wrap" height="100vh  " gap={[5, 0, 0, 0]}>
                <Box
                  bg="#114684"
                  borderRadius={[
                    '0px 200px 0px 0px',
                    '0px 245px 0px 0px',
                    '0px 350px 0px 0px',
                  ]}
                  w={['100%', '40%']}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  flexDir={'column'}
                  gap={'10px'}
                >
                  <Box display="flex" justifyContent="center">
                    <Image
                      src="Image/insurance3.jpg"
                      alt="insurance"
                      width={['700px', '400px', '500px', '700px']}
                      boxSize={['250px', '230px', '260px', '300px']}
                      objectFit="cover"
                    />
                  </Box>
                  {!isAdminLogin && (
                    <>
                      <Text
                        fontWeight="bold"
                        color="white"
                        textAlign="center"
                        fontSize={20}
                      >
                        If you don't have an account?
                      </Text>
                      <Box textAlign="center">
                        <Button
                          bg="#114684"
                          color="white"
                          variant="outline"
                          _hover={{ bg: '#114684' }}
                          onClick={OnHandelUser}
                          mb={5}
                        >
                          Create An Account
                        </Button>
                      </Box>
                    </>
                  )}
                </Box>

                <Container
                  textAlign="center"
                  w={formWidth}
                  boxShadow=" rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 1.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset"
                  h="auto"
                  margin="auto"
                  p="18px"
                  borderRadius="20px"
                  display={'flex'}
                  flexDir={'column'}
                  gap={'0.1rem'}
                >
                  <Text
                    fontWeight="bold"
                    color="#114684"
                    textAlign="start"
                    fontSize={28}
                  >
                    Sign In
                  </Text>

                  <Box>
                    <form
                      onSubmit={formik.handleSubmit}
                      autoComplete="on"
                      style={{
                        display: 'flex',
                        gap: '10px',
                        flexDirection: 'column',
                      }}
                    >
                      {!isAdminLogin && (
                        <>
                          <Text
                            fontWeight="bold"
                            color="#114684"
                            display="flex"
                            justifyContent="start"
                            textAlign="center"
                            fontSize={20}
                          >
                            Select Your Account
                          </Text>

                          <RadioGroup
                            onChange={(value) =>
                              formik.setFieldValue('userType', value)
                            }
                            defaultValue={formik.values.userType}
                            name="userType"
                          >
                            <Stack
                              direction="row"
                              color="#114684"
                              fontWeight="500"
                              flexWrap="wrap"
                            >
                              <Radio value="applicant">Applicant</Radio>
                              <Radio value="broker">Broker</Radio>
                              <Radio value="brokerage">Brokerage</Radio>
                            </Stack>
                          </RadioGroup>
                          {formik.touched.userType &&
                            formik.errors.userType && (
                              <div
                                style={{
                                  color: 'red',
                                  fontSize: '15px',
                                  textAlign: 'start',
                                  fontWeight: '500',
                                }}
                              >
                                {formik.errors.userType}
                              </div>
                            )}
                        </>
                      )}
                      <Box display="flex" flexDirection={'column'}>
                        <Text
                          fontWeight="bold"
                          color="#114684"
                          display="flex"
                          justifyContent="start"
                          textAlign="center"
                          fontSize={20}
                        >
                          Email Address
                        </Text>

                        <Box display="flex">
                          <Box
                            mr={2}
                            fontSize={25}
                            color="#114684"
                            m={0}
                            borderRadius="5px 0px 0px 5px"
                            backgroundColor="#f0f5f9"
                          >
                            <MdEmail
                              style={{
                                marginTop: '7px',
                                marginLeft: '6px',
                                marginRight: '6px',
                              }}
                            />
                          </Box>

                          <Input
                            ml="-2px"
                            fontSize="lg"
                            border="none"
                            backgroundColor="#f0f5f9"
                            focusBorderColor="transparent"
                            w="100%"
                            name="email"
                            type="email"
                            placeholder="Email Address"
                            borderRadius="0px 5px 5px 0px"
                            fontFamily='"Montserrat", sans-se"'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            _hover={{ borderColor: '#114684' }}
                          />
                        </Box>
                        {formik.touched.email && formik.errors.email && (
                          <div
                            style={{
                              color: 'red',
                              fontSize: '15px',
                              textAlign: 'start',
                              fontWeight: '500',
                            }}
                          >
                            {formik.errors.email}
                          </div>
                        )}
                      </Box>
                      <Box display="flex" flexDirection={'column'}>
                        <Text
                          fontWeight="bold"
                          color="#114684"
                          display="flex"
                          justifyContent="start"
                          textAlign="center"
                          fontSize={20}
                        >
                          Password
                        </Text>

                        <Box display="flex">
                          <Box
                            mr={2}
                            fontSize={25}
                            color="#114684"
                            m={0}
                            borderRadius="5px 0px 0px 5px"
                            backgroundColor="#f0f5f9"
                          >
                            <RiLockPasswordFill
                              style={{
                                marginTop: '7px',
                                marginLeft: '6px',
                                marginRight: '6px',
                              }}
                            />
                          </Box>
                          <Input
                            ml="-2px"
                            _hover={{ borderColor: '#114684' }}
                            border="none"
                            backgroundColor="#f0f5f9"
                            focusBorderColor="transparent"
                            w="100%"
                            name="password"
                            fontSize="lg"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                            type={showPassword ? 'text' : 'password'}
                            placeholder="Password"
                            borderRadius="0px"
                          />
                          <IconButton
                            aria-label={
                              showPassword ? 'Hide password' : 'Show password'
                            }
                            icon={
                              showPassword ? (
                                <MdVisibilityOff />
                              ) : (
                                <MdVisibility />
                              )
                            }
                            onClick={handleTogglePassword}
                            backgroundColor="#f0f5f9"
                            borderRadius="0px 5px 5px 0px"
                            ml="-2px"
                            bg="#f0f5f9"
                            _hover={{ bg: '#f0f5f9' }}
                            color="#114684"
                            fontSize={25}
                          />
                        </Box>
                        {formik.touched.password && formik.errors.password && (
                          <div
                            style={{
                              color: 'red',
                              fontSize: '15px',
                              textAlign: 'start',
                              fontWeight: '500',
                            }}
                          >
                            {formik.errors.password}
                          </div>
                        )}
                      </Box>
                      <Flex justifyContent="space-between">
                        <Box display="flex" justifyContent="start">
                          <Checkbox
                            colorScheme="yellow"
                            name="remember"
                            color="#fbb03b"
                            borderColor="#fbb03b"
                            defaultChecked={formik.values.remember}
                            onChange={(e) => {
                              formik.setFieldValue(
                                'remember',
                                e.target.checked
                              );
                              handleRememberMe(e);
                            }}
                          >
                            Remember Me
                          </Checkbox>
                        </Box>

                        {!isAdminLogin && (
                          <Box display="flex" justifyContent="end">
                            <Text
                              color="#fbb03b"
                              onClick={OnHandelForget}
                              cursor={'pointer'}
                            >
                              Forgot Password
                            </Text>
                          </Box>
                        )}
                      </Flex>

                      <Box>
                        <Button
                          isLoading={
                            auth.status === 'loading' &&
                            (auth.type === 'GET_ISLOGIN' ||
                              auth.type === 'GET_ISLOGIN_ADMIN')
                          }
                          loadingText="Sign In"
                          type="submit"
                          bg="#114684"
                          color="white"
                          w="100%"
                          _hover={{ bg: '#114684', color: 'white' }}
                        >
                          Sign In
                        </Button>
                      </Box>

                      {!isAdminLogin && (
                        <Text
                          fontWeight={500}
                          color="#fbb03b"
                          textAlign="center"
                          fontSize={16}
                        >
                          Don't have an account ?{' '}
                          <Link fontWeight="bold" onClick={OnHandelUser}>
                            Sign Up
                          </Link>
                        </Text>
                      )}
                    </form>
                  </Box>
                </Container>
              </Flex>
            </Container>
          </Flex>
        </Box>
      )}
    </>
  );
}

export default Login;
