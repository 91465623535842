import { REPORT_NAME } from 'src/types/enums';
import { APIStatus, REPORT_SHORTFORM, REPORT_TYPE } from 'src/types/unions';
import { IWIPData } from '../WorkInProgress/state';
import { TWICData } from '../WorkingCapital/state';

type AdaData = {
  Total?: { ada: any; amountOrPerc: any };
  '1 - 30'?: { ada: any; amountOrPerc: any };
  '31 - 60'?: { ada: any; amountOrPerc: any };
  '61 - 90'?: { ada: any; amountOrPerc: any };
  Current?: { ada: any; amountOrPerc: any };
  '91 and over'?: { ada: any; amountOrPerc: any };
  adaType?: any;
  valueType?: any;
  CURRENT?: { ada: any; amountOrPerc: any };
  '91 AND OVER'?: { ada: any; amountOrPerc: any };
  TOTAL?: { ada: any; amountOrPerc: any };
};

type AdjustedTotal = {
  Total?: any;
  '1 - 30'?: any;
  '31 - 60'?: any;
  '61 - 90'?: any;
  Current?: any;
  '91 and over'?: any;
  CURRENT?: any;
  '91 AND OVER'?: any;
  TOTAL?: any;
};

type ReportInfo = Record<string, any>;
type ReportData = Record<string, any>[];

export type OCRReport = {
  id: number;
  applicantId: number;
  reportInfo: ReportInfo;
  ocrReportData: ReportData;
  reportLinks?: Record<string, any>[];
  updatedInfo?: string;
  reportUploadedBy?: any;
  reportGeneratedBy?: number;
  isApproved?: boolean;
  isAssigned?: boolean;
  isOcrReportValid?: boolean;
  validateBy?: string;
  createdAt?: string;
  updatedAt?: string | null;
  deletedAt?: string | null;
  assignedAt?: string | null;
  validatedAt?: string;
  reportReviewLink?: string;
  isDeleted?: boolean;
  history?: Record<string, any>[];
  reportType: 'OCR';
  adaData?: AdaData;
  adjustedTotal?: AdjustedTotal;
};

type QBOReport = {
  id: number;
  applicantId: number;
  reportName: string;
  reportInfo: ReportInfo;
  reportData: ReportData;
  adaData?: AdaData;
  adjustedTotal?: AdjustedTotal;
  reportType: 'QBO';
  lastUpdateAt?: string;
  createdAt?: string;
  updatedAt?: string;
};

type ResData = OCRReport | QBOReport;
export interface IReportStatus {
  shortForm: REPORT_SHORTFORM;
  reportName: REPORT_NAME;
  status: boolean;
}

export interface APAgingResData {
  reportType: TReportType;
  reports: ResData;
}

interface APAgingSummary {
  status: APIStatus | null;
  error: any;
  data: APAgingResData | null;
  type?:
    | 'GET_ACCOUNT_PAYABLE_DATA'
    | 'GET_ACCOUNT_RECEIVABLE_DATA'
    | 'GET_PROFIT_LOSS_DATA'
    | 'GET_BALANCE_SHEET_DATA'
    | 'UPDATE_ACCOUNT_RECEIVABLE_RECORD'
    | 'REFRESH_AR_REPORT'
    | 'REFRESH_AP_REPORT'
    | 'REFRESH_BS_REPORT'
    | 'REFRESH_PL_REPORT'
    | 'GET_ALL_REPORTS'
    | 'GET_REPORTS_STATUS'
    | 'GET_REPORT_DATE_LIST';
  allReportsData?: IReport[];
  reportsStatus?: IReportStatus[];
  reportsDateList?: {
    reports: Record<string, any>[];
  };
  errorResponse?: Record<string, any> | null;
}

export type APReportDataType = {
  Vendor?: string;
  Customer?: string;
  Current: string;
  '1 - 30': string;
  '31 - 60': string;
  '61 - 90': string;
  '91 and over': string;
  Total: string;
};

export type TFilterOCRReport =
  | 'Most Recent Interim Financial Statement'
  | 'Most Recent Fiscal Year End'
  | '1-Year Previous Fiscal Year End'
  | '2-Year Previous Fiscal Year End'
  | 'Other';

export type TFilterAccountPayable =
  | 'Last Fiscal Year'
  | 'This Fiscal Year-to-date'
  | 'Historical';

export type TReportType = 'OCR' | 'QBO';
export type TReportTimeFrame = TFilterOCRReport | TFilterAccountPayable;

type updatedARData = {
  question: string;
  holdbackRetention: number | null;
  retentionValue: number | null;
  id: number;
};

export interface IReportInfo {
  filter: TFilterAccountPayable;
  reportName: REPORT_TYPE;
  qboCompanyName: string;
  reportGeneratedDate: string;
  startPeriod?: string;
  reportAsOf?: string;
}

export interface IBaseData {
  id: number;
  applicantId: number;
  reportName: REPORT_TYPE;
  reportInfo: IReportInfo;
  reportData: Record<string, any>[];
  lastUpdateAt: string;
  createdAt: string;
  updatedAt: string;
  adaData?: Record<string, any>;
  adjustedTotal?: Record<string, any>;
}
export interface IReport {
  reportName: REPORT_TYPE;
  TFYD?: IBaseData | TWICData;
  LFY?: IBaseData | TWICData;
  data?: IWIPData;
}

export type updateARRecordType = {
  id: number | null;
  updatedData?: updatedARData[];
  adaData?: Record<string, any>;
  adjustedTotal?: Record<string, any>;
};

const initialState: APAgingSummary = {
  error: null,
  status: null,
  data: null,
};

export default initialState;
