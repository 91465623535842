export interface BondApplicationAttributes {
  attention?: string | null; // Manual Input by broker, allows default favorites, editable
  finalBondNumber?: string | null; // Manual Input by broker, editable
  brokerName: string | null; // Automatically populated
  brokerPhone: string | null; // Automatically populated
  brokerEmail: string | null; // Automatically populated
  applicantName: string | null; // Automatically populated
  applicantPhone: string | null; // Automatically populated
  applicantEmail: string | null; // Automatically populated
  dateOfRequisition: string; // Date application was created
  dateRequired?: Date | null; // Manual Input or pulled from the RFP tool
  generalContractorName?: string | null; // Manual Input or pulled from Subtraid
  generalContractorPhone?: string | null; // Manual Input or pulled from Subtraid
  generalContractorEmail?: string | null; // Manual Input or pulled from Subtraid
  engineerOrArchitectName?: string | null; // Manual Input or pulled from Subtraid
  engineerOrArchitectPhone?: string | null; // Manual Input or pulled from Subtraid
  engineerOrArchitectEmail?: string | null; // Manual Input or pulled from Subtraid
  ownerName?: string | null; // Manual Input or pulled from RFP tool
  ownerPhone?: string | null; // Manual Input or pulled from RFP tool
  ownerEmail?: string | null; // Manual Input or pulled from RFP tool
  typeOfOwner?: TypeOfOwner | null; // Same as in the insurance application
  typeOfOwnerOther?: string | null; // Same as in the insurance application
  typeOfWork?: TypeOfWork[] | null; // Same as in the insurance application
  typeOfWorkOther?: string | null; // Same as in the insurance application
  workOnHandsAsOfDate?: string | null; // Manual Input
  totalBondedJobs?: ProjectTotals; // Data from WIP report
  totalUnbondedJobs?: ProjectTotals; // Data from WIP report
  totalOutstandingBids?: ProjectTotals; // Manual Input
  totalSum?: ProjectTotals; // Calculated from bonded, unbonded, and outstanding bids // Type of work
  projectName?: string | null; // Details of the project
  projectLocation?: string | null; // Details of the project
  projectLocationCountry?: string | null; // Details of the projectLocationCountry
  projectStartDate?: Date | null; // Details of the project
  projectEndDate?: Date | null; // Details of the project
  uploadContract?: fileUpload[] | null; // Yes or No
  uploadContractAttachments: File[] | null;
  contractDetails?: ContractDetails | null; // Contract details for the project
  contractDetailsOther?: string | null; // Manual Input or pulled from the RFP tool
  privateOwnersClause?: boolean | null; // Manual Input or pulled from the RFP tool
  privateOwnersClauseReferences: fileUpload[] | null;
  privateOwnersClauseAttachment?: File[] | null; // Manual Input or pulled from the RFP tool
  contractType?: ContractType | null; // Contract type
  estimatedSubletBudget?: number | null; // Estimated sublet budget
  workSublet?: WorkSublet[]; // If applicable, major subcontractors
  paymentTerms?: PaymentTerms; // Payment schedule
  holdback?: boolean | null; // Holdback percentage
  holdbackPercentage?: number | null; // Manual Input or pulled from the RFP tool
  architectEstimate?: number | null; // Manual Input or pulled from the RFP tool
  maintenanceWarrantyTerm?: number | null; // Input number of years
  penaltiesInContract?: boolean | null; // Penalty details in the contract
  penaltyContranctReferences?: fileUpload[] | null; // Attach contract references
  penaltyContranctReferencesAttachments?: File[] | null; // Attach contract references
  arePenaltiesCapped?: boolean | null; // Yes or No
  liquidatedDamages?: boolean | null; // Yes or No
  liquidatedDamagesReferences?: fileUpload[] | null; // Attach contract references
  liquidatedDamagesReferencesAttachments?: File[] | null; // Attach contract references
  liquidatedDamagesCapped?: boolean | null; // Yes or No
  environmentalHazards?: boolean | null; // Yes or No
  designResponsibility?: boolean | null; // Design-related details
  designResponsibilityReferences?: fileUpload[] | null; // Attach contract references
  designResponsibilityAttachments?: File[] | null; // Attach contract references
  bondForm?: BondForm; // Bond form details for Canada or the U.S.
  bondApplicationType: TApplicationtype; // Type of application
  bidBondDetails?: BidBondDetails; // Details if Bid Bond is selected
  bidBondDetailsUpload?: File[] | null;
  preQualificationLetterAttachments?: File[] | null; // Attached document
  finalBondDetails?: FinalBondDetails; // Details if Final Bond is selected
  premiumCalculation?: string | null; // Premium calculation description
  // approvedDate?: Date | null; // Date of approval
  // approver?: string | null; // Name of the approver
  // approvalStamp?: string | null; // Approval stamp (if electronic)
  consentOfSurety?: boolean | null; // Yes or No
  isNegotiated?: boolean | null;
  tenderResults?: {
    bidders?: Bidder[];
  };
  // bondNumber: number | null;
  applicantId: number | null;
  // brokerId: number | null;
  // createdBy: number | null;
  // createdByType: string | null;
  // createdAt: Date | null;
  status: bondApplicationStatus;
  assignedBy: number | null;
  assignedTo: number | null;
  assignedAt: Date | null;
  isRejected: number;
  isCreatedFromBidBond: boolean;
  bondType: BondTypes[] | null;
  projectDescription?: string | null;
}

export type TypeOfOwner =
  | 'Government'
  | 'Institutions'
  | 'Utilities'
  | 'General Contractors'
  | 'Developers'
  | 'Private Residential'
  | 'Private Companies'
  | 'Others';

export type TypeOfWork =
  | 'General Building'
  | 'Paving'
  | 'Electrical'
  | 'Excavation'
  | 'Concrete'
  | 'Framing'
  | 'Roofing/Siding/Sheet metal work'
  | 'Plumbing'
  | 'HVAC'
  | 'Carpentry'
  | 'Masonry/Stonework'
  | 'Plastering'
  | 'Painting/ Paper hanging'
  | 'Tile Setting'
  | 'Others';

export type bondApplicationStatus =
  | 'pending'
  | 'completed'
  | 'approved'
  | 'rejected';

export type TApplicationtype = 'Bid' | 'Final';

export const enum TApplicationTypeEnum {
  Bid = 'Bid',
  Fianl = 'Fianl',
}

interface ProjectTotals {
  contractPrice?: number | null;
  costToComplete?: number | null;
}

export type ContractDetails =
  | 'CCDC'
  | 'AIA'
  | 'Consensus Docs'
  | 'EJCDC'
  | 'Other';

export type ContractType =
  | 'Fixed Price'
  | 'Lump Sum'
  | 'Guaranteed Maximum Price'
  | 'Cost Plus'
  | 'Unit Price'
  | 'Construction Management';

interface WorkSublet {
  name: string | null;
  amount: number | null;
  bonded: boolean | null;
}

type PaymentTerms = 'Monthly' | 'Milestone' | 'Other';
interface BondForm {
  bondFormValue?: bondFormValue | null;
  otherDetails?: string | null; // Attach bond form if "Other"
}

export type bondFormValue =
  | 'CCDC'
  | 'Federal'
  | 'Provincial'
  | 'AIA'
  | 'Consensus Docs'
  | 'Federal'
  | 'State'
  | 'Other';

export type BondTypes =
  | 'Performance Bond'
  | 'Payment Bond'
  | 'Maintenance Bond'
  | 'Supply Bond'
  | 'Subdivision Bond'
  | 'License and Permit Bond'
  | 'Environmental or Reclamation Bond'
  | 'Completion Bond'
  | 'Site Improvement Bond'
  | 'Material Bond';

interface BidBondDetails {
  estimatedContractPrice: number | null;
  tenderDate: Date | null;
  bidBondAmount: number | null;
  acceptancePeriod: number | null;
  isPreQualificationLetter?: boolean | null;
  preQualificationLetter?: fileUpload[] | null; // Attached document
  // finalBondsRequired?: FinalBonds;
  additionalComments?: string | null;
  attachments?: fileUpload[] | null;
  performanceBondAmount?: number | null;
  performanceBondPercentage?: number | null;
  paymentBondAmount?: number | null;
  paymentBondPercentage?: number | null;
  maintenanceBondAmount?: number | null;
  supplyBondAmount?: number | null;
  subdivisionBondAmount?: number | null;
  licenseAndPermitBondAmount?: number | null;
  environmentalOrReclamationBondAmount?: number | null;
  completionBondAmount?: number | null;
  siteImprovementBondAmount?: number | null;
  materialBondAmount?: number | null;
  materialBondPercentage?: number | null;
  completionBondPercentage?: number | null;
  siteImprovementBondPercentage?: number | null;
  subdivisionBondPercentage?: number | null;
  licenseAndPermitBondPercentage?: number | null;
  environmentalOrReclamationBondPercentage?: number | null;
  maintenanceBondPercentage?: number | null;
  supplyBondPercentage?: number | null;
  isFinalBondRequired: boolean | null;
}

interface FinalBondDetails {
  contractPrice: number | null;
  includesTaxes: boolean | null;
  taxType?: taxType | null;
  contractDate: Date | null;
  performanceBondAmount: number | null;
  paymentBondAmount: number | null;
  maintenanceBondAmount: number | null;
  supplyBondAmount: number | null;
  subdivisionBondAmount: number | null;
  licenseAndPermitBondAmount: number | null;
  environmentalOrReclamationBondAmount: number | null;
  completionBondAmount: number | null;
  siteImprovementBondAmount: number | null;
  materialBondAmount: number | null;
}

export type taxType = 'Federal' | 'Provincial' | 'State' | 'Local';
interface FinalBonds {
  performance: number | null;
  payment: number | null;
  maintenance: number | null;
}

interface Bidder {
  companyName: string | null;
  amount: number | null;
}

export interface fileUpload {
  linkId: string | null;
  link: string | null;
  urlKey: string | null;
}

// initial state

export const initialBondApplicationState: BondApplicationAttributes = {
  attention: null,
  finalBondNumber: null,
  brokerName: null,
  brokerPhone: null,
  brokerEmail: null,
  applicantName: null,
  applicantPhone: null,
  applicantEmail: null,
  dateOfRequisition: new Date().toISOString(),
  dateRequired: null,
  generalContractorName: null,
  generalContractorPhone: null,
  generalContractorEmail: null,
  engineerOrArchitectName: null,
  engineerOrArchitectPhone: null,
  engineerOrArchitectEmail: null,
  ownerName: null,
  ownerPhone: null,
  ownerEmail: null,
  typeOfOwner: null,
  typeOfWork: null,
  typeOfOwnerOther: null,
  typeOfWorkOther: null,
  workOnHandsAsOfDate: null,
  totalBondedJobs: {
    contractPrice: null,
    costToComplete: null,
  },
  totalUnbondedJobs: {
    contractPrice: null,
    costToComplete: null,
  },
  totalOutstandingBids: {
    contractPrice: null,
    costToComplete: null,
  },
  totalSum: {
    contractPrice: null,
    costToComplete: null,
  },
  projectName: null,
  projectLocation: null,
  projectLocationCountry: null,
  projectStartDate: null,
  projectEndDate: null,
  uploadContract: null,
  uploadContractAttachments: null,
  contractDetails: null,
  contractDetailsOther: null,
  privateOwnersClause: null,
  privateOwnersClauseReferences: null,
  privateOwnersClauseAttachment: null,
  contractType: null,
  estimatedSubletBudget: null,
  workSublet: [
    {
      name: null,
      amount: null,
      bonded: null,
    },
  ],
  paymentTerms: 'Monthly',
  holdback: null,
  holdbackPercentage: null,
  architectEstimate: null,
  maintenanceWarrantyTerm: null,
  penaltiesInContract: null,
  penaltyContranctReferences: null,
  penaltyContranctReferencesAttachments: null,
  arePenaltiesCapped: null,
  liquidatedDamages: null,
  liquidatedDamagesReferences: null,
  liquidatedDamagesCapped: null,
  environmentalHazards: null,
  designResponsibility: null,
  designResponsibilityReferences: null,
  designResponsibilityAttachments: null,
  bondForm: {
    bondFormValue: null,
    otherDetails: '',
  },
  bondApplicationType: 'Bid',
  bondType: null,
  bidBondDetails: {
    estimatedContractPrice: null,
    tenderDate: null,
    bidBondAmount: null,
    acceptancePeriod: null,
    preQualificationLetter: null,
    isPreQualificationLetter: null,
    additionalComments: null,
    attachments: [],
    performanceBondAmount: null,
    performanceBondPercentage: null,
    paymentBondAmount: null,
    paymentBondPercentage: null,
    maintenanceBondAmount: null,
    supplyBondAmount: null,
    subdivisionBondAmount: null,
    licenseAndPermitBondAmount: null,
    environmentalOrReclamationBondAmount: null,
    completionBondAmount: null,
    siteImprovementBondAmount: null,
    materialBondAmount: null,
    materialBondPercentage: null,
    completionBondPercentage: null,
    siteImprovementBondPercentage: null,
    subdivisionBondPercentage: null,
    licenseAndPermitBondPercentage: null,
    environmentalOrReclamationBondPercentage: null,
    maintenanceBondPercentage: null,
    supplyBondPercentage: null,
    isFinalBondRequired: null,
  },
  bidBondDetailsUpload: null,
  preQualificationLetterAttachments: null,
  finalBondDetails: {
    contractPrice: null,
    includesTaxes: null,
    taxType: null,
    contractDate: null,
    performanceBondAmount: null,
    paymentBondAmount: null,
    maintenanceBondAmount: null,
    supplyBondAmount: null,
    subdivisionBondAmount: null,
    licenseAndPermitBondAmount: null,
    environmentalOrReclamationBondAmount: null,
    completionBondAmount: null,
    siteImprovementBondAmount: null,
    materialBondAmount: null,
  },
  premiumCalculation: null,
  // approvedDate: null,
  // approver: null,
  // approvalStamp: null,
  consentOfSurety: null,
  isNegotiated: null,
  tenderResults: {
    bidders: [
      {
        companyName: null,
        amount: null,
      },
    ],
  },
  // bondNumber: null,
  applicantId: null,
  // brokerId: null,
  // createdBy: null,
  // createdByType: null,
  // createdAt: null,
  status: 'pending',
  assignedBy: null,
  assignedTo: null,
  assignedAt: null,
  isRejected: 0,
  isCreatedFromBidBond: false,
  projectDescription: null,
};

//dropdown options

export const contractTypeOptions = [
  {
    value: null,
    label: 'Select Option',
  },
  {
    value: 'Fixed Price',
    label: 'Fixed Price',
  },
  {
    value: 'Lump Sum',
    label: 'Lump Sum',
  },
  {
    value: 'Guaranteed Maximum Price',
    label: 'Guaranteed Maximum Price',
  },
  {
    value: 'Cost Plus',
    label: 'Cost Plus',
  },
  {
    value: 'Unit Price',
    label: 'Unit Price',
  },
  {
    value: 'Construction Management',
    label: 'Construction Management',
  },
];

export const paymentTermsOptions = [
  {
    value: 'Monthly',
    label: 'Monthly',
  },
  {
    value: 'Milestone',
    label: 'Milestone',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];

export const contractDetailsOtherOption = [
  {
    value: 'Other',
    label: 'Other',
  },
];

export const contractDetailsCanadaOptions = [
  {
    value: 'CCDC',
    label: 'Canadian Construction Documents Committee',
  },
  ...contractDetailsOtherOption,
];

export const contractDetailsUSOptions = [
  {
    value: 'AIA',
    label: 'American Institute of Architects',
  },
  {
    value: 'Consensus Docs',
    label: 'Consensus Docs',
  },
  {
    value: 'EJCDC',
    label: 'Engineers Joint Contract Documents Committee',
  },
  ...contractDetailsOtherOption,
];

export const bondFormOtherOption = [
  {
    value: 'Other',
    label: 'Other',
  },
];

export const bondFormCanadaOptions = [
  {
    value: 'CCDC',
    label: 'Canadian Construction Documents Committee',
  },
  {
    value: 'Federal',
    label: 'Federal',
  },
  {
    value: 'Provincial',
    label: 'Provincial',
  },
  ...bondFormOtherOption,
];

export const bondFormUSOptions = [
  {
    value: 'AIA',
    label: 'American Institute of Architects',
  },
  {
    value: 'Consensus Docs',
    label: 'Consensus Docs',
  },
  {
    value: 'Federal',
    label: 'Federal',
  },
  {
    value: 'State',
    label: 'State',
  },
  ...bondFormOtherOption,
];

// bondApplicationType options
export const applicationTypeOptions = [
  {
    value: 'Bid',
    label: 'Bid Bond',
  },
  {
    value: 'Final',
    label: 'Final Bond',
  },
];

// taxType
export const taxTypeOptions = [
  {
    value: 'Federal',
    label: 'Federal',
  },
  {
    value: 'Local',
    label: 'Local',
  },
];

export const taxTypeCanadaOptions = [
  {
    value: 'Federal',
    label: 'Federal',
  },
  {
    value: 'Provincial',
    label: 'Provincial',
  },
  {
    value: 'Local',
    label: 'Local',
  },
];
export const taxTypeUsOptions = [
  {
    value: 'Federal',
    label: 'Federal',
  },
  {
    value: 'State',
    label: 'State',
  },
  {
    value: 'Local',
    label: 'Local',
  },
];

export const getBonfFormDownOption = (value: string) => {
  switch (value) {
    case 'Canada':
      return bondFormCanadaOptions;
    case 'United States':
      return bondFormUSOptions;
    default:
      return bondFormOtherOption;
  }
};

export const typeOfOwnerOptions = [
  { label: 'Government', value: 'Government' },
  { label: 'Institutions', value: 'Institutions' },
  { label: 'Utilities', value: 'Utilities' },
  { label: 'General Contractors', value: 'General Contractors' },
  { label: 'Developers', value: 'Developers' },
  { label: 'Private Residential', value: 'Private Residential' },
  { label: 'Private Companies', value: 'Private Companies' },
  { label: 'Others', value: 'Others' },
];

export const BondType = [
  {
    label: 'Performance Bond',
    value: 'Performance Bond',
    tooltip: 'Contract Bond, Performance Security, Completion Bond',
  },
  {
    label: 'Payment Bond',
    value: 'Payment Bond',
    tooltip: 'Labor and Material Payment Bond, Supply Bond, Materialmen’s Bond',
  },
  {
    label: 'Maintenance Bond',
    value: 'Maintenance Bond',
    tooltip: 'Warranty Bond, Defect Bond, Guarantee Bond',
  },
  {
    label: 'Supply Bond',
    value: 'Supply Bond',
    tooltip: 'Material Supply Bond, Supplier Bond, Material Delivery Bond',
  },
  {
    label: 'Subdivision Bond',
    value: 'Subdivision Bond',
    tooltip: 'Improvement Bond, Developer Bond, Land Development Bond',
  },
  {
    label: 'License and Permit Bond',
    value: 'License and Permit Bond',
    tooltip: 'Compliance Bond, Regulatory Bond, Code Compliance Bond',
  },
  {
    label: 'Environmental or Reclamation Bond',
    value: 'Environmental or Reclamation Bond',
    tooltip:
      'Restoration Bond, Environmental Protection Bond, Mine Reclamation Bond',
  },
  {
    label: 'Completion Bond',
    value: 'Completion Bond',
    tooltip: 'Project Completion Bond, Contract Completion Bond',
  },
  {
    label: 'Site Improvement Bond',
    value: 'Site Improvement Bond',
    tooltip: 'Off-Site Improvement Bond, Private Improvement Bond',
  },
  {
    label: 'Material Bond',
    value: 'Material Bond',
    tooltip:
      'Materialmen’s Bond, Supplier Payment Bond, Material Delivery Bond',
  },
];

export const bondTypeMap: Record<string, string> = {
  'Performance Bond': 'performanceBond',
  'Payment Bond': 'paymentBond',
  'Site Improvement Bond': 'siteImprovementBond',
  'Environmental or Reclamation Bond': 'environmentalOrReclamationBond',
  'Completion Bond': 'completionBond',
  'Material Bond': 'materialBond',
  'Maintenance Bond': 'maintenanceBond',
  'Supply Bond': 'supplyBond',
  'Subdivision Bond': 'subdivisionBond',
  'License and Permit Bond': 'licenseAndPermitBond',
};

export const typeOfWorkOptions: { label: string; value: TypeOfWork }[] = [
  { label: 'General Building', value: 'General Building' },
  { label: 'Paving', value: 'Paving' },
  { label: 'Electrical', value: 'Electrical' },
  { label: 'Excavation', value: 'Excavation' },
  { label: 'Concrete', value: 'Concrete' },
  { label: 'Framing', value: 'Framing' },
  {
    label: 'Roofing/Siding/Sheet metal work',
    value: 'Roofing/Siding/Sheet metal work',
  },
  { label: 'Plumbing', value: 'Plumbing' },
  { label: 'HVAC', value: 'HVAC' },
  { label: 'Carpentry', value: 'Carpentry' },
  { label: 'Masonry/Stonework', value: 'Masonry/Stonework' },
  { label: 'Plastering', value: 'Plastering' },
  { label: 'Painting/ Paper hanging', value: 'Painting/ Paper hanging' },
  { label: 'Tile Setting', value: 'Tile Setting' },
  { label: 'Others', value: 'Others' },
];

export const getContractFormDownOption = (value: string) => {
  switch (value) {
    case 'Canada':
      return contractDetailsCanadaOptions;
    case 'United States':
      return contractDetailsUSOptions;
    default:
      return contractDetailsOtherOption;
  }
};
export const getTaxTypeDownOption = (value: string) => {
  switch (value) {
    case 'Canada':
      return taxTypeCanadaOptions;
    case 'United States':
      return taxTypeUsOptions;
    default:
      return taxTypeOptions;
  }
};

export const convertToYearsAndMonths = (n: number): string => {
  const years = Math.floor(n / 12); // Calculate the number of years
  const months = n % 12; // Calculate the remaining months

  // Build the result string dynamically
  const yearStr = years > 0 ? `${years} year${years > 1 ? 's' : ''}` : '';
  const monthStr = months > 0 ? `${months} month${months > 1 ? 's' : ''}` : '';

  // Combine year and month string properly
  return [yearStr, monthStr].filter(Boolean).join(' ');
};
