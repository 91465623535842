import {
  Box,
  Button,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Text,
  useToast,
} from '@chakra-ui/react';
import { Tooltip } from '@mantine/core';
import {
  ArrayHelpers,
  ErrorMessage,
  Field,
  FieldArray,
  FormikProvider,
  useFormik,
} from 'formik';
import _ from 'lodash';
import moment from 'moment';
import { DatePickerInput } from 'rc-datepicker';
import { useEffect, useRef, useState } from 'react';
import { FaDollarSign, FaRegCalendarAlt } from 'react-icons/fa';
import { IoMdClose, IoMdRemove } from 'react-icons/io';
import {
  addFocusEventListenerOnInput,
  amountOnly,
  preventAlphabetsTyping,
  removeFocusEventListenerOnInput,
} from 'src/utils/helpers';
import {
  manageBondRowYupSchema,
  manageBondYupSchema,
} from 'src/ValidationSchema/Bond/bond-facility-tracker';
import Swal from 'sweetalert2';
import {
  BondActivityBonds,
  BondEntry,
  bondTypes,
  initialBondEntry,
} from './model';

/**
 * Expiry Date Picker field
 * @param param0
 * @returns
 */
const DatePickerField = ({
  field,
  form,
  ...props
}: {
  field: any;
  form: any;
  props: any;
}) => {
  const rowExpiryDateRef = useRef<DatePickerInput>(null);

  return (
    <DatePickerInput
      {...field}
      {...props}
      value={field.value ? field.value : undefined}
      ref={rowExpiryDateRef}
      locale="en"
      placeholder="Expiry Date"
      className={`react-datepicker-component react-datepicker-input input gray-border`}
      showOnInputClick={true}
      onShow={() => addFocusEventListenerOnInput(rowExpiryDateRef)}
      onHide={() => removeFocusEventListenerOnInput(rowExpiryDateRef)}
      onChange={(date: Date | null) => {
        form.setFieldValue(field.name, date);
      }}
    />
  );
};

/**
 * Bond Amount field
 * @param param0
 * @returns
 */
const BondAmountField = ({
  field,
  form,
  ...props
}: {
  field: any;
  form: any;
  props: any;
}) => {
  return (
    <InputGroup width={'22%'} flexGrow={0} flexShrink={0}>
      <InputLeftElement pointerEvents="none">
        <FaDollarSign color="#babec1" />
      </InputLeftElement>
      <Input
        type="text"
        {...field}
        {...props}
        placeholder="Bond Amount"
        onKeyDown={amountOnly}
        value={field.value || ''}
      />
    </InputGroup>
  );
};

/**
 * Maintenence field initalization
 */
const MaintenanceField = ({
  field,
  form,
  ...props
}: {
  field: any;
  form: any;
  props: any;
}) => {
  return (
    <Tooltip
      label="Enter Maintenance Period in months. E.g., 18 = 18 months"
      position="top-start"
      withArrow
    >
      <Input
        type="text"
        {...field}
        {...props}
        placeholder="Maintenance Period"
        width={'11%'}
        onKeyDown={preventAlphabetsTyping}
      />
    </Tooltip>
  );
};

/**
 * Disabled expiry date field for Maintenence bond type
 * @param param0
 * @returns
 */
const DisabledExpiryDateField = ({
  field,
  form,
}: {
  field: any;
  form: any;
}) => {
  return (
    <Tooltip label="Expiry Date">
      <InputGroup width={'27%'}>
        <InputLeftElement pointerEvents="none">
          <FaRegCalendarAlt color="#babec1" />
        </InputLeftElement>
        <Input
          type="text"
          isDisabled
          value={field.value}
          placeholder="Expiry Date"
        />
      </InputGroup>
    </Tooltip>
  );
};

/**
 * Modal to add/edit/remove bond numbers
 * @param param0
 * @returns
 */
const ManageBonds = ({
  isOpen,
  onClose,
  bondsList,
  onSubmit,
  projectEndDate,
}: {
  isOpen: boolean;
  onClose: () => void;
  bondsList: BondActivityBonds[];
  onSubmit: any;
  projectEndDate: Date | null;
}) => {
  const [bondNumbersRowList, setBondNumbersRowList] = useState<
    BondActivityBonds[]
  >([]);
  const [bondsNumberList, setBondsNumberList] = useState<string[]>([]);
  const Toast = useToast();
  const [removedBondNos, setRemovedBondNos] = useState<string[]>([]);

  let boundArrayHelpers: ArrayHelpers;

  const bindArrayHelpers = (arrayHelpers: any) => {
    boundArrayHelpers = arrayHelpers;
  };

  useEffect(() => {
    setRemovedBondNos([]);
    const bondNum = bondsList.map((obj) => obj.number);

    setBondsNumberList(bondNum);

    setBondNumbersRowList(_.cloneDeep(bondsList));
    const deepCopy = _.cloneDeep(bondsList);
    let numberWithBondType: BondActivityBonds[] = deepCopy.filter(
      (obj) => obj.bonds.length > 0
    );
    numberWithBondType.forEach((obj: any) => {
      obj.bonds.forEach((item: any) => {
        if (item.maintenancePeriod && projectEndDate) {
          item.disabledExpiry = moment(projectEndDate)
            .add(item.maintenancePeriod, 'months')
            .format('YYYY-MMM-DD');
        }
      });
    });
    formikRowEntry.setFieldValue('bondType', numberWithBondType);
  }, [bondsList]);

  /**
   * Configure formik form to add Bond number and Bond type
   */
  const formikRowEntry = useFormik({
    initialValues: { bondType: [] },

    validationSchema: manageBondRowYupSchema,
    onSubmit: (values: any) => {
      const deepCopy = _.cloneDeep(values.bondType);
      setBondNumbersRowList(deepCopy);

      onSubmit(deepCopy, removedBondNos, true);
      formik.resetForm({
        values: {
          customBond: '',
          bondType: '',
          bondNo: '',
        },
      });

      onClose();
    },
  });

  /**
   * Configure formik form to add multiple bonds for each bond numbers
   */
  const formik = useFormik({
    initialValues: initialBondEntry,
    validationSchema: manageBondYupSchema,
    onSubmit: (values: BondEntry, { resetForm }) => {
      if (values.bondNo === '-addCustom-') {
        ///custom bond no add
        if (
          bondsNumberList.findIndex(
            (obj: string) => obj === values.customBond?.trim()
          ) === -1
        ) {
          boundArrayHelpers.push({
            number: values.customBond?.trim(),
            bonds: [
              {
                type: values.bondType,
                amount: null,
                expDate: null,
                maintenancePeriod: null,
              },
            ],
          });
          setBondsNumberList([values.customBond?.trim()!, ...bondsNumberList]);
          // resetForm();
          resetForm({
            values: {
              customBond: '',
              bondType: '',
              bondNo: '',
            },
          });
          Toast({
            title: 'Bond Number added',
            status: 'success',
            isClosable: true,
            duration: 1000,
            position: 'top',
          });
        } else {
          Swal.fire({
            title:
              "Bond No. '" +
              values.customBond +
              "' already exist. Select from Bond Number list.",
            icon: 'warning',
            showConfirmButton: true,
            customClass: {
              container: 'custom-swal',
            },
          });
        }
      } else {
        /// bond no selected from list

        let bondsdata = formikRowEntry.values.bondType;
        const existIdx = bondsdata.findIndex(
          (obj: BondActivityBonds) => obj.number === values.bondNo
        );
        if (existIdx !== -1) {
          //if index of bond no is found
          if (
            formikRowEntry.values.bondType[existIdx].bonds.find(
              (item: any) => item.type === values.bondType
            ) === undefined
          ) {
            ///check for duplicate bond type
            boundArrayHelpers.replace(existIdx, {
              number: values.bondNo,
              bonds: [
                ...formikRowEntry.values.bondType[existIdx].bonds,
                {
                  type: values.bondType,
                  amount: null,
                  expDate: null,
                  maintenancePeriod: null,
                },
              ],
            });
            resetForm({
              values: {
                customBond: '',
                bondType: '',
                bondNo: values.bondNo,
              },
            });
            Toast({
              title: 'Bond type added',
              status: 'success',
              isClosable: true,
              duration: 1000,
              position: 'top',
            });
          } else {
            Swal.fire({
              title:
                "'" +
                values.bondType +
                "' already exist in Bond No. '" +
                values.bondNo +
                "'.",
              icon: 'warning',
              showConfirmButton: true,
              customClass: {
                container: 'custom-swal',
              },
            });
          }
        } else {
          boundArrayHelpers.push({
            number: values.bondNo.trim(),
            bonds: [
              {
                type: values.bondType,
                amount: null,
                expDate: null,
                maintenancePeriod: null,
              },
            ],
          });

          resetForm({
            values: {
              customBond: '',
              bondType: '',
              bondNo: values.bondNo,
            },
          });
          Toast({
            title: 'Bond added',
            status: 'success',
            isClosable: true,
            duration: 1000,
            position: 'top',
          });
        }
      }
    },
  });

  /**
   * Close Manage Bonds modal
   */
  const handleClose = () => {
    const oldList = _.orderBy(
      _.map(bondNumbersRowList, ({ bonds, ...o }) => ({
        ...o,
        bonds: _.orderBy(bonds, 'type', 'asc'),
      })),
      'number',
      'asc'
    );
    const newList = _.orderBy(
      _.map(formikRowEntry.values.bondType, ({ bonds, ...o }) => ({
        ...o,
        bonds: _.orderBy(bonds, 'type', 'asc'),
      })),
      'number',
      'asc'
    );

    // console.log('old list=', oldList);
    // console.log('new list=', newList);
    const bondSetChanged = _.isEqual(oldList, newList); //compare old and new bond list before closing and get confirmation from user for save
    if (!bondSetChanged) {
      //if changed
      Swal.fire({
        title: 'The changes you made will not be saved. Do you want to exit?',
        icon: 'warning',
        showConfirmButton: true,
        customClass: {
          container: 'custom-swal',
        },
        showCancelButton: true,
        confirmButtonColor: '#DC3741',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed === true) {
          formik.resetForm({
            values: {
              customBond: '',
              bondType: '',
              bondNo: '',
            },
          });
          formikRowEntry.resetForm({
            values: { bondType: bondNumbersRowList },
          });
          onSubmit(bondNumbersRowList, [], false);
          onClose();
        }
      });
    } else {
      formikRowEntry.resetForm({
        values: { bondType: bondNumbersRowList },
      });
      onSubmit(bondNumbersRowList, [], false);
      onClose();
    }
  };
  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent minW={'60vw'} maxH={'90vh'}>
        <ModalHeader>Manage Bonds</ModalHeader>
        <ModalCloseButton onClick={handleClose} />
        <ModalBody overflowY={'auto'}>
          <Flex color={'#114684'} mb={'1.5em'} direction={'column'} gap={5}>
            <form>
              <Flex direction={'column'} gap={5}>
                <Flex gap={5}>
                  <Box w={'50%'}>
                    <Text fontWeight="600" fontSize={13} pb="5px">
                      Bond Number
                    </Text>
                    <Select
                      onChange={(e) => {
                        formik.setFieldValue('customBond', '');
                        formik.handleChange(e);
                      }}
                      name="bondNo"
                      value={formik.values.bondNo}
                    >
                      <option
                        value={''}
                        style={{ color: 'black' }}
                        label="Select Bond Number"
                      />
                      {bondsNumberList &&
                        bondsNumberList.map((ele?: string, index?: number) => (
                          <option
                            style={{ color: 'black' }}
                            key={index}
                            value={ele}
                            label={ele}
                          />
                        ))}
                      <option
                        value={'-addCustom-'}
                        style={{ color: 'black' }}
                        label="--- Add New ---"
                      />
                    </Select>
                    {formik.touched.bondNo && formik.errors.bondNo && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          textAlign: 'start',
                          fontWeight: '500',
                        }}
                      >
                        {formik.errors?.bondNo as string}
                      </div>
                    )}
                  </Box>
                  {formik.values.bondNo === '-addCustom-' && (
                    <Box w={'50%'}>
                      <Text fontWeight="600" fontSize={13} pb="5px">
                        New Bond Number
                      </Text>
                      <Input
                        type="text"
                        name="customBond"
                        value={formik.values.customBond}
                        placeholder="Bond Number"
                        onChange={formik.handleChange}
                      />
                      {formik.touched.customBond &&
                        formik.errors.customBond && (
                          <div
                            style={{
                              color: 'red',
                              fontSize: '13px',
                              textAlign: 'start',
                              fontWeight: '500',
                            }}
                          >
                            {formik.errors?.customBond as string}
                          </div>
                        )}
                    </Box>
                  )}
                </Flex>
                <Flex gap={5}>
                  <Box w={'30%'}>
                    <Text fontWeight="600" fontSize={13} pb="5px">
                      Bond Type
                    </Text>
                    <Select
                      onChange={formik.handleChange}
                      value={formik.values.bondType}
                      name="bondType"
                    >
                      <option
                        value={''}
                        style={{ color: 'black' }}
                        label="Select Bond Type"
                      />
                      {bondTypes.map((item, index) => (
                        <option
                          key={index}
                          value={item.value}
                          style={{ color: 'black' }}
                          label={item.label}
                        />
                      ))}
                    </Select>
                    {formik.touched.bondType && formik.errors.bondType && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          textAlign: 'start',
                          fontWeight: '500',
                        }}
                      >
                        {formik.errors?.bondType as string}
                      </div>
                    )}
                  </Box>

                  <Box width={'10%'} mt="24px">
                    <Button
                      onClick={() => {
                        formik.handleSubmit();
                      }}
                      backgroundColor={'#114684'}
                      color={'#ffffff'}
                      _hover={{ opacity: 0.8 }}
                    >
                      Add
                    </Button>
                  </Box>
                </Flex>
              </Flex>
            </form>

            <Box
              style={{
                border: '1px solid rgb(221 228 237)',
                borderRadius: '5px',
              }}
            >
              <FormikProvider value={formikRowEntry}>
                <FieldArray
                  name="bondType"
                  render={(bondArray) => {
                    bindArrayHelpers(bondArray);

                    return (
                      <Box>
                        {formikRowEntry.values.bondType.length > 0 &&
                          formikRowEntry.values.bondType.map(
                            (bondNo: BondActivityBonds, index: number) => {
                              return (
                                <Box
                                  key={index}
                                  borderBottom={'1px solid rgb(221 228 237)'}
                                >
                                  <Flex
                                    borderRadius={
                                      index === 0 ? '5px 5px 0 0' : undefined
                                    }
                                    padding={'5px 10px'}
                                    color={'#000000'}
                                    backgroundColor={'#f6f6f6'}
                                    gap={5}
                                    justifyContent={'space-between'}
                                    alignItems={'center'}
                                  >
                                    <Text fontWeight={600}>
                                      Bond No. {bondNo.number}
                                    </Text>
                                    <Tooltip label="Remove Bond No">
                                      <IconButton
                                        isRound={true}
                                        variant="solid"
                                        backgroundColor={'#114684'}
                                        size="sm"
                                        fontSize={'14px'}
                                        aria-label="Remove"
                                        _hover={{ opacity: 0.8 }}
                                        icon={<IoMdRemove color="#ffffff" />}
                                        onClick={() => {
                                          const bno =
                                            bondArray.form.values.bondType[
                                              index
                                            ].number;
                                          setRemovedBondNos((prev) => [
                                            ...prev,
                                            bondArray.form.values.bondType[
                                              index
                                            ].number,
                                          ]);
                                          bondArray.remove(index);

                                          setBondsNumberList((prev) =>
                                            prev.filter((obj) => obj !== bno)
                                          );
                                        }}
                                      />
                                    </Tooltip>
                                  </Flex>

                                  {bondNo.bonds.length > 0 &&
                                    bondNo.bonds.map((bonds, bondIndex) => (
                                      <Box key={bondIndex}>
                                        <Flex
                                          gap={5}
                                          alignItems={'center'}
                                          borderTop={
                                            '1px solid rgb(221 228 237)'
                                          }
                                          padding={'5px 0'}
                                        >
                                          <Text
                                            flexGrow={0}
                                            flexShrink={0}
                                            width={'30%'}
                                            pl={'20px'}
                                            fontWeight={600}
                                          >
                                            {bonds.type}
                                          </Text>
                                          <Field
                                            name={`bondType.${index}.bonds.${bondIndex}.amount`}
                                            component={BondAmountField}
                                          />
                                          {bonds.type !==
                                            'Maintenance Bond' && (
                                            <Field
                                              name={`bondType.${index}.bonds.${bondIndex}.expDate`}
                                              component={DatePickerField}
                                            />
                                          )}
                                          {bonds.type ===
                                            'Maintenance Bond' && (
                                            <Field
                                              name={`bondType.${index}.bonds.${bondIndex}.maintenancePeriod`}
                                              component={MaintenanceField}
                                              onChange={(e: any) => {
                                                if (
                                                  /^[1-9][0-9]*$/.test(
                                                    e.target.value
                                                  )
                                                ) {
                                                  if (projectEndDate) {
                                                    formikRowEntry.setFieldValue(
                                                      `bondType.${index}.bonds.${bondIndex}.disabledExpiry`,
                                                      moment(projectEndDate)
                                                        .add(
                                                          e.target.value,
                                                          'months'
                                                        )
                                                        .format('YYYY-MMM-DD')
                                                    );
                                                  }
                                                  formikRowEntry.setFieldValue(
                                                    `bondType.${index}.bonds.${bondIndex}.maintenancePeriod`,
                                                    e.target.value
                                                  );
                                                } else if (
                                                  e.target.value.trim() !== ''
                                                ) {
                                                  // formikRowEntry.setFieldValue(
                                                  //   `bondType.${index}.bonds.${bondIndex}.disabledExpiry`,
                                                  //   ''
                                                  // );
                                                  formikRowEntry.setFieldError(
                                                    `bondType.${index}.bonds.${bondIndex}.maintenancePeriod`,
                                                    'Enter Maintenance Period in months. E.g., 18 = 18 months'
                                                  );
                                                } else {
                                                  formikRowEntry.setFieldValue(
                                                    `bondType.${index}.bonds.${bondIndex}.maintenancePeriod`,
                                                    e.target.value
                                                  );
                                                  formikRowEntry.setFieldValue(
                                                    `bondType.${index}.bonds.${bondIndex}.disabledExpiry`,
                                                    ''
                                                  );
                                                }
                                              }}
                                            />
                                          )}
                                          {bonds.type ===
                                            'Maintenance Bond' && (
                                            <Field
                                              name={`bondType.${index}.bonds.${bondIndex}.disabledExpiry`}
                                              component={
                                                DisabledExpiryDateField
                                              }
                                            />
                                          )}
                                          <Spacer></Spacer>
                                          <Box
                                            w={'10%'}
                                            textAlign={'center'}
                                            flexGrow={0}
                                            flexShrink={0}
                                          >
                                            <IconButton
                                              isRound={true}
                                              variant="ghost"
                                              size="sm"
                                              fontSize={'14px'}
                                              aria-label="Remove"
                                              icon={<IoMdClose />}
                                              onClick={() => {
                                                bondArray.form.values.bondType[
                                                  index
                                                ].bonds.splice(bondIndex, 1);

                                                if (
                                                  bondArray.form.values
                                                    .bondType[index].bonds
                                                    .length === 0
                                                )
                                                  bondArray.remove(index);
                                                else {
                                                  bondArray.replace(
                                                    index,
                                                    bondArray.form.values
                                                      .bondType[index]
                                                  );
                                                }
                                              }}
                                            />
                                          </Box>
                                        </Flex>
                                        <Flex gap={5}>
                                          <Box
                                            flexGrow={0}
                                            flexShrink={0}
                                            width={'30%'}
                                          ></Box>
                                          <Box
                                            flexGrow={0}
                                            flexShrink={0}
                                            width={'22%'}
                                          >
                                            <ErrorMessage
                                              className="bond-field-error"
                                              component="div"
                                              name={`bondType.${index}.bonds.${bondIndex}.amount`}
                                            />
                                          </Box>

                                          {bonds.type !==
                                            'Maintenance Bond' && (
                                            <Box
                                              flexGrow={0}
                                              flexShrink={0}
                                              width={'38%'}
                                            >
                                              <ErrorMessage
                                                className="bond-field-error"
                                                component="div"
                                                name={`bondType.${index}.bonds.${bondIndex}.expDate`}
                                              />
                                            </Box>
                                          )}

                                          {bonds.type ===
                                            'Maintenance Bond' && (
                                            <Box
                                              flexGrow={0}
                                              flexShrink={0}
                                              width={'38%'}
                                            >
                                              <ErrorMessage
                                                className="bond-field-error"
                                                component="div"
                                                name={`bondType.${index}.bonds.${bondIndex}.maintenancePeriod`}
                                              />
                                            </Box>
                                          )}
                                        </Flex>
                                      </Box>
                                    ))}
                                </Box>
                              );
                            }
                          )}
                      </Box>
                    );
                  }}
                />
              </FormikProvider>
            </Box>
          </Flex>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" type="button" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            colorScheme="blue"
            ml={3}
            type="button"
            onClick={() => {
              formikRowEntry.handleSubmit();
            }}
          >
            OK
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ManageBonds;
