import * as Yup from 'yup';

export const UserLoginSchema = Yup.object({
  userType: Yup.string()
    .oneOf(
      ['applicant', 'broker', 'brokerage', 'admin'],
      'Please select a user type'
    )
    .required('User type is required'),

  email: Yup.string()
    .trim()
    .max(255, 'Email must not be greater than 255 characters')
    .matches(
      /^[^\s@]+@[^\s@]+\.(?:com|tech|io|in|net|org|com\.au)$/,
      'Email must be a valid email address'
    )
    .required('Email address is required'),
  password: Yup.string()
    .trim()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
    .max(15, 'Password must not exceed 15 characters'),
  // .matches(
  //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
  //   "Enter a password with minimum one upper case, lower case and number, ranging from 8-15 characters"
  // ),
});
export const ForgetEmailSchema = Yup.object({
  userType: Yup.string()
    .oneOf(['applicant', 'broker', 'brokerage'], 'Please select a user type')
    .required('User type is required'),

  email: Yup.string()
    .trim()
    .max(255, 'Email address must not be greater than 255 characters')
    .matches(
      /^[^\s@]+@[^\s@]+\.(?:com|tech|io|in|net|org|com\.au)$/,
      'Email must be a valid email address'
    )
    .required('Email address is required'),
});

export const ResetPasswordSchema = Yup.object({
  password: Yup.string()
    .trim()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
    .max(15, 'Password must not exceed 15 characters')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
      'Enter a password with minimum one upper case, lower case and number, ranging from 8-15 characters'
    ),
  confirmPassword: Yup.string()
    .trim()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('password')], 'Passwords must match'),
  termCondition: Yup.boolean().oneOf(
    [true],
    'You must accept the terms and conditions'
  ),
});

export const UserApplicatAccountSchema = Yup.object().shape({
  userType: Yup.string(),
  userData: Yup.object().shape({
    companyName: Yup.string().trim().required('Company name  is required'),
    email: Yup.string()
      .trim()
      .max(255, 'Email must not be greater than 255 characters')
      .matches(
        /^[^\s@]+@[^\s@]+\.(?:com|tech|io|in|net|org|com\.au)$/,
        'Email must be a valid email address'
      )
      .required('Email Address is required'),
    password: Yup.string()
      .trim()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters')
      .max(15, 'Password must not exceed 15 characters')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
        'Enter a password with minimum one upper case, lower case and number, ranging from 8-15 characters'
      ),
    confirmPassword: Yup.string()
      .trim()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('password')], 'Passwords must match'),
    termCondition: Yup.boolean().oneOf(
      [true],
      'You must accept the terms and conditions'
    ),
  }),
});
export const ApplicatProfileSchema = Yup.object().shape({
  companyName: Yup.string().trim().required('Company name is required'),
  email: Yup.string()
    .trim()
    .max(255, 'Email must not be greater than 255 characters')
    .matches(
      /^[^\s@]+@[^\s@]+\.(?:com|tech|io|in|net|org|com\.au)$/,
      'Email must be a valid email address'
    )
    .required('Email address is required'),
  // officeAddress: Yup.string().trim().required('Office address is required'),
  companyLegalName: Yup.string().trim().required('This field is required'),
  companyAddress: Yup.string().trim().required('This field is required'),
  businessPhone: Yup.string()
    .trim()
    .required('This field is required')
    .matches(/^\d{10}$/, 'Invalid number, please enter 10 digits'),
  companyDomain: Yup.string()
    .trim()
    .required('This field is required')
    .matches(
      // /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/,
      /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d{1,5})?(\/[^\s]*)?$/,
      'Please Enter a valid domain'
    ),
  mailingAddressSameAsOffice: Yup.boolean().oneOf([true, false]),
  mailingAddress: Yup.string().when(
    'mailingAddressSameAsOffice',
    (mailingAddressSameAsOffice, schema) => {
      const ismailingAddressSameAsOfficeTrue = Array.isArray(
        mailingAddressSameAsOffice
      )
        ? mailingAddressSameAsOffice.includes(false)
        : mailingAddressSameAsOffice === false;
      return ismailingAddressSameAsOfficeTrue
        ? schema.trim().required('This field is required')
        : schema.notRequired();
    }
  ),
  mailingPostalCode: Yup.string()
    .nullable()
    .test(
      'postal-code-check',
      'Please select an address with a postal code/Zip code',
      function (value) {
        const { mailingAddress } = this.parent;
        const isMailingAddressValid =
          Array.isArray(mailingAddress) && !mailingAddress.includes(undefined);
        console.log('value', value, isMailingAddressValid, this.parent);

        if (mailingAddress && !value) {
          return false;
        }

        return true;
      }
    ),
  postalCode: Yup.string()
    .nullable()
    .when('companyAddress', (companyAddress, schema, value) => {
      const isAddress =
        Array.isArray(companyAddress) && !companyAddress.includes(undefined);
      const isPostalCode = value?.context?.postalCode;

      return isAddress
        ? isPostalCode
          ? schema.notRequired()
          : schema.required(
              'Please select an address with a postal code/Zip code'
            )
        : schema.notRequired();
    }),
  fiscalYearEnd: Yup.string().trim().required('This field is required'),
});
export const BrokerProfileSchema = Yup.object().shape({
  firstName: Yup.string().trim().required('First name is required'),
  lastName: Yup.string().trim().required('Last name  is required'),

  email: Yup.string()
    .trim()
    .max(255, 'Email must not be greater than 255 characters')
    .matches(
      /^[^\s@]+@[^\s@]+\.(?:com|tech|io|in|net|org|com\.au)$/,
      'Email must be a valid email address'
    )
    .required('Email Address is required'),
  contactNumber: Yup.string()
    .trim()
    .required('Contact number is required')
    .matches(/^\d{10}$/, 'Invalid mobile number, please enter 10 digits'),
});
export const BrokerageProfileSchema = Yup.object().shape({
  legalName: Yup.string().trim().required('Legal name  is required'),
  contactNumber: Yup.string()
    .trim()
    .required('Contact number is required')
    .matches(/^\d{10}$/, 'Invalid mobile number, please enter 10 digits'),

  email: Yup.string()
    .trim()
    .max(255, 'Email must not be greater than 255 characters')
    .matches(
      /^[^\s@]+@[^\s@]+\.(?:com|tech|io|in|net|org|com\.au)$/,
      'Email must be a valid email address'
    )
    .required('Email address is required'),
  officeAddress: Yup.string().trim().required('Office address  is required'),
  logoInfo: Yup.object()
    .shape({
      linkId: Yup.string().required('Name is required'),
      link: Yup.string().required('Application logo is required'),
      urlKey: Yup.string().required('UrlKey is required'),
      bondLogo: Yup.object()
        .shape({
          linkId: Yup.string().required('Name is required'),
          link: Yup.string().required('Bond Form logo is required'),
          urlKey: Yup.string().required('UrlKey is required'),
        })
        .required('Bond Logo is required'),
    })
    .required('Logo is required'),
});

export const BrokerageCLformSchemaTabel = Yup.object().shape({
  corporate_license: Yup.array().of(
    Yup.object().shape({
      province: Yup.string().trim().required('Province is required'),
      license: Yup.string()
        .nullable()
        .required('License number is required')
        .max(50, 'License number can not be more than 50'),
      // expiryData: Yup.date()
      //   .nullable()
      //   .transform((value, originalValue) => {
      //     return originalValue instanceof Date || originalValue === null ? originalValue : null;
      //   })
      //   .required("Expiry date is required"),
    })
  ),
});

export const BrokerageCLformSchema = Yup.object().shape({
  hasLicenseSuspensionFine: Yup.boolean().nullable().notRequired(),
  reprimanded: Yup.boolean().nullable().notRequired(),
  reprimanded_info: Yup.string().when(
    'hasLicenseSuspensionFine',
    (hasLicenseSuspensionFine, schema) => {
      console.log('test', hasLicenseSuspensionFine);

      const isHasLicenseSuspensionFine = Array.isArray(hasLicenseSuspensionFine)
        ? hasLicenseSuspensionFine.includes(true)
        : hasLicenseSuspensionFine === true;
      return isHasLicenseSuspensionFine
        ? schema.trim().required('This field is required')
        : schema.notRequired();
    }
  ),
});
export const UserBrokerAccountSchema = Yup.object().shape({
  userType: Yup.string(),
  userData: Yup.object().shape({
    firstName: Yup.string().trim().required('First name is required'),
    lastName: Yup.string().trim().required('Last name is required'),

    password: Yup.string()
      .trim()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters')
      .max(15, 'Password must not exceed 15 characters')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
        'Enter a password with minimum one upper case, lower case and number, ranging from 8-15 characters'
      ),
    confirmPassword: Yup.string()
      .trim()
      .required('Confirm password is required')
      .oneOf([Yup.ref('password')], 'Passwords must match'),
    email: Yup.string()
      .max(255, 'Email must not be greater than 255 characters')
      .matches(
        /^[^\s@]+@[^\s@]+\.(?:com|tech|io|in|net|org|com\.au)$/,
        'Email must be a valid email address'
      )
      .required('Email Address is required'),

    contactNumber: Yup.string()
      .trim()
      .required('Contact number is required')
      .matches(/^\d{10}$/, 'Invalid mobile number, please enter 10 digits'),
    brokerageId: Yup.number()
      .min(1, 'Please select a brokerage')
      .required('Brokerage is required'),
    termCondition: Yup.boolean().oneOf(
      [true],
      'You must accept the terms and conditions'
    ),
  }),
});

export const UserBrokererageAccountSchema = Yup.object().shape({
  userType: Yup.string(),
  userData: Yup.object().shape({
    legalName: Yup.string().trim().required('Legal name is required'),
    contactNumber: Yup.string()
      .trim()
      .required('Contact number is required')
      .matches(/^\d{10}$/, 'Invalid mobile number, please enter 10 digits'),

    email: Yup.string()
      .trim()
      .max(255, 'Email must not be greater than 255 characters')
      .matches(
        /^[^\s@]+@[^\s@]+\.(?:com|tech|io|in|net|org|com\.au)$/,
        'Email must be a valid email address'
      )
      .required('Email Address is required'),

    password: Yup.string()
      .trim()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters')
      .max(15, 'Password must not exceed 15 characters')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
        'Enter a password with minimum one upper case, lower case and number, ranging from 8-15 characters'
      ),
    confirmPassword: Yup.string()
      .trim()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('password')], 'Passwords must match'),
    termCondition: Yup.boolean().oneOf(
      [true],
      'You must accept the terms and conditions'
    ),
  }),
});

export const UserAdminSignupSchema = Yup.object().shape({
  userType: Yup.string(),
  userData: Yup.object().shape({
    name: Yup.string().trim().required('Name is required'),
    contactNumber: Yup.string()
      .trim()
      .required('Contact number is required')
      .matches(/^\d{10}$/, 'Invalid mobile number, please enter 10 digits'),
    email: Yup.string()
      .trim()
      .max(255, 'Email must not be greater than 255 characters')
      .matches(
        /^[^\s@]+@[^\s@]+\.(?:com|tech|io|in|net|org|com\.au)$/,
        'Email must be a valid email address'
      )
      .required('Email Address is required'),
    password: Yup.string()
      .trim()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters')
      .max(15, 'Password must not exceed 15 characters')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
        'Enter a password with minimum one upper case, lower case and number, ranging from 8-15 characters'
      ),
    confirmPassword: Yup.string()
      .trim()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('password')], 'Passwords must match'),
    termCondition: Yup.boolean().oneOf(
      [true],
      'You must accept the terms and conditions'
    ),
  }),
});

export const AuthenticationSchema = Yup.object().shape({
  password: Yup.string()
    .trim()
    .required('Password is required')
    .min(6, 'Password must be at least 6 characters')
    .max(30, 'Password must be at most 30 characters'),
});

export const InviteApplicantSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .max(255, 'Email must not be greater than 255 characters')
    .matches(
      /^[^\s@]+@[^\s@]+\.(?:com|tech|io|in|net|org|com\.au)$/,
      'Email must be a valid email address'
    )
    .required('Email Address is required'),
  companyName: Yup.string().trim().required('Company Name is required'),
});
