import {
  Box,
  Button as ChakraButton,
  Flex,
  Heading,
  Select,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import {
  Button,
  FileInput,
  Group,
  List,
  Loader,
  Radio,
  Tabs,
  Textarea,
} from '@mantine/core';
import { IconDownload, IconFileCv } from '@tabler/icons-react';
import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { CgComment, CgTrash } from 'react-icons/cg';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch, useAppSelector } from 'src/Redux/Store';
// import { contentType } from 'mime-types';
import { useDisclosure } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import moment from 'moment';
import { DatePickerInput } from 'rc-datepicker';
import { HiMiniInformationCircle } from 'react-icons/hi2';
import { useLocation, useNavigate } from 'react-router-dom';
import { FinancialYearDateRange } from 'src/Redux/Applicant/ApplicantProfile/ApplicantProfile';
import { getApplicationsByApplicant } from 'src/Redux/Broker/CreateApplicationForm/CreateApplicationForm';
import { ApplicantBrokerage } from 'src/Redux/Broker/Dashboard/BrokerageDashboard';
import { getLegalFormDocuments } from 'src/Redux/Broker/Documents/slice';
import {
  removeWIPSliceType,
  uploadWIPTemplate,
} from 'src/Redux/Broker/Reports/WorkInProgress/slice';
import { IApplicantData } from 'src/Redux/Broker/Reports/WorkInProgress/state';
import { getOCRReportCount } from 'src/Redux/OcrReports/slice';
import {
  listAllReports,
  removeUploadReportsSliceType,
  uploadReports,
  uploadReportsFinancial,
} from 'src/Redux/UploadReports/slice';
import { IUploadReportsData } from 'src/Redux/UploadReports/state';
import {
  MAX_UPLOAD_SIZE,
  PDF_CSV_EXECLE_TYPES,
} from 'src/constants/UploadDocuments';
import { useAppToast, useS3FileUpload } from 'src/hooks';
import { REPORT_NAME, REPORT_TIME } from 'src/types/enums';
import {
  addFocusEventListenerOnInput,
  getDateFormat,
  isFileTypeAllowed,
  removeFocusEventListenerOnInput,
} from 'src/utils/helpers';
import { UploadSchema } from '../../ValidationSchema/Pages/Index';
import { calculateDateRanges } from '../ApplicantPages/ApplicantProfile/utils';
import WIPBondListModal from '../Reports/components/WIPReportList/WIPBondListModal';
import WIPProjectListModal from '../Reports/components/WIPReportList/WIPProjectListModal';
import LegalFormUploadFilesTable from '../Reports/components/uploadFiles/LegalFormUploadFilesTable';
import UploadFilesTable from '../Reports/components/uploadFiles/UploadFilesTable';
import {
  cleanObject,
  renameKeys,
} from '../Reports/components/uploadFiles/utils';
import { WIP_TEMPLATE_DOC_TYPES } from '../Reports/utils';
import {
  FinancialOptionType,
  OtherOptionWithtaxingEntity,
  exportDocumentDownloadOptions,
  financialOption,
  financialPeriodOptions,
  financialPeriodOptionsRange,
  financialPeriodOptionsType,
  financialReviewTypeOptions,
  financialStatementOption,
} from './data';
import { TZipName } from './types';
import {
  findStartAndEndDate,
  getDateRangeOfTimeFrame,
  getSecondDropDownOption,
  getSelectedFinancialYear,
  getSelectedPeriodRange,
  getdateRangeSpan,
  isShoWFinancialReviewType,
} from './utils';

export interface Props {
  callesFrom: string;
}

function UploadDropdowns({ callesFrom }: Props) {
  // states
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [isSubmitDoc, setIsSubmitDoc] = useState(false);
  const [zipName, setZipName] = useState<TZipName | null>(null);
  const [applicantOptions, setApplicantOptions] = useState<IApplicantData[]>(
    []
  );
  const [downloadDocOption, setDownloadDocOption] =
    useState<string>('All Documents');
  const [legalFormDocuments, setLegalFormDocuments] = useState<
    Record<string, any>
  >({});
  const [reportList, setReportList] = useState<IUploadReportsData[]>();
  const [loader, setLoader] = useState<{
    isUpdateProjectStatus: boolean;
    isBondNumberUpdating: boolean;
  }>({
    isUpdateProjectStatus: false,
    isBondNumberUpdating: false,
  });

  // hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const { showBox } = location.state || {};
  const { S3UploadError, S3UploadLoading, S3UploadResponse, uploadFilesInS3 } =
    useS3FileUpload();
  const userType = localStorage.getItem('userType');
  const createApplicationForm = useSelector(
    (state: RootState) => state.createApplicationFormSlice
  );
  const keydata =
    createApplicationForm?.applications.length > 0
      ? createApplicationForm?.applications[0]?.cms?.keyPersonnel
      : [];
  const paymentPlan =
    createApplicationForm?.applications[0]?.creditBureau?.paymentPlan;
  const uploadReportsStore = useSelector(
    (state: RootState) => state.uploadReportsSlice
  );
  const progressReport = useAppSelector((state) => state.brokerReportProgress);
  // const userData = useSelector(
  //   (state: RootState) => state.brokerageDashboard.user
  // );
  const appliactionsSlice = useAppSelector((state) => state.appliactionsSlice);
  const brokerageDashboard = useSelector(
    (state: RootState) => state.brokerageDashboard
  );
  const documents = useAppSelector((state: RootState) => state.documentSlice);
  const notificationSlice = useAppSelector((state) => state.notification);
  const applicantProfileSlice = useAppSelector(
    (state) => state.applicantProfile
  );

  const keypersonneldata =
    appliactionsSlice?.currentApplication?.cms?.keyPersonnel;
  // let AccoutingFormData = userData && userData?.data?.accountingSystem;
  let CreditBureauFormData =
    appliactionsSlice && appliactionsSlice?.currentApplication?.creditBureau;

  // const paramsData = useParams<{ id: string }>();

  // const { showHistory, applicantId } = location.state || {};
  // const ongoingID = paramsData.id ? parseInt(paramsData.id, 10) : null;
  let Applicantbrokerage =
    appliactionsSlice && appliactionsSlice?.currentApplication;
  let currentApplicationId = Applicantbrokerage?.applicationId;
  let currentApplicantId = Applicantbrokerage?.applicantId;
  let ApplicationData =
    createApplicationForm && createApplicationForm?.applications;
  const reportAsOfDateRef = useRef<DatePickerInput>(null);
  const [fiscalYearDateRange, setFiscalYearDateRange] =
    useState<FinancialYearDateRange | null>(null);
  const startDateRef = useRef<DatePickerInput>(null);
  const endDateDateRef = useRef<DatePickerInput>(null);

  const [
    showProjectListModal,
    { open: openProjectListModal, close: closeProjectListModal },
  ] = useDisclosure(false);

  const [
    showBondListModal,
    { open: openBondListModal, close: closeBondListModal },
  ] = useDisclosure(false);

  interface FormValues {
    applicationId: string;
    applicantId: string;
    financial: string;
    financialYear: string;
    financialStatement: string;
    financialFileWithComment: { file: File; comment?: string | null }[] | null;
    reportAsOf: string | null;
    financialReviewType: string;
    startDate: string | null;
    endDate: string | null;
    financialPeriod: string;
    financialPeriodRange: FinancialOptionType['value'] | '';
  }
  const initialValues: FormValues = {
    applicationId: '',
    applicantId: '',
    financial: '',
    financialYear: '',
    financialStatement: '',
    financialFileWithComment: null,
    reportAsOf: null,
    financialReviewType: '',
    startDate: null,
    endDate: null,
    financialPeriod: '',
    financialPeriodRange: '',
  };

  const toast = useAppToast();

  useEffect(() => {
    return () => {
      dispatch(removeUploadReportsSliceType({}));
      dispatch(removeWIPSliceType({}));
    };
  }, []);

  useEffect(() => {
    const { status, type, legalFormDocuments, error } = documents;
    switch (status) {
      case 'loading': {
        if (type === 'GET_LEGAL_FORM_DOCUMENTS') {
          setIsTableLoading(true);
        }
        break;
      }
      case 'succeed': {
        if (type === 'GET_LEGAL_FORM_DOCUMENTS') {
          const renamedLegalData = renameKeys(legalFormDocuments);

          // remove null key-value and blank object/array value contain keys
          const removeNullKeyValue = cleanObject(renamedLegalData);
          console.log('removeNullKeyValue', removeNullKeyValue);

          //below two action perform before Export Legal All Data - legal document data table added
          // //arrange zip folderwise data
          // //if object key has multiple object then add folder,if single object then don't add folder,and add nested object key is array then add that upload field folder.
          // const folderStructureFormat = restructureObject(removeNullKeyValue);
          // console.log('folderStructureFormat', folderStructureFormat);

          // //clean caseNumberKey and then cleanObject to remove blank array
          // const cleanCaseNumber = cleanObject(
          //   cleanCaseNumberObjects(folderStructureFormat)
          // );
          // console.log('cleanCaseNumber', cleanCaseNumber);

          setLegalFormDocuments(removeNullKeyValue);

          setIsTableLoading(false);
        }
        break;
      }
      case 'failed': {
        if (type === 'GET_LEGAL_FORM_DOCUMENTS') {
          setIsTableLoading(false);
          toast({
            title: error,
            status: 'error',
          });
        }
        break;
      }

      default:
        break;
    }
    return () => {};
  }, [documents.status]);

  // useEffect(() => {
  //   if (
  //     downloadDocOption === 'Legal Form Documents' &&
  //     Object.keys(legalFormDocuments)?.length === 0 &&
  //     values?.applicationId
  //   ) {
  //     dispatch(
  //       getLegalFormDocuments({
  //         applicationId: Number(values?.applicationId),
  //       })
  //     );
  //   }
  // }, [downloadDocOption]);

  const getReportList = () => {
    dispatch(
      listAllReports({
        applicantId: Number(values.applicantId),
      })
    );
  };

  useEffect(() => {
    const { status, type, error, errorResponse } = createApplicationForm;

    switch (status) {
      case 'loading': {
        break;
      }
      case 'succeed': {
        break;
      }
      case 'failed': {
        if (type === 'GET_APPLICATIONS_BY_APPLICANT_ID') {
          if (!!errorResponse && errorResponse?.type === 'INFO') {
            toast({
              status: 'info',
              title: error,
            });
          } else {
            toast({
              status: 'error',
              title: error,
            });
          }
          resetForm();
          // setFieldValue('applicantId', '');
        }
        break;
      }

      default:
        break;
    }
  }, [createApplicationForm.status]);

  useEffect(() => {
    const { status, type, error } = notificationSlice;

    switch (status) {
      case 'loading': {
        break;
      }
      case 'succeed': {
        if (type === 'GET_NOTIFICATION_LIST') {
          if (values?.applicantId) {
            getReportList();
          }
          if (['broker', 'applicant'].includes(userType!)) {
            dispatch(getOCRReportCount() as any);
          }
        }
        break;
      }
      case 'failed': {
        break;
      }

      default:
        break;
    }
  }, [notificationSlice.status]);

  useEffect(() => {
    const { status, type, applicantsByBrokrageData } = brokerageDashboard;
    if (status === 'succeed') {
      if (
        type === 'GET_APPLICANT_BY_BROKERAGE' &&
        !!applicantsByBrokrageData?.length
      ) {
        setApplicantOptions(applicantsByBrokrageData);
      }
    }
  }, [brokerageDashboard.status]);

  useEffect(() => {
    if (S3UploadResponse && isSubmitDoc) {
      let payload = {
        applicantId: Number(values.applicantId),
        reportInfo: {
          reportName:
            values.financial === 'Financial Statement'
              ? values.financialStatement
              : values.financialYear,
          timeFrame:
            values.financial === 'Financial Statement'
              ? values.financialYear
              : null,
          type: values.financial,
          reportAsOf: moment(values.reportAsOf),
        },
        reportLinks:
          (S3UploadResponse as any)?.data?.map((value: any, index: number) => {
            return {
              link: value?.location,
              urlKey: value?.key,
              comment:
                values?.financialFileWithComment?.[index].comment ?? null,
            };
          }) ?? [],
      };

      dispatch(uploadReports(payload));
    }
    return () => {};
  }, [S3UploadResponse]);

  const uploadDocsS3 = async (values: FormValues) => {
    try {
      const localFiles = values.financialFileWithComment?.map(
        (data) => data.file
      );
      if (localFiles?.length) {
        const formData = new FormData();
        localFiles.forEach((file, index) => {
          formData.append(`files`, file);
        });
        setIsSubmitDoc(true);

        await uploadFilesInS3(formData);

        if (S3UploadError) {
          toast({
            title: 'Erro in file uploading!!',
            status: 'error',
          });
          return;
        }
      }
    } catch (err) {
      console.log('submitReportsHandler', err);
    }
  };

  const submitReportsHandler = async (values: FormValues) => {
    try {
      if (values?.financial === 'Financial Statement') {
        const localFiles = values.financialFileWithComment?.map(
          (data) => data.file
        );
        if (localFiles?.length) {
          const formData = new FormData();
          const reportAsOf = moment(values.reportAsOf).toISOString();
          const startDate = moment(values.startDate).toISOString();
          const endDate = moment(values.endDate).toISOString();
          const timeFrame =
            values.financial === 'Financial Statement'
              ? values.financialYear
              : null;

          localFiles.forEach((file, index) => {
            formData.append(`files`, file);
          });
          formData.append(`applicantId`, values?.applicantId);
          formData.append(
            `reportName`,
            values.financial === 'Financial Statement'
              ? values.financialStatement
              : values.financialYear
          );
          formData.append(`timeFrame`, timeFrame!);
          formData.append(`type`, values?.financial);
          if (values.financialStatement === 'Profit and Loss') {
            formData.append(`startDate`, startDate);
            formData.append(`endDate`, endDate);
            formData.append(`financialPeriod`, values.financialPeriod);
          }
          if (values.financialStatement !== 'Profit and Loss') {
            formData.append(`reportAsOf`, reportAsOf);
          }
          if (isShoWFinancialReviewType(values)) {
            formData.append(`financialReviewType`, values?.financialReviewType);
          }
          // await uploadFilesInS3(formData);

          dispatch(uploadReportsFinancial(formData));
        }
      } else if (values?.financial === 'Work in Progress') {
        const isValid = values.financialFileWithComment?.map((file) => ({
          typeError: !isFileTypeAllowed(
            file.file?.name,
            WIP_TEMPLATE_DOC_TYPES
          ),
        }));

        if (isValid?.some((error) => error.typeError)) {
          toast({
            title: 'Invalid file type!',
            status: 'error',
          });
          return;
        }
        const formData = new FormData();
        const localFiles = values.financialFileWithComment?.map(
          (data) => data.file
        );
        if (localFiles?.length) {
          localFiles?.forEach((file, index) => {
            formData.append(`file`, file);
          });
          formData.append(`applicantId`, values?.applicantId);
          dispatch(uploadWIPTemplate(formData));
        }
      } else {
        uploadDocsS3(values);
      }
    } catch (error) {
      console.log('submitReportsHandler', error);
    }
  };

  const checkReportTypeValidation = (values: FormValues) => {
    console.log('values', values);
    if (reportList && !!reportList?.length) {
      const findUploadReport = reportList?.filter((item) => {
        const { reportInfo } = item;
        if (values?.financial === 'Financial Statement') {
          if (values?.financialStatement === REPORT_NAME.PL) {
            return (
              reportInfo?.type === values?.financial &&
              reportInfo?.reportName === values?.financialStatement &&
              reportInfo?.timeFrame === values?.financialYear &&
              moment(reportInfo?.startDate)
                .startOf('d')
                .isSame(moment(values?.startDate).startOf('d')) &&
              moment(reportInfo?.endDate)
                .startOf('d')
                .isSame(moment(values?.endDate).startOf('d'))
            );
          } else {
            if (values?.financialYear === REPORT_TIME.Interim) {
              return (
                reportInfo?.type === values?.financial &&
                reportInfo?.reportName === values?.financialStatement &&
                reportInfo?.timeFrame === values?.financialYear &&
                moment(reportInfo?.reportAsOf)
                  .startOf('d')
                  .isSame(moment(values?.reportAsOf).startOf('d'))
              );
            } else {
              return (
                reportInfo?.type === values?.financial &&
                reportInfo?.reportName === values?.financialStatement &&
                reportInfo?.timeFrame === values?.financialYear
              );
            }
          }
        } else {
          return (
            reportInfo?.type === values?.financial &&
            reportInfo?.reportName === values?.financialYear
          );
        }
      });
      console.log('findUploadReport', findUploadReport);
      if (!!findUploadReport?.length) {
        // alert('already uploaded!!!');
        const isAnnualReport = moment(
          moment(values?.startDate!).subtract(1, 'd').add(1, 'y')
        ).isSame(moment(values?.endDate!).startOf('d'));

        modals.openConfirmModal({
          title: ``,
          withCloseButton: false,
          children: (
            <Box display={'flex'} flexDirection={'column'} gap={3}>
              <Text color="black" fontSize={'16px'}>
                Do you want to replace?
              </Text>
              <Text color="black" fontSize={'14px'}>
                You have already uploaded document for{' '}
                {values?.financial +
                  ' - ' +
                  (values?.financial === 'Financial Statement'
                    ? values?.financialStatement + ' - '
                    : '') +
                  values?.financialYear +
                  (values?.financialYear === REPORT_TIME.Interim &&
                  values?.financialStatement !== REPORT_NAME.PL
                    ? values?.reportAsOf
                      ? ' - ' +
                        getDateFormat(values?.reportAsOf, 'MMM DD, YYYY')
                      : ''
                    : '') +
                  (values?.financialPeriod &&
                  ['Custom Range', 'Year To Date'].includes(
                    values?.financialPeriod
                  )
                    ? isAnnualReport
                      ? ' - ' + 'Annual'
                      : ` - ${getDateFormat(values?.startDate!, 'MMM DD, YYYY')} to ${getDateFormat(values?.endDate!, 'MMM DD, YYYY')}`
                    : values?.financialPeriod
                      ? ' - ' + values?.financialPeriod
                      : '')}
                .
              </Text>
            </Box>
          ),
          labels: { confirm: 'Yes', cancel: 'No' },
          confirmProps: { color: 'blue' },
          cancelProps: { color: 'red' },
          onConfirm: () => {
            submitReportsHandler(values);
          },
          onCancel: () => {
            resetData();
          },
        });
      } else {
        submitReportsHandler(values);
      }
    } else {
      submitReportsHandler(values);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: UploadSchema(downloadDocOption),
    onSubmit: checkReportTypeValidation,
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
    resetForm,
    setTouched,
  } = formik;

  const resetData = () => {
    setFieldValue('financial', initialValues.financial);
    setFieldValue('financialYear', initialValues.financialYear);
    setFieldValue('financialStatement', initialValues.financialStatement);
    setFieldValue(
      'financialFileWithComment',
      initialValues.financialFileWithComment
    );
    setFieldValue('reportAsOf', initialValues.reportAsOf);
    setFieldValue('startDate', initialValues.startDate);
    setFieldValue('endDate', initialValues.endDate);
    setTouched({ ...touched, financialFileWithComment: false });
  };

  useEffect(() => {
    const { status, type, error, applicantReportsData } = uploadReportsStore;

    switch (status) {
      case 'loading': {
        if (type === 'GET_APPLICANT_REPORTS') {
          setIsTableLoading(true);
        }
        if (
          type === 'POST_UPLOAD_REPORTS' ||
          type === 'POST_UPLOAD_REPORTS_FINANCIAL'
        ) {
          setIsSubmitDoc(true);
        }
        break;
      }
      case 'succeed': {
        if (type === 'GET_APPLICANT_REPORTS' && applicantReportsData) {
          setIsTableLoading(false);
          setReportList(applicantReportsData);
        }
        if (
          type === 'POST_UPLOAD_REPORTS' ||
          type === 'POST_UPLOAD_REPORTS_FINANCIAL'
        ) {
          setIsSubmitDoc(false);
          toast({
            title: 'Report uploaded successfully. OCR is in progress.',
            status: 'success',
          });
          resetData();
          if (values?.applicantId) {
            getReportList();
          }
        }
        if (type === 'DELETE_REPORT') {
          toast({
            status: 'success',
            title: 'Report deleted successfully',
          });
          getReportList();
        }
        if (type === 'UPDATE_REPORT') {
          toast({
            status: 'success',
            title: 'Report updated successfully',
          });
          getReportList();
        }
        if (
          type === 'REGENERATE_OCR_FROM_ORIGINAL_REPORT' ||
          type === 'CREATE_SUPPORT_TICKET_OF_FAILED_OCR_REPORT'
        ) {
          getReportList();
        }
        break;
      }
      case 'failed': {
        if (type === 'GET_APPLICANT_REPORTS') {
          setIsTableLoading(false);
        }
        if (
          type === 'POST_UPLOAD_REPORTS' ||
          type === 'POST_UPLOAD_REPORTS_FINANCIAL'
        ) {
          setIsSubmitDoc(false);
          toast({
            status: 'error',
            title: error,
          });
        }
        break;
      }
    }
  }, [uploadReportsStore.status, uploadReportsStore.type]);

  useEffect(() => {
    switch (progressReport.status) {
      case 'succeed': {
        if (progressReport.type === 'UPLOAD_WIP_TEMPLATE') {
          setIsSubmitDoc(false);
          toast({
            title: progressReport?.uploadTemplte?.message,
            status: 'success',
          });
          resetData();
          if (!!progressReport?.uploadTemplte?.projectList?.length) {
            openProjectListModal();
          } else if (!!progressReport?.uploadTemplte?.bondList?.length) {
            openBondListModal();
          } else {
            !!values?.applicantId &&
              navigate(`/${userType}/report/working_progress`, {
                state: {
                  applicantId: values?.applicantId,
                  reportAsOf: progressReport?.uploadTemplte?.reportAsOf,
                },
              });
          }
          // else {
          //   !!selectedBrokerageId &&
          //     dispatch(
          //       getWIPTableList({
          //         applicantId: Number(selectedBrokerageId),
          //       })
          //     );
          // }
        }
        if (progressReport.type === 'WIP_UPDATE_PROJECT_STATUS') {
          setLoader((loader) => ({ ...loader, isUpdateProjectStatus: false }));
          toast({
            title: 'Work in progress report updated successfully',
            status: 'success',
          });
          closeProjectListModal();
          if (!!values?.applicantId) {
            getReportList();
          }
        }
        if (progressReport.type === 'WIP_UPDATE_BOND_NUMBERS') {
          setLoader((loader) => ({ ...loader, isBondNumberUpdating: false }));
          toast({
            title: 'Work in progress report updated successfully',
            status: 'success',
          });
          closeBondListModal();
          if (!!values?.applicantId) {
            getReportList();
          }
        }
        return;
      }
      case 'loading': {
        if (progressReport.type === 'UPLOAD_WIP_TEMPLATE') {
          setIsSubmitDoc(true);
        }
        if (progressReport.type === 'WIP_UPDATE_PROJECT_STATUS') {
          setLoader((loader) => ({ ...loader, isUpdateProjectStatus: true }));
        }
        if (progressReport.type === 'WIP_UPDATE_BOND_NUMBERS') {
          setLoader((loader) => ({ ...loader, isBondNumberUpdating: true }));
        }
        return;
      }
      case 'failed': {
        values?.applicantId !== '' &&
          progressReport.error &&
          toast({
            title: progressReport.error,
            status: 'error',
            duration:
              progressReport.type === 'UPLOAD_WIP_TEMPLATE' ? null : 3000,
          });
        if (progressReport.type === 'UPLOAD_WIP_TEMPLATE') {
          setIsSubmitDoc(false);
        }
        if (progressReport.type === 'WIP_UPDATE_PROJECT_STATUS') {
          setLoader((loader) => ({ ...loader, isUpdateProjectStatus: false }));
          closeProjectListModal();
        }
        if (progressReport.type === 'WIP_UPDATE_BOND_NUMBERS') {
          setLoader((loader) => ({ ...loader, isBondNumberUpdating: false }));
          closeBondListModal();
        }
        return;
      }
    }
  }, [progressReport.status, progressReport.type]);

  useEffect(() => {
    if (userType === 'applicant' && callesFrom !== 'Documents') {
      const applicantId = localStorage.getItem('userID');
      formik.setFieldValue('applicantId', applicantId);
    }
  }, [userType]);

  useEffect(() => {
    if (
      callesFrom === 'Documents' &&
      currentApplicationId &&
      Applicantbrokerage
    ) {
      formik.setFieldValue('applicationId', currentApplicationId);
      setZipName({
        applicantName: Applicantbrokerage?.applicant?.companyName,
        // applicationName: Applicantbrokerage?.applicationName,
      });
      // fetchOngoingApplication();
    }
  }, [currentApplicationId]);

  useEffect(() => {
    setZipName({
      applicantName:
        zipName?.applicantName ||
        Applicantbrokerage?.applicant?.companyName ||
        'Financial Statements',
    });
  }, []);

  useEffect(() => {
    if (callesFrom === 'Documents' && currentApplicantId) {
      // fetchOngoingApplicant();
      formik.setFieldValue('applicantId', currentApplicantId);
    }
  }, [currentApplicantId, dispatch, callesFrom]);

  useEffect(() => {
    if (callesFrom !== 'Documents' && userType !== 'applicant') {
      dispatch(ApplicantBrokerage());
    }
    // if (callesFrom !== 'Documents') {
    //   setFieldValue('applicationId', currentApplicationId);
    // }
  }, []);
  // useEffect(() => {
  //   if (values.applicantId !== '') {
  //     if (callesFrom !== 'Documents')
  //       dispatch(
  //         getApplicationsByApplicant({
  //           applicantId: Number(values.applicantId),
  //         })
  //       );
  //   }
  // }, [values.applicantId]);

  useEffect(() => {
    if (!!values.applicantId && downloadDocOption === 'All Documents') {
      getReportList();
    }
    if (
      !!values.applicantId &&
      downloadDocOption === 'Legal Form Documents' &&
      callesFrom !== 'Documents'
    ) {
      dispatch(
        getApplicationsByApplicant({
          applicantId: Number(values.applicantId),
        })
      );
    }
  }, [values.applicantId, downloadDocOption]);

  useEffect(() => {
    if (
      !!values.applicationId &&
      downloadDocOption === 'Legal Form Documents'
    ) {
      dispatch(
        getLegalFormDocuments({
          applicationId: Number(values?.applicationId),
        })
      );
    }
  }, [values.applicationId, downloadDocOption]);

  // const fetchOngoingApplicant = async () => {
  //   let id = !showHistory && ongoingID ? ongoingID : 0;
  //   id = showHistory && applicantId ? applicantId : id;
  //   formik.setFieldValue('applicantId', id);
  //   let actionResult = await dispatch(OngoingApplicant({ id: id }) as any);
  //   if (actionResult?.payload?.status === 401) {
  //     navigate('/login');
  //   }
  // };

  // const fetchOngoingApplication = async () => {
  //   dispatch(
  //     getApplicationById({
  //       applicationId: currentApplicationId ?? Number(values?.applicationId),
  //     }) as any
  //   );
  // };

  const toggleCommentBox = (index: number, val: string | null) => {
    if (val !== null)
      setFieldValue(`financialFileWithComment[${index}].comment`, null);
    else setFieldValue(`financialFileWithComment[${index}].comment`, '');
  };

  const handleFileInputUpload = (payload: File | File[]) => {
    const files = Array.isArray(payload) ? payload : [payload];

    const isValid = files?.map((file) => ({
      typeError: !isFileTypeAllowed(
        file?.name,
        formik?.values?.financial === 'Financial Statement'
          ? PDF_CSV_EXECLE_TYPES?.filter(
              (el) => !['.doc', '.docx'].includes(el)
            )
          : PDF_CSV_EXECLE_TYPES
      ),
      sizeError: file?.size > MAX_UPLOAD_SIZE,
    }));

    if (isValid.some((error) => error.typeError)) {
      toast({
        title: 'Invalid file type!',
        status: 'error',
      });
      return;
    }

    // if (isValid.some((error) => error.sizeError)) {
    //   toast({
    //     title: `Max file size allowed is ${MAX_UPLOAD_SIZE / 1024 ** 2} MB!`,
    //     status: 'error',
    //   });
    //   return;
    // }

    const newFiles = files.map((file) => ({
      file,
      comment: null,
    }));

    setFieldValue('financialFileWithComment', newFiles);
  };

  // const getDisabledOptions = (data: IUploadReportsData[]) => {
  //   const disabledOptions = new Set();

  //   data?.forEach((item) => {
  //     const reportName = item.reportInfo.reportName;
  //     if (reportName === 'Balance sheet / Profit and Loss') {
  //       disabledOptions.add('Balance Sheet');
  //       disabledOptions.add('Profit and Loss');
  //     } else if (reportName === 'Balance sheet / Profit and Loss / Cash flow') {
  //       disabledOptions.add('Balance Sheet');
  //       disabledOptions.add('Profit and Loss');
  //       disabledOptions.add('Cashflow');
  //     }
  //   });

  //   return Array.from(disabledOptions);
  // };

  // const handleChangeDownloadOption = (
  //   e: React.ChangeEvent<HTMLSelectElement>
  // ) => {
  //   // console.log('value', e.target.value);
  //   setDownloadDocOption(e.target.value);
  // };

  useEffect(() => {
    if (!!values.applicantId) {
      const fiscalYearEndDate = getFiscalYearEndDate();
      const dateRanges = !!fiscalYearEndDate
        ? calculateDateRanges(new Date(fiscalYearEndDate))
        : null;
      setFiscalYearDateRange(dateRanges);
    }
  }, [values.applicantId, applicantProfileSlice?.user]);

  const getFiscalYearEndDate = () => {
    // console.log('appliactionsSlice', appliactionsSlice, ApplicationData);
    console.log('ApplicationData:::', ApplicationData, applicantProfileSlice);
    if (userType === 'broker') {
      if (
        !!applicantOptions.length &&
        !!values.applicantId &&
        callesFrom !== 'Documents'
      ) {
        let selectedApplication = applicantOptions?.find(
          (el) => el.applicantId === Number(values.applicantId)
        );
        return selectedApplication?.fiscalYearEnd;
      } else {
        return appliactionsSlice?.currentApplication?.applicant?.fiscalYearEnd;
      }
    } else if (userType === 'applicant' && applicantProfileSlice?.user) {
      return applicantProfileSlice?.user?.data?.fiscalYearEnd;
    }
  };

  const getEndDateMaxDate = (financialYear: string) => {
    switch (financialYear) {
      case 'Most Recent Interim Financial Statement': {
        return fiscalYearDateRange?.mostRecentInterim?.end;
      }
      case 'Most Recent Fiscal Year End': {
        return fiscalYearDateRange?.mostRecentFiscalYear?.end;
      }
      case '1-Year Previous Fiscal Year End': {
        return fiscalYearDateRange?.oneYearPrevious?.end;
      }
      case '2-Year Previous Fiscal Year End': {
        return fiscalYearDateRange?.twoYearsPrevious?.end;
      }
      case 'Other': {
        return fiscalYearDateRange?.twoYearsPrevious?.start;
      }
      default:
        break;
    }
  };

  const reportDateForHistoricalOption = (
    selectedDate: Date | null,
    selectedFinancialYear: string
  ) => {
    switch (selectedFinancialYear) {
      case 'Most Recent Fiscal Year End': {
        return moment(
          fiscalYearDateRange?.mostRecentFiscalYear?.end,
          'DD MMM YYYY'
        ).toDate();
      }
      case '1-Year Previous Fiscal Year End': {
        return moment(
          fiscalYearDateRange?.oneYearPrevious?.end,
          'DD MMM YYYY'
        ).toDate();
      }
      case '2-Year Previous Fiscal Year End': {
        return moment(
          fiscalYearDateRange?.twoYearsPrevious?.end,
          'DD MMM YYYY'
        ).toDate();
      }
      case 'Other': {
        const historicalDateSpan = findStartAndEndDate(
          fiscalYearDateRange,
          selectedDate
        );
        console.log('historicalDateSpan', historicalDateSpan);
        return moment(historicalDateSpan?.end, 'DD MMM YYYY').toDate();
        // const historicalDateRangeEndDate = moment(
        //   fiscalYearDateRange?.twoYearsPrevious?.start,
        //   'DD MMM YYYY'
        // );
        // const getHistoricalDate = historicalDateRangeEndDate.get('date');
        // const getHistoricalMonth = historicalDateRangeEndDate.format('MMM');
        // const selectedDateYear = moment(selectedDate).get('year');

        // if (getHistoricalDate === 1 && getHistoricalMonth === 'Mar') {
        //   const updatedDate = `28-Mar-${selectedDateYear}`;
        //   console.log(
        //     'updatedDate',
        //     updatedDate,
        //     moment(updatedDate, 'DD-MMM-YYYY').toDate()
        //   );
        //   return moment(updatedDate, 'DD-MMM-YYYY').toDate();
        // } else {
        //   const updatedDate = `${getHistoricalDate}-${getHistoricalMonth}-${selectedDateYear}`;
        //   console.log(
        //     'updatedDate',
        //     updatedDate,
        //     moment(updatedDate, 'DD-MMM-YYYY').subtract(1, 'day').toDate()
        //   );
        //   return moment(updatedDate, 'DD-MMM-YYYY').subtract(1, 'day').toDate();
        // }
      }
      default:
        break;
    }
  };

  const getFiscalYearTooltip = () => {
    return (
      <Box
        padding={1}
        display={'flex'}
        flexDirection={'column'}
        gap={1}
        style={{ whiteSpace: 'nowrap' }}
      >
        <Text fontSize="xs">
          Most Recent Interim Financial Statement(
          {fiscalYearDateRange?.mostRecentInterim?.start} -{' '}
          {fiscalYearDateRange?.mostRecentInterim?.end})
        </Text>

        <Text fontSize="xs">
          Most Recent Fiscal Year End(
          {fiscalYearDateRange?.mostRecentFiscalYear?.start} -{' '}
          {fiscalYearDateRange?.mostRecentFiscalYear?.end})
        </Text>
        <Text fontSize="xs">
          1 - Year Previous Fiscal Year End(
          {fiscalYearDateRange?.oneYearPrevious?.start} -{' '}
          {fiscalYearDateRange?.oneYearPrevious?.end})
        </Text>
        <Text fontSize="xs">
          2 - Year Previous Fiscal Year End(
          {fiscalYearDateRange?.twoYearsPrevious?.start} -{' '}
          {fiscalYearDateRange?.twoYearsPrevious?.end})
        </Text>
        <Text fontSize="xs">
          Historical ( Date less than{' '}
          {fiscalYearDateRange?.twoYearsPrevious?.start})
        </Text>
        <Text fontSize="xs">
          For combined reports, Select recent fiscal year end date from report
        </Text>
      </Box>
    );
  };

  const financialPeriodHandleChange = (
    val: financialPeriodOptionsType['value'],
    financialPeriodRangeValue?: FinancialOptionType['value']
  ) => {
    let PLReportStartDate = formik.initialValues?.startDate;
    let PLReportEndDate = formik.initialValues?.endDate;
    let financialYear = formik.initialValues?.financialYear;
    let financialReviewType = formik.initialValues?.financialReviewType;
    console.log('fiscalYearDateRange', fiscalYearDateRange);
    if (!['Custom Range'].includes(val)) {
      financialYear = 'Most Recent Interim Financial Statement';
      financialReviewType = 'Internally Reviewed';
      const selectedRange = getSelectedPeriodRange(
        fiscalYearDateRange?.mostRecentInterim!,
        val
      );
      PLReportStartDate = selectedRange?.startDate!;
      PLReportEndDate = selectedRange?.endDate!;
    }
    // formik.setFieldValue('financialPeriod', val);
    formik.setValues({
      ...formik.values,
      financialPeriod: val,
      startDate: PLReportStartDate,
      endDate: PLReportEndDate,
      financialYear: financialYear,
      financialReviewType: financialReviewType,
      financialPeriodRange: !!financialPeriodRangeValue
        ? financialPeriodRangeValue
        : values?.financialPeriodRange,
    });
  };

  const financialPeriodRangeHandleChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    let financialPeriod = formik.initialValues.financialPeriod;
    const financialPeriodRangeValue = event.target.value;

    if (['year-to-date', 'custom-range'].includes(event.target.value)) {
      financialPeriod =
        event.target.value === 'year-to-date' ? 'Year To Date' : 'Custom Range';
      financialPeriodHandleChange(
        event.target.value === 'year-to-date' ? 'Year To Date' : 'Custom Range',
        financialPeriodRangeValue as FinancialOptionType['value']
      );
    } else {
      formik.setValues({
        ...values,
        financialPeriodRange: financialPeriodRangeValue as any,
        startDate: null,
        endDate: null,
        financialPeriod: financialPeriod,
      });
    }
  };

  return (
    <>
      <Box py={5}>
        {!showBox && (
          <>
            <Box borderBottom={'1px solid #E2E8F0'}>
              <Flex
                flexDir={'column'}
                justifyContent={'flex-start'}
                gap={2}
                m={4}
              >
                <Heading
                  fontSize={'24px'}
                  fontWeight={'600'}
                  // borderBottomWidth="1px"
                >
                  Upload Documents
                </Heading>
                <Text fontSize={'12px'} color={'black'}>
                  Please be advised that any documents uploaded to our system
                  will be deleted two years after the creation of the
                  application.If you require these documents for legal,
                  regulatory, or business purposes, we recommend downloading and
                  storing them on your own system for future reference.
                </Text>
              </Flex>
              {/* <PdfContent /> */}
            </Box>
            <Tabs
              value={downloadDocOption}
              onTabChange={(val) => {
                if (val) {
                  setDownloadDocOption(val);
                  resetData();
                }
              }}
              my={'20px'}
            >
              <Tabs.List
                style={{
                  border: 'unset',
                }}
              >
                {exportDocumentDownloadOptions?.map((item) => {
                  return <Tabs.Tab value={item?.value}>{item?.label}</Tabs.Tab>;
                })}
              </Tabs.List>
            </Tabs>
            {((downloadDocOption === 'Legal Form Documents' &&
              callesFrom !== 'Documents') ||
              downloadDocOption === 'All Documents') && (
              <Box p={5}>
                <form onSubmit={formik.handleSubmit}>
                  <Flex direction={'column'} gap={3}>
                    <Flex
                      gap={1}
                      flexWrap={'wrap'}
                      minH={
                        values?.financialStatement === 'Profit and Loss'
                          ? 'unset'
                          : '65px'
                      }
                      h={'max-content'}
                    >
                      {userType === 'broker' && callesFrom !== 'Documents' && (
                        <Box>
                          <Box maxW="max-content">
                            <Select
                              w="100%"
                              onChange={(event) => {
                                const selectedOption =
                                  event.target.options[
                                    event.target.selectedIndex
                                  ];
                                const label =
                                  selectedOption.getAttribute('label') ?? '';
                                setZipName({
                                  // applicationName:
                                  //   zipName?.applicationName ?? '',
                                  applicantName: label,
                                });

                                setFieldValue(
                                  'applicantId',
                                  event.target.value
                                );
                              }}
                              value={formik?.values?.applicantId ?? ''}
                              name="applicantId"
                              borderRadius="5px"
                              bg={'#114684'}
                              color={'white'}
                              textAlign={'center'}
                            >
                              <option
                                value={''}
                                style={{ color: 'black' }}
                                label="Select Applicant"
                              />
                              {applicantOptions.map((applicant) => (
                                <option
                                  style={{ color: 'black' }}
                                  key={applicant?.applicantId}
                                  value={applicant?.applicantId ?? ''}
                                  label={applicant?.companyName ?? ''}
                                  aria-label={applicant?.companyName ?? ''}
                                />
                              ))}
                            </Select>
                          </Box>
                          {touched.applicantId && errors.applicantId && (
                            <div
                              style={{
                                color: 'red',
                                fontSize: '13px',
                                textAlign: 'start',
                                fontWeight: '500',
                              }}
                            >
                              {errors.applicantId}
                            </div>
                          )}
                        </Box>
                      )}
                      {values?.applicantId &&
                        (createApplicationForm.status === 'succeed' ||
                          callesFrom !== 'Documents') &&
                        callesFrom !== 'Documents' &&
                        downloadDocOption === 'Legal Form Documents' && (
                          <Box>
                            <Box maxW="max-content">
                              <Select
                                w="100%"
                                onChange={(event) => {
                                  const selectedOption =
                                    event.target.options[
                                      event.target.selectedIndex
                                    ];
                                  const label =
                                    selectedOption.getAttribute('label') ?? '';
                                  console.log('label', label);

                                  setZipName({
                                    applicantName: zipName?.applicantName ?? '',
                                    // applicationName: label,
                                  });

                                  setFieldValue(
                                    'applicationId',
                                    event.target.value
                                  );
                                }}
                                value={formik?.values?.applicationId ?? ''}
                                name="applicationId"
                                borderRadius="5px"
                                bg={'#114684'}
                                color={'white'}
                                textAlign={'center'}
                              >
                                <option
                                  value={''}
                                  style={{ color: 'black' }}
                                  label="Select Application"
                                />
                                {Array.isArray(ApplicationData) &&
                                  ApplicationData?.map(
                                    (user?: any, index?: number) => (
                                      <option
                                        style={{ color: 'black' }}
                                        key={index}
                                        value={user?.applicationId ?? ''}
                                        label={user?.applicationName ?? ''}
                                      />
                                    )
                                  )}
                              </Select>
                            </Box>
                            {touched.applicationId && errors.applicationId && (
                              <div
                                style={{
                                  color: 'red',
                                  fontSize: '13px',
                                  textAlign: 'start',
                                  fontWeight: '500',
                                }}
                              >
                                {errors.applicationId}
                              </div>
                            )}
                          </Box>
                        )}
                      {values?.applicantId &&
                        downloadDocOption === 'All Documents' && (
                          // createApplicationForm.status === 'succeed' &&
                          <Box>
                            <Box maxW="max-content">
                              <Select
                                name="financial"
                                id="financial"
                                w="100%"
                                borderRadius="5px"
                                bg={'#114684'}
                                color={'white'}
                                textAlign={'center'}
                                value={formik?.values?.financial ?? ''}
                                onChange={(e) => {
                                  // formik.setFieldValue(
                                  //   'financial',
                                  //   e.target.value
                                  // );
                                  // formik.setFieldValue('financialYear', null);
                                  // formik.setFieldValue('financialStatement', null);
                                  formik.setValues({
                                    ...formik.values,
                                    financial: e.target.value,
                                    financialYear: '',
                                    financialStatement: '',
                                    reportAsOf: null,
                                    financialReviewType: '',
                                    startDate: null,
                                    endDate: null,
                                    financialPeriod: '',
                                    financialPeriodRange: '',
                                  });
                                  if (
                                    values?.financialFileWithComment &&
                                    values?.financialFileWithComment?.length >
                                      1 &&
                                    e.target.value === 'Financial Statement'
                                  ) {
                                    formik.setFieldValue(
                                      'financialFileWithComment',
                                      values.financialFileWithComment?.splice(
                                        0,
                                        1
                                      )
                                    );
                                  }
                                }}
                              >
                                <option
                                  value={''}
                                  style={{ color: 'black' }}
                                  label="Select Option"
                                />
                                {Array.isArray(financialOption) &&
                                  financialOption.map(
                                    (item: any, index: number) => (
                                      <option
                                        style={{ color: 'black' }}
                                        key={index}
                                        value={item.value}
                                        label={item.label}
                                      />
                                    )
                                  )}
                              </Select>
                            </Box>
                            {touched.financial &&
                              errors.financial &&
                              values.financial === '' && (
                                <div
                                  style={{
                                    color: 'red',
                                    fontSize: '13px',
                                    textAlign: 'start',
                                    fontWeight: '500',
                                  }}
                                >
                                  {errors.financial}
                                </div>
                              )}
                          </Box>
                        )}
                      {((brokerageDashboard.status === 'loading' &&
                        brokerageDashboard.type ===
                          'GET_APPLICANT_BY_BROKERAGE') ||
                        createApplicationForm.status === 'loading') && (
                        <Flex alignItems={'flex-start'} mt={2}>
                          <Loader size={'sm'} h={'100%'} />
                        </Flex>
                      )}

                      {values?.applicantId &&
                        formik?.values?.financial &&
                        formik?.values?.financial === 'Financial Statement' &&
                        downloadDocOption === 'All Documents' && (
                          <Box>
                            <Box
                              maxW="max-content"
                              className="financial_statement_dropdown"
                            >
                              <Select
                                w="100%"
                                borderRadius="5px"
                                bg={'#114684'}
                                color={'white'}
                                textAlign={'center'}
                                onChange={(e) => {
                                  formik.setValues({
                                    ...formik.values,
                                    financialStatement: e.target.value,
                                    financialYear: '',
                                    reportAsOf: null,
                                    startDate: null,
                                    endDate: null,
                                    financialPeriod: '',
                                    financialPeriodRange: '',
                                  });
                                }}
                                value={formik?.values?.financialStatement ?? ''}
                                name="financialStatement"
                              >
                                <option
                                  value={''}
                                  style={{ color: 'black' }}
                                  label="Select Option"
                                />
                                {Array.isArray(financialStatementOption) &&
                                  financialStatementOption.map(
                                    (item: any, index: number) => {
                                      // const disabledOptions =
                                      //   getDisabledOptions(reportList!);

                                      return (
                                        <option
                                          // style={{
                                          //   color: 'black',
                                          //   cursor: disabledOptions.includes(
                                          //     item.value
                                          //   )
                                          //     ? 'not-allowed !important'
                                          //     : 'default',
                                          //   background:
                                          //     disabledOptions.includes(
                                          //       item.value
                                          //     )
                                          //       ? 'light-grey'
                                          //       : 'white',
                                          // }}
                                          style={{ color: 'black' }}
                                          key={index}
                                          value={item.value}
                                          label={item.label}
                                          // disabled={disabledOptions.includes(
                                          //   item.value
                                          // )}
                                        />
                                      );
                                    }
                                  )}
                              </Select>
                            </Box>
                            {touched.financialStatement &&
                              errors.financialStatement && (
                                <div
                                  style={{
                                    color: 'red',
                                    fontSize: '13px',
                                    textAlign: 'start',
                                    fontWeight: '500',
                                  }}
                                >
                                  {errors.financialStatement}
                                </div>
                              )}
                          </Box>
                        )}

                      {formik?.values?.financialStatement ===
                        'Account Receivable Aging' && (
                        <Tooltip
                          label={
                            'Upload the holdback (retention) value in the last column, if applicable.'
                          }
                          placement="right"
                          hasArrow
                          background={'grey'}
                          minWidth={!!fiscalYearDateRange ? '350px' : 'auto'}
                        >
                          <Box mt={3}>
                            <HiMiniInformationCircle
                              size={18}
                              color="#114684"
                              cursor="pointer"
                            />
                          </Box>
                        </Tooltip>
                      )}

                      {values.applicantId !== '' &&
                        values?.financial &&
                        !['Financial Statement', 'Work in Progress'].includes(
                          values?.financial
                        ) &&
                        downloadDocOption === 'All Documents' && (
                          <Box>
                            <Box maxW="max-content">
                              <Select
                                w="100%"
                                borderRadius="5px"
                                bg={'#114684'}
                                color={'white'}
                                textAlign={'center'}
                                onChange={handleChange}
                                value={formik?.values?.financialYear ?? ''}
                                name="financialYear"
                              >
                                <option
                                  value={''}
                                  style={{ color: 'black' }}
                                  label="Select Option"
                                />
                                {formik.values.financial ===
                                  'Key Personnel Resume' &&
                                callesFrom === 'Documents'
                                  ? keypersonneldata?.map(
                                      (item: any, index: number) => (
                                        <option
                                          style={{ color: 'black' }}
                                          key={index}
                                          value={item.name}
                                          label={item.name}
                                        />
                                      )
                                    )
                                  : formik?.values?.financial ===
                                        'Key Personnel Resume' &&
                                      callesFrom !== 'Documents'
                                    ? keydata?.map(
                                        (item: any, index: number) => (
                                          <option
                                            style={{ color: 'black' }}
                                            key={index}
                                            value={item.name}
                                            label={item.name}
                                          />
                                        )
                                      )
                                    : formik?.values?.financial === 'Other' &&
                                        ((callesFrom === 'Documents' &&
                                          CreditBureauFormData?.paymentPlan) ||
                                          (callesFrom !== 'Documents' &&
                                            paymentPlan))
                                      ? OtherOptionWithtaxingEntity?.map(
                                          (item: any, index: number) => (
                                            <option
                                              style={{ color: 'black' }}
                                              key={index}
                                              value={item.value}
                                              label={item.label}
                                            />
                                          )
                                        )
                                      : getSecondDropDownOption(
                                          formik?.values?.financial
                                        )?.map((item: any, index: number) => (
                                          <option
                                            style={{ color: 'black' }}
                                            key={index}
                                            value={item.value}
                                            label={item.label}
                                          />
                                        ))}
                              </Select>
                            </Box>
                            {touched.financialYear && errors.financialYear && (
                              <div
                                style={{
                                  color: 'red',
                                  fontSize: '13px',
                                  textAlign: 'start',
                                  fontWeight: '500',
                                }}
                              >
                                {errors.financialYear}
                              </div>
                            )}
                          </Box>
                        )}
                      {values.applicantId !== '' &&
                        values?.financial &&
                        values.financial === 'Financial Statement' &&
                        values.financialStatement !== 'Profit and Loss' &&
                        downloadDocOption === 'All Documents' && (
                          <Flex
                            gap={1}
                            flexWrap={'nowrap'}
                            alignItems={'flex-start'}
                          >
                            <Box>
                              {/*date picker*/}
                              <Box maxW="max-content">
                                <DatePickerInput
                                  ref={reportAsOfDateRef}
                                  value={
                                    formik.values.reportAsOf !== null
                                      ? moment(
                                          formik.values.reportAsOf
                                        ).toDate()
                                      : undefined
                                  }
                                  onChange={(date: Date | null) => {
                                    console.log('reportAsOf', date);

                                    // formik.setFieldValue('reportAsOf', date);
                                    // const fiscalYearEndDate =
                                    //   getFiscalYearEndDate();
                                    // const dateRanges = calculateDateRanges(
                                    //   new Date(fiscalYearEndDate!)
                                    // );
                                    console.log(
                                      'fiscalYearDateRange',
                                      fiscalYearDateRange
                                    );
                                    const result = getdateRangeSpan(
                                      fiscalYearDateRange,
                                      date
                                    );
                                    const selectedFinancialYear =
                                      getSelectedFinancialYear(result);
                                    // formik.setFieldValue(
                                    //   'financialYear',
                                    //   selectedFinancialYear
                                    // );

                                    let updateSelectedDate = moment(
                                      moment(date).format('DD MMM YYYY')
                                    ).toISOString();
                                    let financialReviewType =
                                      'Internally Reviewed';
                                    if (
                                      selectedFinancialYear !==
                                      'Most Recent Interim Financial Statement'
                                    ) {
                                      updateSelectedDate =
                                        reportDateForHistoricalOption(
                                          date,
                                          selectedFinancialYear!
                                        )?.toISOString()!;
                                      financialReviewType = 'Review Engagement';
                                      console.log(
                                        'updateSelectedDate',
                                        updateSelectedDate
                                      );
                                      // formik.setFieldValue(
                                      //   'reportAsOf',
                                      //   updateSelectedDate
                                      // );
                                    }

                                    formik.setValues({
                                      ...formik.values,
                                      reportAsOf: updateSelectedDate,
                                      financialYear: selectedFinancialYear!,
                                      financialReviewType: financialReviewType,
                                    });

                                    // setFiscalYearDateRange(dateRanges);
                                  }}
                                  locale="en"
                                  placeholder={'Report Date'}
                                  className={`react-datepicker-component react-datepicker-input input bg-blue upload_report_date`}
                                  showOnInputClick={true}
                                  onShow={() => {
                                    addFocusEventListenerOnInput(
                                      reportAsOfDateRef
                                    );
                                  }}
                                  onHide={() => {
                                    removeFocusEventListenerOnInput(
                                      reportAsOfDateRef
                                    );
                                  }}
                                  displayFormat="MMM DD, YYYY"
                                  // minDate={moment(new Date()).startOf('y')}
                                  // maxDate={moment(new Date()).endOf('y')}
                                  maxDate={moment(new Date()).endOf('d')}
                                />
                              </Box>
                              {touched.reportAsOf && errors.reportAsOf && (
                                <div
                                  style={{
                                    color: 'red',
                                    fontSize: '13px',
                                    textAlign: 'start',
                                    fontWeight: '500',
                                  }}
                                >
                                  {errors.reportAsOf}
                                </div>
                              )}
                              {!!values?.financialYear && (
                                <Text
                                  fontSize="small"
                                  fontWeight={'600'}
                                  mt={2}
                                  ml={1}
                                >
                                  {values?.financialYear === 'Other'
                                    ? 'Historical'
                                    : values?.financialYear}
                                </Text>
                              )}
                            </Box>
                            <Tooltip
                              label={getFiscalYearTooltip()}
                              placement="right"
                              hasArrow
                              background={'grey'}
                              minWidth={'350px'}
                            >
                              <Box mt={3}>
                                <HiMiniInformationCircle
                                  size={18}
                                  color="#114684"
                                  cursor="pointer"
                                />
                              </Box>
                            </Tooltip>
                          </Flex>
                        )}
                      {values.applicantId !== '' &&
                        ['Work in Progress'].includes(values?.financial) && (
                          <Box maxW="max-content">
                            <ChakraButton
                              as="a"
                              href="/document/WIP_Template.xlsx"
                              download="Work in Progress (WIP) Template.xlsx"
                              bg="#114684"
                              color="white"
                              _hover={{ bg: '#114684', color: 'white' }}
                              fontWeight={'normal'}
                              leftIcon={<IconDownload />}
                            >
                              Download Template
                            </ChakraButton>
                          </Box>
                        )}
                    </Flex>
                    <Flex gap={5} flexWrap={'wrap'} alignItems={'center'}>
                      {values.applicantId !== '' &&
                        values?.financial &&
                        values.financial === 'Financial Statement' &&
                        values.financialStatement === 'Profit and Loss' && (
                          <Box>
                            <Box maxW="max-content">
                              <Select
                                w="100%"
                                onChange={(event) =>
                                  financialPeriodRangeHandleChange(event)
                                }
                                value={formik.values.financialPeriodRange}
                                name="financialPeriodRange"
                                borderRadius="5px"
                                bg={'#114684'}
                                color={'white'}
                                textAlign={'center'}
                              >
                                <option
                                  value={''}
                                  style={{ color: 'black' }}
                                  label="Select Duration"
                                />
                                {financialPeriodOptionsRange?.map(
                                  ({ value, label }, index) => (
                                    <option
                                      style={{ color: 'black' }}
                                      key={index}
                                      value={value}
                                      label={label}
                                    />
                                  )
                                )}
                              </Select>
                            </Box>
                            {touched.financialPeriodRange &&
                              errors.financialPeriodRange && (
                                <div
                                  style={{
                                    color: 'red',
                                    fontSize: '13px',
                                    textAlign: 'start',
                                    fontWeight: '500',
                                  }}
                                >
                                  {errors.financialPeriodRange}
                                </div>
                              )}
                          </Box>
                        )}
                      <>
                        {values.applicantId !== '' &&
                          values?.financial &&
                          values.financial === 'Financial Statement' &&
                          values.financialStatement === 'Profit and Loss' &&
                          values.financialPeriodRange &&
                          !['year-to-date', 'custom-range'].includes(
                            values.financialPeriodRange
                          ) && (
                            <Radio.Group
                              value={values?.financialPeriod}
                              onChange={(val) =>
                                financialPeriodHandleChange(
                                  val as financialPeriodOptionsType['value']
                                )
                              }
                              // name="financialPeriod"
                              // label="Select Range"
                              size={'sm'}
                            >
                              <Group mt={5}>
                                {financialPeriodOptions
                                  .filter(
                                    ({ type }) =>
                                      type === values.financialPeriodRange
                                  )
                                  ?.map((item, index) => (
                                    <Radio
                                      key={(index + 1).toString()}
                                      value={item.value}
                                      label={item.label}
                                    />
                                  ))}
                              </Group>
                            </Radio.Group>
                          )}
                      </>
                    </Flex>

                    {values.applicantId !== '' &&
                      values?.financial &&
                      values.financial === 'Financial Statement' &&
                      values.financialStatement === 'Profit and Loss' &&
                      downloadDocOption === 'All Documents' && (
                        <Flex gap={1} flexWrap={'wrap'}>
                          <Box>
                            {/*date picker*/}
                            <Box maxW="max-content">
                              <DatePickerInput
                                ref={startDateRef}
                                value={
                                  formik.values.startDate !== null
                                    ? moment(formik.values.startDate).toDate()
                                    : undefined
                                }
                                onChange={(date: Date | null) => {
                                  console.log('startDate', date);
                                  let PLReportSDate = date?.toISOString();
                                  const result = getdateRangeSpan(
                                    fiscalYearDateRange,
                                    date
                                  );
                                  const selectedFinancialYear =
                                    getSelectedFinancialYear(result);

                                  if (
                                    selectedFinancialYear !==
                                    REPORT_TIME.Interim
                                  ) {
                                    if (selectedFinancialYear !== 'Other') {
                                      console.log(
                                        'fiscalYearDateRange',
                                        fiscalYearDateRange
                                      );
                                      const selectedTimeFrameDateRange =
                                        getDateRangeOfTimeFrame(
                                          fiscalYearDateRange,
                                          selectedFinancialYear!
                                        );
                                      PLReportSDate = moment(
                                        selectedTimeFrameDateRange?.startDate
                                      )?.toISOString();
                                    } else {
                                      const yearEndDateRangeSpan =
                                        findStartAndEndDate(
                                          fiscalYearDateRange,
                                          moment(PLReportSDate).toDate()!
                                        );
                                      PLReportSDate = moment(
                                        yearEndDateRangeSpan?.start
                                      ).toISOString();
                                    }
                                  }

                                  formik.setValues({
                                    ...formik.values,
                                    startDate: PLReportSDate!,
                                    endDate: null,
                                    financialYear: selectedFinancialYear!,
                                  });

                                  // setFiscalYearDateRange(dateRanges);
                                }}
                                locale="en"
                                placeholder={'Start Date'}
                                className={`react-datepicker-component react-datepicker-input input bg-blue upload_report_date`}
                                showOnInputClick={true}
                                onShow={() => {
                                  addFocusEventListenerOnInput(startDateRef);
                                }}
                                onHide={() => {
                                  removeFocusEventListenerOnInput(startDateRef);
                                }}
                                displayFormat="MMM DD, YYYY"
                                disabled={
                                  !['Custom Range'].includes(
                                    values?.financialPeriod
                                  )
                                }
                                // minDate={moment(new Date()).startOf('y')}
                                // maxDate={moment(new Date()).endOf('y')}
                                // maxDate={
                                //   ['Custom Range'].includes(
                                //     values?.financialPeriod
                                //   )
                                //     ? moment(
                                //         fiscalYearDateRange?.mostRecentInterim
                                //           ?.start
                                //       )?.subtract(1, 'd')
                                //     : moment(new Date()).startOf('d')
                                // }
                                maxDate={moment(
                                  fiscalYearDateRange?.mostRecentInterim?.end
                                )}
                              />
                            </Box>
                            {touched.startDate && errors.startDate && (
                              <div
                                style={{
                                  color: 'red',
                                  fontSize: '13px',
                                  textAlign: 'start',
                                  fontWeight: '500',
                                }}
                              >
                                {errors.startDate}
                              </div>
                            )}
                            {!!values?.financialYear && (
                              <Text
                                fontSize="small"
                                fontWeight={'600'}
                                mt={2}
                                ml={1}
                              >
                                {values?.financialYear === 'Other'
                                  ? 'Historical'
                                  : values?.financialYear}
                              </Text>
                            )}
                          </Box>
                          <Box>
                            {/*date picker*/}
                            <Box maxW="max-content">
                              <DatePickerInput
                                ref={endDateDateRef}
                                value={
                                  formik.values.endDate !== null
                                    ? moment(formik.values.endDate).toDate()
                                    : undefined
                                }
                                onChange={(date: Date | null) => {
                                  console.log('endDate', date);
                                  console.log(
                                    'fiscalYearDateRange',
                                    fiscalYearDateRange
                                  );
                                  // const result =
                                  //   getdateRangeSpanFromStartandEndDate(
                                  //     fiscalYearDateRange,
                                  //     getDateFormat(
                                  //       values?.startDate!,
                                  //       'DD MMM YYYY'
                                  //     ),
                                  //     getDateFormat(
                                  //       date?.toISOString()!,
                                  //       'DD MMM YYYY'
                                  //     )
                                  //   );
                                  let PLReportEDate = date?.toISOString();
                                  let financialReviewType =
                                    'Internally Reviewed';

                                  if (
                                    values?.financialYear !==
                                    REPORT_TIME.Interim
                                  ) {
                                    financialReviewType = 'Review Engagement';

                                    if (values?.financialYear !== 'Other') {
                                      console.log(
                                        'fiscalYearDateRange',
                                        fiscalYearDateRange
                                      );
                                      const selectedTimeFrameDateRange =
                                        getDateRangeOfTimeFrame(
                                          fiscalYearDateRange,
                                          values?.financialYear!
                                        );
                                      console.log(
                                        'ttt',
                                        selectedTimeFrameDateRange,
                                        values?.financialYear
                                      );

                                      PLReportEDate = moment(
                                        selectedTimeFrameDateRange?.endDate
                                      )?.toISOString();
                                      console.log(
                                        'PLReportEDate',
                                        PLReportEDate
                                      );
                                    } else {
                                      const yearEndDateRangeSpan =
                                        findStartAndEndDate(
                                          fiscalYearDateRange,
                                          moment(PLReportEDate).toDate()!
                                        );
                                      PLReportEDate = moment(
                                        yearEndDateRangeSpan?.end
                                      ).toISOString();
                                    }
                                  }

                                  formik.setValues({
                                    ...formik.values,
                                    endDate: PLReportEDate!,
                                    // financialYear: selectedFinancialYear!,
                                    financialReviewType: financialReviewType,
                                  });
                                }}
                                locale="en"
                                placeholder={'End Date'}
                                className={`react-datepicker-component react-datepicker-input input bg-blue upload_report_date`}
                                showOnInputClick={true}
                                onShow={() => {
                                  addFocusEventListenerOnInput(endDateDateRef);
                                }}
                                onHide={() => {
                                  removeFocusEventListenerOnInput(
                                    endDateDateRef
                                  );
                                }}
                                displayFormat="MMM DD, YYYY"
                                disabled={
                                  !['Year To Date', 'Custom Range'].includes(
                                    values?.financialPeriod
                                  )
                                }
                                // minDate={moment(values?.startDate)}
                                // maxDate={moment(
                                //   getEndDateMaxDate(values?.financialYear)
                                // )}
                                minDate={moment(values?.startDate).add(1, 'd')}
                                // maxDate={
                                //   ['Custom Range'].includes(
                                //     values?.financialPeriod
                                //   )
                                //     ? moment(
                                //         fiscalYearDateRange?.mostRecentInterim
                                //           ?.start
                                //       )?.subtract(1, 'd')
                                //     : moment(
                                //         fiscalYearDateRange?.mostRecentInterim
                                //           ?.end
                                //       )
                                // }
                                maxDate={
                                  values?.financialYear === REPORT_TIME.Interim
                                    ? moment(
                                        fiscalYearDateRange?.mostRecentInterim
                                          ?.end
                                      )
                                    : moment(values?.startDate)
                                        .add(1, 'y')
                                        .subtract(1, 'd')
                                }
                              />
                            </Box>
                            {touched.endDate && errors.endDate && (
                              <div
                                style={{
                                  color: 'red',
                                  fontSize: '13px',
                                  textAlign: 'start',
                                  fontWeight: '500',
                                }}
                              >
                                {errors.endDate}
                              </div>
                            )}
                          </Box>
                        </Flex>
                      )}
                    {downloadDocOption === 'All Documents' && (
                      <>
                        {isShoWFinancialReviewType(values) && (
                          <Radio.Group
                            value={values?.financialReviewType}
                            onChange={(val) => {
                              formik.setFieldValue('financialReviewType', val);
                            }}
                            // name="financialReviewType"
                            label="Financial Review Type"
                            size={'sm'}
                          >
                            <Group mt={5}>
                              {financialReviewTypeOptions?.map(
                                (item, index) => (
                                  <Radio
                                    key={(index + 1).toString()}
                                    value={item.value}
                                    label={item.label}
                                  />
                                )
                              )}
                            </Group>
                          </Radio.Group>
                        )}
                        <Box>
                          <Box mb={5}>
                            <Flex
                              flexWrap={'wrap'}
                              gap={3}
                              alignItems={'flex-end'}
                            >
                              <FileInput
                                icon={
                                  <IconFileCv
                                    style={{ width: 18, height: 18 }}
                                    stroke={1.5}
                                  />
                                }
                                styles={{
                                  input: {
                                    maxWidth: '350px',
                                    border: '1px solid #114684',
                                    minWidth: '250px',
                                  },
                                }}
                                // @ts-ignore
                                placeholder="Upload files"
                                label="Upload files"
                                multiple={
                                  ![
                                    'Financial Statement',
                                    'Work in Progress',
                                    'Key Personnel Resume',
                                  ].includes(formik?.values?.financial)
                                }
                                onChange={(payload: File[]) =>
                                  handleFileInputUpload(payload)
                                }
                                value={
                                  values.financialFileWithComment?.map(
                                    (file) => file.file
                                  ) ?? []
                                }
                                description={`The file format should be - ${formik?.values?.financial === 'Financial Statement' ? 'xlsx, xls, csv, pdf' : 'xlsx, xls, csv, pdf, doc, docx'}`}
                                withAsterisk
                                variant="filled"
                                accept=".pdf, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                              />
                              <Button
                                disabled={
                                  !values.financialFileWithComment?.length
                                }
                                color="red"
                                onClick={(e) =>
                                  setFieldValue('financialFileWithComment', [])
                                }
                                type="button"
                              >
                                Reset
                              </Button>
                            </Flex>
                            {touched.financialFileWithComment &&
                              errors.financialFileWithComment && (
                                <div
                                  style={{
                                    color: 'red',
                                    fontSize: '13px',
                                    textAlign: 'start',
                                    fontWeight: '500',
                                  }}
                                >
                                  {errors.financialFileWithComment}
                                </div>
                              )}
                          </Box>
                          {values.financialFileWithComment &&
                            values.financialFileWithComment.length > 0 && (
                              <List
                                spacing="xs"
                                size="sm"
                                center
                                type="ordered"
                                mb={16}
                              >
                                {values.financialFileWithComment?.map(
                                  (data, index) => (
                                    <List.Item className="file-item">
                                      <Box w={500}>
                                        <Flex gap={2} mb={1}>
                                          <Text>{data.file.name}</Text>
                                          <Tooltip
                                            label="Delete"
                                            placement={'top'}
                                            hasArrow
                                          >
                                            <Box>
                                              <CgTrash
                                                style={{
                                                  cursor: 'pointer',
                                                }}
                                                className="file-item-trash"
                                                onClick={() => {
                                                  const newData =
                                                    values.financialFileWithComment?.filter(
                                                      (_, i) => i !== index
                                                    );

                                                  setFieldValue(
                                                    `financialFileWithComment`,
                                                    newData
                                                  );
                                                }}
                                              />
                                            </Box>
                                          </Tooltip>
                                          <Tooltip
                                            label="Additional Info"
                                            placement={'top'}
                                            hasArrow
                                          >
                                            <Box>
                                              <CgComment
                                                style={{
                                                  cursor: 'pointer',
                                                }}
                                                className="file-item-trash"
                                                onClick={() =>
                                                  toggleCommentBox(
                                                    index,
                                                    data.comment!
                                                  )
                                                }
                                              />
                                            </Box>
                                          </Tooltip>
                                        </Flex>
                                        {data.comment !== null && (
                                          <Textarea
                                            placeholder="comment"
                                            label="Comment"
                                            autosize
                                            minRows={2}
                                            maxRows={4}
                                            value={data.comment ?? ''}
                                            onChange={(event) =>
                                              setFieldValue(
                                                `financialFileWithComment[${index}].comment`,
                                                event.currentTarget.value
                                              )
                                            }
                                            styles={{
                                              wrapper: {
                                                marginBottom: 16,
                                              },
                                            }}
                                          />
                                        )}
                                      </Box>
                                    </List.Item>
                                  )
                                )}
                              </List>
                            )}
                        </Box>
                        <Box>
                          <ChakraButton
                            isLoading={S3UploadLoading || isSubmitDoc}
                            type="submit"
                            bg="#114684"
                            color="white"
                            _hover={{ bg: '#114684', color: 'white' }}
                            loadingText={'Submit Documents'}
                            // disabled={!(formik.isValid && formik.dirty)}
                          >
                            Submit Documents
                          </ChakraButton>
                        </Box>
                      </>
                    )}
                  </Flex>
                </form>
              </Box>
            )}
          </>
        )}
        {/* <Box p={5}>
          <Select
            // placeholder="Select option"
            maxW={'max-content'}
            onChange={handleChangeDownloadOption}
          >
            {exportDocumentDownloadOptions?.map((item: any) => (
              <option value={item?.value}>{item?.label}</option>
            ))}
          </Select>
        </Box> */}
        <Box px={5}>
          {zipName &&
            values.applicantId !== '' &&
            downloadDocOption === 'All Documents' && (
              <UploadFilesTable
                formState={formik?.values}
                isTableLoading={isTableLoading}
                zipName={zipName}
              />
            )}
          {zipName &&
            values.applicantId !== '' &&
            values.applicationId !== '' &&
            (createApplicationForm.status === 'succeed' ||
              callesFrom === 'Documents') &&
            downloadDocOption === 'Legal Form Documents' && (
              <LegalFormUploadFilesTable
                isTableLoading={isTableLoading}
                zipName={zipName}
                legalFormDocuments={legalFormDocuments}
              />
            )}
        </Box>
      </Box>
      <WIPProjectListModal
        isOpen={showProjectListModal}
        onClose={closeProjectListModal}
        isUpdateStatusLoader={loader.isUpdateProjectStatus}
        uploadTemplte={progressReport?.uploadTemplte!}
      />
      <WIPBondListModal
        isOpen={showBondListModal}
        onClose={closeBondListModal}
        isUpdateStatusLoader={loader.isBondNumberUpdating}
        uploadTemplte={progressReport?.uploadTemplte!}
      />
    </>
  );
}

export default UploadDropdowns;
