import { EditIcon } from '@chakra-ui/icons';
import {
  Badge,
  Box,
  Tooltip as ChakraTooltip,
  Flex,
  Heading,
  Text,
} from '@chakra-ui/react';
import { ActionIcon, Divider, Progress, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { ModalsProvider } from '@mantine/modals';
import { IconEye } from '@tabler/icons-react';
import {
  MRT_ColumnDef,
  MRT_GlobalFilterTextInput,
  MRT_Row,
  MRT_TablePagination,
  MRT_ToolbarInternalButtons,
  MantineReactTable,
  useMantineReactTable,
} from 'mantine-react-table';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppToast } from 'src/hooks';
import {
  getIssueCurrentStatus,
  getOcrReportIssueTableData,
} from 'src/Pages/Admin/OcrReportIssues/components/data';
import ViewOcrIssueModal from 'src/Pages/Admin/OcrReportIssues/components/ViewOcrIssueModal';
import {
  getBrokerOcrReportIssueList,
  getOcrReportIssueById,
  removeOcrReportIssueSliceType,
} from 'src/Redux/Admin/OcrReportIssues/slice';
import { IOcrReportIssueList } from 'src/Redux/Admin/OcrReportIssues/state';
import { useAppDispatch, useAppSelector } from 'src/Redux/Store';

const BrokerOCRIssueListTable = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const toast = useAppToast();
  const { id } = useParams();
  const ocrReportIssueSlice = useAppSelector(
    (state) => state.OcrReportIssueSlice
  );
  const [selectedEditRow, setSelectedEditRow] = useState<{
    row: MRT_Row<IOcrReportIssueList> | null;
  }>({
    row: null,
  });
  const [loader, setLoader] = useState({
    btnLoader: false,
    tableContentLoader: false,
  });
  const [tableData, setTableData] = useState<IOcrReportIssueList[]>([]);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [
    isviewOcrIssueModal,
    { open: openViewOcrIssueModal, close: closeViewOcrIssueModal },
  ] = useDisclosure(false);
  const userType = localStorage.getItem('userType');

  const getBrokerOcrReportIssueListHandler = () => {
    dispatch(getBrokerOcrReportIssueList());
  };

  useEffect(() => {
    getBrokerOcrReportIssueListHandler();
    return () => {
      dispatch(removeOcrReportIssueSliceType());
    };
  }, []);

  useEffect(() => {
    if (!!id) {
      dispatch(getOcrReportIssueById({ id: Number(id) }));
    }
  }, [id]);

  useEffect(() => {
    const { status, type, error, ocrIssueListByUser, msg } =
      ocrReportIssueSlice;
    switch (status) {
      case 'loading': {
        if (type === 'GET_LOGINUSER_OCR_REPROT_ISSUE_LIST') {
          setLoader({ ...loader, tableContentLoader: true, btnLoader: false });
        }
        if (type === 'OCR_ISSUE_STATUS_UPDATE_BY_SUPPORT_ADMIN') {
          setLoader({ ...loader, btnLoader: true });
        }
        break;
      }
      case 'succeed': {
        if (type === 'GET_LOGINUSER_OCR_REPROT_ISSUE_LIST') {
          setLoader({ ...loader, tableContentLoader: false, btnLoader: false });
          const tData = getOcrReportIssueTableData(ocrIssueListByUser);
          setTableData(tData);
        }
        if (type === 'OCR_ISSUE_STATUS_UPDATE_BY_SUPPORT_ADMIN') {
          setLoader({ ...loader, btnLoader: false });
          toast({
            title: msg,
            status: 'success',
          });
          handleCloseOcrIssueModal();
          getBrokerOcrReportIssueListHandler();
        }
        break;
      }
      case 'failed': {
        if (type === 'GET_LOGINUSER_OCR_REPROT_ISSUE_LIST') {
          setLoader({ ...loader, tableContentLoader: false, btnLoader: false });
          toast({
            title: error,
            status: 'error',
          });
        }
        if (type === 'OCR_ISSUE_STATUS_UPDATE_BY_SUPPORT_ADMIN') {
          setLoader({ ...loader, btnLoader: false });
          toast({
            title: error,
            status: 'error',
          });
          handleCloseOcrIssueModal();
        }
        break;
      }

      default:
        break;
    }
  }, [ocrReportIssueSlice?.status, ocrReportIssueSlice?.type]);

  const columns = useMemo<MRT_ColumnDef<IOcrReportIssueList>[]>(
    () => [
      // {
      //   accessorKey: 'id',
      //   header: 'ID',
      //   size: 2,
      // },
      {
        accessorKey: 'ticketId',
        header: 'Ticket No.',
        size: 5,
        maxSize: 5,
        minSize: 5,
        Cell: ({ row }) => {
          const { uniqueId } = row?.original;
          return <Text>{`#${uniqueId}`}</Text>;
        },
      },
      {
        accessorKey: 'reportId',
        header: 'Report ID',
        size: 5,
        maxSize: 5,
        minSize: 5,
        Cell: ({ row }) => {
          const { reportId } = row?.original;
          return <Text>{reportId}</Text>;
        },
      },
      {
        accessorKey: 'brokerEmail',
        header: 'Broker Email',
        size: 5,
        maxSize: 5,
        minSize: 5,
        Cell: ({ row }) => {
          const { brokerName, brokerEmail } = row?.original;
          return (
            <ChakraTooltip
              label={brokerName}
              placement="right"
              fontSize={'md'}
              hasArrow
            >
              <Text display="inline-block">{brokerEmail}</Text>
            </ChakraTooltip>
          );
        },
      },
      {
        accessorKey: 'applicantEmail',
        header: 'Applicant Email',
        size: 5,
        maxSize: 5,
        minSize: 5,
        Cell: ({ row }) => {
          const { applicantName, applicantEmail } = row?.original;
          return (
            <ChakraTooltip
              label={applicantName}
              placement="right"
              fontSize={'md'}
              hasArrow
            >
              <Text display="inline-block">{applicantEmail}</Text>
            </ChakraTooltip>
          );
        },
      },
      {
        accessorKey: 'reason',
        header: 'Issue',
        Cell: ({ row }) => {
          const { reason } = row?.original;
          return <Box>{reason && !!reason?.length && reason?.[0]}</Box>;
        },
      },
      {
        accessorKey: 'status',
        header: 'Status',
        Cell: ({ row }) => {
          const { status } = row?.original;
          return (
            <Badge
              bg={getIssueCurrentStatus(status[status?.length - 1]!)?.bgColor}
              color={
                getIssueCurrentStatus(status[status?.length - 1]!)?.textColor
              }
              px="2"
              py="1"
              borderRadius="md"
            >
              {
                getIssueCurrentStatus(status[status?.length - 1]!)
                  ?.updatedStatus
              }
            </Badge>
          );
        },
      },
      {
        accessorKey: 'ageing',
        header: 'Aging',
        Cell: ({ row }) => {
          const { ageing } = row?.original;
          return <Text>{ageing ?? 0}</Text>;
        },
      },
      // {
      //   accessorKey: 'createdAt',
      //   header: 'Date',
      //   Cell: ({ row, cell }: { row: any; cell: any }) => {
      //     return getDateFormat(row?.original?.createdAt, 'DD-MM-YYYY hh:mm A');
      //   },
      // },
    ],
    []
  );

  const table = useMantineReactTable({
    columns: columns,
    data: tableData as any,
    mantineCreateRowModalProps: {
      className: '',
    },
    layoutMode: 'semantic',
    createDisplayMode: 'modal', //default ('row', and 'custom' are also available)
    editDisplayMode: 'custom', //default ('row', 'cell', 'table', and 'custom' are also available)
    enableEditing: true,
    getRowId: (row, index) => row?.id?.toString() ?? '' + index,
    defaultColumn: { minSize: 20, maxSize: 20, size: 20 },
    initialState: {
      density: 'xs',
      columnPinning: { left: ['mrt-row-actions'] },
    },
    mantineTableContainerProps: {
      sx: {
        tableLayout: 'fixed',
        width: '100%',
        overflow: loader?.tableContentLoader ? 'hidden' : 'auto',
      },
      className: 'upload-reports-table',
    },
    mantineTableHeadCellProps: {
      sx: {
        '& .mantine-TableHeadCell-Content': {
          justifyContent: 'space-between',
        },
      },
    },
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: 'Actions', //change header text
        size: 10, //make actions column wider
        maxSize: 1,
        minSize: 1,
        mantineTableHeadCellProps: {
          sx: {
            // maxWidth: '100px',
            zIndex: 999,
          },
        },
      },
    },
    enableTopToolbar: true,
    enablePagination: true,
    positionPagination: 'none',
    enableBottomToolbar: false,
    // enableExpanding: false,
    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        <Tooltip label="View" position="right">
          <ActionIcon
            // disabled={showBox}
            onClick={() => {
              setSelectedEditRow({
                row,
              });
              setIsEditMode(false);
              navigate(`/broker/upload/report-issues/${row?.original?.id}`);
              openViewOcrIssueModal();
            }}
            size={'sm'}
          >
            <IconEye />
          </ActionIcon>
        </Tooltip>
        {['completed'].includes(
          row?.original?.status?.[row?.original?.status?.length - 1]!
        ) &&
          userType === 'broker' &&
          !['closed'].includes(
            row?.original?.status?.[row?.original?.status?.length - 1]!
          ) && (
            <Tooltip label="Update" position="right">
              <ActionIcon
                // disabled={showBox}
                onClick={() => {
                  setSelectedEditRow({
                    row,
                  });
                  setIsEditMode(true);
                  navigate(`/broker/upload/report-issues/${row?.original?.id}`);
                  openViewOcrIssueModal();
                }}
                size={'sm'}
              >
                <EditIcon />
              </ActionIcon>
            </Tooltip>
          )}
      </Flex>
    ),
    renderTopToolbar: ({ table }) => {
      // console.log('tableData', tableData, legalFormDocuments);

      return (
        <Flex direction={'column'}>
          <Flex
            justifyContent={'flex-end'}
            alignItems={'center'}
            flexWrap={'wrap'}
            ml={'12px'}
          >
            <Flex py={1} alignItems={'center'} flexWrap={'wrap'}>
              <Flex alignItems={'center'} gap={4} p={3}>
                <MRT_GlobalFilterTextInput table={table} />
                <MRT_ToolbarInternalButtons table={table} />
              </Flex>
              <Divider
                size="md"
                orientation="vertical"
                h={40}
                style={{ alignSelf: 'center' }}
              />
              <Box className="pagination-wrapper">
                <MRT_TablePagination position="top" table={table} />
              </Box>
            </Flex>
          </Flex>
          {loader?.tableContentLoader && (
            <Progress value={100} animate={true} />
          )}
        </Flex>
      );
    },
    state: {
      showProgressBars: loader?.tableContentLoader,
      isLoading: loader?.tableContentLoader,
    },
  });

  const handleCloseOcrIssueModal = () => {
    const navigatePath =
      userType === 'broker' ? '/broker/upload/report-issues' : '/report-issues';
    setSelectedEditRow({
      row: null,
    });
    navigate(navigatePath);
    closeViewOcrIssueModal();
  };

  return (
    <Box>
      <Heading
        p="4"
        fontSize={'24px'}
        fontWeight={'600'}
        borderBottomWidth="1px"
      >
        Ticket List
      </Heading>
      <Flex direction={'column'} gap={12} alignItems={'center'} mx={10}>
        <Box maxW={'1550px'} w={'100%'} py={5}>
          <ModalsProvider>
            <MantineReactTable table={table} key={'upload'} />
          </ModalsProvider>
        </Box>
      </Flex>
      <ViewOcrIssueModal
        isOpen={isviewOcrIssueModal}
        onClose={handleCloseOcrIssueModal}
        row={selectedEditRow.row!}
        submitLoader={loader?.btnLoader}
        isEditMode={isEditMode}
      />
    </Box>
  );
};

export default BrokerOCRIssueListTable;
