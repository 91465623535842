import { HStack, Tooltip } from '@chakra-ui/react';
import { Box, Flex, MultiSelect, Text, TextInput } from '@mantine/core';
import type React from 'react';
import { useEffect, useMemo } from 'react';
import { HiMiniInformationCircle } from 'react-icons/hi2';
import { useEfficiencyContext } from 'src/context/EfficiencyRatioContext';
import {
  CalculationFields,
  calculateTotalWthPLBS,
  getBSAssetsOptions,
  getStyles,
} from '../../data';

interface CalculationDisplayTangibleNetworthEffProps {
  label: string;
  calculationFields: CalculationFields;
}

export const CalculationDisplayTangibleNetworthEff: React.FC<
  CalculationDisplayTangibleNetworthEffProps
> = ({ label, calculationFields }) => {
  const {
    totalAssets,
    setTotalAssets,
    totalLiabilities,
    setTotalLiabilities,
    inTangibleAssets,
    setInTangibleAssets,
    setTangibleNetworth,
    reportReferenceData,
    historyData,
  } = useEfficiencyContext();

  const isTotalAssetOption = !!calculationFields?.totalAssets?.fields?.length;
  const isTotalAssetValue = !!calculationFields?.totalAssets?.value
    ? calculationFields?.totalAssets?.value
    : 0;
  const isTotalLiabilitiesOption =
    !!calculationFields?.totalLiabilities?.fields?.length;
  const isTotalLiabilitiesValue = !!calculationFields?.totalLiabilities?.value
    ? calculationFields?.totalLiabilities?.value
    : 0;
  const isInTangibleAssetOption =
    !!calculationFields?.inTangibleAssets?.fields?.length;
  const isInTangibleAssetValue = !!calculationFields?.inTangibleAssets?.value
    ? calculationFields?.inTangibleAssets?.value
    : 0;

  const totalAssetSelectedFieldSumInitial = useMemo(() => {
    return calculateTotalWthPLBS(
      totalAssets,
      calculationFields?.totalAssets,
      reportReferenceData
    );
  }, []);
  const assetsTotalInitialValue =
    isTotalAssetValue - totalAssetSelectedFieldSumInitial;

  const totalLiabilitiesSelectedFieldSumInitial = useMemo(() => {
    return calculateTotalWthPLBS(
      totalLiabilities,
      calculationFields?.totalLiabilities,
      reportReferenceData
    );
  }, []);
  const liabilitiesTotalInitialValue =
    isTotalLiabilitiesValue - totalLiabilitiesSelectedFieldSumInitial;

  const inTangibleAssetSelectedFieldSumInitial = useMemo(() => {
    return calculateTotalWthPLBS(
      inTangibleAssets,
      calculationFields?.inTangibleAssets,
      reportReferenceData
    );
  }, []);
  const intangibleAssetsTotalInitialValue =
    isInTangibleAssetValue - inTangibleAssetSelectedFieldSumInitial;

  const assetsTotal = useMemo(() => {
    const totalSumSelectedFields = calculateTotalWthPLBS(
      totalAssets,
      calculationFields?.totalAssets,
      reportReferenceData
    );
    return Number(
      Number(totalSumSelectedFields + assetsTotalInitialValue)?.toFixed(2)
    );
  }, [totalAssets, calculationFields?.totalAssets]);

  const liabilitiesTotal = useMemo(() => {
    const totalSumSelectedFields = calculateTotalWthPLBS(
      totalLiabilities,
      calculationFields?.totalLiabilities,
      reportReferenceData
    );
    return Number(
      Number(totalSumSelectedFields + liabilitiesTotalInitialValue)?.toFixed(2)
    );
  }, [totalLiabilities, calculationFields?.totalLiabilities]);

  const inTangibleAssetsTotal = useMemo(() => {
    const totalSumSelectedFields = calculateTotalWthPLBS(
      inTangibleAssets,
      calculationFields?.inTangibleAssets,
      reportReferenceData
    );
    return Number(
      Number(
        totalSumSelectedFields + intangibleAssetsTotalInitialValue
      )?.toFixed(2)
    );
  }, [inTangibleAssets, calculationFields?.inTangibleAssets]);

  const result = useMemo(() => {
    console.log('result', assetsTotal, liabilitiesTotal, inTangibleAssetsTotal);
    return Number(
      (assetsTotal - liabilitiesTotal - inTangibleAssetsTotal).toFixed(2)
    );
  }, [assetsTotal, liabilitiesTotal, inTangibleAssetsTotal]);

  useEffect(() => {
    setTangibleNetworth(result);
  }, [result]);

  return (
    <Box w={'100%'}>
      <HStack gap={2} mb="md">
        <Text size="lg" weight={700}>
          {label}
        </Text>
        {!!historyData?.tangibleNetworth && (
          <Tooltip
            label={`${label} (${historyData?.tangibleNetworth?.value}) is updated by ${historyData?.tangibleNetworth?.updatedBy}`}
            placement="right"
            hasArrow
          >
            <Box>
              <HiMiniInformationCircle
                size={18}
                color="black"
                cursor="pointer"
              />
            </Box>
          </Tooltip>
        )}
      </HStack>
      <Flex direction="column" align="stretch" gap="md">
        <Flex wrap={'wrap'} align="center" justify={'flex-start'} gap="xs">
          <MultiSelect
            label={`Total Assets (${assetsTotal})`}
            data={getBSAssetsOptions(
              calculationFields?.totalAssets?.fields!,
              reportReferenceData!
            )}
            value={totalAssets}
            onChange={setTotalAssets}
            placeholder="Select Total Assets items"
            searchable
            className="legalform_pdfPreference"
            maxDropdownHeight={400}
            sx={{ flex: 1 }}
            styles={getStyles(calculationFields?.totalAssets?.fields!)}
            readOnly
          />
          <Text size="xl" weight={700} mt={'xs'}>
            -
          </Text>
          <MultiSelect
            label={`Total Liabilities (${liabilitiesTotal})`}
            data={getBSAssetsOptions(
              calculationFields?.totalLiabilities?.fields!,
              reportReferenceData!
            )}
            value={totalLiabilities}
            onChange={setTotalLiabilities}
            placeholder="Select Total Liabilities items"
            searchable
            className="legalform_pdfPreference"
            maxDropdownHeight={400}
            sx={{ flex: 1 }}
            styles={getStyles(calculationFields?.totalLiabilities?.fields!)}
            readOnly
          />
        </Flex>
        <Flex align="center" justify="flex-start" wrap={'wrap'} gap={'xs'}>
          <Text size="xl" weight={700} mt={'xs'}>
            -
          </Text>
          <MultiSelect
            label={`Intangible Assets (${inTangibleAssetsTotal})`}
            data={getBSAssetsOptions(
              calculationFields?.inTangibleAssets?.fields!,
              reportReferenceData!
            )}
            value={inTangibleAssets}
            onChange={setInTangibleAssets}
            placeholder="Select Intangible Assets items"
            searchable
            className="legalform_pdfPreference"
            maxDropdownHeight={400}
            sx={{ flex: 1 }}
          />
          {/* Equals Sign */}
          <Text size="xl" weight={700} mt={'xs'}>
            =
          </Text>
          {/* Result */}
          <TextInput
            label="Result"
            value={result ?? 0}
            disabled
            styles={{
              input: {
                textAlign: 'right',
                '&:disabled': {
                  color: 'black',
                  fontWeight: 'bold',
                },
              },
            }}
            sx={{ flex: 1 }}
          />
        </Flex>
      </Flex>
    </Box>
  );
};
