import {
  Box,
  Button,
  Checkbox,
  Container,
  Input,
  Stack,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { isNil, omitBy } from 'lodash';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { CiCircleMinus, CiCirclePlus } from 'react-icons/ci';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getApplicationById } from 'src/Redux/Applications/slice';
import { suretyvalidationSchema } from 'src/ValidationSchema/Pages/Index';
import { useAppToast } from 'src/hooks';
import Swal from 'sweetalert2';
import {
  RescindFromApplicant,
  surety,
  suretyUpdate,
} from '../../../Redux/Broker/CreateApplicationForm/CreateApplicationForm';
import { getApplicantHistory } from '../../../Redux/Broker/Dashboard/History';
import { RootState, useAppSelector } from '../../../Redux/Store';
import {
  deepObjectComparison,
  formatDate,
  preventAlphabetsTyping,
  trimStringValues,
} from '../../../utils/helpers';
import { IsSubmited } from './Stepper';

const Surety = forwardRef<
  any,
  {
    formData?: TSuretyAPIData;
    isSubmited: IsSubmited;
    setIsSubmited: (pros: IsSubmited) => void;
  }
>(({ isSubmited, setIsSubmited, formData }, ref) => {
  console.log('Surety-formData', formData);

  const [showTextarea, setShowTextarea] = useState(false);
  const [isReturnToSender, setIsReturnToSender] = useState(false);
  const [loader, setLoader] = useState<{ btnLoader: boolean }>({
    btnLoader: false,
  });
  const [rescindFromApplicant, setRescindFromApplicant] =
    useState<boolean>(false);
  const [historyIndex, setHistoryIndex] = useState(0);
  const [historyData, setHistoryData] = useState<Partial<TSurety> | null>(null);

  const location = useLocation();
  const { showBox, showHistory, applicantId } = location.state || {};

  const navigate = useNavigate();
  const Toast = useToast();
  const dispatch = useDispatch();
  // const userData = useSelector(
  //   (state: RootState) => state.createApplicationFormSlice.user
  // );
  const HistoryData = useSelector((state: RootState) => state.history.user);
  const createApplicationForm = useAppSelector(
    (state: RootState) => state.createApplicationFormSlice
  );
  const toast = useAppToast();
  const currentHistoryData = HistoryData?.data[historyIndex]?.details;

  // let userGetidData = userData && userData.data;
  // const data: any = useSelector(
  //   (state: RootState) => state.brokerageDashboard.user
  // );
  const paramsData = useParams<{ id: string }>();
  const ongoingID = paramsData.id ? parseInt(paramsData.id, 10) : null;
  // let suretyFormData = data && data?.data?.surety;

  const userType = localStorage.getItem('userType');
  const isApplicantUser = userType === 'applicant';
  const applicationId = formData?.applicationId;
  const brokerId = formData?.brokerId;

  //applicant fetch
  // const applicantFetch = useSelector(
  //   (state: RootState) => state.notification.user
  // );
  // const applicantFetchData =
  //   applicantFetch && applicantFetch?.data?.applications;
  // const applicantObject = applicantFetchData?.find(
  //   (applicant: any) => applicant.applicationId === ongoingID
  // );
  // useEffect(() => {
  //   if (ongoingID) {
  //     fetchOngoingApplicant();
  //   }
  //   if (!showHistory) {
  //     fetchDashboardData();
  //   }
  /*!showHistory &&
      dispatch(fetchDashboardDetails({ applicationFilter: "" }) as any);*/
  // }, [dispatch, ongoingID]);

  useEffect(() => {
    if (HistoryData?.data[historyIndex]?.details?.updatedData) {
      const data = omitBy(
        HistoryData?.data[historyIndex]?.details?.updatedData,
        isNil
      );
      setHistoryData(data);
    }
  }, [HistoryData, historyIndex]);

  useEffect(() => {
    const { status, type, error, user } = createApplicationForm;

    switch (status) {
      case 'loading': {
        if (
          type === 'RESCIND_APPLICATION_FROM_APPLICANT' &&
          rescindFromApplicant
        ) {
          setLoader({ ...loader, btnLoader: true });
        }
        break;
      }
      case 'succeed': {
        if (
          type === 'RESCIND_APPLICATION_FROM_APPLICANT' &&
          rescindFromApplicant
        ) {
          setLoader({ ...loader, btnLoader: false });
          setRescindFromApplicant(false);
          toast({
            status: 'success',
            title: user?.data,
          });
          fetchOngoingApplication();
        }
        break;
      }
      case 'failed': {
        if (
          type === 'RESCIND_APPLICATION_FROM_APPLICANT' &&
          rescindFromApplicant
        ) {
          setLoader({ ...loader, btnLoader: false });
          setRescindFromApplicant(false);
          toast({
            status: 'error',
            title: error,
          });
        }
        break;
      }

      default:
        break;
    }
  }, [createApplicationForm.status]);

  // const fetchOngoingApplicant = async () => {
  //   if (!!localStorage.getItem('Token')) {
  //     let id = !showHistory && ongoingID ? ongoingID : 0;
  //     id = showHistory && applicantId ? applicantId : id;
  //     let actionResult = await dispatch(OngoingApplicant({ id: id }) as any);
  //     if (actionResult?.payload?.status === 401) {
  //       navigate('/login');
  //     }
  //   } else {
  //     navigate('/login');
  //   }
  // };

  // const fetchDashboardData = async () => {
  //   if (!!localStorage.getItem('Token')) {
  //     let actionResult = await dispatch(
  //       fetchDashboardDetails({ applicationFilter: '' }) as any
  //     );
  //     if (actionResult?.payload?.status === 401) {
  //       navigate('/login');
  //     }
  //   } else {
  //     navigate('/login');
  //   }
  // };

  useEffect(() => {
    const fetchData = async () => {
      if (!!localStorage.getItem('Token')) {
        if (ongoingID && showHistory) {
          let actionResult = await dispatch(
            getApplicantHistory({
              applicationId: ongoingID,
              modelName: 'Surety',
            }) as any
          );
          if (actionResult?.payload?.status === 401) {
            navigate('/login');
          }
        }
      } else {
        navigate('/login');
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (formData) {
      const whoSignSeal = formData?.whoSignSeal
        ? formData?.whoSignSeal?.map((company) => ({
            firstName: company.firstName || null,
            lastName: company.lastName || null,
            position: company.position || null,
          }))
        : initialValues?.whoSignSeal;
      const apiValues = {
        ...initialValues,
        brokerId: formData?.brokerId || null,

        applicationId: formData?.applicationId,
        applicantId: formData?.applicantId,

        suretyName: formData?.suretyName || null,
        totalWorkOnHand: formData?.totalWorkOnHand || null,
        singleJob: formData?.singleJob || null,
        reasonChangingSurety: formData?.reasonChangingSurety || null,
        refusedBond: formData?.refusedBond || null,
        refusedBondExplanation: formData?.refusedBondExplanation || null,
        returnToSender: userType === 'broker' ? false : null,
        applicationName: formData?.applicationName || null,
        whoSignSeal: whoSignSeal,
      };
      setShowTextarea(formData?.refusedBond === true);
      setInitialValues(apiValues);
    }
  }, [formData, currentHistoryData]);

  const [initialValues, setInitialValues] = useState<TSurety>({
    applicationId: applicationId!,

    brokerId: brokerId!,
    applicantId: formData?.applicantId!,

    suretyName: null,
    totalWorkOnHand: null,
    singleJob: null,
    reasonChangingSurety: null,
    refusedBond: null,
    refusedBondExplanation: null,
    returnToSender: userType === 'broker' ? false : null,
    applicationName: null,
    whoSignSeal: [
      {
        firstName: null,
        lastName: null,
        position: null,
      },
    ],
  });

  const OnHandelReturnToSender = () => {
    setIsReturnToSender(true);
  };

  const OnHandleReturnToBroker = () => {
    setRescindFromApplicant(true);
    dispatch(
      RescindFromApplicant({
        applicationId: formData?.applicationId!,
        formName: 'surety',
      }) as any
    );
  };

  // const fetchId = suretyId ? suretyId : ongoingsuretyId;
  // const fetchId = true;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: suretyvalidationSchema,
    onSubmit: async (values: TSurety) => {
      setIsSubmited({ ...isSubmited, Surety: true });
      let res = '';
      try {
        let whoSignSeal: Array<{
          firstName: string | null;
          lastName: string | null;
          position: string | null;
        }> = [];
        values.whoSignSeal.forEach((obj) => {
          let empty = false;
          if (
            !(
              (obj.firstName === null || obj.firstName.trim() === '') &&
              (obj.lastName === null || obj.lastName.trim() === '') &&
              (obj.position === null || obj.position.trim() === '')
            )
          )
            whoSignSeal.push(obj);
        });
        if (whoSignSeal.length === 0)
          whoSignSeal.push({
            firstName: null,
            lastName: null,
            position: null,
          });
        values.whoSignSeal = whoSignSeal;

        console.log('whoSignSeal=', whoSignSeal);

        let duplicate = null;

        for (var i = 0; i < whoSignSeal.length; i++) {
          duplicate = whoSignSeal.filter(
            (obj) =>
              obj.firstName?.toLowerCase().trim() ===
                whoSignSeal[i].firstName?.toLowerCase().trim() &&
              obj.lastName?.toLowerCase().trim() ===
                whoSignSeal[i].lastName?.toLowerCase().trim() &&
              obj.position?.toLowerCase().trim() ===
                whoSignSeal[i].position?.toLowerCase().trim()
          );
          if (duplicate !== null && duplicate.length > 1) break;
          duplicate = null;
        }

        if (duplicate === null) {
          if (!isReturnToSender) {
            let editedValues = {};

            console.log('form vallll=', values);

            const updatedData: Partial<TSurety> = deepObjectComparison(
              values,
              formData
            );
            // const keys: (keyof TSurety)[] = Object.keys(
            //   values
            // ) as (keyof TSurety)[];

            // for (const key of keys) {
            //   if (!deepObjectComparison(values[key], suretyFormData[key])) {
            //     (editedValues as any)[key] = values[key];
            //   }
            // }

            editedValues = {
              ...values,
              updatedData: updatedData,
              // returnToSender: isApplicantUser,
              // returnToSender: false,
              applicationId: formData?.applicationId!,
              applicantId: formData?.applicantId,
              brokerId: formData?.brokerId!,
              applicationName: formData?.applicationName!,
            };
            const trimedValues = trimStringValues(editedValues);
            const actionResult = await dispatch(
              suretyUpdate({
                updatedData: trimedValues,
                applicationId: applicationId,
              }) as any
            );
            const response = actionResult?.payload;
            res = response?.msg;
            const resStatus = response?.status;

            if (resStatus === 200) {
              Toast({
                title: response.msg,
                status: 'success',
                isClosable: true,
                duration: 3000,
                position: 'top-right',
              });
              fetchOngoingApplication();
            } else {
              if (resStatus === 401) {
                navigate('/login');
              } else {
                Toast({
                  title: res,
                  status: 'error',
                  isClosable: true,
                  duration: 3000,
                  position: 'top-right',
                });
              }
            }
          } else if (isReturnToSender) {
            let editedValues: any = {};
            const updatedData: Partial<TSurety> = deepObjectComparison(
              values,
              formData
            );
            // const keys: (keyof TSurety)[] = Object.keys(
            //   values
            // ) as (keyof TSurety)[];

            // for (const key of keys) {
            //   if (!deepObjectComparison(values[key], suretyFormData[key])) {
            //     (editedValues as any)[key] = values[key];
            //   }
            // }

            editedValues = {
              ...values,
              updatedData: updatedData,
              // returnToSender: isApplicantUser,
              returnToSender: userType === 'applicant' ? false : true,
              applicationId: formData?.applicationId!,
              applicantId: formData?.applicantId!,
              brokerId: formData?.brokerId!,
              applicationName: formData?.applicationName!,
            };
            const trimedValues = trimStringValues(editedValues);
            setLoader({ ...loader, btnLoader: true });
            const actionResult = await dispatch(
              suretyUpdate({
                updatedData: trimedValues,
                applicationId: applicationId,
              }) as any
            );
            if (userType === 'broker') {
              const response = actionResult?.payload;
              res = response?.msg;
              const resStatus = response?.status;

              if (resStatus === 200) {
                Toast({
                  title: response.msg,
                  status: 'success',
                  isClosable: true,
                  duration: 3000,
                  position: 'top-right',
                });
                fetchOngoingApplication();
              } else {
                if (resStatus === 401) {
                  navigate('/login');
                } else {
                  Toast({
                    title: res,
                    status: 'error',
                    isClosable: true,
                    duration: 3000,
                    position: 'top-right',
                  });
                }
              }
            } else {
              navigate('/');
            }
            setIsReturnToSender(false);
            setLoader({ ...loader, btnLoader: false });
          } else {
            const data = {
              ...values,
              applicationId: applicationId!,
              brokerId: brokerId!,
            };
            const actionResult = await dispatch(surety(data) as any);
            const response = actionResult?.payload;
            res = response?.msg;
            const resStatus = response?.status;

            if (resStatus === 200) {
              Toast({
                title: response.msg,
                status: 'success',
                isClosable: true,
                duration: 3000,
                position: 'top-right',
              });
              fetchOngoingApplication();
            } else {
              if (resStatus !== 401) {
                Toast({
                  title: res,
                  status: 'error',
                  isClosable: true,
                  duration: 3000,
                  position: 'top-right',
                });
              } else {
                navigate('/login');
              }
            }
          }
        } else {
          Swal.fire({
            title: `Duplicate records in 'Authorized user to sign and seal documents'`,
            html: `<div>Remove duplicate record of following:</div><br><br><div>First Name : <b>${duplicate[0].firstName}</b></div><br><div>Last Name : <b>${duplicate[0].lastName}</b></div><br><div>Position : <b>${duplicate[0].position}</b></div>`,
            icon: 'warning',
            showConfirmButton: true,
            customClass: {
              container: 'custom-swal',
            },
          });
        }
      } catch (error: any) {
        if (res) {
          Toast({
            title: res,
            status: 'error',
            isClosable: true,
            duration: 3000,
            position: 'top-right',
          });
        }
      }
    },
  });

  useImperativeHandle(ref, () => ({
    formik: formik,
  }));

  const fetchOngoingApplication = async () => {
    dispatch(
      getApplicationById({
        applicationId: applicationId!,
      }) as any
    );
  };

  console.log('formik', formik.errors);

  function capitalizeFirstLetter(str: any) {
    return (
      str.charAt(0).toUpperCase() +
      str.slice(1).replace(/[A-Z]/g, (match: any) => ' ' + match.toLowerCase())
    );
  }

  const handleAddBox = (index: number) => {
    formik.setValues((prevValues: any) => {
      const newValues = { ...prevValues };
      const whoSignSeal = newValues?.whoSignSeal || [];

      whoSignSeal?.push({
        firstName: null,
        lastName: null,
        position: null,
      });

      return {
        ...newValues,
        whoSignSeal,
      };
    });
  };

  const handleRemoveBox = (index: number) => {
    formik.setValues((prevValues: any) => {
      const newValues = { ...prevValues };
      const whoSignSeal = newValues?.whoSignSeal || [];

      if (whoSignSeal?.length > 1) {
        const removeIndex = index;
        whoSignSeal?.splice(removeIndex, 1);

        return {
          ...newValues,
          whoSignSeal,
        };
      }

      return newValues;
    });
  };

  return (
    <Box
      display="flex"
      gap={4}
      w="min(88rem,95%)"
      // margin={'-236px auto 0'}
      flexDirection={{ base: 'column', lg: 'row' }}
      h={'100%'}
    >
      <Container
        maxW={'container.lg'}
        boxShadow=" rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 1.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset"
        h="100%"
        p="20px"
        borderRadius="20px"
        gap={5}
        bg={'white'}
        // overflow="auto" height="calc(100vh - 150px)" sx={{ "&::-webkit-scrollbar": { display: "none" } }}
      >
        <form onSubmit={formik.handleSubmit} style={{ height: '100%' }}>
          <Stack justifyContent={'space-between'} h={'100%'}>
            <Box display={'flex'} flexDirection="column" gap={3}>
              <Box>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="center"
                  fontSize={20}
                >
                  Surety{' '}
                  {/* {applicantHeaderName && applicantHeaderName.length > 0
                    ? `: ${applicantHeaderName} `
                    : null} */}
                </Text>
              </Box>
              <Box>
                <Box display={{ base: 'block', md: 'flex' }} gap={5}>
                  <Box
                    flex={{ base: '1', md: '1' }}
                    display={'flex'}
                    flexDir={'column'}
                    justifyContent={'space-between'}
                  >
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                      mb={1}
                    >
                      <li>Name of your current or most recent Surety</li>
                    </Text>
                    <Input
                      focusBorderColor="#7f7f7f"
                      borderColor={'#7f7f7f'}
                      _hover={{ borderColor: '#7f7f7f' }}
                      fontSize={'13px'}
                      w="100%"
                      type="text"
                      placeholder=" Name of your current or most recent Surety"
                      borderRadius="5px"
                      onChange={formik.handleChange}
                      value={formik.values.suretyName ?? ''}
                      name="suretyName"
                      readOnly={showBox}
                      bgColor={
                        showHistory &&
                        historyData &&
                        'suretyName' in historyData &&
                        '#114684'
                      }
                      color={
                        showHistory &&
                        historyData &&
                        'suretyName' in historyData &&
                        'white'
                      }
                    />
                  </Box>
                  <Box
                    flex={{ base: '1', md: '1' }}
                    display={'flex'}
                    flexDir={'column'}
                    justifyContent={'space-between'}
                  >
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                      mb={1}
                    >
                      <li> $ Total work on hand limit</li>
                    </Text>
                    <Input
                      fontSize={'13px'}
                      w="100%"
                      type="number"
                      onWheel={(e) => (e.target as HTMLInputElement).blur()}
                      onKeyDown={preventAlphabetsTyping}
                      placeholder=" $ Total work on hand limit"
                      borderRadius="5px"
                      focusBorderColor="#7f7f7f"
                      borderColor={'#7f7f7f'}
                      _hover={{ borderColor: '#7f7f7f' }}
                      // onChange={formik.handleChange}
                      onChange={(e) => {
                        formik.handleChange({
                          target: {
                            name: 'totalWorkOnHand',
                            value:
                              e.target.value === '' ? null : +e.target.value,
                          },
                        });
                      }}
                      value={formik.values.totalWorkOnHand ?? ''}
                      name="totalWorkOnHand"
                      readOnly={showBox}
                      bgColor={
                        showHistory &&
                        historyData &&
                        'totalWorkOnHand' in historyData &&
                        '#114684'
                      }
                      color={
                        showHistory &&
                        historyData &&
                        'totalWorkOnHand' in historyData &&
                        'white'
                      }
                    />
                  </Box>
                  <Box
                    flex={{ base: '1', md: '1' }}
                    display={'flex'}
                    flexDir={'column'}
                    justifyContent={'space-between'}
                  >
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                      mb={1}
                    >
                      <li> $ Single job limit</li>
                    </Text>
                    <Input
                      fontSize={'13px'}
                      w="100%"
                      type="number"
                      onWheel={(e) => (e.target as HTMLInputElement).blur()}
                      onKeyDown={preventAlphabetsTyping}
                      placeholder="$ Single job limit"
                      borderRadius="5px"
                      focusBorderColor="#7f7f7f"
                      borderColor={'#7f7f7f'}
                      _hover={{ borderColor: '#7f7f7f' }}
                      // onChange={formik.handleChange}
                      onChange={(e) => {
                        formik.handleChange({
                          target: {
                            name: 'singleJob',
                            value:
                              e.target.value === '' ? null : +e.target.value,
                          },
                        });
                      }}
                      value={formik.values.singleJob ?? ''}
                      name="singleJob"
                      readOnly={showBox}
                      bgColor={
                        showHistory &&
                        historyData &&
                        'singleJob' in historyData &&
                        '#114684'
                      }
                      color={
                        showHistory &&
                        historyData &&
                        'singleJob' in historyData &&
                        'white'
                      }
                    />
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box display={{ base: 'block', md: 'flex' }} gap={5}>
                  <Box flex={{ base: '1', md: '1' }}>
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                      mb={1}
                    >
                      <li>Reason for changing Surety</li>
                    </Text>
                    <Input
                      fontSize={'13px'}
                      w="100%"
                      type="text"
                      placeholder="Reason for changing Surety"
                      maxLength={500}
                      borderRadius="5px"
                      focusBorderColor="#7f7f7f"
                      borderColor={'#7f7f7f'}
                      _hover={{ borderColor: '#7f7f7f' }}
                      onChange={formik.handleChange}
                      value={formik.values.reasonChangingSurety ?? ''}
                      name="reasonChangingSurety"
                      readOnly={showBox}
                      bgColor={
                        showHistory &&
                        historyData &&
                        'reasonChangingSurety' in historyData &&
                        '#114684'
                      }
                      color={
                        showHistory &&
                        historyData &&
                        'reasonChangingSurety' in historyData &&
                        'white'
                      }
                    />
                  </Box>
                </Box>
              </Box>
              <Box display={'flex'} justifyContent={'space-between'}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                >
                  <li>
                    {' '}
                    Have you ever been refused a bond?{' '}
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </li>
                </Text>
                <Stack spacing={5} direction="row">
                  <Checkbox
                    colorScheme="green"
                    onChange={(e) => {
                      formik.setFieldValue('refusedBond', e.target.checked);
                      setShowTextarea(e.target.checked);
                    }}
                    isChecked={formik.values.refusedBond === true}
                    readOnly={showBox}
                  >
                    Yes
                  </Checkbox>

                  <Checkbox
                    colorScheme="red"
                    onChange={(e) => {
                      formik.setFieldValue('refusedBond', !e.target.checked);
                      setShowTextarea(!e.target.checked);
                    }}
                    isChecked={formik.values.refusedBond === false}
                    readOnly={showBox}
                  >
                    No
                  </Checkbox>
                </Stack>
              </Box>

              {formik.touched.refusedBond && formik.errors.refusedBond && (
                <div
                  style={{
                    color: 'red',
                    fontSize: '13px',
                    textAlign: 'start',
                    fontWeight: '500',
                  }}
                >
                  {capitalizeFirstLetter(formik.errors.refusedBond)}
                </div>
              )}
              {showTextarea && (
                <Box display={{ base: 'block', md: 'flex' }} gap={5}>
                  <Box flex={{ base: '1', md: '1' }}>
                    <Text
                      fontWeight="600"
                      color="#114684"
                      display="flex"
                      justifyContent="start"
                      textAlign="start"
                      fontSize={13}
                      mb={1}
                    >
                      <li>
                        Explain why{' '}
                        {/* <span style={{ color: "red" }}>*</span> */}
                      </li>
                    </Text>
                    <Textarea
                      fontSize={'13px'}
                      w="100%"
                      placeholder="Explain why"
                      borderRadius="5px"
                      onChange={formik.handleChange}
                      maxLength={500}
                      value={formik.values.refusedBondExplanation ?? ''}
                      name="refusedBondExplanation"
                      focusBorderColor="#7f7f7f"
                      borderColor={'#7f7f7f'}
                      _hover={{ borderColor: '#7f7f7f' }}
                      readOnly={showBox}
                      bgColor={
                        showHistory &&
                        historyData &&
                        'refusedBondExplanation' in historyData &&
                        '#114684'
                      }
                      color={
                        showHistory &&
                        historyData &&
                        'refusedBondExplanation' in historyData &&
                        'white'
                      }
                    />
                    {formik.touched.refusedBondExplanation &&
                      formik.errors.refusedBondExplanation && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {formik.errors.refusedBondExplanation}
                        </div>
                      )}
                  </Box>
                </Box>
              )}
              <Box>
                <Box
                  display={'flex'}
                  flexDir={{ base: 'column', lg: 'row' }}
                  justifyContent={'space-between'}
                  gap={{ base: 2, lg: 5 }}
                >
                  <Text
                    fontWeight="600"
                    color="#114684"
                    textAlign="start"
                    fontSize={13}
                  >
                    <li>
                      Who is authorized to sign and seal documents on behalf of
                      the company?{' '}
                      {/* <span style={{ color: "red" }}>*</span> */}
                    </li>
                  </Text>
                </Box>
                <Box>
                  {formik?.values?.whoSignSeal?.map((item: any, i: number) => (
                    <>
                      <Box display={{ base: 'block', md: 'flex' }} gap={5}>
                        <Box gap={2} flex={{ base: '1', md: '1' }}>
                          <Box>
                            <Text
                              fontWeight="600"
                              color="#114684"
                              textAlign="start"
                              fontSize={13}
                            >
                              <>
                                First Name
                                {/* <span style={{ color: "red" }}>*</span> */}
                              </>
                            </Text>
                          </Box>

                          <Box
                            display="flex"
                            mb={{ base: '20px', md: '5px' }}
                            mt={2}
                          >
                            <Input
                              fontSize={'13px'}
                              w="100%"
                              type="text"
                              placeholder="First Name"
                              borderRadius="5px"
                              focusBorderColor="#7f7f7f"
                              borderColor={'#7f7f7f'}
                              _hover={{ borderColor: '#7f7f7f' }}
                              onChange={formik.handleChange}
                              value={
                                formik.values.whoSignSeal?.[i]?.firstName ?? ''
                              }
                              name={`whoSignSeal[${i}].firstName`}
                              readOnly={showBox}
                              bgColor={
                                showHistory &&
                                historyData &&
                                historyData?.whoSignSeal?.[i] &&
                                'firstName' in historyData?.whoSignSeal?.[i] &&
                                historyData?.whoSignSeal?.[i]?.firstName !==
                                  null &&
                                '#114684'
                              }
                              color={
                                showHistory &&
                                historyData &&
                                historyData?.whoSignSeal?.[i] &&
                                'firstName' in historyData?.whoSignSeal?.[i] &&
                                historyData?.whoSignSeal?.[i]?.firstName !==
                                  null &&
                                'white'
                              }
                            />
                          </Box>
                        </Box>
                        <Box flex={{ base: '1', md: '1' }}>
                          <Box>
                            <Text
                              fontWeight="600"
                              color="#114684"
                              textAlign="start"
                              fontSize={13}
                            >
                              <> Last Name</>
                            </Text>
                          </Box>

                          <Box
                            display="flex"
                            mb={{ base: '20px', md: '5px' }}
                            mt={2}
                          >
                            <Input
                              fontSize={'13px'}
                              w="100%"
                              type="text"
                              placeholder="Last Name"
                              borderRadius="5px"
                              focusBorderColor="#7f7f7f"
                              borderColor={'#7f7f7f'}
                              _hover={{ borderColor: '#7f7f7f' }}
                              onChange={formik.handleChange}
                              value={
                                formik.values.whoSignSeal?.[i]?.lastName ?? ''
                              }
                              name={`whoSignSeal[${i}].lastName`}
                              readOnly={showBox}
                              bgColor={
                                showHistory &&
                                historyData &&
                                historyData?.whoSignSeal?.[i] &&
                                'lastName' in historyData?.whoSignSeal?.[i] &&
                                historyData?.whoSignSeal?.[i]?.lastName !==
                                  null &&
                                '#114684'
                              }
                              color={
                                showHistory &&
                                historyData &&
                                historyData?.whoSignSeal?.[i] &&
                                'lastName' in historyData?.whoSignSeal?.[i] &&
                                historyData?.whoSignSeal?.[i]?.lastName !==
                                  null &&
                                'white'
                              }
                            />
                          </Box>
                        </Box>
                        <Box flex={{ base: '1', md: '1' }}>
                          <Box>
                            <Text
                              fontWeight="600"
                              color="#114684"
                              textAlign="start"
                              fontSize={13}
                            >
                              <> Position</>
                            </Text>
                          </Box>

                          <Box
                            display="flex"
                            mb={{ base: '20px', md: '5px' }}
                            mt={2}
                          >
                            <Input
                              fontSize={'13px'}
                              w="100%"
                              type="text"
                              placeholder="Position"
                              borderRadius="5px"
                              focusBorderColor="#7f7f7f"
                              borderColor={'#7f7f7f'}
                              _hover={{ borderColor: '#7f7f7f' }}
                              onChange={formik.handleChange}
                              value={
                                formik.values.whoSignSeal?.[i]?.position ?? ''
                              }
                              name={`whoSignSeal[${i}].position`}
                              readOnly={showBox}
                              bgColor={
                                showHistory &&
                                historyData &&
                                historyData?.whoSignSeal?.[i] &&
                                'position' in historyData?.whoSignSeal?.[i] &&
                                historyData?.whoSignSeal?.[i]?.position !==
                                  null &&
                                '#114684'
                              }
                              color={
                                showHistory &&
                                historyData &&
                                historyData?.whoSignSeal?.[i] &&
                                'position' in historyData?.whoSignSeal?.[i] &&
                                historyData?.whoSignSeal?.[i]?.position !==
                                  null &&
                                'white'
                              }
                            />
                          </Box>
                        </Box>
                        {i === 0 ? (
                          <Box
                            display="flex"
                            alignItems="center"
                            fontSize="xl"
                            marginTop="18px"
                            onClick={() => handleAddBox(i)}
                            cursor={showBox ? 'not-allowed' : 'pointer'}
                            pointerEvents={showBox ? 'none' : 'auto'}
                          >
                            <CiCirclePlus />
                          </Box>
                        ) : (
                          <Box
                            display="flex"
                            alignItems="center"
                            fontSize="xl"
                            marginTop="18px"
                            onClick={() => handleRemoveBox(i)}
                            cursor={showBox ? 'not-allowed' : 'pointer'}
                            pointerEvents={showBox ? 'none' : 'auto'}
                          >
                            <CiCircleMinus />
                          </Box>
                        )}
                      </Box>
                    </>
                  ))}
                </Box>
              </Box>
            </Box>
            <Box
              display={'flex'}
              justifyContent={'end'}
              marginTop={'10px'}
              gap={2}
            >
              {!showBox && (
                <Button
                  bg={'#114684'}
                  color={'white'}
                  _hover={{ bg: '#114684', color: 'white' }}
                  onClick={
                    userType === 'broker' && formData?.assignToApplicant
                      ? () => OnHandleReturnToBroker()
                      : () => OnHandelReturnToSender()
                  }
                  type={
                    userType === 'broker' && formData?.assignToApplicant
                      ? 'button'
                      : 'submit'
                  }
                  isLoading={loader?.btnLoader}
                  // isDisabled={
                  //   userType === 'broker' && formData?.assignToApplicant
                  //     ? true
                  //     : false
                  // }
                >
                  {isApplicantUser
                    ? 'Return to Broker'
                    : userType === 'broker' && formData?.assignToApplicant
                      ? 'Edit - Rescind Assignment from Applicant'
                      : 'Assign to applicant'}
                </Button>
              )}
              {!showBox && (
                <Box>
                  <Button
                    bg={'#114684'}
                    color={'white'}
                    _hover={{ bg: '#114684', color: 'white' }}
                    type="submit"
                  >
                    Update
                  </Button>
                </Box>
              )}
            </Box>
          </Stack>
        </form>
      </Container>
      {showHistory && (
        <>
          <Box
            height="calc(100vh - 150px)"
            w={{
              base: '100%',
              lg: '360px',
            }}
            sx={{ '&::-webkit-scrollbar': { display: 'none' } }}
            bg={'white'}
            display={'flex'}
            flexDir={'column'}
            gap={'10px'}
            borderRadius={'12px'}
            boxShadow="0 0 14px -6px rgba(0,0,0,0.3)"
            p={4}
          >
            <Box style={{ fontSize: '25px', fontWeight: 'bold' }}>History</Box>
            <Box
              display={'flex'}
              flexDir={'column'}
              gap={'10px'}
              overflow={'auto'}
            >
              {HistoryData?.data?.map((item: any, index: number) => {
                const formattedDate = formatDate(item?.createdAt);
                return (
                  <Box
                    bgColor={historyIndex == index ? '#1146844a' : '#f5f5f5'}
                    p={3}
                    borderRadius={'12px'}
                    cursor={'pointer'}
                    mr={2}
                    border={
                      historyIndex == index
                        ? '1px solid #114684'
                        : '1px solid gray'
                    }
                    onClick={() => setHistoryIndex(index)}
                  >
                    <Box fontWeight={500} mt={2} display={'flex'}>
                      <Box marginRight={1}>Date :</Box>
                      <Text> {formattedDate.date}</Text>
                      <Text
                        mx={2}
                        borderLeft="1px solid #114684"
                        height="auto"
                        boxShadow="none"
                      ></Text>
                      <Text>{formattedDate.time}</Text>
                    </Box>
                    <Box fontSize="15px" fontWeight={500}>
                      Status : {item?.applicationStatus}
                    </Box>
                    <Box fontSize="15px" fontWeight={500}>
                      Initiated By : {item?.initiatedBy}
                    </Box>
                    <Box fontSize="15px" fontWeight={500}>
                      Updated By : {item?.broker?.firstName}
                      &nbsp;
                      {item?.broker?.lastName}
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
});

export default Surety;
