import { Box, Button, Flex } from '@chakra-ui/react';
import { Modal, Tooltip } from '@mantine/core';
import { useEffect, useState } from 'react';
import { updateAnalyticalReport } from 'src/Redux/Broker/AnalyticalReports/slice';
import { AnalyticalReportDetails } from 'src/Redux/Broker/AnalyticalReports/state';
import { useAppDispatch, useAppSelector } from 'src/Redux/Store';
import { useEfficiencyContext } from 'src/context/EfficiencyRatioContext';
import { useAnalyticalReportContext } from '../..';
import {
  compareAnalyticalReportData,
  efficiencyHistoryDataKeys,
  historyDataOfEfficiencyRatio,
  updateDataWithSelectedItemsAndSum,
  updateSelectedItems,
} from '../../data';
import { SideContent } from '../SideContent';
import EfficiencyCalculationReferenceFields from './EfficiencyCalculationReferenceFields';

const initialData: any = {
  totalIncome: {
    value: 100,
    fields: [
      { label: 'Total Revenue 1', value: 100, id: 'tr1' },
      { label: 'Total Revenue 2', value: 100, id: 'tr2' },
    ],
  },
  averageAR: {
    value: 100,
  },
  totalCostOfGoodsSold: {
    value: 100,
  },
  averageInventory: {
    value: 100,
  },
  averageTotalAssets: { value: 100 },
  costsInExcessOfBillings: {
    value: 100,
    fields: [
      { label: 'Costs in Excess of Billings 1', value: 100, id: 'ceib1' },
      { label: 'Costs in Excess of Billings 2', value: 100, id: 'ceib2' },
    ],
  },
  tangibleNetworth: {
    value: 100,
    fields: [
      { label: 'Tangible Networth 1', value: 50, id: 'tn1' },
      { label: 'Tangible Networth 2', value: 100, id: 'tn2' },
    ],
  },
};

const calculationHistoryInfo = {
  tangibleNetworth: {
    value: 103,
    updatedBy: 'user',
  },
  underBillingsToTangibleNetWorth: {
    value: 104,
    updatedBy: 'user',
  },
  revenueToTangibleNetWorth: {
    value: 105,
    updatedBy: 'user',
  },
};

const EditEfficiencyRatioModal = ({
  isOpen,
  onClose,
  TData,
}: {
  isOpen: boolean;
  onClose: () => void;
  TData: AnalyticalReportDetails;
}) => {
  const {
    costsInExcessOfBillings,
    setCostsInExcessOfBillings,
    totalIncome,
    setTotalIncome,
    reportReferenceData,
    setReportReferenceData,
    totalAssets,
    totalLiabilities,
    inTangibleAssets,
    historyData,
    setTotalAssets,
    setTotalLiabilities,
    setInTangibleAssets,
    setHistoryData,
  } = useEfficiencyContext();
  const {
    applicantId,
    analyticalReportPeriod,
    analyticalReportRatio,
    btnLoader,
  } = useAnalyticalReportContext();
  const dispatch = useAppDispatch();
  const analyticalReportsSclice = useAppSelector(
    (state) => state.analyticalReportSlice
  );
  const [calculationReferenceFields, setCalculationReferenceFields] = useState<
    Record<string, any>
  >({});
  const [isCalucationFieldUpdate, setIsCalculationFieldUpdate] =
    useState<boolean>(false);

  const selectedItemsMap = {
    costsInExcessOfBillings,
    totalIncome,
    totalAssets,
    totalLiabilities,
    inTangibleAssets,
  };

  const allOptionsbinded = [
    ...(reportReferenceData?.balanceSheetData?.assets ?? []),
    ...(reportReferenceData?.balanceSheetData?.liabilities ?? []),
    ...(reportReferenceData?.profitAndLossData?.expenses ?? []),
  ];

  useEffect(() => {
    const { status, type } = analyticalReportsSclice;
    switch (status) {
      case 'succeed': {
        if (type === 'UPDATE_ANALYTICAL_REPORTS_DETAILS') {
          onClose();
        }
        break;
      }
      case 'failed': {
        if (type === 'UPDATE_ANALYTICAL_REPORTS_DETAILS') {
          onClose();
        }
        break;
      }
      default:
        break;
    }
  }, [analyticalReportsSclice.status, analyticalReportsSclice.type]);
  const [
    initialCalculationReferenceFields,
    setInitialCalculationReferenceFields,
  ] = useState<Record<string, any>>({});

  useEffect(() => {
    if (isOpen) {
      setCalculationReferenceFields(TData?.editable);
      setInitialCalculationReferenceFields(TData?.editable);
      setReportReferenceData({
        profitAndLossData: TData?.profitAndLossData,
        balanceSheetData: TData?.balanceSheetData,
        balanceSheetPreviewLink: TData?.balanceSheetPreviewLink,
        profitAndLossPreviewLink: TData?.profitAndLossPreviewLink,
      });
      setHistoryData(TData?.history);
      updateSelectedItems(TData?.editable, {
        costsInExcessOfBillings: setCostsInExcessOfBillings,
        totalIncome: setTotalIncome,
        totalAssets: setTotalAssets,
        totalLiabilities: setTotalLiabilities,
        inTangibleAssets: setInTangibleAssets,
      });
    }
  }, [isOpen, TData?.editable]);

  useEffect(() => {
    const updatedData: Record<string, any> = updateDataWithSelectedItemsAndSum(
      calculationReferenceFields,
      selectedItemsMap,
      allOptionsbinded,
      initialCalculationReferenceFields
    );
    const changedData = compareAnalyticalReportData(
      initialCalculationReferenceFields,
      updatedData
    );
    setIsCalculationFieldUpdate(!!Object.keys(changedData)?.length);
  }, [
    costsInExcessOfBillings,
    totalIncome,
    totalAssets,
    totalLiabilities,
    inTangibleAssets,
  ]);

  const handleSubmitLiquidityRatio = () => {
    console.log(
      'selectedItemsMap',
      selectedItemsMap,
      calculationReferenceFields
    );
    // const allOptionsbinded = [
    //   ...Object.values(reportReferenceData.profitAndLossData).flat(),
    //   ...Object.values(reportReferenceData.balanceSheetData).flat(),
    // ];
    // const allOptionsbinded = [
    //   ...(reportReferenceData?.balanceSheetData?.assets ?? []),
    //   ...(reportReferenceData?.balanceSheetData?.liabilities ?? []),
    //   ...(reportReferenceData?.profitAndLossData?.expenses ?? []),
    // ];

    const updatedData: Record<string, any> = updateDataWithSelectedItemsAndSum(
      calculationReferenceFields,
      selectedItemsMap,
      allOptionsbinded,
      initialCalculationReferenceFields
    );

    const changedData = compareAnalyticalReportData(
      initialCalculationReferenceFields,
      updatedData
    );

    const historyDataEfficiency = historyDataOfEfficiencyRatio(
      updatedData,
      initialCalculationReferenceFields,
      efficiencyHistoryDataKeys,
      historyData
    );

    const payload = {
      ratioName: analyticalReportRatio,
      duration: analyticalReportPeriod,
      applicantId: applicantId,
      updatedFields: changedData,
      history: historyDataEfficiency,
    };

    try {
      dispatch(updateAnalyticalReport(payload as any));
    } catch (error) {
      console.log('Liquidity Ratio Update Error', error);
    }

    console.log('Liquidity updatedData', updatedData, payload);
  };

  return (
    <Modal
      opened={isOpen}
      onClose={() => {
        onClose();
      }}
      title="Edit Efficiency Ratio"
      overlayProps={{
        opacity: 0.1,
        blur: 3,
      }}
      // size={'750px'}
      fullScreen
      padding={20}
      closeOnClickOutside={false}
      styles={{
        title: {
          fontSize: '14px',
          fontWeight: 'bold',
        },
      }}
    >
      <form>
        <Flex gap={10} flexWrap={'wrap'}>
          <Box flex={1}>
            <EfficiencyCalculationReferenceFields
              calculationFields={calculationReferenceFields}
            />
          </Box>
          <Box flex={1}>
            <SideContent
              reportURL={{
                BSReportURL: reportReferenceData?.balanceSheetPreviewLink,
                PLReportURL: reportReferenceData?.profitAndLossPreviewLink,
              }}
            />
          </Box>
        </Flex>

        <Flex justifyContent={'flex-end'} gap={2} alignItems={'center'} mt={3}>
          <Button
            type="button"
            onClick={() => {
              onClose();
            }}
            isDisabled={btnLoader}
          >
            Cancel
          </Button>
          <Tooltip
            label={`No calculation formula has changed.`}
            position="right"
            withArrow
            disabled={isCalucationFieldUpdate}
          >
            <Button
              type="button"
              colorScheme="blue"
              loadingText="Update"
              isLoading={btnLoader}
              onClick={() => handleSubmitLiquidityRatio()}
              isDisabled={!isCalucationFieldUpdate}
            >
              Update
            </Button>
          </Tooltip>
        </Flex>
      </form>
    </Modal>
  );
};

export default EditEfficiencyRatioModal;
