import { Box, Button, Flex } from '@chakra-ui/react';
import { Modal, Tooltip } from '@mantine/core';
import { useEffect, useState } from 'react';
import { updateAnalyticalReport } from 'src/Redux/Broker/AnalyticalReports/slice';
import { AnalyticalReportDetails } from 'src/Redux/Broker/AnalyticalReports/state';
import { useAppDispatch, useAppSelector } from 'src/Redux/Store';
import { useLiquidityContext } from 'src/context/LiquidityRatioContext';
import { useAnalyticalReportContext } from '../..';
import {
  compareAnalyticalReportData,
  historyDataOfLiquidityRatio,
  liquidityHistoryDataKeys,
  updateDataWithSelectedItemsAndSum,
  updateSelectedItems,
} from '../../data';
import { SideContent } from '../SideContent';
import LiquidityCalculationReferenceFields from './LiquidityCalculationReferenceFields';

const initialData: any = {
  cashOnHand: {
    value: 10,
    fields: [
      { label: 'cashOnHand 1', value: 100, id: 'coh1', isSelected: true },
      { label: 'cashOnHand 2', value: 100, id: 'coh2', isSelected: false },
    ],
  },
  totalExpenses: {
    value: 10,
    fields: [
      {
        label: 'totalExpenses 1',
        value: 500,
        id: 'aoe1',
        isSelected: false,
      },
      {
        label: 'totalExpenses 2',
        value: 50,
        id: 'aoe2',
        isSelected: false,
      },
    ],
  },
  nonCashItems: {
    value: 10,
    fields: [
      { label: 'nonCashItems 1', value: 300, id: 'nci1', isSelected: false },
      { label: 'nonCashItems 2', value: 200, id: 'nci2', isSelected: true },
    ],
  },
  totalCurrentAssets: {
    value: 30,
  },
  totalCurrentLiabilities: { value: 10 },
  netIncome: { value: 10 },
  averageWorkingCapital: { value: 10 },
  backlog: {
    value: 10,
    fields: [{ label: 'backlog 1', value: 100, id: 'b1', isSelected: false }],
  },
  quickAssets: { value: 10 },
  tangibleNetworth: {
    value: 10,
    fields: [
      { label: 'tangibleNetworth 1', value: 200, id: 'bo1', isSelected: false },
      { label: 'tangibleNetworth 2', value: 50, id: 'bo2', isSelected: false },
    ],
  },
  billingInExcessOfCosts: {
    value: 10,
    fields: [
      {
        label: 'billingInExcessOfCosts 1',
        value: 100,
        id: 'bi1',
        isSelected: false,
      },
      {
        label: 'billingInExcessOfCosts 2',
        value: 100,
        id: 'bi2',
        isSelected: false,
      },
    ],
  },
  totalCashAndCashEquivalents: {
    value: 10,
    fields: [
      {
        label: 'totalCashAndCashEquivalents 1',
        value: 100,
        id: 'cc1',
        isSelected: false,
      },
      {
        label: 'totalCashAndCashEquivalents 2',
        value: 100,
        id: 'cc2',
        isSelected: false,
      },
    ],
  },
};

const calculationHistoryInfo = {
  daysOfCash: {
    value: 100,
    updatedBy: 'user',
  },
  tangibleNetworth: {
    value: 103,
    updatedBy: 'user',
  },
  backlogToTangibleNetWorth: {
    value: 104,
    updatedBy: 'user',
  },
  overBillingsToNetCash: {
    value: 105,
    updatedBy: 'user',
  },
};

const EditLiquidityRatioModal = ({
  isOpen,
  onClose,
  TData,
}: {
  isOpen: boolean;
  onClose: () => void;
  TData: AnalyticalReportDetails;
}) => {
  const {
    totalExpenses,
    nonCashItems,
    backlog,
    billingInExcessOfCosts,
    totalCashAndCashEquivalents,
    reportReferenceData,
    totalAssets,
    totalLiabilities,
    inTangibleAssets,
    historyData,

    setTotalExpenses,
    setSelectedNonCashItems,
    setBacklog,
    setBillingInExcessOfCosts,
    setTotalCashAndCashEquivalents,
    setReportReferenceData,
    setTotalAssets,
    setTotalLiabilities,
    setInTangibleAssets,
    setHistoryData,
  } = useLiquidityContext();
  const {
    applicantId,
    analyticalReportPeriod,
    analyticalReportRatio,
    btnLoader,
  } = useAnalyticalReportContext();

  const dispatch = useAppDispatch();
  const analyticalReportsSclice = useAppSelector(
    (state) => state.analyticalReportSlice
  );
  const [calculationReferenceFields, setCalculationReferenceFields] = useState<
    Record<string, any>
  >({});
  const [
    initialCalculationReferenceFields,
    setInitialCalculationReferenceFields,
  ] = useState<Record<string, any>>({});
  const [isCalucationFieldUpdate, setIsCalculationFieldUpdate] =
    useState<boolean>(false);

  const selectedItemsMap = {
    totalExpenses,
    nonCashItems,
    backlog,
    billingInExcessOfCosts,
    totalCashAndCashEquivalents,
    totalAssets,
    totalLiabilities,
    inTangibleAssets,
  };
  const allOptionsbinded = [
    ...(reportReferenceData?.balanceSheetData?.assets ?? []),
    ...(reportReferenceData?.balanceSheetData?.liabilities ?? []),
    ...(reportReferenceData?.profitAndLossData?.expenses ?? []),
  ];

  useEffect(() => {
    const { status, type } = analyticalReportsSclice;
    switch (status) {
      case 'succeed': {
        if (type === 'UPDATE_ANALYTICAL_REPORTS_DETAILS') {
          onClose();
        }
        break;
      }
      case 'failed': {
        if (type === 'UPDATE_ANALYTICAL_REPORTS_DETAILS') {
          onClose();
        }
        break;
      }
      default:
        break;
    }
  }, [analyticalReportsSclice.status, analyticalReportsSclice.type]);

  useEffect(() => {
    if (isOpen) {
      setCalculationReferenceFields(TData?.editable);
      setInitialCalculationReferenceFields(TData?.editable);
      setReportReferenceData({
        profitAndLossData: TData?.profitAndLossData,
        balanceSheetData: TData?.balanceSheetData,
        balanceSheetPreviewLink: TData?.balanceSheetPreviewLink,
        profitAndLossPreviewLink: TData?.profitAndLossPreviewLink,
      });
      setHistoryData(TData?.history);
      updateSelectedItems(TData?.editable, {
        totalExpenses: setTotalExpenses,
        nonCashItems: setSelectedNonCashItems,
        backlog: setBacklog,
        billingInExcessOfCosts: setBillingInExcessOfCosts,
        totalCashAndCashEquivalents: setTotalCashAndCashEquivalents,
        totalAssets: setTotalAssets,
        totalLiabilities: setTotalLiabilities,
        inTangibleAssets: setInTangibleAssets,
      });
    }
  }, [isOpen, TData?.editable]);

  useEffect(() => {
    const updatedData: Record<string, any> = updateDataWithSelectedItemsAndSum(
      calculationReferenceFields,
      selectedItemsMap,
      allOptionsbinded,
      initialCalculationReferenceFields
    );
    const changedData = compareAnalyticalReportData(
      initialCalculationReferenceFields,
      updatedData
    );
    setIsCalculationFieldUpdate(!!Object.keys(changedData)?.length);
  }, [
    totalExpenses,
    nonCashItems,
    backlog,
    billingInExcessOfCosts,
    totalCashAndCashEquivalents,
    totalAssets,
    totalLiabilities,
    inTangibleAssets,
  ]);

  const handleSubmitLiquidityRatio = () => {
    console.log(
      'selectedItemsMap',
      selectedItemsMap,
      calculationReferenceFields
    );
    // const allOptionsbinded = [
    //   ...Object.values(reportReferenceData.profitAndLossData).flat(),
    //   ...Object.values(reportReferenceData.balanceSheetData).flat(),
    // ];
    // const allOptionsbinded = [
    //   ...(reportReferenceData?.balanceSheetData?.assets ?? []),
    //   ...(reportReferenceData?.balanceSheetData?.liabilities ?? []),
    //   ...(reportReferenceData?.profitAndLossData?.expenses ?? []),
    // ];

    const updatedData: Record<string, any> = updateDataWithSelectedItemsAndSum(
      calculationReferenceFields,
      selectedItemsMap,
      allOptionsbinded,
      initialCalculationReferenceFields
    );

    const changedData = compareAnalyticalReportData(
      initialCalculationReferenceFields,
      updatedData
    );
    console.log('changedData:::', changedData);

    const historyDataOfLiquidity = historyDataOfLiquidityRatio(
      updatedData,
      initialCalculationReferenceFields,
      liquidityHistoryDataKeys,
      historyData
    );

    const payload = {
      ratioName: analyticalReportRatio,
      duration: analyticalReportPeriod,
      applicantId: applicantId,
      updatedFields: changedData,
      history: historyDataOfLiquidity,
    };

    try {
      dispatch(updateAnalyticalReport(payload as any));
    } catch (error) {
      console.log('Liquidity Ratio Update Error', error);
    }

    console.log('Liquidity updatedData', updatedData, payload, historyData);
  };

  return (
    <Modal
      opened={isOpen}
      onClose={() => {
        onClose();
      }}
      title="Edit Liquidity Ratio"
      overlayProps={{
        opacity: 0.1,
        blur: 3,
      }}
      // size={'750px'}
      fullScreen
      padding={20}
      closeOnClickOutside={false}
      styles={{
        title: {
          fontSize: '14px',
          fontWeight: 'bold',
        },
      }}
    >
      <form>
        <Flex gap={10} flexWrap={'wrap'}>
          <Box flex={1}>
            <LiquidityCalculationReferenceFields
              calculationFields={calculationReferenceFields}
            />
          </Box>
          <Box flex={1}>
            <SideContent
              reportURL={{
                BSReportURL: reportReferenceData?.balanceSheetPreviewLink,
                PLReportURL: reportReferenceData?.profitAndLossPreviewLink,
              }}
            />
          </Box>
        </Flex>
        <Flex justifyContent={'flex-end'} gap={2} alignItems={'center'} mt={3}>
          <Button
            type="button"
            onClick={() => {
              onClose();
            }}
            isDisabled={btnLoader}
          >
            Cancel
          </Button>
          <Tooltip
            label={`No calculation formula has changed.`}
            position="right"
            withArrow
            disabled={isCalucationFieldUpdate}
          >
            <Button
              type="button"
              colorScheme="blue"
              loadingText="Update"
              isLoading={btnLoader}
              onClick={() => handleSubmitLiquidityRatio()}
              isDisabled={!isCalucationFieldUpdate}
            >
              Update
            </Button>
          </Tooltip>
        </Flex>
      </form>
    </Modal>
  );
};

export default EditLiquidityRatioModal;
