import { HStack, Tooltip } from '@chakra-ui/react';
import { Box, Flex, MultiSelect, Text, TextInput } from '@mantine/core';
import type React from 'react';
import { useMemo } from 'react';
import { HiMiniInformationCircle } from 'react-icons/hi2';
import { useLeverageContext } from 'src/context/LeverageRatioContext';
import {
  CalculationFields,
  calculateTotalWthPLBS,
  getOptionWithPLBSReports,
  getStyles,
} from '../../data';

interface CalculationDisplayTLTNProps {
  label: string;
  calculationFields: CalculationFields;
}

export const CalculationDisplayTLTN: React.FC<CalculationDisplayTLTNProps> = ({
  label,
  calculationFields,
}) => {
  const {
    totalLiabilities,
    setTotalLiabilities,
    tangibleNetworth,
    reportReferenceData,
    historyData,
  } = useLeverageContext();

  const isTotalLiabilitiesOption =
    !!calculationFields?.totalLiabilities?.fields?.length;
  const isTotalLiabilitiesValue = !!calculationFields?.totalLiabilities?.value
    ? calculationFields?.totalLiabilities?.value
    : 0;

  const totalLiabilitiesSelectedFieldSumInitial = useMemo(() => {
    return calculateTotalWthPLBS(
      totalLiabilities,
      calculationFields?.totalLiabilities,
      reportReferenceData
    );
  }, []);
  const liabilitiesTotalInitialValue =
    isTotalLiabilitiesValue - totalLiabilitiesSelectedFieldSumInitial;

  // const totalLiabilitiesTotal = useMemo(() => {
  //   const totalSum =
  //     calculateTotalWthPLBS(
  //       totalLiabilities,
  //       calculationFields?.totalLiabilities,
  //       reportReferenceData
  //     ) + (isTotalLiabilitiesOption ? 0 : isTotalLiabilitiesValue);
  //   return Number(totalSum?.toFixed(2));
  // }, [totalLiabilities, calculationFields.totalLiabilities]);

  const totalLiabilitiesTotal = useMemo(() => {
    const totalSumSelectedFields = calculateTotalWthPLBS(
      totalLiabilities,
      calculationFields?.totalLiabilities,
      reportReferenceData
    );
    return Number(
      Number(totalSumSelectedFields + liabilitiesTotalInitialValue)?.toFixed(2)
    );
  }, [totalLiabilities, calculationFields?.totalLiabilities]);

  const result = useMemo(() => {
    const answer = Number(
      (totalLiabilitiesTotal / tangibleNetworth).toFixed(2)
    );
    return !!tangibleNetworth ? answer : 'N/A';
  }, [totalLiabilitiesTotal, tangibleNetworth]);

  return (
    <Box w={'100%'}>
      <HStack gap={2} mb="md">
        <Text size="lg" weight={700}>
          {label}
        </Text>
        {!!historyData?.totalLiabilitiesToTangibleNetWorth && (
          <Tooltip
            label={`${label} (${historyData?.totalLiabilitiesToTangibleNetWorth?.value}) is updated by ${historyData?.totalLiabilitiesToTangibleNetWorth?.updatedBy}`}
            placement="right"
            hasArrow
          >
            <Box>
              <HiMiniInformationCircle
                size={18}
                color="black"
                cursor="pointer"
              />
            </Box>
          </Tooltip>
        )}
      </HStack>
      <Flex direction="column" align="stretch" gap="md">
        <Flex wrap={'wrap'} align="center" justify={'flex-start'} gap="xs">
          <MultiSelect
            label={`Total Liabilities (${totalLiabilitiesTotal})`}
            data={getOptionWithPLBSReports(
              calculationFields?.totalLiabilities?.fields!,
              reportReferenceData!
            )}
            value={totalLiabilities}
            onChange={setTotalLiabilities}
            placeholder="Select Total Liabilities items"
            searchable
            className="legalform_pdfPreference"
            maxDropdownHeight={400}
            sx={{ flex: 1 }}
            styles={getStyles(calculationFields?.totalLiabilities?.fields!)}
            readOnly
          />
          <Text size="xl" weight={700} mt={'xs'}>
            ÷
          </Text>
          {/* <MultiSelect
            label="Tangible Networth"
            data={getOptions(calculationFields?.tangibleNetworth?.fields!)}
            value={liabilitiesTangibleNetworth}
            onChange={setLiabilitiesTangibleNetworth}
            placeholder="Select Tangible Networth items"
            searchable
            sx={{ flex: 1 }}
          /> */}
          <TextInput
            label="Tangible Networth"
            value={tangibleNetworth?.toFixed(2) ?? 0}
            disabled
            styles={{
              input: {
                textAlign: 'right',
                '&:disabled': {
                  color: 'black',
                  fontWeight: 'bold',
                },
              },
            }}
            sx={{ flex: 1 }}
          />
        </Flex>
        <Flex align="center" justify="flex-start" wrap={'wrap'} gap={'xs'}>
          {/* Equals Sign */}
          <Text size="xl" weight={700} mt={'xs'}>
            =
          </Text>
          {/* Result */}
          <TextInput
            label="Result"
            value={result ?? 0}
            disabled
            styles={{
              input: {
                textAlign: 'right',
                '&:disabled': {
                  color: 'black',
                  fontWeight: 'bold',
                },
              },
            }}
            maw={'50%'}
            w={'100%'}
          />
        </Flex>
      </Flex>
    </Box>
  );
};
