import { Button, Flex, HStack } from '@chakra-ui/react';
import { Box, Divider, MantineProvider, Progress, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
  MRT_GlobalFilterTextInput,
  MRT_TablePagination,
  MRT_ToolbarInternalButtons,
  MantineReactTable,
  useMantineReactTable,
  type MRT_ColumnDef,
} from 'mantine-react-table';
import type React from 'react';
import { useEffect, useMemo, useState } from 'react';
import {
  AnalyticalReportDetails,
  IEfficiencyTableData,
} from 'src/Redux/Broker/AnalyticalReports/state';
import { EfficiencyProvider } from 'src/context/EfficiencyRatioContext';
import { useAnalyticalReportContext } from '../..';
import { extractHeaders, monthLabels } from '../../data';
import EditEfficiencyRatioModal from './EditEfficiencyRatioModal';

//LiquidityRatiosTable props interface
interface EfficiencyRatiosTableProps {
  TData: AnalyticalReportDetails;
}

const calculateRatios = (data: Record<string, any>) => {
  if (!data) return null; // Ensure data exists

  return {
    accountReceivableTurnover: data.accountReceivableTurnover,
    daysSalesOutStanding: data.daysSalesOutStanding,
    inventoryTurnover: data.inventoryTurnover,
    daysInventoryOutstanding: data.daysInventoryOutstanding,
    totalAssetTurnover: data.totalAssetTurnover,
    underBillingsToTangibleNetWorth: data.underBillingsToTangibleNetWorth,
    revenueToTangibleNetWorth: data.revenueToTangibleNetWorth,
  };
};

const ratioNames = [
  'Account Receivable Turnover',
  'Days Sales Outstanding',
  'Inventory Turnover',
  'Days Inventory Outstanding',
  'Total Asset Turnover',
  'Underbillings to Tangible Networth',
  'Revenue to Tangible NetWorth',
];

const EfficiencyRatiosTable: React.FC<EfficiencyRatiosTableProps> = ({
  TData,
}) => {
  const { contentLoader: loading, analyticalReportPeriod } =
    useAnalyticalReportContext();
  const [
    isEditAnalyticalReport,
    { open: openEditAnalyticalReport, close: closeEditAnalyticalReport },
  ] = useDisclosure(false);

  const [restructuredData, setRestructuredData] = useState<any>([]);
  console.log('TData', TData);
  const [columnOrder, setColumnOrder] = useState<string[]>();

  useEffect(() => {
    const tabledata: any[] = TData?.tableData;
    const labels = tabledata?.map(({ label }) => label); // Extracting labels dynamically

    // Restructure the data dynamically
    const restructuredData =
      tabledata && tabledata?.length > 0
        ? ratioNames.map((ratioName) => {
            return {
              ratioName,
              ...Object.fromEntries(labels?.map((label) => [label, 0]) ?? []), // Dynamically adding labels as keys
            };
          })
        : [];

    // Calculate ratios for all years
    const yearlyRatios =
      tabledata && tabledata?.length > 0 ? tabledata?.map(calculateRatios) : [];

    console.log('yearlyRatios', yearlyRatios);

    // // Populate the restructured data
    yearlyRatios?.forEach((ratio: any, index: any) => {
      const year =
        tabledata && tabledata?.length > 0 && tabledata?.[index]?.label;
      Object.keys(ratio).forEach((key: any, ratioIndex: any) => {
        (restructuredData as any)[ratioIndex][year as any] =
          ratio[key as keyof typeof ratio];
      });
    });
    setRestructuredData(restructuredData);
  }, [TData]);

  const renderCell =
    (label: string) =>
    ({ cell, row }: { cell: any; row: any }) => {
      const { ratioName } = row?.original;
      const value = Number(row.original[label] ?? 0);

      let formattedValue = value.toFixed(2);
      if (ratioName.includes('$')) {
        formattedValue = value.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      } else if (
        ratioName.includes('%') ||
        ratioName.toLowerCase().includes('margin')
      ) {
        formattedValue = `${value.toFixed(2)} %`;
      }

      return (
        <Box style={{ cursor: 'help' }}>
          <Text>{formattedValue}</Text>
        </Box>
      );
    };

  const getFormattedColumns = (
    tableData: Record<string, any>[],
    period: string
  ) => {
    const currentYear = new Date().getFullYear();
    let formattedColumns: MRT_ColumnDef<IEfficiencyTableData>[] = [
      {
        accessorKey: 'ratioName',
        header: 'Ratio',
        size: 180,
      },
    ];

    const tableDataWithoutAverageCol = tableData.filter(
      (elm) => !['average'].includes(elm.label)
    );

    if (period === 'Monthly' && tableDataWithoutAverageCol?.length > 0) {
      formattedColumns.push({
        accessorKey: 'monthly',
        header: `${currentYear}`,
        columns: tableDataWithoutAverageCol.map((elm) => ({
          accessorKey: elm.label,
          header: monthLabels[elm.label] || elm.label,
          Cell: renderCell(elm.label),
        })),
      });
    } else if (
      period === 'Quarterly' &&
      tableDataWithoutAverageCol?.length > 0
    ) {
      const currentYearData = tableDataWithoutAverageCol.filter(
        (elm) => Number(elm?.year) === currentYear
      );
      const previousYearData = tableDataWithoutAverageCol.filter(
        (elm) => Number(elm?.year) === currentYear - 1
      );
      if (currentYearData?.length > 0) {
        formattedColumns.push({
          header: `${currentYear}`,
          columns: currentYearData?.map((elm, index) => ({
            accessorKey: `${elm?.year}-${elm?.label}`,
            header: elm.label,
            Cell: renderCell(elm.label),
            id: `${elm?.year}-${elm?.label}`,
          })),
        });
      }
      if (previousYearData?.length > 0) {
        formattedColumns.push({
          header: `${currentYear - 1}`,
          columns: previousYearData?.map((elm, index) => ({
            accessorKey: `${elm?.year}-${elm?.label}`,
            header: elm.label,
            Cell: renderCell(elm.label),
            id: `${elm?.year}-${elm?.label}`,
          })),
        });
      }
    } else if (
      period === 'Semi Annually' &&
      tableDataWithoutAverageCol?.length > 0
    ) {
      const currentYearData = tableDataWithoutAverageCol.filter(
        (elm) => Number(elm?.year) === currentYear
      );
      const previousYearData = tableDataWithoutAverageCol.filter(
        (elm) => Number(elm?.year) === currentYear - 1
      );
      const twoPreviousYearData = tableDataWithoutAverageCol.filter(
        (elm) => Number(elm?.year) === currentYear - 2
      );
      const threePreviousYearData = tableDataWithoutAverageCol.filter(
        (elm) => Number(elm?.year) === currentYear - 3
      );

      if (currentYearData?.length > 0) {
        formattedColumns.push({
          header: `${currentYear}`,
          columns: currentYearData?.map((elm, index) => ({
            accessorKey: `${elm?.year}-${elm?.label}`,
            header: elm.label,
            Cell: renderCell(elm.label),
            id: `${elm?.year}-${elm?.label}`,
          })),
        });
      }
      if (previousYearData?.length > 0) {
        formattedColumns.push({
          header: `${currentYear - 1}`,
          columns: previousYearData?.map((elm, index) => ({
            accessorKey: `${elm?.year}-${elm?.label}`,
            header: elm.label,
            Cell: renderCell(elm.label),
            id: `${elm?.year}-${elm?.label}`,
          })),
        });
      }
      if (twoPreviousYearData?.length > 0) {
        formattedColumns.push({
          header: `${currentYear - 2}`,
          columns: twoPreviousYearData?.map((elm, index) => ({
            accessorKey: `${elm?.year}-${elm?.label}`,
            header: elm.label,
            Cell: renderCell(elm.label),
            id: `${elm?.year}-${elm?.label}`,
          })),
        });
      }
      if (threePreviousYearData?.length > 0) {
        formattedColumns.push({
          header: `${currentYear - 3}`,
          columns: threePreviousYearData?.map((elm, index) => ({
            accessorKey: `${elm?.year}-${elm?.label}`,
            header: elm.label,
            Cell: renderCell(elm.label),
            id: `${elm?.year}-${elm?.label}`,
          })),
        });
      }
    } else {
      const yearCols = tableDataWithoutAverageCol.map((elm, index) => {
        const yearNumber = parseInt(elm.label.replace(/\D/g, ''), 10);
        return {
          accessorKey: elm.label,
          header: `${currentYear - (yearNumber - 1)}`,
          Cell: renderCell(elm.label),
        };
      });
      formattedColumns.push(...yearCols);
    }

    const averageColumn = tableData
      ?.filter((el) => ['average'].includes(el.label))
      .map((elm) => ({
        accessorKey: elm.label,
        header: elm.label,
        Cell: renderCell(elm.label),
      }));

    formattedColumns.push(...averageColumn);

    setColumnOrder(extractHeaders(formattedColumns));
    return formattedColumns;
  };

  const columns = useMemo(
    () => getFormattedColumns(TData?.tableData, analyticalReportPeriod),
    [TData, analyticalReportPeriod]
  );

  const table = useMantineReactTable({
    columns,
    data: restructuredData, // restructuredData,
    enableTopToolbar: true,
    enableBottomToolbar: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    enablePagination: true,
    enableSorting: false,
    positionPagination: 'none',
    getRowId: (row, index) => '' + index,
    defaultColumn: { minSize: 10, maxSize: 10, size: 10 },
    initialState: {
      density: 'xs',
      columnOrder,
    },
    mantineTableContainerProps: {
      sx: {
        tableLayout: 'fixed',
        width: '100%',
        overflow: loading ? 'hidden' : 'auto',
      },
    },
    mantineTableProps: {
      striped: true,
      highlightOnHover: true,
      sx: {
        tableLayout: 'fixed',
      },
    },
    renderTopToolbar: ({ table }) => {
      console.log('table', table);

      return (
        <Flex direction={'column'} justifyContent={'space-between'} px={5}>
          <HStack justifyContent={'space-between'} alignItems={'center'}>
            <Button
              bg={'#114684'}
              color={'white'}
              minW={'150px'}
              p={2}
              _hover={{ bg: '#114684', color: 'white' }}
              type="button"
              onClick={() => {
                openEditAnalyticalReport();
              }}
            >
              Edit
            </Button>
            <Flex
              py={1}
              alignItems={'center'}
              flexWrap={'wrap'}
              justifyContent={'flex-end'}
            >
              <Flex alignItems={'center'} gap={4} p={3}>
                <MRT_GlobalFilterTextInput table={table} />
                <MRT_ToolbarInternalButtons table={table} />
              </Flex>
              <Divider
                size="md"
                orientation="vertical"
                h={40}
                style={{ alignSelf: 'center' }}
              />
              <Box className="pagination-wrapper">
                <MRT_TablePagination position="top" table={table} />
              </Box>
            </Flex>
          </HStack>
          {loading && <Progress value={100} animate={true} />}
        </Flex>
      );
    },
    state: {
      showProgressBars: loading,
      isLoading: loading,
      columnOrder,
    },
  });

  return (
    <>
      <MantineProvider>
        <MantineReactTable table={table} />
      </MantineProvider>
      <EfficiencyProvider>
        <EditEfficiencyRatioModal
          isOpen={isEditAnalyticalReport}
          onClose={() => {
            closeEditAnalyticalReport();
          }}
          TData={TData}
        />
      </EfficiencyProvider>
    </>
  );
};

export default EfficiencyRatiosTable;
