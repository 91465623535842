import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { isRejectedActionWithPayload } from 'src/Redux/Broker/Documents/slice';
import callAPI from 'src/utils/callAPI';
import initialState from './state';

export const getOcrReportIssueList = createAsyncThunk(
  'admin/getOcrReportIssueList',
  async (_, { rejectWithValue }) => {
    try {
      const res = await callAPI('/supportTickets/getAll', 'GET', null, false);
      return res.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);
export const getOcrReportIssueById = createAsyncThunk(
  'admin/getOcrReportIssueById',
  async ({ id }: { id: number }, { rejectWithValue }) => {
    try {
      const res = await callAPI(
        `/supportTickets/getById/${id}`,
        'GET',
        null,
        false
      );
      return res.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const getBrokerOcrReportIssueList = createAsyncThunk(
  'admin/getBrokerOcrReportIssueList',
  async (_, { rejectWithValue }) => {
    try {
      const res = await callAPI(
        '/supportTickets/getByUser',
        'GET',
        null,
        false
      );
      return res.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const OCRIssueUpdateStatus = createAsyncThunk(
  'admin/OCRIssueUpdateStatus',
  async (
    {
      reportId,
      status,
      comment,
      reason,
    }: { reportId: number; status: string; comment?: string; reason?: string },
    { rejectWithValue }
  ) => {
    const payload = {
      status,
      comment,
      reason,
    };
    try {
      const res = await callAPI(
        `/supportTickets/updateStatus/${reportId}`,
        'PUT',
        payload,
        false
      );
      return res.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const OcrReportIssueStore = createSlice({
  name: 'OcrReportIssue',
  initialState,
  reducers: {
    removeOcrReportIssueSliceType(state) {
      state.type = null;
      state.status = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      //get OcrReportIssue  list api
      .addCase(getOcrReportIssueList.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.type = 'GET_ALL_OCR_REPROT_ISSUE_LIST';
      })
      .addCase(getOcrReportIssueList.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'GET_ALL_OCR_REPROT_ISSUE_LIST';
        state.ocrIssueList = action.payload.data?.tickets;
      })
      .addCase(getOcrReportIssueList.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'GET_ALL_OCR_REPROT_ISSUE_LIST';
        state.error = isRejectedActionWithPayload(action);
      })

      //get current broker OcrReportIssue  list api
      .addCase(getBrokerOcrReportIssueList.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.type = 'GET_LOGINUSER_OCR_REPROT_ISSUE_LIST';
      })
      .addCase(getBrokerOcrReportIssueList.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'GET_LOGINUSER_OCR_REPROT_ISSUE_LIST';
        state.ocrIssueListByUser = action.payload.data?.tickets;
      })
      .addCase(getBrokerOcrReportIssueList.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'GET_LOGINUSER_OCR_REPROT_ISSUE_LIST';
        state.error = isRejectedActionWithPayload(action);
      })
      //get OcrReportIssue by id API
      .addCase(getOcrReportIssueById.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.type = 'GET_OCR_REPORT_ISSUE_BY_ID';
      })
      .addCase(getOcrReportIssueById.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'GET_OCR_REPORT_ISSUE_BY_ID';
        state.ocrIssueInfo = action.payload.data?.tickets;
      })
      .addCase(getOcrReportIssueById.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'GET_OCR_REPORT_ISSUE_BY_ID';
        state.error = isRejectedActionWithPayload(action);
      })
      //OcrReportIssue update status API
      .addCase(OCRIssueUpdateStatus.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.type = 'OCR_ISSUE_STATUS_UPDATE_BY_SUPPORT_ADMIN';
      })
      .addCase(OCRIssueUpdateStatus.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'OCR_ISSUE_STATUS_UPDATE_BY_SUPPORT_ADMIN';
        state.msg = action.payload.data;
      })
      .addCase(OCRIssueUpdateStatus.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'OCR_ISSUE_STATUS_UPDATE_BY_SUPPORT_ADMIN';
        state.error = isRejectedActionWithPayload(action);
      });
  },
});

const OcrReportIssueSlice = OcrReportIssueStore.reducer;
export const { removeOcrReportIssueSliceType } = OcrReportIssueStore.actions;

export default OcrReportIssueSlice;
