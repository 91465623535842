import {
  Box,
  IconButton,
  Link,
  Menu,
  MenuButton,
  Link as MenuItem,
  MenuList,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { FiMenu } from 'react-icons/fi';
import { MdOutlineAccountCircle } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetAuth } from 'src/Redux/Auth/Auth';
import { removeOcrReportSliceType } from 'src/Redux/OcrReports/slice';
import { removePlansInfo } from 'src/Redux/Plans/slice';
import {
  fetchApplicantUserData,
  removeApplicantProfile,
} from '../../Redux/Applicant/ApplicantProfile/ApplicantProfile';
import {
  fetchBrokerUserData,
  removeBrokerData,
} from '../../Redux/Broker/BrokerProfile/BrokerProfile';
import {
  fetchBrokerageUserData,
  removeBrokerageData,
} from '../../Redux/Brokerage/BrokerageProfile/BrokerageProfile';
import { RootState, useAppSelector } from '../../Redux/Store';
import Notification from './Notification/Notification';

interface NavbarProps {
  navSize: 'small' | 'large';
  changeNavSize: React.Dispatch<React.SetStateAction<'small' | 'large'>>;
}

const Navbar = ({ navSize, changeNavSize }: NavbarProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const displayName = useDisplayName();
  const brokerageSlice = useAppSelector((state) => state.brokerage.user);
  const userData = useAppSelector((state) => state.broker.brokerProfileData);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userType = localStorage.getItem('userType');
        const userId = localStorage.getItem('userID');
        let userName = '';
        if (userType === 'applicant') {
          const actionResult = await dispatch(
            fetchApplicantUserData({ applicantId: Number(userId)! }) as any
          );
          const response = actionResult?.payload;
          if (response?.payload?.status === 401) {
            Navigate('/login');
          }
          userName = response?.data?.companyName;
        } else if (userType === 'broker') {
          if (!userData || !Object.keys(userData?.data).length) {
            const actionResult = await dispatch(fetchBrokerUserData() as any);
            const response = actionResult?.payload;
            if (response?.payload?.status === 401) {
              Navigate('/login');
            }
            userName = `${response?.data?.firstName} ${response?.data?.lastName}`;
          }
        } else if (userType === 'brokerage') {
          if (!brokerageSlice || !Object.keys(brokerageSlice).length) {
            const actionResult = await dispatch(
              fetchBrokerageUserData() as any
            );
            const response = actionResult?.payload;
            if (response?.payload?.status === 401) {
              Navigate('/login');
            }
            // userName = response?.data?.legalName;
          }
        }
      } catch (error) {
        console.error('Error fetching applicant user data:', error);
      }
    };
    fetchData();
    return () => {};
  }, [dispatch, brokerageSlice, userData]);

  const handleLogout = () => {
    dispatch(resetAuth());
    dispatch(removeBrokerData({}));
    dispatch(removeBrokerageData({}));
    dispatch(removePlansInfo({}));
    dispatch(removeApplicantProfile());
    dispatch(removeOcrReportSliceType({}));
    localStorage.removeItem('Token');
    localStorage.removeItem('userType');
    Cookies.remove('token');
    Navigate('/login');
  };

  const HandleClickProfile = () => {
    const userType = localStorage.getItem('userType');
    if (userType === 'applicant') {
      Navigate('/applicant/profile');
    } else if (userType === 'broker') {
      Navigate('/broker/profile');
    } else if (userType === 'brokerage') {
      Navigate('/');
    }
    onClose();
  };
  return (
    <>
      <Box
        position={'sticky'}
        top={0}
        zIndex={199}
        p={{ base: '2', md: '3' }}
        bg="white"
        display="flex"
        justifyContent="space-between"
        width="100%"
        boxShadow="0 2px 6px 0 rgba(0,0,0,0.3)"
      >
        <Box
          pt={{ base: '1', md: '2' }}
          pb={{ base: '1', md: '2' }}
          display={'flex'}
          alignItems={'center'}
          gap={2}
        >
          <Tooltip
            hasArrow
            label={navSize === 'small' ? 'Right' : 'Left'}
            bg="gray.300"
            color="black"
            placement="bottom"
          >
            <IconButton
              // display={{base:"flex",md:"none"}}
              aria-label="Toggle Navigation"
              background="none"
              p="5px"
              borderRadius="14px"
              bg="#114684"
              _hover={{ bg: '#ffa011' }}
              icon={<FiMenu size={22} color="white" />}
              onClick={() => {
                if (navSize === 'small') changeNavSize('large');
                else changeNavSize('small');
              }}
            />
          </Tooltip>
          <Text size="md" color="#114684" fontWeight="700" fontSize={'larger'}>
            Insurance
          </Text>
        </Box>
        <Box
          p={{ base: '0', md: '0' }}
          display={'flex'}
          alignItems={'center'}
          gap={2}
        >
          <Text
            // bg="#114684"
            color="#114684"
            borderRadius={5}
            fontWeight={'bold'}
            display={'flex'}
          >
            <div></div>
            <h1
              style={{
                display: 'flex',
                alignItems: 'center',
                fontSize: 'larger',
              }}
            >
              {displayName}
            </h1>
          </Text>
          <Box>
            {!['admin'].includes(localStorage.getItem('userType')!) && (
              <Notification />
            )}
          </Box>
          <Menu placement="bottom-end" isOpen={isOpen}>
            <Tooltip
              label="Profile"
              aria-label="Profile Tooltip"
              hasArrow
              bg="gray.300"
              color="black"
            >
              <MenuButton
                as={Link}
                onMouseEnter={onOpen}
                display="flex"
                justifyContent="center"
                borderRadius="14px"
                bg="#114684"
                _hover={{ bg: '#ffa011' }}
                h="40px"
                p="4px"
                w="40px"
                onClick={isOpen ? onClose : onOpen}
              >
                <MdOutlineAccountCircle
                  fontSize={32}
                  color="white"
                ></MdOutlineAccountCircle>
              </MenuButton>
            </Tooltip>
            <MenuList
              mt={2}
              onMouseLeave={onClose}
              borderRadius="10px"
              border="2px solid #114684"
              display="flex"
              flexDirection={'column'}
              gap={3}
            >
              <MenuItem
                color="#114684"
                fontWeight="bolder"
                ml={15}
                _hover={{
                  bg: '#114684',
                  color: 'white',
                  borderRadius: '6px',
                  p: '10px',
                  marginLeft: '0px',
                  marginTop: '-8px',
                  textDecoration: 'none',
                }}
                onClick={() => {
                  HandleClickProfile();
                }}
              >
                Profile
              </MenuItem>

              <MenuItem
                color="#114684"
                fontWeight="bolder"
                ml={15}
                _hover={{
                  bg: '#114684',
                  color: 'white',
                  borderRadius: '6px',
                  marginLeft: '0px',
                  p: '10px',
                  mb: '-8px',
                }}
                onClick={handleLogout}
              >
                Logout
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      </Box>
    </>
  );
};

export default Navbar;

const useDisplayName = () => {
  const userType = localStorage.getItem('userType');
  const userData: string = useSelector((state: RootState) => {
    if (userType === 'applicant') {
      return state?.applicantProfile?.user?.data?.companyName ?? '';
    } else if (userType === 'broker') {
      return `${state?.broker?.brokerProfileData?.data?.firstName ?? ''} ${state?.broker?.brokerProfileData?.data?.lastName ?? ''}`;
    } else if (userType === 'brokerage') {
      return state?.brokerage?.user?.brokerageInfo?.legalName ?? '';
    }
  });
  return !!userData ? userData : '';
};
