import { Box, Text, useToast } from '@chakra-ui/react';
import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getApplicationById } from 'src/Redux/Applications/slice';
import { formatDate } from 'src/utils/helpers';
import {
  PFinfo,
  PFinfoUpdate,
  fetchPFinfoData,
} from '../../../Redux/Broker/CreateApplicationForm/CreateApplicationForm';
import { SubmitPersonalFinanceForms } from '../../../Redux/Broker/Dashboard/BrokerageDashboard';
import { getApplicantHistory } from '../../../Redux/Broker/Dashboard/History';
import { RootState } from '../../../Redux/Store';
import PersonalFinancialInfo from './PersonalFinancialInfo';

const OwnerPersonalFinanceInfo = forwardRef<any, any>(
  (
    {
      ongoingID,
      setIsSubmited,
      isSubmited,
      setActiveStep,
      formData,
      businessEstablishedDate,
    },
    ref
  ) => {
    let PFOwnerDetails: any[] = [];
    let PFOwnerInitialDetails: any[] = [];
    const [personalFinanceInfoList, setPersonalFinanceInfoList] = useState<
      Record<string, any>
    >({});
    const hasSubmittedRef = useRef(false);
    const [submitForm, setSubmitForm] = useState(false);
    const [isReturnToSender, setIsReturnToSender] = useState(false);
    const [historyIndex, setHistoryIndex] = useState(0);
    const [assignToId, setAssignToId] = useState(0);
    const location = useLocation();
    const { showHistory, applicationStatus, applicantId } =
      location.state || {};
    const toast = useToast();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const brokerageDashboard: any = useSelector(
      (state: RootState) => state.brokerageDashboard
    );
    // const userData: any = useSelector(
    //   (state: RootState) => state.brokerageDashboard.user
    // );
    const historyData: any = useSelector(
      (state: RootState) => state.history.user?.data
    );

    const userState = useSelector((state: RootState) => state.owner.user);
    const ownerData = useMemo(
      () => (userState && userState.data?.length ? userState.data : [{}]),
      [userState]
    );

    // const onGoingData = userData?.data;
    // let financialInfoFormData = userData && userData?.data?.financialInfo;
    let financialInfoFormData = formData;
    const applicationId = formData?.applicationId;

    const isHistoryMode =
      showHistory && historyData && historyData[historyIndex];
    const historyOwnersFinancialInfo = isHistoryMode
      ? historyData[historyIndex]?.details?.updatedData || []
      : [];
    const ownersFinancialInfo =
      (isHistoryMode
        ? historyData[historyIndex]?.details?.ownersFinancialInfo
        : financialInfoFormData?.ownersFinancialInfo) || [];

    // const financialInfoRef = useRef<any>(null);
    // useImperativeHandle(ref, () => ({
    //   formik: financialInfoRef?.current?.formik,
    // }));

    const handleSetChildFormData = useCallback(
      ({
        key,
        formData,
        updatedData,
        applicationId,
        isReturnToSender,
        flag,
      }: any) => {
        setPersonalFinanceInfoList((prev) => {
          return {
            ...prev,
            [`${key}`]: { formData, updatedData },
            applicationId: applicationId,
            returnToSender: isReturnToSender,
            flag: flag,
          };
        });
      },
      []
    );

    // const fetchOngoingApplicant = async () => {
    //   if (!!localStorage.getItem('Token')) {
    //     let actionResult = await dispatch(
    //       OngoingApplicant({ id: ongoingID }) as any
    //     );
    //     if (actionResult?.payload?.status === 401) {
    //       navigate('/login');
    //     }
    //   } else {
    //     navigate('/login');
    //   }
    // };

    const fetchOngoingApplication = async () => {
      dispatch(
        getApplicationById({
          applicationId: financialInfoFormData?.applicationId,
        }) as any
      );
    };

    const submit = async () => {
      console.log(
        'personalFinanceInfoList',
        personalFinanceInfoList,
        ownerData
      );

      if (Object.keys(personalFinanceInfoList).length === ownerData?.length)
        return;

      const { flag, returnToSender } = personalFinanceInfoList;

      const allData = Object.values(personalFinanceInfoList)
        .filter((item) => typeof item === 'object' && item !== null)
        .map((item) => item?.formData);
      const updatedData = Object.values(personalFinanceInfoList)
        .filter((item) => typeof item === 'object' && item !== null)
        .map((item) => item?.updatedData);

      console.log('allData', allData, updatedData);

      const data: Record<string, any> = {
        applicationId: financialInfoFormData?.applicationId,
        applicantId: financialInfoFormData?.applicantId,
        brokerId: financialInfoFormData?.brokerId,
        applicationName: financialInfoFormData?.applicationName,
        returnToSender: returnToSender || false,
        ownersFinancialInfo: allData,
        updatedData: updatedData,
        ownerId: 1 || undefined,
      };
      if (assignToId) {
        data['ownerId'] = assignToId;
      } else {
        delete data.ownerId;
      }

      let res = '';
      try {
        if (flag === 'INSERT') {
          const actionResult = await dispatch(
            PFinfo({
              applicationId: personalFinanceInfoList?.applicationId,
              updatedData: data,
            }) as any
          );
          const response = actionResult.payload;
          res = response.msg;
          const resStatus = response.status;

          if (resStatus === 200) {
            const applicationData = response?.data;

            localStorage.setItem(
              'applicationId',
              applicationData?.applicationId
            );
            toast({
              title: response.msg,
              status: 'success',
              isClosable: true,
              duration: 3000,
              position: 'top-right',
            });
            fetchOngoingApplication();

            dispatch(fetchPFinfoData() as any);
          } else {
            toast({
              title: res,
              status: 'error',
              isClosable: true,
              duration: 3000,
              position: 'top-right',
            });
          }
        }

        if (flag === 'UPDATE') {
          if (returnToSender) {
            const updatedData = {
              ...data,
              returnToSender: true,
            };
            const actionResult = await dispatch(
              PFinfoUpdate({
                applicationId: personalFinanceInfoList?.applicationId,
                updatedData: updatedData,
              }) as any
            );
            const response = actionResult.payload;
            res = response?.msg;
            const resStatus = response?.status;
            if (resStatus === 200) {
              toast({
                title: response?.msg,
                status: 'success',
                isClosable: true,
                duration: 3000,
                position: 'top-right',
              });
            } else {
              if (resStatus === 401) {
                navigate('/login');
              } else {
                toast({
                  title: res,
                  status: 'error',
                  isClosable: true,
                  duration: 3000,
                  position: 'top-right',
                });
              }
            }
            setIsSubmited({ ...isSubmited, 'Personal Financial Info': true });
            setIsReturnToSender(false);
            // navigate('/');
          } else {
            const updatedData = {
              ...data,
              returnToSender: false,
            };

            const actionResult = await dispatch(
              PFinfoUpdate({
                applicationId: personalFinanceInfoList?.applicationId,
                updatedData: updatedData,
              }) as any
            );

            const response = actionResult?.payload;
            res = response?.msg;
            const resStatus = response?.status;
            if (resStatus === 200) {
              toast({
                title: response?.msg,
                status: 'success',
                isClosable: true,
                duration: 3000,
                position: 'top-right',
              });
              fetchOngoingApplication();
            } else {
              if (resStatus === 401) {
                navigate('/login');
              } else {
                toast({
                  title: res,
                  status: 'error',
                  isClosable: true,
                  duration: 3000,
                  position: 'top-right',
                });
              }
            }
            // const actionResult = await dispatch(PFinfo(data) as any);
            // const response = actionResult.payload;
          }
        }
      } catch (error: any) {
        console.log('error', error?.message);
      }

      setPersonalFinanceInfoList({});
      setSubmitForm(() => false);
      hasSubmittedRef.current = false;
      if (brokerageDashboard?.submitPersonalFinanceForm) {
        setActiveStep((prevStep: number) => prevStep + 1);
      }
      await dispatch(SubmitPersonalFinanceForms(false) as any);
    };

    const resetSubmitValues = () => {
      setPersonalFinanceInfoList({});
      setSubmitForm(() => false);
      hasSubmittedRef.current = false;
    };

    useEffect(() => {
      // if (applicantId) {
      //   dispatch(OngoingApplicant({ id: applicantId }) as any);
      // }
      if (applicationId && showHistory) {
        dispatch(
          getApplicantHistory({
            applicationId: applicationId,
            modelName: 'Financial Info',
          }) as any
        );
      }
    }, []);

    useEffect(() => {
      const dataLength =
        Object.values(personalFinanceInfoList)?.filter(
          (item) =>
            typeof item === 'object' && item !== null && item !== undefined
        )?.length || 0;

      console.log(
        'submitForm',
        submitForm,
        dataLength,
        ownerData?.length,
        personalFinanceInfoList
      );
      //(submitForm || brokerageDashboard?.submitPersonalFinanceForm) && dataLength === ownerData?.length (pf form submit using dispatch condition)
      if (
        submitForm &&
        dataLength === ownerData?.length &&
        !hasSubmittedRef.current
      ) {
        submit();
        hasSubmittedRef.current = true;
      }

      if (!assignToId) setSubmitForm(() => false);
    }, [submitForm, personalFinanceInfoList]);

    return (
      <>
        <Box
          display={'flex'}
          gap={4}
          w="min(88rem,95%)"
          // margin={'-236px auto 0'}
          flexDirection={{ base: 'column', lg: 'row' }}
          h={'100%'}
        >
          <Box display={'flex'} flexDirection={'column'} w={'100%'}>
            {ownerData?.map((data: any, index: number) => (
              <PersonalFinancialInfo
                key={index}
                index={index}
                setIsSubmited={setIsSubmited}
                isSubmited={isSubmited}
                ownerDetails={data}
                ownersCount={ownerData?.length || 1}
                setIsReturnToSender={setIsReturnToSender}
                isReturnToSender={isReturnToSender}
                handleSetChildFormData={handleSetChildFormData}
                setSubmitForm={setSubmitForm}
                submitForm={submitForm}
                ownersFinancialInfo={ownersFinancialInfo}
                setAssignToId={setAssignToId}
                assignToId={assignToId}
                historyOwnersFinancialInfo={
                  historyOwnersFinancialInfo
                    ? historyOwnersFinancialInfo[index]
                    : []
                }
                resetSubmitValues={resetSubmitValues}
                ref={ref}
                formData={formData}
                PFOwnerDetails={PFOwnerDetails}
                PFOwnerInitialDetails={PFOwnerInitialDetails}
                businessEstablishedDate={businessEstablishedDate}
              />
            ))}
          </Box>
          {showHistory && (
            <>
              <Box
                height="calc(100vh - 150px)"
                w={{
                  base: '100%',
                  lg: '360px',
                }}
                sx={{ '&::-webkit-scrollbar': { display: 'none' } }}
                bg={'white'}
                display={'flex'}
                flexDir={'column'}
                gap={'10px'}
                borderRadius={'12px'}
                boxShadow="0 0 14px -6px rgba(0,0,0,0.3)"
                p={4}
              >
                <Box style={{ fontSize: '25px', fontWeight: 'bold' }}>
                  History
                </Box>
                <Box
                  display={'flex'}
                  flexDir={'column'}
                  gap={'10px'}
                  overflow={'auto'}
                >
                  {historyData?.map((item: any, index: number) => {
                    const formattedDate = formatDate(item?.createdAt);
                    return (
                      <Box
                        bgColor={
                          historyIndex == index ? '#1146844a' : '#f5f5f5'
                        }
                        p={3}
                        borderRadius={'12px'}
                        cursor={'pointer'}
                        mr={2}
                        border={
                          historyIndex == index
                            ? '1px solid #114684'
                            : '1px solid gray'
                        }
                        onClick={() => setHistoryIndex(index)}
                      >
                        <Box fontWeight={500} mt={2} display={'flex'}>
                          <Box marginRight={1}>Date :</Box>
                          <Text> {formattedDate.date}</Text>
                          <Text
                            mx={2}
                            borderLeft="1px solid #114684"
                            height="auto"
                            boxShadow="none"
                          ></Text>
                          <Text>{formattedDate.time}</Text>
                        </Box>
                        <Box fontSize="15px" fontWeight={500}>
                          Status : {applicationStatus}
                        </Box>
                        <Box fontSize="15px" fontWeight={500}>
                          Initiated By : {item?.initiatedBy}
                        </Box>
                        <Box fontSize="15px" fontWeight={500}>
                          Updated By : {item?.broker?.firstName}
                          &nbsp;
                          {item?.broker?.lastName}
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </>
          )}
        </Box>
      </>
    );
  }
);

export default OwnerPersonalFinanceInfo;
