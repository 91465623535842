import { HStack, Tooltip } from '@chakra-ui/react';
import { Box, Flex, MultiSelect, Text, TextInput } from '@mantine/core';
import type React from 'react';
import { useMemo } from 'react';
import { HiMiniInformationCircle } from 'react-icons/hi2';
import { useLiquidityContext } from 'src/context/LiquidityRatioContext';
import {
  CalculationFields,
  calculateTotalWthPLBS,
  getBSAssetsOptions,
  getPLExpensesOptions,
} from '../../data';

interface CalculationDisplayDaysOfCashProps {
  label: string;
  calculationFields: CalculationFields;
}

export const CalculationDisplayDaysOfCash: React.FC<
  CalculationDisplayDaysOfCashProps
> = ({ label, calculationFields }) => {
  const {
    totalCashAndCashEquivalents,
    setTotalCashAndCashEquivalents,
    totalExpenses,
    setTotalExpenses,
    nonCashItems,
    setSelectedNonCashItems,
    reportReferenceData,
    historyData,
  } = useLiquidityContext();
  const isTotalCashAndCashEquivalentsValue = !!calculationFields
    ?.totalCashAndCashEquivalents?.value
    ? calculationFields?.totalCashAndCashEquivalents?.value
    : 0;
  const isAnnualOperatingExpenseValue = !!calculationFields?.totalExpenses
    ?.value
    ? calculationFields?.totalExpenses?.value
    : 0;
  const isNonCashItemsValue = !!calculationFields?.nonCashItems?.value
    ? calculationFields?.nonCashItems?.value
    : 0;

  const totalCashAndCashEquivalentsSelectedFieldSumInitial = useMemo(() => {
    return calculateTotalWthPLBS(
      totalCashAndCashEquivalents,
      calculationFields?.totalCashAndCashEquivalents,
      reportReferenceData
    );
  }, []);
  const cashAndCashEquivalentsTotalInitialValue =
    isTotalCashAndCashEquivalentsValue -
    totalCashAndCashEquivalentsSelectedFieldSumInitial;

  const totalAnnualOperatingExpenseSelectedFieldSumInitial = useMemo(() => {
    return calculateTotalWthPLBS(
      totalExpenses,
      calculationFields?.totalExpenses,
      reportReferenceData
    );
  }, []);
  const annualOperatingExpenseTotalInitialValue =
    isAnnualOperatingExpenseValue -
    totalAnnualOperatingExpenseSelectedFieldSumInitial;

  const totalNonCashItemsSelectedFieldSumInitial = useMemo(() => {
    return calculateTotalWthPLBS(
      nonCashItems,
      calculationFields?.nonCashItems,
      reportReferenceData
    );
  }, []);
  const nonCashItemsTotalInitialValue =
    isNonCashItemsValue - totalNonCashItemsSelectedFieldSumInitial;

  const cashAndCashEquivalentsTotal = useMemo(() => {
    const totalSumSelectedFields = calculateTotalWthPLBS(
      totalCashAndCashEquivalents,
      calculationFields?.totalCashAndCashEquivalents,
      reportReferenceData
    );
    return Number(
      Number(
        totalSumSelectedFields + cashAndCashEquivalentsTotalInitialValue
      )?.toFixed(2)
    );
  }, [
    totalCashAndCashEquivalents,
    calculationFields.totalCashAndCashEquivalents,
  ]);

  const annualOperatingExpenseTotal = useMemo(() => {
    const totalSumSelectedFields = calculateTotalWthPLBS(
      totalExpenses,
      calculationFields?.totalExpenses,
      reportReferenceData
    );
    return Number(
      Number(
        totalSumSelectedFields + annualOperatingExpenseTotalInitialValue
      )?.toFixed(2)
    );
  }, [totalExpenses, calculationFields.totalExpenses]);

  const nonCashItemsTotal = useMemo(() => {
    const totalSumSelectedFields = calculateTotalWthPLBS(
      nonCashItems,
      calculationFields?.nonCashItems,
      reportReferenceData
    );
    return Number(
      Number(totalSumSelectedFields + nonCashItemsTotalInitialValue)?.toFixed(2)
    );
  }, [nonCashItems, calculationFields.nonCashItems]);

  const result = useMemo(() => {
    const denominator = (annualOperatingExpenseTotal - nonCashItemsTotal) / 365;
    const answer = Number(
      (cashAndCashEquivalentsTotal / denominator).toFixed(2)
    );
    return !!denominator ? answer : 'N/A';
  }, [
    cashAndCashEquivalentsTotal,
    annualOperatingExpenseTotal,
    nonCashItemsTotal,
  ]);

  return (
    <Box w={'100%'}>
      <HStack gap={2} mb="md">
        <Text size="lg" weight={700}>
          {label}
        </Text>
        {!!historyData?.daysOfCash && (
          <Tooltip
            label={`${label} (${historyData?.daysOfCash?.value}) is updated by ${historyData?.daysOfCash?.updatedBy}`}
            placement="right"
            hasArrow
          >
            <Box>
              <HiMiniInformationCircle
                size={18}
                color="black"
                cursor="pointer"
              />
            </Box>
          </Tooltip>
        )}
      </HStack>
      <Flex direction="column" align="stretch" gap="md">
        <Flex wrap={'wrap'} align="center" justify={'flex-start'} gap="xs">
          <MultiSelect
            label={`Cash on Hand (${cashAndCashEquivalentsTotal})`}
            data={getBSAssetsOptions(
              calculationFields?.totalCashAndCashEquivalents?.fields!,
              reportReferenceData!
            )}
            value={totalCashAndCashEquivalents}
            onChange={setTotalCashAndCashEquivalents}
            placeholder="Select Cash on Hand items"
            searchable
            className="legalform_pdfPreference"
            maxDropdownHeight={400}
            maw={'50%'}
            w={'100%'}
          />
          <Text size="xl" weight={700} mt={'xs'}>
            ÷
          </Text>
        </Flex>

        <Flex align="center" justify="center" wrap={'wrap'} gap={'xs'}>
          <Box sx={{ flex: 1 }}>
            <MultiSelect
              label={`Annual Operating Expense (${annualOperatingExpenseTotal})`}
              data={getPLExpensesOptions(
                calculationFields?.totalExpenses?.fields!,
                reportReferenceData!
              )}
              value={totalExpenses}
              onChange={setTotalExpenses}
              placeholder="Select Annual Operating Expense items"
              searchable
              className="legalform_pdfPreference"
              maxDropdownHeight={400}
            />
          </Box>
          <Text size="xl" weight={700} mt={'xs'}>
            -
          </Text>
          <Box sx={{ flex: 1 }}>
            <MultiSelect
              label={`Non-Cash Items (${nonCashItemsTotal})`}
              data={getBSAssetsOptions(
                calculationFields?.nonCashItems?.fields!,
                reportReferenceData!
              )}
              value={nonCashItems}
              onChange={setSelectedNonCashItems}
              placeholder="Select Non-Cash Items"
              searchable
              className="legalform_pdfPreference"
              maxDropdownHeight={400}
            />
          </Box>
          <Text size="xl" weight={700} mt={'xs'}>
            /
          </Text>
          <Text align="center" size={'xs'}>
            365 Days
          </Text>
        </Flex>

        <Flex align="center" justify="flex-start" wrap={'wrap'} gap={'xs'}>
          {/* Equals Sign */}
          <Text size="xl" weight={700} mt={'xs'}>
            =
          </Text>
          {/* Result */}
          <TextInput
            label="Result"
            value={result ?? 0}
            disabled
            styles={{
              input: {
                textAlign: 'right',
                '&:disabled': {
                  color: 'black',
                  fontWeight: 'bold',
                },
              },
            }}
            maw={'50%'}
            w={'100%'}
          />
        </Flex>
      </Flex>
    </Box>
  );
};
