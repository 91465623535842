import { HStack, Tooltip } from '@chakra-ui/react';
import { Box, Flex, MultiSelect, Text, TextInput } from '@mantine/core';
import type React from 'react';
import { useMemo } from 'react';
import { HiMiniInformationCircle } from 'react-icons/hi2';
import { useLeverageContext } from 'src/context/LeverageRatioContext';
import {
  CalculationFields,
  calculateTotalWthPLBS,
  getOptionWithPLBSReports,
  getStyles,
} from '../../data';

interface CalculationDisplayLTATNProps {
  label: string;
  calculationFields: CalculationFields;
}

export const CalculationDisplayLTATN: React.FC<
  CalculationDisplayLTATNProps
> = ({ label, calculationFields }) => {
  const {
    totalLongTermAssets,
    setTotalLongTermAssets,
    tangibleNetworth,
    reportReferenceData,
    historyData,
  } = useLeverageContext();

  const isLongTermAssetsOption =
    !!calculationFields?.totalLongTermAssets?.fields?.length;
  const isLongTermAssetsValue = !!calculationFields?.totalLongTermAssets?.value
    ? calculationFields?.totalLongTermAssets?.value
    : 0;

  const longTermAssetsSelectedFieldSumInitial = useMemo(() => {
    return calculateTotalWthPLBS(
      totalLongTermAssets,
      calculationFields?.totalLongTermAssets,
      reportReferenceData
    );
  }, []);
  const longTermAssetsTotalInitialValue =
    isLongTermAssetsValue - longTermAssetsSelectedFieldSumInitial;

  // const longTermAssetsTotal = useMemo(() => {
  //   const totalSum =
  //     calculateTotalWthPLBS(
  //       totalLongTermAssets,
  //       calculationFields?.totalLongTermAssets,
  //       reportReferenceData
  //     ) + (isLongTermAssetsOption ? 0 : isLongTermAssetsValue);
  //   return Number(totalSum?.toFixed(2));
  // }, [totalLongTermAssets, calculationFields.totalLongTermAssets]);

  const longTermAssetsTotal = useMemo(() => {
    const totalSumSelectedFields = calculateTotalWthPLBS(
      totalLongTermAssets,
      calculationFields?.totalLongTermAssets,
      reportReferenceData
    );
    return Number(
      Number(totalSumSelectedFields + longTermAssetsTotalInitialValue)?.toFixed(
        2
      )
    );
  }, [totalLongTermAssets, calculationFields?.totalLongTermAssets]);

  const result = useMemo(() => {
    const answer = Number((longTermAssetsTotal / tangibleNetworth).toFixed(2));
    return !!tangibleNetworth ? answer : 'N/A';
  }, [longTermAssetsTotal, tangibleNetworth]);

  return (
    <Box w={'100%'}>
      <HStack gap={2} mb="md">
        <Text size="lg" weight={700}>
          {label}
        </Text>
        {!!historyData?.longTermAssetsToTangibleNetWorth && (
          <Tooltip
            label={`${label} (${historyData?.longTermAssetsToTangibleNetWorth?.value}) is updated by ${historyData?.longTermAssetsToTangibleNetWorth?.updatedBy}`}
            placement="right"
            hasArrow
          >
            <Box>
              <HiMiniInformationCircle
                size={18}
                color="black"
                cursor="pointer"
              />
            </Box>
          </Tooltip>
        )}
      </HStack>
      <Flex direction="column" align="stretch" gap="md">
        <Flex wrap={'wrap'} align="center" justify={'flex-start'} gap="xs">
          <MultiSelect
            label={`Long Term Assets (${longTermAssetsTotal})`}
            data={getOptionWithPLBSReports(
              calculationFields?.totalLongTermAssets?.fields!,
              reportReferenceData!
            )}
            value={totalLongTermAssets}
            onChange={setTotalLongTermAssets}
            placeholder="Select Long Term Assets items"
            searchable
            className="legalform_pdfPreference"
            maxDropdownHeight={400}
            sx={{ flex: 1 }}
            styles={getStyles(calculationFields?.totalLongTermAssets?.fields!)}
            readOnly
          />
          <Text size="xl" weight={700} mt={'xs'}>
            ÷
          </Text>
          {/* <MultiSelect
            label="Tangible Networth"
            data={getOptions(calculationFields?.tangibleNetworth?.fields!)}
            value={tangibleNetworth}
            onChange={setTangibleNetworth}
            placeholder="Select Tangible Networth items"
            searchable
            sx={{ flex: 1 }}
          /> */}
          <TextInput
            label="Tangible Networth"
            value={tangibleNetworth?.toFixed(2) ?? 0}
            disabled
            styles={{
              input: {
                textAlign: 'right',
                '&:disabled': {
                  color: 'black',
                  fontWeight: 'bold',
                },
              },
            }}
            sx={{ flex: 1 }}
          />
        </Flex>
        <Flex align="center" justify="flex-start" wrap={'wrap'} gap={'xs'}>
          {/* Equals Sign */}
          <Text size="xl" weight={700} mt={'xs'}>
            =
          </Text>
          {/* Result */}
          <TextInput
            label="Result"
            value={result ?? 0}
            disabled
            styles={{
              input: {
                textAlign: 'right',
                '&:disabled': {
                  color: 'black',
                  fontWeight: 'bold',
                },
              },
            }}
            maw={'50%'}
            w={'100%'}
          />
        </Flex>
      </Flex>
    </Box>
  );
};
