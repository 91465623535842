import { Box } from '@chakra-ui/react';
import { Button, Flex, Paper, Text } from '@mantine/core';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import type React from 'react';
import { useState } from 'react';
import PDFViewer from 'src/Component/Common/pdf/PDFViewer';

interface SideContentProps {
  reportURL: { BSReportURL: string; PLReportURL: string };
}

export const SideContent: React.FC<SideContentProps> = ({ reportURL }) => {
  const { BSReportURL, PLReportURL } = reportURL;
  const [currentReport, setCurrentReport] = useState<'BS' | 'PL'>('BS');

  const toggleReport = () => {
    setCurrentReport((current) => (current === 'BS' ? 'PL' : 'BS'));
  };

  return (
    <Paper
      // shadow="md"
      p="md"
      m={10}
      // style={{
      //   // height: '100vh',
      //   height: '100%',
      //   maxHeight: `calc(100dvh - 40px)`,
      //   overflowY: 'auto',
      //   width: '50%',
      //   position: 'fixed',
      //   top: 20,
      //   right: 20,
      //   bottom: 20,
      //   zIndex: 1000,
      //   backgroundColor: 'white',
      //   display: 'flex',
      //   flexDirection: 'column',
      //   justifyContent: 'flex-start',
      // }}
    >
      <Flex align="center" justify="space-between" mb="md">
        <Text size="xl" weight={700}>
          {currentReport === 'BS' ? 'Balance Sheet' : 'Profit & Loss'}
        </Text>
        <Flex>
          <Button onClick={toggleReport} variant="subtle" mr="xs">
            <IconChevronLeft size={18} />
          </Button>
          <Button onClick={toggleReport} variant="subtle">
            <IconChevronRight size={18} />
          </Button>
        </Flex>
      </Flex>
      <Box border={'1px solid #000'}>
        <PDFViewer url={currentReport === 'BS' ? BSReportURL : PLReportURL} />
      </Box>
    </Paper>
  );
};
