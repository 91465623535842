import { Badge, Box, Flex, Text } from '@chakra-ui/react';
import { HiArrowLongRight } from 'react-icons/hi2';
import AvatarComponent from 'src/Component/Avatar';
import { ocrIssueLogs } from 'src/Redux/Admin/OcrReportIssues/state';
import { getDateFormat } from 'src/utils/helpers';
import { getIssueCurrentStatus } from './data';

const IssueStatus = ({ Status }: { Status: string }) => {
  return (
    <Badge
      bg={getIssueCurrentStatus(Status!)?.bgColor}
      color={getIssueCurrentStatus(Status!)?.textColor}
      px="2"
      py="1"
      borderRadius="md"
    >
      {getIssueCurrentStatus(Status!!)?.updatedStatus}
    </Badge>
  );
};

const IssueLog = ({
  cardDetails,
  index,
  prevCard,
}: {
  cardDetails: ocrIssueLogs;
  index: number;
  prevCard: ocrIssueLogs | null;
}) => {
  const isBroker = cardDetails?.broker;
  const AvatarName = isBroker
    ? `${cardDetails?.broker?.firstName} ${cardDetails?.broker?.lastName}`
    : 'Subtraid Admin';
  const isStatusMatched = prevCard?.ticketStatus === cardDetails?.ticketStatus;

  return (
    <Box key={cardDetails?.supportTicketId?.toString()}>
      <Flex gap={5} alignItems={'flex-start'} flexWrap={'nowrap'}>
        <AvatarComponent name={AvatarName!} />
        <Box>
          <Flex
            gap={{ base: 1, md: 2 }}
            mb={1}
            direction={{ base: 'column', md: 'row' }}
          >
            <Text>{cardDetails?.message}</Text>
            <Text color={'gray'} fontSize={'14px'}>
              {getDateFormat(cardDetails?.createdAt, 'MMMM DD, YYYY hh:mm A')}
            </Text>
          </Flex>
          {isStatusMatched || index === 0 ? (
            <Flex mb={1}>
              <IssueStatus Status={cardDetails?.ticketStatus} />
            </Flex>
          ) : (
            <Flex flexWrap={'nowrap'} alignItems={'center'} gap={5} mb={1}>
              <IssueStatus Status={prevCard?.ticketStatus!} />
              <Box>
                <HiArrowLongRight size={20} />
              </Box>
              <IssueStatus Status={cardDetails?.ticketStatus!} />
            </Flex>
          )}
        </Box>
      </Flex>
    </Box>
  );
};

export default IssueLog;
