import { ConfigOptions, asBlob, generateCsv, mkConfig } from 'export-to-csv';
import moment from 'moment';
import {
  AccountPayableColumns,
  AccountReceivableColumns,
  BalanceSheetColumns,
  ProfitLossColumns,
  WICColumns,
} from 'src/data/reports/columns';
import { WIPExceleColumns } from 'src/Pages/Reports/components/data';
import {
  IBaseData,
  IReport,
} from 'src/Redux/Broker/Reports/APAgingSummary/state';
import { TWICData } from 'src/Redux/Broker/Reports/WorkingCapital/state';
import { REPORT_TYPE } from 'src/types/unions';
import { fixedNumber, replaceNullUndefined } from '../helpers';

// TYPES
export interface ICSVConfig {
  filename?: string;
  columnHeaders?: {
    key: string;
    displayLabel: string;
  }[];
  title?: string;
  fieldSeparator: string;
  decimalSeparator: string;
  replaceUndefinedWith: string;
  showTitle: boolean;
  showColumnHeaders: boolean;
}

export const getCSVConfigByReportName = ({
  type,
  title,
  filename,
}: {
  type: REPORT_TYPE;
  title: string;
  filename: string;
}): ICSVConfig => {
  let config = {
    fieldSeparator: ',',
    decimalSeparator: '.',
    replaceUndefinedWith: '',
    showTitle: true,
    showColumnHeaders: true,
  };
  switch (type) {
    case 'Balance Sheet':
      return {
        ...config,
        filename,
        columnHeaders: BalanceSheetColumns.map((column) => ({
          key: column.accessorKey as string,
          displayLabel: column.header,
        })),
        title,
      };
    case 'Profit and Loss':
      return {
        ...config,
        // useKeysAsHeaders: true,
        filename,
        columnHeaders: ProfitLossColumns.map((column) => ({
          key: column.accessorKey as string,
          displayLabel: column.header,
        })),
        title,
      };
    case 'Account Receivable Aging':
      return {
        ...config,
        // useKeysAsHeaders: true,
        filename,
        columnHeaders: AccountReceivableColumns.map((column) => ({
          key: column.accessorKey as string,
          displayLabel: column.header,
        })),
        title,
      };
    case 'Account Payable Aging':
      return {
        ...config,
        // useKeysAsHeaders: true,
        filename,
        columnHeaders: AccountPayableColumns.map((column) => ({
          key: column.accessorKey as string,
          displayLabel: column.header,
        })),
        title,
      };
    case 'Work in Progress':
      return {
        ...config,
        // useKeysAsHeaders: true,
        filename,
        columnHeaders: WIPExceleColumns.map((column) => ({
          key: column.accessorKey as string,
          displayLabel: column.header,
        })),
        title,
      };
    case 'Working Capital':
      return {
        ...config,
        // useKeysAsHeaders: true,
        filename,
        columnHeaders: WICColumns.map((column) => ({
          key: column.accessorKey as string,
          displayLabel: column.header,
        })),
        title,
      };
    default:
      return { ...config };
  }
};

export const getIndividualData = ({
  report,
  config,
}: {
  report: IBaseData | TWICData;
  config: ConfigOptions;
}) => {
  try {
    let refineData;
    if ('reportData' in report) {
      refineData = report.reportData.map((element: Record<string, any>) => {
        return replaceNullUndefined(element);
      });
    } else {
      refineData = replaceNullUndefined(report);
    }
    const csv = generateCsv(config)(refineData);
    return asBlob(config)(csv);
  } catch (error) {
    return null;
  }
};

export const generateWIPReport = (reportData: IReport, type: REPORT_TYPE) => {
  try {
    const { data } = reportData;
    if (data && 'progressInfo' in data && data.reportInfo) {
      const { progressInfo, reportInfo } = data;
      const { filter, reportName, qboCompanyName, reportGeneratedDate } =
        reportInfo;

      const config = getCSVConfigByReportName({
        type: type,
        title: `${qboCompanyName}\r\n${reportName} Summary \r\n${filter} \r\nAccounting data last updated at ${moment(reportGeneratedDate).format('DD-MM-YYYY hh:mm A') ?? new Date()} \r\nDate extracted at ${moment(new Date()).format('DD-MM-YYYY hh:mm A')} \r\n`,
        filename: `${qboCompanyName} ${reportName} (${filter})`,
      });

      if (config) {
        const csvConfig = mkConfig(config);

        let updatetableData = progressInfo?.map((item: any) => {
          return {
            ...item,
            projectBonded: item?.projectBonded
              ? item?.projectBonded === true
                ? 'Yes'
                : 'No'
              : null,
            percentEarned: fixedNumber(item.percentEarned / 100),
            forecastedGPMargin: fixedNumber(item.forecastedGPMargin / 100),
            currentGPMargin: fixedNumber(item.currentGPMargin / 100),
          };
        });

        const csv = generateCsv(csvConfig)(updatetableData);
        const blob = asBlob(config)(csv);

        if (blob) {
          return {
            blob,
            filename: `${'qboCompanyName'} ${reportName} (${'filter'})`,
            folderName: reportName,
          };
        }
      }
    }
  } catch (error) {
    console.log('Genarte PDF WIP', error);
    return null;
  }
};

export const generateWICReport = (
  name: string,
  reportData: TWICData,
  type: REPORT_TYPE
) => {
  try {
    if (reportData.currentLiabilities && reportData.reportInfo) {
      const {
        currentAssets,
        currentLiabilities,
        totalAss,
        totalLib,
        totalWIC,
        reportInfo,
      } = reportData;
      const { qboCompanyName, reportName, filter, reportGeneratedDate } =
        reportInfo;
      const allData = [
        ...currentAssets,
        {
          keyName: 'Total Allowable Assets',
          ...reportData.totalAss,
          amount: fixedNumber(totalAss?.amount ?? 0),
          adjustmentAmount: fixedNumber(totalAss?.adjustmentAmount ?? 0),
          adjustment: fixedNumber(totalAss?.adjustment ?? 0),
        },
        {},
        {
          keyName: 'Current Liabilities',
          amount: 'Amount',
          adjustment: 'Adjustment',
          adjustmentAmount: 'Adjustment Amount',
        },
        ...currentLiabilities,
        {
          keyName: 'Total Current Liabilities',
          amount: fixedNumber(totalLib?.amount ?? 0),
          adjustmentAmount: fixedNumber(totalLib?.adjustmentAmount ?? 0),
          adjustment: fixedNumber(totalLib?.adjustment ?? 0),
        },
        {},
        {
          keyName: 'Working Capital',
          amount: fixedNumber(totalWIC.amount ?? 0),
          adjustmentAmount: fixedNumber(totalWIC.adjustmentAmount ?? 0),
          adjustment: fixedNumber(totalWIC.adjustment ?? 0),
        },
      ];

      const config = getCSVConfigByReportName({
        type: type,
        // title: `${qboCompanyName}\r\n${reportName}\r\n${filter}\r\nSummary As of ${reportGeneratedDate ?? new Date()}\r\n`,
        // filename: `${qboCompanyName} ${reportName} (${filter})`,
        title: `${qboCompanyName}\r\n${reportName} Summary \r\n${filter} \r\nAccounting data last updated at ${moment(reportGeneratedDate).format('DD-MM-YYYY hh:mm A') ?? new Date()} \r\nDate extracted at ${moment(new Date()).format('DD-MM-YYYY hh:mm A')} \r\n`,
        filename: `${qboCompanyName} ${reportName} (${filter})`,
      });

      const refineData = allData?.map((element: Record<string, any>) => {
        const adjustedElement = {
          ...element,
          amount:
            typeof element.amount === 'number'
              ? fixedNumber(element.amount ?? 0)
              : element.amount,
          adjustment:
            typeof element.adjustment === 'number'
              ? fixedNumber(element.adjustment / 100, 4)
              : element.adjustment,
          adjustmentAmount:
            typeof element.adjustmentAmount === 'number'
              ? fixedNumber(element.adjustmentAmount ?? 0)
              : element.adjustmentAmount,
        };

        return replaceNullUndefined(adjustedElement);
      });

      if (config) {
        const csvConfig = mkConfig(config);

        const csv = generateCsv(csvConfig)(refineData);
        const blob = asBlob(config)(csv);

        if (blob) {
          return {
            blob,
            filename: `${qboCompanyName} ${reportName} (${filter})`,
            folderName: name,
          };
        }
      }
    }
  } catch (error) {
    console.log('Genarte PDF WIC', error);
    return null;
  }
};

const processReport = (reportData: IBaseData | TWICData, type: REPORT_TYPE) => {
  try {
    if (reportData && 'reportInfo' in reportData && reportData.reportInfo) {
      const { filter, qboCompanyName, reportName, reportGeneratedDate } =
        reportData.reportInfo;
      const config = getCSVConfigByReportName({
        type: type,
        title: `${qboCompanyName}\r\n${reportName} Summary \r\n${filter} \r\nAccounting data last updated at ${moment(reportGeneratedDate).format('DD-MM-YYYY hh:mm A') ?? new Date()} \r\nDate extracted at ${moment(new Date()).format('DD-MM-YYYY hh:mm A')} \r\n`,
        filename: `${qboCompanyName} ${reportName} (${filter})`,
      });
      if (config) {
        const csvConfig = mkConfig(config);
        const blob = getIndividualData({
          report: reportData,
          config: csvConfig,
        });
        if (blob) {
          return {
            blob,
            filename: `${qboCompanyName} ${reportName} (${filter})`,
            folderName: reportName,
          };
        }
      }
    } else if ('currentLiabilities' in reportData) {
      console.log('reportData', reportData);
    }
  } catch (error) {
    console.error('Error generating reports:', error);
    return null;
  }
};

export const generateAllReports = (allReportData: IReport[]) => {
  try {
    const data: { blob: Blob; filename: string; folderName: string }[] = [];

    allReportData.forEach((report) => {
      if (report.reportName === 'Working Capital') {
        if (report.TFYD && 'currentLiabilities' in report.TFYD) {
          const res = generateWICReport(
            report.reportName,
            report.TFYD,
            report.reportName as REPORT_TYPE
          );

          res && data.push(res);
        }
        if (report.LFY && 'currentLiabilities' in report.LFY) {
          const res = generateWICReport(
            report.reportName,
            report.LFY,
            report.reportName as REPORT_TYPE
          );
          res && data.push(res);
        }
      } else {
        if (report.TFYD) {
          const res = processReport(
            report.TFYD,
            report.reportName as REPORT_TYPE
          );
          res && data.push(res);
        }
        if (report.LFY) {
          const res = processReport(
            report.LFY,
            report.reportName as REPORT_TYPE
          );
          res && data.push(res);
        }
        if (report.data) {
          const res = generateWIPReport(
            report,
            report.reportName as REPORT_TYPE
          );
          res && data.push(res);
        }
      }
    });

    return data;
  } catch (error) {
    console.error('Error generating reports:', error);
    return [];
  }
};
