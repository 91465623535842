import {
  Box,
  Button,
  Container,
  Flex,
  Image,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const TokenExpired = () => {
  const navigate = useNavigate();
  const OnHandelUser = () => {
    navigate('/login');
  };
  const formWidth = useBreakpointValue({ base: '96%', sm: '57%' });
  const [searchParams] = useSearchParams();
  const message = searchParams.get('message');

  return (
    <>
      <Box h="100%">
        <Flex>
          <Container maxW="100%" p={['0px', '0px', '0px']}>
            <Flex flexWrap="wrap" height="100vh  " gap={[5, 0, 0, 0]}>
              <Box
                bg="#114684"
                borderRadius={[
                  '0px 200px 0px 0px',
                  '0px 245px 0px 0px',
                  '0px 350px 0px 0px',
                ]}
                w={['100%', '40%']}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                flexDir={'column'}
                gap={'10px'}
              >
                <Box display="flex" justifyContent="center">
                  <Image
                    src="../../../assets/Images/insurance3.jpg"
                    alt="insurance"
                    width={['700px', '400px', '500px', '700px']}
                    boxSize={['250px', '230px', '260px', '300px']}
                    objectFit="cover"
                  />
                </Box>
                <Text
                  fontWeight="bold"
                  color="white"
                  textAlign="center"
                  fontSize={20}
                >
                  Do you already have a account?
                </Text>
                <Box textAlign="center">
                  <Button
                    bg="#114684"
                    color="white"
                    variant="outline"
                    _hover={{ bg: '#114684' }}
                    onClick={OnHandelUser}
                    mb={5}
                  >
                    sign In
                  </Button>
                </Box>
              </Box>

              <Container
                textAlign="center"
                w={formWidth}
                boxShadow=" rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 1.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset"
                h="auto"
                margin="auto"
                p="18px"
                borderRadius="20px"
                display={'flex'}
                flexDir={'column'}
                gap={'0.1rem'}
              >
                <Text
                  fontWeight="bold"
                  color="#114684"
                  textAlign="start"
                  fontSize={28}
                >
                  {message || 'Something went wrong'}
                </Text>
              </Container>
            </Flex>
          </Container>
        </Flex>
      </Box>
    </>
  );
};

export default TokenExpired;
