import {
  Box,
  Button,
  Checkbox,
  Flex,
  Grid,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Modal, Title } from '@mantine/core';
import { useFormik } from 'formik';
import {
  MRT_ColumnDef,
  MRT_Row,
  MRT_TableInstance,
  MantineReactTable,
  useMantineReactTable,
} from 'mantine-react-table';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { updateARRecord } from 'src/Redux/Broker/Reports/APAgingSummary/slice';
import { updateOcrReportRecord } from 'src/Redux/OcrReports/slice';
import {
  ADARow,
  AdjustedTotalRow,
  OCRhistoryInfo,
} from 'src/Redux/OcrReports/state';
import { useAppDispatch, useAppSelector } from 'src/Redux/Store';
import { ARADASchemaEdit } from 'src/ValidationSchema/Pages/Index';
import { useAppToast } from 'src/hooks';
import {
  fixedNumber,
  getDateFormat,
  getRandomUUID,
  preventAlphabetsTyping,
} from 'src/utils/helpers';
import { formatNumber } from '../../MantineTable';
import { TAccountReceivableRow } from '../AccountReceivableTable';

interface EditARReportADAProps {
  row: MRT_Row<Record<string, any>> | null;
  table: MRT_TableInstance<Record<string, any>> | null;
  modalOpen: boolean;
  onClose: () => void;
  tableData: Record<string, any>[];
  ADAData: Record<string, any>;
  isEditable: boolean;
  ocrReportId: number | null;
}

type ADATable = {
  timeInterval: string;
  amount: number;
  value: number;
  total: number;
};
type ADATotalTable = {
  timeInterval: string;
  value: number;
};

const EditAccountReceivableADAModal = ({
  table,
  row,
  modalOpen,
  onClose,
  tableData,
  ADAData,
  isEditable,
  ocrReportId,
}: EditARReportADAProps) => {
  const [recordId, setRecordId] = useState<number | null>(null);
  const [btnLoader, setBtnLoader] = useState<boolean>(false);
  const [formSubmit, setFormSubmit] = useState<boolean>(false);
  const accountPayableReport = useAppSelector((state) => state.accountPayable);
  const dispatch = useAppDispatch();
  const toast = useAppToast();

  const userId = localStorage.getItem('userID');
  const userType = localStorage.getItem('userType');
  const brokerSlice = useAppSelector((state) => state.broker.brokerProfileData);
  const applicantSlice = useAppSelector((state) => state.applicantProfile);
  const userName =
    userType === 'applicant'
      ? applicantSlice?.user?.data?.companyName
      : userType === 'broker'
        ? brokerSlice?.data?.firstName + ' ' + brokerSlice?.data?.lastName
        : '';
  const ocrReportSlice = useAppSelector((state) => state.ocrReportSlice);

  // const [sameAmountError, setSameAmountError] = useState(true);
  // const [amountErrorMsg, setAmountErrorMsg] = useState('');

  // const [ADAtableData, setADAtableData] = useState<
  //   {
  //     timeInterval:
  //       | 'Current'
  //       | '1 - 30'
  //       | '31 - 60'
  //       | '61 - 90'
  //       | '91 and over'
  //       | 'Total';
  //     amount: number | '';
  //     value: number;
  //     total: number;
  //   }[]
  // >([
  //   { timeInterval: 'Current', amount: '', value: 0, total: 0 },
  //   { timeInterval: '1 - 30', amount: '', value: 0, total: 0 },
  //   { timeInterval: '31 - 60', amount: '', value: 0, total: 0 },
  //   { timeInterval: '61 - 90', amount: '', value: 0, total: 0 },
  //   { timeInterval: '91 and over', amount: '', value: 0, total: 0 },
  //   { timeInterval: 'Total', amount: '', value: 0, total: 0 },
  // ]);

  // const ADATotaltableData = [
  //   { timeInterval: 'Current', value: 0 },
  //   { timeInterval: '1 - 30', value: 0 },
  //   { timeInterval: '31 - 60', value: 0 },
  //   { timeInterval: '61 - 90', value: 0 },
  //   { timeInterval: '91 and over', value: 0 },
  //   { timeInterval: 'Total', value: 0 },
  // ];

  interface EditARADAFormValues {
    question: 'Amount' | 'Percentage';
    holdbackRetention?: number | null;
    retentionValue?: number | null;
    tableRow: Record<string, any> | null;
    id: number;
    applyADA: 'Total' | 'Per Interval';
    ADAtableData: {
      timeInterval: string;
      amount: number;
      value: number;
      total: number;
    }[];
  }

  const initialValues: EditARADAFormValues = {
    question: 'Amount',
    holdbackRetention: null,
    retentionValue: null,
    tableRow: row && row?.original,
    id: row && row?.original && row?.original?.id,
    applyADA: 'Total',
    ADAtableData: [
      {
        timeInterval: isEditable ? 'CURRENT' : 'Current',
        amount: 0,
        value: 0,
        total: 0,
      },
      { timeInterval: '1 - 30', amount: 0, value: 0, total: 0 },
      { timeInterval: '31 - 60', amount: 0, value: 0, total: 0 },
      { timeInterval: '61 - 90', amount: 0, value: 0, total: 0 },
      {
        timeInterval: isEditable ? '91 AND OVER' : '91 and over',
        amount: 0,
        value: 0,
        total: 0,
      },
      // { timeInterval: 'Total', amount: 0, value: 0, total: 0 },
    ],
  };

  useEffect(() => {
    return () => {
      if (!modalOpen) {
        setFormSubmit(false);
      }
    };
  }, [modalOpen]);

  useEffect(() => {
    const { status, type, error, msg } = ocrReportSlice;
    switch (status) {
      case 'loading':
        {
          if (type === 'UPDATE_OCR_REPORT') {
            setBtnLoader(true);
          }
        }
        break;
      case 'succeed':
        {
          if (type === 'UPDATE_OCR_REPORT') {
            setBtnLoader(false);
            onClose();
          }
        }
        break;
      case 'failed':
        {
          if (type === 'UPDATE_OCR_REPORT') {
            setBtnLoader(false);
            onClose();
          }
        }
        break;
      default:
        break;
    }
  }, [ocrReportSlice.status]);

  useEffect(() => {
    switch (accountPayableReport.status) {
      case 'succeed': {
        if (accountPayableReport.type === 'GET_ACCOUNT_RECEIVABLE_DATA') {
          if ((accountPayableReport?.data?.reports as any)?.id) {
            setRecordId(
              (accountPayableReport?.data?.reports as any)?.id as any
            );
          }
        } else if (
          accountPayableReport.type === 'UPDATE_ACCOUNT_RECEIVABLE_RECORD' &&
          formSubmit
        ) {
          setBtnLoader(false);
          toast({
            title: 'Success',
            status: 'success',
          });
          onClose();
        }
        return;
      }
      case 'loading': {
        if (
          accountPayableReport.type === 'UPDATE_ACCOUNT_RECEIVABLE_RECORD' &&
          formSubmit
        ) {
          setBtnLoader(true);
        }
        return;
      }
      case 'failed': {
        if (
          accountPayableReport.type === 'UPDATE_ACCOUNT_RECEIVABLE_RECORD' &&
          formSubmit
        ) {
          setBtnLoader(false);
          onClose();
        }
        return;
      }
    }
  }, [accountPayableReport.status]);

  const submitARHandler = async (values: EditARADAFormValues) => {
    try {
      setFormSubmit(true);

      let adaData: Record<string, any> = {
        valueType: values?.question,
        adaType: values?.applyADA,
      };
      let adjustedTotal: Record<string, any> = {};
      let adaSum: number = 0;
      let adjustedSum: number = 0;

      values?.ADAtableData?.forEach((item: any) => {
        adaData[item.timeInterval] = {
          amountOrPerc: Number(item.amount),
          ada: Number(item.value),
        };
        adjustedTotal[item.timeInterval] =
          Number(item.total) - Number(item.value);
        adaSum += Number(item.value);
        adjustedSum += Number(item.total);
      });
      adaData = Object.assign(adaData, {
        [isEditable ? 'TOTAL' : 'Total']: {
          ada: adaSum,
          amountOrPerc: 0,
        },
      });
      adjustedTotal = Object.assign(adjustedTotal, {
        [isEditable ? 'TOTAL' : 'Total']: adjustedSum - adaSum,
      });

      let payload = {
        id: recordId,
        adaData,
        adjustedTotal,
      };
      console.log('payload', payload);
      if (isEditable) {
        if (!!tableData?.length && !!ocrReportId) {
          const currentDateTime = moment(new Date()).toISOString();
          const msgFormatDate = getDateFormat(
            currentDateTime,
            'MMM DD,YYYY hh:mm A'
          );
          console.log('row?.original', row);

          let cols = Object.keys(row?.original!);
          const changes: any[] = [];
          cols.forEach((obj) => {
            if (obj !== 'id') {
              if (
                row?.original[obj as keyof TAccountReceivableRow] !==
                row?._valuesCache[obj]
              )
                changes.push({
                  columnName: obj !== 'Label' ? obj : 'Customer',
                  oldvalue: row?.original[obj as keyof TAccountReceivableRow],
                  newValue: row?._valuesCache[obj],
                });
            }
          });
          console.log('row_id=', Object.keys(tableData[0]));
          console.log('col=', changes);
          const history: OCRhistoryInfo = {
            id: getRandomUUID(),
            message: `Row ${row?.original?.Label} updated on ${msgFormatDate} by ${userName}`,
            user_id: Number(userId),
            row_id: row?.original?.id!,
            date: currentDateTime,
            changes: changes,
          };

          const removeAdaAdjustedTotalRow = tableData?.filter(
            (item) =>
              ![AdjustedTotalRow.AdjustedTotal, ADARow.ADA].includes(
                item?.Label
              )
          );

          dispatch(
            updateOcrReportRecord({
              id: ocrReportId,
              ocrReportData: removeAdaAdjustedTotalRow,
              history,
              adaData,
              adjustedTotal,
            })
          );
        }
      } else {
        await dispatch(updateARRecord({ payload }) as any);
      }
    } catch (err) {
      console.log('error', err);
      setFormSubmit(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ARADASchemaEdit,
    onSubmit: submitARHandler,
  });

  useEffect(() => {
    const prevRowId = (Number(row?.id.trim()) - 1).toString();
    const originalData = table?.getRow(prevRowId).original;
    const orderedKeys = [
      isEditable ? 'CURRENT' : 'Current',
      '1 - 30',
      '31 - 60',
      '61 - 90',
      isEditable ? '91 AND OVER' : '91 and over',
    ];
    const formatADAData = orderedKeys.map((key) => ({
      timeInterval: key,
      amount: ADAData[key].amountOrPerc ?? 0,
      value: ADAData[key].ada ?? 0,
      total: originalData?.[key] ?? 0,
    }));

    console.log('formatADAData', formatADAData);

    if (row?.original) {
      const queValue = row?.original?.question ?? 'Amount';
      const adaValue = row?.original?.applyADA ?? 'Total';
      formik.setValues({
        question: queValue,
        tableRow: row?.original,
        id: row?.original?.id,
        applyADA: queValue === 'Amount' ? 'Per Interval' : adaValue,
        ADAtableData: formatADAData,
        holdbackRetention:
          queValue === 'Percentage' && adaValue === 'Total'
            ? ADAData?.['1 - 30']?.amountOrPerc
            : null,
      });
    }
  }, [row?.original, isEditable]);

  const { values, handleChange, setFieldValue, errors } = formik;

  // useEffect(() => {
  //   const totalData: any = values.ADAtableData;
  //   const userAmount: any = values.holdbackRetention;

  //   for (const data of totalData) {
  //     if (data.total < userAmount) {
  //       setSameAmountError(true);
  //       setAmountErrorMsg(`${userAmount} is greater than ${data.timeInterval}`);
  //       return;
  //     }
  //     setSameAmountError(false);
  //     setAmountErrorMsg('');
  //   }
  // }, [values.holdbackRetention]);
  console.log('formik', formik, recordId);

  const updateADAColumnData = ({
    localRow,
    cellValue,
  }: {
    localRow?: any;
    cellValue: number;
  }) => {
    const findCurrEditRow = formik?.values?.ADAtableData?.map((row) => {
      if (
        (values.applyADA === 'Per Interval' &&
          row.timeInterval === localRow.original.timeInterval) ||
        values.applyADA === 'Total'
      ) {
        if (values.question === 'Percentage') {
          return {
            ...row,
            value: fixedNumber((Number(row.total) * cellValue) / 100),
            amount: cellValue,
          };
        } else if (values.question === 'Amount') {
          return {
            ...row,
            value: cellValue,
            amount: cellValue,
          };
        } else {
          return row; // Default case when values.question is neither 'Percentage' nor 'Amount'
        }
      } else {
        return row; // Default case when neither 'Per Interval' nor 'Total'
      }
    });

    // setADAtableData(findCurrEditRow);
    formik.setFieldValue('ADAtableData', findCurrEditRow);
  };

  const columns = useMemo<MRT_ColumnDef<ADATable>[]>(
    () => [
      {
        accessorKey: 'timeInterval', //access nested data with dot notation
        size: 2,
        maxSize: 2,
        header: 'Time Interval',
        enableColumnActions: false,
        enableSorting: false,
        enableEditing: false,
        mantineTableHeadCellProps: {
          width: '10px !important',
          className: 'px-ADA-column',
        },
        mantineTableBodyCellProps: {
          className: 'px-10',
        },
      },
      {
        accessorKey: 'total', //access nested data with dot notation
        size: 2,
        maxSize: 2,
        header: 'Total',
        enableColumnActions: false,
        enableSorting: false,
        enableEditing: false,
        mantineTableHeadCellProps: {
          width: '10px !important',
          className: 'px-ADA-column',
        },
        mantineTableBodyCellProps: {
          className: 'px-10',
        },
        Cell: ({ cell }) => (
          <>
            {cell.getValue() !== ''
              ? formatNumber(Number(cell.getValue()))
              : cell.getValue()}
          </>
        ),
      },
      {
        accessorKey: 'amount', //access nested data with dot notation
        header: formik.values.question,
        enableColumnActions: false,
        enableSorting: false,
        mantineTableHeadCellProps: {
          width: '10px !important',
          className: 'px-ADA-column',
        },
        mantineTableBodyCellProps: {
          className: 'px-10',
          sx: {
            cursor: 'pointer',
          },
        },
        size: 10,
        maxSize: 10,
        mantineEditTextInputProps: ({ cell, row: localRow }) => {
          return {
            placeholder: formik.values.question === 'Amount' ? '$' : '%',
            min: 0,
            max:
              formik.values.question === 'Amount'
                ? Number(cell?.row?.original?.total)
                : 100,
            type: 'number',
            value: formik?.values?.ADAtableData?.find(
              (data) => data.timeInterval === localRow?.original.timeInterval
            )?.['amount']!,
            required: true,
          };
        },
      },
      {
        accessorKey: 'value',
        header: 'ADA',
        enableColumnActions: false,
        enableSorting: false,
        enableEditing: values?.question === 'Amount',
        mantineTableHeadCellProps: {
          width: '10px !important',
          className: 'px-ADA-column',
        },
        mantineTableBodyCellProps: {
          className: 'px-10',
        },
        mantineEditTextInputProps: ({ cell, row: localRow }) => {
          return {
            placeholder: '$',
            min: 0,
            max: Number(cell?.row?.original?.total),
            type: 'number',
            value: formik?.values?.ADAtableData?.find(
              (data) => data.timeInterval === localRow?.original.timeInterval
            )?.['value']!,
            required: true,
          };
        },
      },
    ],
    [formik?.values?.ADAtableData, formik.values.question]
  );
  const tableADA = useMantineReactTable({
    columns: columns as any,
    data: formik?.values?.ADAtableData,
    enableEditing: true,
    editDisplayMode: 'table',
    getRowId: (row, index) => ' ' + index + 1,
    enableToolbarInternalActions: false,
    enableTopToolbar: false,
    enablePagination: false,
    enableBottomToolbar: false,
    enableRowActions: false,
    defaultColumn: { minSize: 4, maxSize: 4, size: 4 },
    mantineTableProps: {
      sx: {
        tableLayout: 'fixed',
        border: 'none',
        boxShadow: 'none',
      },
    },
    mantineTableHeadCellProps: {
      className: 'custom-column',
    },
    mantineEditTextInputProps: ({ cell, column, row: localRow }) => ({
      onChange: (event: any) => {
        let data: number = event.target.value;
        // if (formik.values.question === 'Percentage') {
        //   data = handleMinMaxNumberInput({
        //     inputValue: Number(event.target.value),
        //     min: 0,
        //     max: 100,
        //   });
        // }
        if (table && row) {
          updateADAColumnData({
            localRow,
            cellValue: data,
          });
        }
      },
      onKeyDown: (e: any) => {
        preventAlphabetsTyping(e);
      },
      variant: 'unstyled',
    }),

    state: {
      columnVisibility: {
        amount:
          values?.applyADA === 'Per Interval' &&
          values?.question === 'Percentage',
      },
    },
  });

  // const columnsADATotal = useMemo<MRT_ColumnDef<ADATotalTable>[]>(
  //   () => [
  //     {
  //       accessorKey: 'timeInterval', //access nested data with dot notation
  //       size: 2,
  //       maxSize: 2,
  //       header: 'Time Interval',
  //       enableColumnActions: false,
  //       enableSorting: false,
  //       enableEditing: false,
  //       mantineTableHeadCellProps: {
  //         width: '10px !important',
  //         className: 'px-10',
  //       },
  //       mantineTableBodyCellProps: {
  //         className: 'px-10',
  //       },
  //     },
  //     {
  //       accessorKey: 'value',
  //       header: 'ADA',
  //       enableColumnActions: false,
  //       enableSorting: false,
  //       enableEditing: false,
  //       mantineTableHeadCellProps: {
  //         width: '10px !important',
  //         className: 'px-10',
  //       },
  //       mantineTableBodyCellProps: {
  //         className: 'px-10',
  //       },
  //     },
  //   ],
  //   []
  // );
  // const tableADATotal = useMantineReactTable({
  //   columns: columnsADATotal as any,
  //   data: ADATotaltableData,
  //   enableEditing: true,
  //   editDisplayMode: 'table',
  //   getRowId: (row, index) => ' ' + index + 1,
  //   enableToolbarInternalActions: false,
  //   enableTopToolbar: false,
  //   enablePagination: false,
  //   enableBottomToolbar: false,
  //   enableRowActions: false,
  //   defaultColumn: { minSize: 4, maxSize: 4, size: 4 },
  //   mantineTableProps: {
  //     sx: {
  //       tableLayout: 'fixed',
  //       border: 'none',
  //       boxShadow: 'none',
  //     },
  //   },
  //   mantineTableHeadCellProps: {
  //     className: 'custom-column',
  //   },
  //   initialState: {
  //     columnVisibility: {
  //       total: false,
  //     },
  //   },
  // });

  return (
    <Modal
      opened={modalOpen}
      onClose={onClose}
      withCloseButton={true}
      size={'xl'}
    >
      <form onSubmit={formik.handleSubmit}>
        <Stack>
          <Title order={3}>Edit Data</Title>
          <Grid gridTemplateColumns={'1fr'} gap={4}>
            <Box>
              <Text
                fontWeight="600"
                display="flex"
                justifyContent="start"
                fontSize={13}
                flexDir={'column'}
              >
                How do you want to add ADA as a % of "TOTAL" or a specific
                dollar amount per time interval?
              </Text>
              <RadioGroup
                value={values?.question ?? ''}
                onChange={(value) => {
                  setFieldValue('question', value);
                  let updateAdaData = formik?.values?.ADAtableData?.map(
                    (item: any) => {
                      return { ...item, amount: 0, value: 0 };
                    }
                  );
                  setFieldValue('ADAtableData', updateAdaData);
                  setFieldValue('holdbackRetention', null);
                  if (value === 'Amount') {
                    setFieldValue('applyADA', 'Per Interval');
                  } else {
                    setFieldValue('applyADA', 'Total');
                  }
                }}
              >
                <Stack
                  direction="row"
                  display={'flex'}
                  flexWrap={'wrap'}
                  mt={2}
                >
                  <Radio value="Amount" size="sm">
                    Amount
                  </Radio>
                  <Radio value="Percentage" size="sm">
                    Percentage
                  </Radio>
                </Stack>
              </RadioGroup>
            </Box>
            {values?.question === 'Percentage' && (
              <Box>
                <Text fontSize={13}>
                  Do you want to apply the same % for all time intervals or
                  enter a percentage per time interval?
                </Text>

                <Stack
                  direction="row"
                  display={'flex'}
                  flexWrap={'wrap'}
                  mt={2}
                >
                  <Checkbox
                    fontSize={13}
                    defaultChecked
                    value="Total"
                    isChecked={values.applyADA === 'Total'}
                    onChange={(value) => {
                      if (value) {
                        setFieldValue('applyADA', 'Total');
                        let updateAdaData = formik?.values?.ADAtableData?.map(
                          (item: any) => {
                            return { ...item, amount: 0, value: 0 };
                          }
                        );
                        setFieldValue('ADAtableData', updateAdaData);
                        setFieldValue('holdbackRetention', null);
                      }
                    }}
                  >
                    Yes
                  </Checkbox>
                  <Checkbox
                    fontSize={13}
                    value="Per Interval"
                    isChecked={values.applyADA === 'Per Interval'}
                    onChange={(value) => {
                      if (value) {
                        setFieldValue('applyADA', 'Per Interval');
                        let updateAdaData = formik?.values?.ADAtableData?.map(
                          (item: any) => {
                            return { ...item, amount: 0, value: 0 };
                          }
                        );
                        setFieldValue('ADAtableData', updateAdaData);
                        setFieldValue('holdbackRetention', null);
                      }
                    }}
                  >
                    No
                  </Checkbox>
                </Stack>
              </Box>
            )}
            {values?.applyADA === 'Total' &&
              values?.question === 'Percentage' && (
                <>
                  <Box display={'flex'} flexDir={'column'} gap={1}>
                    <Text
                      fontWeight="600"
                      display="flex"
                      justifyContent="start"
                      fontSize={13}
                      flexDir={'column'}
                    >
                      Add {values?.question}
                      {' (%)'}
                    </Text>
                    <Input
                      fontSize={'13px'}
                      w="100%"
                      type="number"
                      placeholder={`Add ${values?.question} (%)`}
                      name="holdbackRetention"
                      value={values?.holdbackRetention ?? ''}
                      onChange={(e) => {
                        let val = Number(e.target.value);
                        // if (values?.question === 'Percentage') {
                        //   val = handleMinMaxNumberInput({
                        //     min: 0,
                        //     max: 100,
                        //     inputValue: val,
                        //   });
                        // }
                        // setFieldValue('holdbackRetention', val);
                        updateADAColumnData({
                          cellValue: Number(val),
                        });
                        handleChange(e);
                      }}
                      onWheel={(e) => (e.target as HTMLInputElement).blur()}
                      onKeyDown={preventAlphabetsTyping}
                    />
                    {errors.holdbackRetention && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          textAlign: 'start',
                          fontWeight: '500',
                        }}
                      >
                        {errors.holdbackRetention}
                      </div>
                    )}
                    {/* {sameAmountError && amountErrorMsg !== '' && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          textAlign: 'start',
                          fontWeight: '500',
                        }}
                      >
                        {amountErrorMsg}
                      </div>
                    )} */}
                  </Box>
                </>
              )}

            <Box>
              <MantineReactTable table={tableADA} />
            </Box>
          </Grid>
          <Flex justify="flex-end" mt="xl">
            <Button type="button" variant="white" onClick={onClose}>
              Cancel
            </Button>
            <Button
              type="submit"
              isLoading={btnLoader}
              // isDisabled={sameAmountError}
            >
              Submit
            </Button>
            {/* <MRT_EditActionButtons variant="text" table={table} row={row} /> */}
          </Flex>
        </Stack>
      </form>
    </Modal>
  );
};

export default EditAccountReceivableADAModal;
