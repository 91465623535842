import { Stack } from '@mantine/core';
import {
  calculateAccountReceivableTurnover,
  calculateDaysInventoryOutstanding,
  calculateDaysSalesOutstanding,
  calculateInventoryTurnover,
  calculateTotalAssetTurnover,
} from '../../data/calculations';
import { CalculationDisplay } from '../ProfitabilityRatio/CalculationDisplay';
import { CalculationDisplayRTN } from './CalculationDisplayRTN';
import { CalculationDisplayTangibleNetworthEff } from './CalculationDisplayTangibleNetworthEff';
import { CalculationDisplayUTN } from './CalculationDisplayUTN';

export interface EfficiencyCalculationReferenceFields {
  calculationFields: Record<string, any>;
}

const EfficiencyCalculationReferenceFields: React.FC<
  EfficiencyCalculationReferenceFields
> = ({ calculationFields }) => {
  return (
    <Stack spacing="xl">
      <CalculationDisplay
        label="Account Receivable Turnover"
        inputs={[
          {
            label: 'Total Revenue',
            value: calculationFields?.totalIncome?.value,
            fields: calculationFields?.totalIncome?.fields,
          },
          {
            label: 'Average A/R',
            value: calculationFields?.averageAccountReceivable?.value,
            fields: calculationFields?.averageAccountReceivable?.fields,
          },
        ]}
        operators={['÷']}
        result={calculateAccountReceivableTurnover(calculationFields)}
      />

      <CalculationDisplay
        label="Days Sales Outstanding"
        inputs={[
          {
            label: 'Days',
            value: 365,
            isDisplayTextInput: true,
          },
          {
            label: 'Account Receivable Turnover',
            value:
              calculateAccountReceivableTurnover(calculationFields) &&
              calculateAccountReceivableTurnover(calculationFields) !== 'N/A'
                ? calculateAccountReceivableTurnover(calculationFields)
                : 0,
          },
        ]}
        operators={['÷']}
        result={calculateDaysSalesOutstanding(calculationFields)}
      />

      <CalculationDisplay
        label="Inventory Turnover"
        inputs={[
          {
            label: 'Cost of Goods Sold (Cost of Sales)',
            value: calculationFields?.totalCostOfGoodsSold?.value,
            fields: calculationFields?.totalCostOfGoodsSold?.fields,
          },
          {
            label: 'Average Inventory',
            value: calculationFields?.averageInventory?.value,
            fields: calculationFields?.averageInventory?.fields,
          },
        ]}
        operators={['÷']}
        result={calculateInventoryTurnover(calculationFields)}
      />

      <CalculationDisplay
        label="Days Inventory Outstanding"
        inputs={[
          {
            label: 'Days',
            value: 365,
            isDisplayTextInput: true,
          },
          {
            label: 'Inventory Turnover',
            value:
              calculateInventoryTurnover(calculationFields) &&
              calculateInventoryTurnover(calculationFields) !== 'N/A'
                ? calculateInventoryTurnover(calculationFields)
                : 0,
          },
        ]}
        operators={['÷']}
        result={calculateDaysInventoryOutstanding(calculationFields)}
      />
      <CalculationDisplay
        label="Total Asset Turnover"
        inputs={[
          {
            label: 'Total Revenue',
            value: calculationFields?.totalIncome?.value,
            fields: calculationFields?.totalIncome?.fields,
          },
          {
            label: 'Average Total Assets',
            value: calculationFields?.averageTotalAssets?.value,
            fields: calculationFields?.averageTotalAssets?.fields,
          },
        ]}
        operators={['÷']}
        result={calculateTotalAssetTurnover(calculationFields)}
      />

      <CalculationDisplayTangibleNetworthEff
        label="Tangible Networth"
        calculationFields={calculationFields}
      />

      <CalculationDisplayUTN
        label="Underbillings to Tangible Networth"
        calculationFields={calculationFields}
      />

      <CalculationDisplayRTN
        label="Revenue to Tangible Network"
        calculationFields={calculationFields}
      />
    </Stack>
  );
};

export default EfficiencyCalculationReferenceFields;
