import { ProgressInfoAttributes } from '../../../../Pages/Reports/components/data';
import { APIStatus } from '../../../../types/unions';

export interface WIPReprotTable {
  id: number;
  applicantId: number;
  updatedAt: string;
  reportDate: string;
}

export interface IApplicantData {
  applicantId: number;
  brokerageId: number;
  companyName: string;
  createdAt: string;
  email: string;
  officeAddress: string | null;
  qboConnectedBy: string;
  qboState: string;
  refNumber: string;
  termCondition: boolean;
  updatedAt: string;
  isProfileCompleted: boolean;
  ongoingApplicationId: number | null;
  qboAccountLinked?: boolean;
  companyLegalName: string;
  businessPhone: string;
  companyDomain: string;
  companyAddress: string;
  street: string;
  city: string;
  country: string;
  province: string;
  postalCode: string;
  mailingAddressSameAsOffice: boolean;
  mailingAddress: string;
  mailingStreet: string;
  mailingCity: string;
  mailingCountry: string;
  mailingProvince: string;
  mailingPostalCode: string;
  dunsNumber: string | null;
  dunsNumberCompanyName: string | null;
  onboarding: boolean;
  usKey: string | null;
  fiscalYearEnd?: string;
}

export interface IWIPData {
  id: number | null;
  applicantId: number | null;
  progressInfo: Partial<ProgressInfoAttributes>[];
  updatedInfo?: string;
  revenueBasis?: null | string;
  reportInfo: Record<string, any> | null;
  updatedAt: string | null;
  reportAsOf?: string | null;
}

export interface WIPUploadProjectList {
  uniqueProjectId: string;
  projectName: string;
  projectStatus: 'active' | 'completed';
}
export interface WIPUploadBondList {
  uniqueProjectId: string;
  projectName: string;
  bondNumber: string[];
  projectBonded: boolean;
}
export interface uploadTemplteType {
  message: string;
  reportId: number;
  projectList: WIPUploadProjectList[];
  bondList?: WIPUploadBondList[];
  reportAsOf: string;
}
export interface IAllWIPData {
  status: APIStatus | null;
  error: any;
  // type: "REPORT_PROGRESS";
  allWIPdata: IWIPData;
  message?: string;
  type?:
    | 'REMOVE_WIP_API'
    | 'GET_WIP_DATA_BY_APPLICATIONID_API'
    | 'UPDATE_WIP_API'
    | 'REFRESH_WIP_API'
    | 'ADD_WIP_API'
    | 'UPDATE_REVNUE_API'
    | 'UPLOAD_WIP_TEMPLATE'
    | 'GET_WIP_LIST_BY_APPLICANT_ID'
    | 'WIP_UPDATE_PROJECT_STATUS'
    | 'WIP_UPDATE_BOND_NUMBERS'
    | null;
  WIPTableList: IWIPData[];
  uploadTemplte: uploadTemplteType | null;
}
export const allWIPdataAPI = {
  progressInfo: [],
  applicantId: null,
  id: null,
  reportInfo: null,
  updatedAt: null,
};

const initialState: IAllWIPData = {
  error: null,
  status: null,
  allWIPdata: allWIPdataAPI,
  WIPTableList: [],
  uploadTemplte: null,
};

export default initialState;
