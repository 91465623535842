import { Flex, HStack } from '@chakra-ui/react';
import {
  Box,
  Divider,
  MantineProvider,
  Progress,
  Text,
  Tooltip,
} from '@mantine/core';
import {
  MRT_GlobalFilterTextInput,
  MRT_TablePagination,
  MRT_ToolbarInternalButtons,
  MantineReactTable,
  useMantineReactTable,
  type MRT_ColumnDef,
} from 'mantine-react-table';
import type React from 'react';
import { useEffect, useMemo, useState } from 'react';
import {
  AnalyticalReportDetails,
  IYoYGrowthTableData,
} from 'src/Redux/Broker/AnalyticalReports/state';
import { useAnalyticalReportContext } from '../..';

//LiquidityRatiosTable props interface
interface YearOverYearGrowthTableProps {
  TData: AnalyticalReportDetails;
}

const calculateRatios = (data: Record<string, any>) => {
  if (!data) return null; // Ensure data exists

  return {
    yoyGrowth: data.yoyGrowth,
  };
};

const ratioNames = ['YoY Growth (%)'];

const YearOverYearGrowthTable: React.FC<YearOverYearGrowthTableProps> = ({
  TData,
}) => {
  const { contentLoader: loading, analyticalReportPeriod } =
    useAnalyticalReportContext();
  const [restructuredData, setRestructuredData] = useState<any>([]);
  console.log('TData', TData);

  useEffect(() => {
    const tabledata: any[] = TData?.tableData;
    const labels = tabledata?.map(({ label }) => label); // Extracting labels dynamically

    // Restructure the data dynamically
    const restructuredData =
      tabledata && tabledata?.length > 0
        ? ratioNames.map((ratioName) => {
            return {
              ratioName,
              ...Object.fromEntries(labels?.map((label) => [label, 0]) ?? []), // Dynamically adding labels as keys
            };
          })
        : [];

    // Calculate ratios for all years
    const yearlyRatios =
      tabledata && tabledata?.length > 0 ? tabledata?.map(calculateRatios) : [];

    console.log('yearlyRatios', yearlyRatios);

    // // Populate the restructured data
    yearlyRatios?.forEach((ratio: any, index: any) => {
      const year =
        tabledata && tabledata?.length > 0 && tabledata?.[index]?.label;
      Object.keys(ratio).forEach((key: any, ratioIndex: any) => {
        (restructuredData as any)[ratioIndex][year as any] =
          ratio[key as keyof typeof ratio];
      });
    });
    setRestructuredData(restructuredData);
  }, [TData]);

  const renderCell =
    (label: string, nextColumnLabel: string | undefined) =>
    ({ cell, row }: { cell: any; row: any }) => {
      const { ratioName } = row?.original;
      const value = Number(row.original[label] ?? 0);
      const nextColumnValue = nextColumnLabel
        ? Number(row.original[nextColumnLabel] ?? 0)
        : 0;

      let formattedValue = value.toFixed(2);
      let YoYDiffValue = (value - nextColumnValue).toFixed(2);
      if (ratioName.includes('$')) {
        formattedValue = value.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      } else if (
        ratioName.includes('%') ||
        ratioName.toLowerCase().includes('margin')
      ) {
        formattedValue = `${value.toFixed(2)} %`;
        YoYDiffValue = `${YoYDiffValue} %`;
      }

      return (
        <Box>
          <Tooltip
            label={`YoY: (${formattedValue}) - Diff: (${YoYDiffValue})`}
            position="right"
            withArrow
            disabled={!nextColumnLabel}
          >
            <Text
              style={{
                cursor: !nextColumnLabel ? 'default' : 'help',
                display: 'inline-block',
              }}
            >
              {formattedValue}
            </Text>
          </Tooltip>
        </Box>
      );
    };

  const getFormattedColumns = (
    tableData: Record<string, any>[],
    period: string
  ) => {
    const currentYear = new Date().getFullYear();
    let formattedColumns: MRT_ColumnDef<IYoYGrowthTableData>[] = [
      {
        accessorKey: 'ratioName',
        header: 'Ratio',
        size: 180,
      },
    ];

    const yearCols = tableData?.map((elm, index) => {
      const yearNumber = parseInt(elm.label.replace(/\D/g, ''), 10);
      return {
        accessorKey: elm.label,
        header: `${currentYear - (yearNumber - 1)}`,
        Cell: renderCell(elm.label, tableData[index + 1]?.label),
      };
    });
    formattedColumns.push(...yearCols);

    return formattedColumns;
  };

  const columns = useMemo(
    () => getFormattedColumns(TData?.tableData, analyticalReportPeriod),
    [TData, analyticalReportPeriod]
  );

  console.log('restructuredData', restructuredData);

  const table = useMantineReactTable({
    columns,
    data: restructuredData, // restructuredData,
    enableTopToolbar: true,
    enableBottomToolbar: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    enablePagination: true,
    enableSorting: false,
    positionPagination: 'none',
    getRowId: (row, index) => '' + index,
    defaultColumn: { minSize: 10, maxSize: 10, size: 10 },
    initialState: {
      density: 'xs',
    },
    mantineTableContainerProps: {
      sx: {
        tableLayout: 'fixed',
        width: '100%',
        overflow: loading ? 'hidden' : 'auto',
      },
    },
    mantineTableProps: {
      striped: true,
      highlightOnHover: true,
      sx: {
        tableLayout: 'fixed',
      },
    },
    renderTopToolbar: ({ table }) => {
      console.log('table', table);

      return (
        <Flex direction={'column'} justifyContent={'space-between'} px={5}>
          <HStack justifyContent={'flex-end'} alignItems={'center'}>
            <Flex
              py={1}
              alignItems={'center'}
              flexWrap={'wrap'}
              justifyContent={'flex-end'}
            >
              <Flex alignItems={'center'} gap={4} p={3}>
                <MRT_GlobalFilterTextInput table={table} />
                <MRT_ToolbarInternalButtons table={table} />
              </Flex>
              <Divider
                size="md"
                orientation="vertical"
                h={40}
                style={{ alignSelf: 'center' }}
              />
              <Box className="pagination-wrapper">
                <MRT_TablePagination position="top" table={table} />
              </Box>
            </Flex>
          </HStack>
          {loading && <Progress value={100} animate={true} />}
        </Flex>
      );
    },
    state: {
      showProgressBars: loading,
      isLoading: loading,
    },
  });

  return (
    <>
      <MantineProvider>
        <MantineReactTable table={table} />
      </MantineProvider>
    </>
  );
};

export default YearOverYearGrowthTable;
