import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ProgressInfoAttributes } from '../../../../Pages/Reports/components/data';
import callAPI from '../../../../utils/callAPI';
import initialState, {
  WIPUploadBondList,
  WIPUploadProjectList,
  allWIPdataAPI,
} from './state';

interface IRemoveWIPData {
  documentId: number;
  // progressId: number;
  uniqueProjectId: string;
}

export interface IUpdateWIPData extends IRemoveWIPData {
  applicantId: number;
  documentId: number;
  // progressId: number;
  progressInfo: Partial<ProgressInfoAttributes>;
  uniqueProjectId: string;
}
interface IUpdaterevenueWIPData {
  documentId: number;
  revenueBasis: string;
}

export function isRejectedActionWithPayload(
  action: any
): action is { payload: { errorMessage: string } } {
  return action.payload && typeof action.payload.msg === 'string'
    ? action.payload.msg
    : '';
}

export const getWIPDataByApplicantId = createAsyncThunk(
  'workInProgressSlice/getWIPDataByApplicantId',
  async (
    {
      applicantId,
      showAll,
      reportAsOf,
      reportId,
    }: {
      applicantId: number;
      showAll: boolean;
      reportAsOf: string;
      reportId?: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await callAPI(
        'wip/view',
        'POST',
        {
          applicantId,
          showAll,
          reportAsOf,
          reportId,
        },
        true
      );

      return response.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }

      return rejectWithValue(error.data);
    }
  }
);
export const getWIPTableList = createAsyncThunk(
  'workInProgressSlice/getWIPTableList',
  async (
    {
      applicantId,
    }: {
      applicantId: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await callAPI(
        `wip/list/${applicantId}`,
        'GET',
        null,
        true
      );

      return response.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }

      return rejectWithValue(error.data);
    }
  }
);

export const updateWIPData = createAsyncThunk(
  'workInProgressSlice/updateWIPData',
  async (
    { documentId, uniqueProjectId, applicantId, progressInfo }: IUpdateWIPData,
    { rejectWithValue }
  ) => {
    try {
      const response = await callAPI('wip/update', 'PUT', {
        id: documentId,
        uniqueProjectId,
        applicantId,
        progressInfo,
      });
      return response.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const addWIPData = createAsyncThunk(
  'workInProgressSlice/addWIPData',
  async (
    {
      documentId,
      applicantId,
      progressInfo,
    }: Pick<IUpdateWIPData, 'documentId' | 'applicantId' | 'progressInfo'>,
    { rejectWithValue }
  ) => {
    try {
      const response = await callAPI('wip/update', 'PUT', {
        id: documentId,
        // progressId,
        applicantId,
        progressInfo,
      });
      return response.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const removeWIPData = createAsyncThunk(
  'workInProgressSlice/deleteProgressReport',
  async (
    { documentId, uniqueProjectId }: IRemoveWIPData,
    { rejectWithValue }
  ) => {
    try {
      const response = await callAPI('wip/remove', 'PUT', {
        id: documentId,
        uniqueProjectId,
      });
      return response.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const refreshProgressReport = createAsyncThunk(
  'workInProgressSlice/RefreshProgressReport',
  async (
    {
      applicantId,
      reportId,
    }: Pick<IUpdateWIPData, 'applicantId'> & { reportId: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await callAPI('wip/refresh', 'POST', {
        applicantId,
        reportId,
      });
      return response.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);
export const updateProjectStatus = createAsyncThunk(
  'workInProgressSlice/updateProjectStatus',
  async (
    payload: { id: number; projectList: WIPUploadProjectList[] },
    { rejectWithValue }
  ) => {
    try {
      const response = await callAPI('wip/updateMany', 'PUT', payload, false);
      return response.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const updateBondNumbers = createAsyncThunk(
  'workInProgressSlice/updateBondNumbers',
  async (
    payload: { id: number; bondList: WIPUploadBondList[] },
    { rejectWithValue }
  ) => {
    try {
      const response = await callAPI('wip/updateMany', 'PUT', payload, false);
      return response.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const updateRevenue = createAsyncThunk(
  'workInProgressSlice/updateRevenue',
  async (
    { documentId, revenueBasis }: IUpdaterevenueWIPData,
    { rejectWithValue }
  ) => {
    try {
      const response = await callAPI('wip/update-revenue', 'PUT', {
        id: documentId,
        // progressId,
        revenueBasis,
      });
      return response.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const uploadWIPTemplate = createAsyncThunk(
  'workInProgressSlice/uploadWIPTemplate',
  async (payload: FormData, { rejectWithValue }) => {
    try {
      const response = await callAPI(
        'wip/upload-new',
        'POST',
        payload,
        false,
        'multipart/form-data'
      );
      return response.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

const brokerReportSlice = createSlice({
  name: 'workInProgressSlice',
  initialState: initialState,
  reducers: {
    removeWIPSliceType(state, action) {
      state.type = null;
      state.status = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWIPDataByApplicantId.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'GET_WIP_DATA_BY_APPLICATIONID_API';
        state.allWIPdata = action.payload.data;
      })
      .addCase(getWIPDataByApplicantId.pending, (state) => {
        state.status = 'loading';
        state.type = 'GET_WIP_DATA_BY_APPLICATIONID_API';
      })
      .addCase(getWIPDataByApplicantId.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'GET_WIP_DATA_BY_APPLICATIONID_API';
        state.error = isRejectedActionWithPayload(action);
        state.allWIPdata = allWIPdataAPI;
      })
      .addCase(updateWIPData.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'UPDATE_WIP_API';
        state.message = action.payload.data;
      })
      .addCase(updateWIPData.pending, (state, action) => {
        state.status = 'loading';
        state.type = 'UPDATE_WIP_API';
      })
      .addCase(updateWIPData.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'UPDATE_WIP_API';
        state.error = isRejectedActionWithPayload(action);
      })
      .addCase(removeWIPData.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'REMOVE_WIP_API';
        state.message = action.payload.data;
      })
      .addCase(removeWIPData.pending, (state, action) => {
        state.status = 'loading';
        state.type = 'REMOVE_WIP_API';
      })
      .addCase(removeWIPData.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'REMOVE_WIP_API';
        state.error = isRejectedActionWithPayload(action);
      })
      .addCase(addWIPData.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'ADD_WIP_API';
        state.message = action.payload.data;
      })
      .addCase(addWIPData.pending, (state, action) => {
        state.status = 'loading';
        state.type = 'ADD_WIP_API';
      })
      .addCase(addWIPData.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'ADD_WIP_API';
        state.error = isRejectedActionWithPayload(action);
      })
      .addCase(refreshProgressReport.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'REFRESH_WIP_API';
        state.allWIPdata = action.payload;
      })
      .addCase(refreshProgressReport.pending, (state, action) => {
        state.status = 'loading';
        state.type = 'REFRESH_WIP_API';
      })
      .addCase(refreshProgressReport.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'REFRESH_WIP_API';
        state.error = isRejectedActionWithPayload(action);
      })
      .addCase(updateRevenue.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'UPDATE_REVNUE_API';
        // state.allWIPdata = action.payload;
      })
      .addCase(updateRevenue.pending, (state, action) => {
        state.status = 'loading';
        state.type = 'UPDATE_REVNUE_API';
      })
      .addCase(updateRevenue.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'UPDATE_REVNUE_API';
        state.error = isRejectedActionWithPayload(action);
      })

      .addCase(uploadWIPTemplate.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'UPLOAD_WIP_TEMPLATE';
        state.uploadTemplte = action?.payload?.data;
      })
      .addCase(uploadWIPTemplate.pending, (state, action) => {
        state.status = 'loading';
        state.type = 'UPLOAD_WIP_TEMPLATE';
      })
      .addCase(uploadWIPTemplate.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'UPLOAD_WIP_TEMPLATE';
        state.error = isRejectedActionWithPayload(action);
      })

      //get WIP table list api
      .addCase(getWIPTableList.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'GET_WIP_LIST_BY_APPLICANT_ID';
        state.WIPTableList = action.payload.data;
      })
      .addCase(getWIPTableList.pending, (state) => {
        state.status = 'loading';
        state.type = 'GET_WIP_LIST_BY_APPLICANT_ID';
      })
      .addCase(getWIPTableList.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'GET_WIP_LIST_BY_APPLICANT_ID';
        state.error = isRejectedActionWithPayload(action);
      })
      //WIP updateProjectStatus api
      .addCase(updateProjectStatus.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'WIP_UPDATE_PROJECT_STATUS';
        // state.WIPTableList = action.payload.data;
      })
      .addCase(updateProjectStatus.pending, (state) => {
        state.status = 'loading';
        state.type = 'WIP_UPDATE_PROJECT_STATUS';
      })
      .addCase(updateProjectStatus.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'WIP_UPDATE_PROJECT_STATUS';
        state.error = isRejectedActionWithPayload(action);
      })
      //WIP updateBondNumbers api
      .addCase(updateBondNumbers.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'WIP_UPDATE_BOND_NUMBERS';
        // state.WIPTableList = action.payload.data;
      })
      .addCase(updateBondNumbers.pending, (state) => {
        state.status = 'loading';
        state.type = 'WIP_UPDATE_BOND_NUMBERS';
      })
      .addCase(updateBondNumbers.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'WIP_UPDATE_BOND_NUMBERS';
        state.error = isRejectedActionWithPayload(action);
      });
  },
});

const brokerReportProgressSlice = brokerReportSlice.reducer;
export const { removeWIPSliceType } = brokerReportSlice.actions;

export default brokerReportProgressSlice;
