import { Box, Button, Text, Textarea } from '@chakra-ui/react';
import { Group, Modal } from '@mantine/core';
import { MRT_Row } from 'mantine-react-table';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'src/Redux/Store';
import { addSupportTicket } from 'src/Redux/UploadReports/slice';
import { uploadTable } from '../UploadFilesTable';

const ContactAdminModal = ({
  isOpen,
  onClose,
  row,
  isSupportTicket,
}: {
  isOpen: boolean;
  onClose: () => void;
  row: MRT_Row<uploadTable>;
  isSupportTicket: boolean;
}) => {
  const dispatch = useAppDispatch();
  const [message, setMessage] = useState<string>('');

  useEffect(() => {
    if (isOpen) {
      setMessage('');
    }
  }, [isOpen]);

  const contactAdminSubmitHandler = () => {
    const { id } = row?.original;
    try {
      if (!!message?.trim().length) {
        dispatch(
          addSupportTicket({
            reportId: id,
            reason: message,
          })
        );
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <Modal
      opened={isOpen}
      onClose={() => {
        setMessage('');
        onClose();
      }}
      className="modal-style"
      title="Contact Admin"
      overlayProps={{
        opacity: 0.1,
        blur: 3,
      }}
      size={'lg'}
    >
      {/* <Box pos={'relative'}> */}
      {/* <LoadingOverlay
          visible={isSupportTicket}
          zIndex={1000}
          overlayBlur={0.5}
          loaderProps={{ size: 'sm' }}
        /> */}
      <Box display={'flex'} flexDirection={'column'} gap={3}>
        <Box display={'flex'} flexDirection={'column'} mt={2}>
          <Text
            fontWeight="600"
            color="#114684"
            textAlign="start"
            fontSize={13}
            mb={1}
            ml={1}
          >
            Brief Description of Help Needed
          </Text>
          <Textarea
            fontSize={'13px'}
            w="100%"
            placeholder="Brief Description of Help Needed"
            borderRadius="5px"
            //   focusBorderColor="#7f7f7f"
            borderColor={'#7f7f7f'}
            _hover={{ borderColor: '#7f7f7f' }}
            onChange={(e) => setMessage(e.target.value)}
            value={message}
            name="message"
            resize={'none'}
          />
        </Box>
        <Group position="right">
          <Button
            type="button"
            colorScheme="blue"
            onClick={contactAdminSubmitHandler}
            loadingText="Send"
            isDisabled={!message?.trim().length}
            isLoading={isSupportTicket}
            minW={'80px'}
          >
            Send
          </Button>
        </Group>
      </Box>
      {/* </Box> */}
    </Modal>
  );
};

export default ContactAdminModal;
