import React, { ReactNode, createContext, useContext, useState } from 'react';
import { optionsFields } from 'src/Pages/BrokerPages/AnalyticalReports/data';

// Define Context Type
interface LiquidityContextType {
  totalExpenses: string[];
  setTotalExpenses: React.Dispatch<React.SetStateAction<string[]>>;
  nonCashItems: string[];
  setSelectedNonCashItems: React.Dispatch<React.SetStateAction<string[]>>;
  backlog: string[];
  setBacklog: React.Dispatch<React.SetStateAction<string[]>>;
  tangibleNetworth: number;
  setTangibleNetworth: React.Dispatch<React.SetStateAction<number>>;
  billingInExcessOfCosts: string[];
  setBillingInExcessOfCosts: React.Dispatch<React.SetStateAction<string[]>>;
  totalCashAndCashEquivalents: string[];
  setTotalCashAndCashEquivalents: React.Dispatch<
    React.SetStateAction<string[]>
  >;
  reportReferenceData: {
    profitAndLossData: { [key: string]: optionsFields[] };
    balanceSheetData: { [key: string]: optionsFields[] };
    balanceSheetPreviewLink: string;
    profitAndLossPreviewLink: string;
  };
  setReportReferenceData: React.Dispatch<
    React.SetStateAction<{
      profitAndLossData: { [key: string]: optionsFields[] };
      balanceSheetData: { [key: string]: optionsFields[] };
      balanceSheetPreviewLink: string;
      profitAndLossPreviewLink: string;
    }>
  >;
  totalAssets: string[];
  setTotalAssets: React.Dispatch<React.SetStateAction<string[]>>;
  totalLiabilities: string[];
  setTotalLiabilities: React.Dispatch<React.SetStateAction<string[]>>;
  inTangibleAssets: string[];
  setInTangibleAssets: React.Dispatch<React.SetStateAction<string[]>>;
  historyData: {
    [key: string]: { value: number; updatedBy: string };
  };
  setHistoryData: React.Dispatch<
    React.SetStateAction<{
      [key: string]: { value: number; updatedBy: string };
    }>
  >;
}

// Create Context with Default Values
const LiquidityContext = createContext<LiquidityContextType | undefined>(
  undefined
);

// Define Props for Provider
interface LiquidityContextProviderProps {
  children: ReactNode;
}

// Create Provider Component
export const LiquidityProvider: React.FC<LiquidityContextProviderProps> = ({
  children,
}) => {
  const [totalExpenses, setTotalExpenses] = useState<string[]>([]);
  const [nonCashItems, setSelectedNonCashItems] = useState<string[]>([]);
  const [backlog, setBacklog] = useState<string[]>([]);
  const [tangibleNetworth, setTangibleNetworth] = useState<number>(0);
  const [totalAssets, setTotalAssets] = useState<string[]>([]);
  const [totalLiabilities, setTotalLiabilities] = useState<string[]>([]);
  const [inTangibleAssets, setInTangibleAssets] = useState<string[]>([]);

  const [billingInExcessOfCosts, setBillingInExcessOfCosts] = useState<
    string[]
  >([]);
  const [totalCashAndCashEquivalents, setTotalCashAndCashEquivalents] =
    useState<string[]>([]);
  const [reportReferenceData, setReportReferenceData] = useState<{
    profitAndLossData: { [key: string]: optionsFields[] };
    balanceSheetData: { [key: string]: optionsFields[] };
    balanceSheetPreviewLink: string;
    profitAndLossPreviewLink: string;
  }>({
    profitAndLossData: {},
    balanceSheetData: {},
    balanceSheetPreviewLink: '',
    profitAndLossPreviewLink: '',
  });
  const [historyData, setHistoryData] = useState<{
    [key: string]: { value: number; updatedBy: string };
  }>({});

  return (
    <LiquidityContext.Provider
      value={{
        totalExpenses,
        setTotalExpenses,
        nonCashItems,
        setSelectedNonCashItems,
        backlog,
        setBacklog,
        tangibleNetworth,
        setTangibleNetworth,
        billingInExcessOfCosts,
        setBillingInExcessOfCosts,
        totalCashAndCashEquivalents,
        setTotalCashAndCashEquivalents,
        reportReferenceData,
        setReportReferenceData,
        totalAssets,
        setTotalAssets,
        totalLiabilities,
        setTotalLiabilities,
        inTangibleAssets,
        setInTangibleAssets,
        historyData,
        setHistoryData,
      }}
    >
      {children}
    </LiquidityContext.Provider>
  );
};

// Custom Hook for Context Access
export const useLiquidityContext = (): LiquidityContextType => {
  const context = useContext(LiquidityContext);
  if (!context) {
    throw new Error(
      'useLiquidityContext must be used within an LiquidityProvider'
    );
  }
  return context;
};
