import { APIStatus } from 'src/types/unions';

export type ocrReportissueStatus =
  | 'open'
  | 'in-progress'
  | 'completed'
  | 'reopened'
  | 'closed';

export interface ocrIssueLogs {
  id: number;
  supportTicketId: number;
  message: string;
  createdAt: string;
  updatedAt: string;
  brokerId: number | null;
  adminId: number | null;
  ticketStatus: ocrReportissueStatus;
  broker: {
    brokerId: number;
    firstName: string;
    lastName: string;
  } | null;
  admin: {
    email: string;
    adminId: number;
  } | null;
}
export interface IOcrReportIssueList {
  id: number;
  reportId: number;
  ageing: number;
  comment: string[];
  reason: string[];
  status: ocrReportissueStatus[];
  uniqueId: number;
  createdAt: string;
  updatedAt: string;
  applicantName: string;
  brokerName: string;
  brokerEmail: string;
  applicantEmail: string;
  report: {
    id: number;
    applicantId: number;
    applicantName: string;
    brokerName: string;
    reportInfo: {
      type: string | null;
      endDate: string | null;
      startDate: string | null;
      timeFrame: string | null;
      reportName: string | null;
      financialPeriod: string | null;
      fiscalYearEndPeriodDate: string | null;
      fiscalYearStartPeriodDate: string | null;
    };
    reportLinks: {
      link: string | null;
      linkId: number | null;
      urlKey: string | null;
      comment: string | null;
    }[];
    reportUploadedBy: number | null;
    hasDeletedOcrReports: boolean;
    reportStatus: string | null;
    reasonForOcrFailure: string | null;
  };
  history: ocrIssueLogs[];
}

export interface IInitialState {
  error: any;
  status: APIStatus | null;
  type?:
    | 'GET_ALL_OCR_REPROT_ISSUE_LIST'
    | 'GET_LOGINUSER_OCR_REPROT_ISSUE_LIST'
    | 'GET_OCR_REPORT_ISSUE_BY_ID'
    | 'OCR_ISSUE_STATUS_UPDATE_BY_SUPPORT_ADMIN'
    | null;
  ocrIssueList: IOcrReportIssueList[];
  ocrIssueListByUser: IOcrReportIssueList[];
  msg: string;
  ocrIssueInfo: IOcrReportIssueList | null;
}

const initialState: IInitialState = {
  error: null,
  status: null,
  ocrIssueList: [],
  msg: '',
  ocrIssueListByUser: [],
  ocrIssueInfo: null,
};

export default initialState;
