import React, { ReactNode, createContext, useContext, useState } from 'react';
import { optionsFields } from 'src/Pages/BrokerPages/AnalyticalReports/data';

// Define Context Type
interface EfficiencyContextType {
  costsInExcessOfBillings: string[];
  setCostsInExcessOfBillings: React.Dispatch<React.SetStateAction<string[]>>;
  tangibleNetworth: number;
  setTangibleNetworth: React.Dispatch<React.SetStateAction<number>>;
  totalIncome: string[];
  setTotalIncome: React.Dispatch<React.SetStateAction<string[]>>;
  reportReferenceData: {
    profitAndLossData: { [key: string]: optionsFields[] };
    balanceSheetData: { [key: string]: optionsFields[] };
    balanceSheetPreviewLink: string;
    profitAndLossPreviewLink: string;
  };
  setReportReferenceData: React.Dispatch<
    React.SetStateAction<{
      profitAndLossData: { [key: string]: optionsFields[] };
      balanceSheetData: { [key: string]: optionsFields[] };
      balanceSheetPreviewLink: string;
      profitAndLossPreviewLink: string;
    }>
  >;
  totalAssets: string[];
  setTotalAssets: React.Dispatch<React.SetStateAction<string[]>>;
  totalLiabilities: string[];
  setTotalLiabilities: React.Dispatch<React.SetStateAction<string[]>>;
  inTangibleAssets: string[];
  setInTangibleAssets: React.Dispatch<React.SetStateAction<string[]>>;
  historyData: {
    [key: string]: { value: number; updatedBy: string };
  };
  setHistoryData: React.Dispatch<
    React.SetStateAction<{
      [key: string]: { value: number; updatedBy: string };
    }>
  >;
}

// Create Context with Default Values
const EfficiencyContext = createContext<EfficiencyContextType | undefined>(
  undefined
);

// Define Props for Provider
interface EfficiencyContextProviderProps {
  children: ReactNode;
}

// Create Provider Component
export const EfficiencyProvider: React.FC<EfficiencyContextProviderProps> = ({
  children,
}) => {
  const [costsInExcessOfBillings, setCostsInExcessOfBillings] = useState<
    string[]
  >([]);
  const [tangibleNetworth, setTangibleNetworth] = useState<number>(0);
  const [totalAssets, setTotalAssets] = useState<string[]>([]);
  const [totalLiabilities, setTotalLiabilities] = useState<string[]>([]);
  const [inTangibleAssets, setInTangibleAssets] = useState<string[]>([]);
  const [totalIncome, setTotalIncome] = useState<string[]>([]);
  // const [revenueTangibleNetworth, setRevenueTangibleNetworth] = useState<
  //   string[]
  // >([]);
  const [reportReferenceData, setReportReferenceData] = useState<{
    profitAndLossData: { [key: string]: optionsFields[] };
    balanceSheetData: { [key: string]: optionsFields[] };
    balanceSheetPreviewLink: string;
    profitAndLossPreviewLink: string;
  }>({
    profitAndLossData: {},
    balanceSheetData: {},
    balanceSheetPreviewLink: '',
    profitAndLossPreviewLink: '',
  });
  const [historyData, setHistoryData] = useState<{
    [key: string]: { value: number; updatedBy: string };
  }>({});

  return (
    <EfficiencyContext.Provider
      value={{
        costsInExcessOfBillings,
        setCostsInExcessOfBillings,
        tangibleNetworth,
        setTangibleNetworth,
        totalIncome,
        setTotalIncome,
        reportReferenceData,
        setReportReferenceData,
        totalAssets,
        setTotalAssets,
        totalLiabilities,
        setTotalLiabilities,
        inTangibleAssets,
        setInTangibleAssets,
        historyData,
        setHistoryData,
      }}
    >
      {children}
    </EfficiencyContext.Provider>
  );
};

// Custom Hook for Context Access
export const useEfficiencyContext = (): EfficiencyContextType => {
  const context = useContext(EfficiencyContext);
  if (!context) {
    throw new Error(
      'useEfficiencyContext must be used within an EfficiencyProvider'
    );
  }
  return context;
};
