import {
  Document,
  Font,
  G,
  Image,
  Link,
  Page,
  Path,
  StyleSheet,
  Svg,
  Text,
  View,
} from '@react-pdf/renderer';
import moment from 'moment';
import {
  IsNonNullOrObject,
  getAccordianSectionName,
  getAccordianSubSectionName,
  getNonNullSections,
} from 'src/Pages/BrokerPages/CreateApplicationForm/utils';
import {
  bondClaimsAcc,
  buySellAgreementAcc,
  changesInManagementAcc,
  guaranteesForOtherPartiesAcc,
  relatedCompaniesAcc,
} from 'src/data/LegalForm/AccordianContent';
import {
  hasBankruptcyHistoryTableFields,
  liensFiledAgainstCompanyTableFields,
  outstandingJudgementsTableFields,
  outstandingSuitsTableFields,
  stateFederalTaxTableFields,
} from 'src/data/LegalForm/tableColumns';
import { broketDetails } from '../../Pages/Reports/components/PdfModal';

type stepperFormLabel = {
  generalBanking: string;
  legal: string;
  personalFinancial: string;
  about: string;
  surety: string;
  insurance: string;
};

export type allFormData = {
  accountingSystem: any;
  creditBureau: any;
  financialInfo: any;
  cms: any;
  surety: any;
  insurance: any;
};

interface Props {
  title?: string;
  heading?: string;
  summary?: string;
  logo?: string;
  companyName?: string;
  contactInfo?: boolean | null;
  brokerInfo?: broketDetails;
  stepperFormLabel?: stepperFormLabel;
  allFormData?: any;
  qboCompanyName: string;
}

const ApplicationPDF = (props: Props) => {
  const {
    heading,
    logo,
    summary,
    title,
    companyName,
    contactInfo,
    brokerInfo,
    stepperFormLabel,
    allFormData,
    qboCompanyName,
  } = props || {};
  const {
    accountingSystem,
    creditBureau,
    insurance,
    surety,
    financialInfo,
    cms,
  } = allFormData;

  let accounting = accountingSystem || '';

  console.log('props', props);

  const isDisplayCompany = !title && !heading && !summary;

  const reciept_data = {
    id: '642be0b4bbe5d71a5341dfb1',
    invoice_no: '20200669',
    address: '739 Porter Avenue, Cade, Missouri, 1134',
    date: '24-09-2019',
    items: [
      {
        id: 1,
        desc: 'do ex anim quis velit excepteur non',
        qty: 8,
        price: 179.25,
      },
      {
        id: 2,
        desc: 'incididunt cillum fugiat aliqua Lorem sit Lorem',
        qty: 9,
        price: 107.78,
      },
      {
        id: 3,
        desc: 'quis Lorem ad laboris proident aliqua laborum',
        qty: 4,
        price: 181.62,
      },
      {
        id: 4,
        desc: 'exercitation non do eu ea ullamco cillum',
        qty: 4,
        price: 604.55,
      },
      {
        id: 5,
        desc: 'ea nisi non excepteur irure Lorem voluptate',
        qty: 6,
        price: 687.08,
      },
    ],
  };

  const styles = StyleSheet.create({
    page: {
      fontSize: 11,
      paddingTop: 60,
      paddingBottom: 50,
      paddingLeft: 40,
      paddingRight: 40,
      // lineHeight: 1.5,
      flexDirection: 'column',
    },

    spaceBetween: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      color: '#3E3E3E',
    },

    container: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: '15px',
      justifyContent: 'space-between',
    },
    fieldContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '5px',
      padding: '8px',
    },
    label: {
      fontSize: 11,
      fontWeight: 'bold',
      color: '#333',
    },
    inputBox: {
      minHeight: 30,
      borderWidth: 1,
      borderColor: '#000',
      borderRadius: 5,
      paddingVertical: 5,
      paddingHorizontal: 10,
      justifyContent: 'center',
      backgroundColor: '#f8f8f8', // Light gray background to resemble PDF input fields
    },
    inputText: {
      fontSize: 10,
      color: '#000',
    },

    row: {
      flexDirection: 'row',
      paddingVertical: 8,
      paddingHorizontal: 10,
      borderBottomWidth: 1,
      borderColor: '#ddd',
    },
    value: {
      flex: 1,
      fontSize: 12,
      textAlign: 'right',
      backgroundColor: '#fff',
    },

    tableContainerNew: {
      display: 'flex',
      flexDirection: 'column',
      borderColor: '#000',
      overflow: 'hidden',
      width: '100%',
    },
    tableHeader: {
      flexDirection: 'row',
      backgroundColor: '#e0e0e0',
      paddingVertical: 8,
      borderWidth: 1,
      borderColor: '#000',
    },
    headerCell: {
      flex: 1,
      fontWeight: 'bold',
      fontSize: 9,
      textAlign: 'left',
      paddingVertical: 5,
      borderRightWidth: 1,
      borderRightColor: '#000',
    },
    headerCellNew: {
      flex: 1,
      fontWeight: 'bold',
      fontSize: 11,
      textAlign: 'left',
      paddingVertical: 5,
      borderRightWidth: 1,
      paddingHorizontal: 8,
      borderRightColor: '#000',
    },
    tableRow: {
      flexDirection: 'row',
      borderBottomWidth: 1,
      borderBottomColor: '#ccc',
    },
    cell: {
      flex: 1,
      fontSize: 9,
      paddingVertical: 5,
      paddingHorizontal: 10,
      textAlign: 'left',
      borderRightWidth: 1,
      borderRightColor: '#ccc',
    },

    titleContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
      // marginTop: 24,
    },

    logo: { width: 90 },

    reportTitle: {
      fontSize: 18,
      textAlign: 'center',
      fontWeight: 'ultrabold',
      color: '#114684',
    },

    addressTitle: { fontSize: 11, fontWeight: 'bold' },

    invoice: { fontWeight: 'bold', fontSize: 18, marginLeft: 10 },

    invoiceNumber: {
      fontSize: 10,
      fontWeight: 'bold',
      marginTop: 10,
      textAlign: 'center',
    },

    address: { fontWeight: 400, fontSize: 10 },

    theader: {
      marginTop: 20,
      fontSize: 10,
      fontWeight: 'bold',
      paddingTop: 4,
      paddingLeft: 7,
      flex: 1,
      height: 20,
      backgroundColor: '#DEDEDE',
      borderColor: 'whitesmoke',
      borderRightWidth: 1,
      borderBottomWidth: 1,
    },

    theader2: { flex: 2, borderRightWidth: 0, borderBottomWidth: 1 },

    tbody: {
      fontSize: 9,
      paddingTop: 4,
      paddingLeft: 7,
      flex: 1,
      borderColor: 'whitesmoke',
      borderRightWidth: 1,
      borderBottomWidth: 1,
    },

    total: {
      fontSize: 9,
      paddingTop: 4,
      paddingLeft: 7,
      flex: 1.5,
      borderColor: 'whitesmoke',
      borderBottomWidth: 1,
    },

    tbody2: { flex: 2, borderRightWidth: 1 },

    pageNumber: {
      position: 'absolute',
      fontSize: 10,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
    },

    pageHeader: {
      backgroundColor: 'lightgray',
      color: '#121212',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      padding: '0 40px',
      display: 'flex',
      alignItems: 'flex-end',
      margin: '5px 5px',
    },
    headerLogo: {
      maxWidth: '100px',
    },

    companyLogo: {
      width: '140px',
      // height: '70px',
    },

    companyName: { margin: 'auto 0' },

    logoTitle: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'row',
    },

    brokerName: {
      fontSize: 11,
      fontWeight: 600,
    },
    brokerInfo: {
      fontSize: 11,
      fontWeight: 600,
      color: '#114684',
    },
    brokerContainer: {
      position: 'absolute',
      left: 40,
      right: 0,
      bottom: 60,
    },

    subTitle: {
      fontWeight: 'bold',
      fontSize: 16,
      marginTop: 10,
      textAlign: 'center',
    },

    brokerData: {
      display: 'flex',
      // justifyContent: 'flex-start',
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
    companyDetails: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      marginTop: '30px',
    },

    brokerEmail: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    formHeading: {
      fontSize: 20,
      // textAlign: 'center',
      fontWeight: 'ultrabold',
      // marginTop: 34,
      // marginBottom: 5,
    },
    AccountingFormLabel: {
      fontSize: 11,
      fontWeight: 'bold',
    },

    flexRowWrap: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    flexRowNoWrap: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
    },

    flexColumn: {
      display: 'flex',
      flexDirection: 'column',
    },

    dot: {
      height: '4px',
      width: '4px',
      backgroundColor: '#114684',
      borderRadius: '50%',
      marginTop: '3px',
      marginRight: '7px',
    },

    tableContainer: {
      width: '100%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: 'grey',
      marginBottom: 10,
      overflow: 'hidden',
      borderRadius: 1,
      // margin: 10,
    },
    tableCell: {
      flex: 1,
      borderRightWidth: 1,
      borderRightColor: 'grey',
      borderRightStyle: 'solid',
      borderBottomWidth: 1,
      borderBottomColor: 'grey',
      borderBottomStyle: 'solid',
      padding: 5,
      fontSize: 9,
      textAlign: 'left',
      // minWidth: '130px', //110px for vertical data display
      width: '25%',
    },
    lastCell: {
      borderRightWidth: 0,
    },
    table: {
      // display: '',
      width: '100%',
      marginTop: 10,
    },
    header: {
      backgroundColor: '#f0f0f0',
      fontWeight: 'bold',
    },
    cells: {
      padding: 2,
      fontSize: 9,
      flex: 2,
      width: '40%',
      borderRightWidth: 1,
      borderRightColor: '#ccc',
    },
    cellData: {
      width: '15%',
      padding: 5,
      fontSize: 10,
      flex: 1, // Adjust width proportionally
      textAlign: 'right',
      borderRightWidth: 1,
      borderRightColor: '#ccc',
      fontWeight: 'extrabold',
    },
    rowWithBottomBorder: {
      display: 'flex',
      gap: '5px',
      borderBottom: '1px solid black',
      flexDirection: 'row',
      fontSize: 10,
      padding: '3px',
    },
    rowWithoutBottomBorder: {
      display: 'flex',
      gap: '5px',
      flexDirection: 'row',
      fontSize: 10,
      padding: '3px',
    },
    colWithBottomBorder: {
      display: 'flex',
      gap: '5px',
      borderBottom: '1px solid black',
      flexDirection: 'column',
      fontSize: 10,
      padding: '3px',
    },
    flexWithBottomBorder: {
      display: 'flex',
      borderBottom: '1px solid black',
      flexDirection: 'row',
    },
    simpleCol: {
      display: 'flex',
      gap: '5px',
      flexDirection: 'row',
      fontSize: 10,
      padding: '3px',
    },
  });

  Font.register({
    family: 'Nunito',
    fonts: [
      { src: '/fonts/nunito/Nunito-Regular.ttf', fontWeight: 'normal' },
      {
        src: '/fonts/nunito/Nunito-Italic.ttf',
        fontWeight: 'normal',
        fontStyle: 'italic',
      },
      { src: '/fonts/nunito/Nunito-Bold.ttf', fontWeight: 'bold' },
      {
        src: '/fonts/nunito/Nunito-BoldItalic.ttf',
        fontWeight: 'bold',
        fontStyle: 'italic',
      },
      { src: '/fonts/nunito/Nunito-ExtraBold.ttf', fontWeight: 'ultrabold' },
      {
        src: '/fonts/nunito/Nunito-ExtraBoldItalic.ttf',
        fontWeight: 'ultrabold',
        fontStyle: 'italic',
      },
      { src: '/fonts/nunito/Nunito-Black.ttf', fontWeight: 'heavy' },
      {
        src: '/fonts/nunito/Nunito-BlackItalic.ttf',
        fontWeight: 'heavy',
        fontStyle: 'italic',
      },
      { src: '/fonts/nunito/Nunito-Light.ttf', fontWeight: 'light' },
      {
        src: '/fonts/nunito/Nunito-LightItalic.ttf',
        fontWeight: 'light',
        fontStyle: 'italic',
      },
      { src: '/fonts/nunito/Nunito-Medium.ttf', fontWeight: 'medium' },
      {
        src: '/fonts/nunito/Nunito-MediumItalic.ttf',
        fontWeight: 'medium',
        fontStyle: 'italic',
      },
      { src: '/fonts/nunito/Nunito-SemiBold.ttf', fontWeight: 'semibold' },
      {
        src: '/fonts/nunito/Nunito-SemiBoldItalic.ttf',
        fontWeight: 'semibold',
        fontStyle: 'italic',
      },
    ],
  });

  const InvoiceItemsTable = ({ invoice }: { invoice: any[] }) => {
    return (
      <View style={styles.tableContainer}>
        <InvoiceTableHeader />
        <InvoiceTableRow items={invoice} />
      </View>
    );
  };

  const InvoiceTableHeader = () => (
    <View style={styles.tableRow}>
      <Text style={[styles.tableCell, styles.headerCell]}>
        Type of construction
      </Text>
      <Text style={[styles.tableCell, styles.headerCell]}>
        Largest fixed price contract in the last 3 years for this type of
        construction
      </Text>
      <Text style={[styles.tableCell, styles.headerCell]}>
        Location of largest fixed price contract in the last 3 years for this
        type of construction
      </Text>
      <Text style={[styles.tableCell, styles.headerCell, styles.lastCell]}>
        % of annual sales related to this type of construction
      </Text>
    </View>
  );
  const TableRow = ({ label, value }: any) => (
    <View style={styles.tableRow}>
      <Text style={styles.cell}>{label}</Text>
      <Text style={styles.cell}>{value}</Text>
    </View>
  );

  const InvoiceTableRow = ({ items }: { items: any[] }) => {
    return (
      <>
        {items.map((item: any, i: number) => (
          <View style={styles.tableRow} key={i}>
            <Text style={styles.tableCell}>{item.contractorType}</Text>
            <Text style={styles.tableCell}>
              {item?.contractPrice
                ? `$${item?.contractPrice?.toLocaleString('en-US', {
                    maximumFractionDigits: 2,
                  })}`
                : ''}
            </Text>

            <Text style={styles.tableCell}>{item.location}</Text>
            <Text style={[styles.tableCell, styles.lastCell]}>
              {item?.annualSales?.toLocaleString('en-US', {
                maximumFractionDigits: 2,
              })}
              %
            </Text>
          </View>
        ))}
      </>
    );
  };

  const PDFHeading = () => (
    <View style={{ ...styles.titleContainer }}>
      <View style={{ textAlign: 'center', marginTop: '10px' }}>
        <Text style={styles.reportTitle}>{heading}</Text>
      </View>
    </View>
  );

  const Address = () => (
    <View>
      <Text style={styles.invoiceNumber}>{summary}</Text>
    </View>
  );

  const UserAddress = () => (
    <View style={styles.titleContainer}>
      <View style={styles.spaceBetween}>
        <View style={{ maxWidth: 200 }}>
          <Text style={styles.addressTitle}>Bill to </Text>
          <Text style={styles.address}>{reciept_data.address}</Text>
        </View>
        <Text style={styles.addressTitle}>{reciept_data.date}</Text>
      </View>
    </View>
  );

  const CompanyName = () => (
    <View style={styles.companyName}>
      <View style={{ textAlign: 'center' }}>
        <Text style={styles.reportTitle}>{companyName}</Text>
      </View>
    </View>
  );

  const PDFHeader = () => (
    <View style={styles.pageHeader} fixed>
      <Svg
        style={styles.headerLogo}
        // xmlns="http://www.w3.org/2000/svg"
        width="264.976"
        height="42.708"
        viewBox="0 0 264.976 42.708"
      >
        <G transform="translate(-433.734 -1043.091)">
          <G data-name="Group 16891">
            <G data-name="Group 16890">
              <Path
                data-name="Path 4196"
                d="M521.288,1073.012l.873-1.715a10.349,10.349,0,0,0,6.954,2.559c3.945,0,5.661-1.656,5.661-3.734,0-5.78-12.976-2.227-12.976-10.025,0-3.1,2.408-5.78,7.768-5.78a11.905,11.905,0,0,1,6.532,1.866l-.752,1.777a10.853,10.853,0,0,0-5.78-1.746c-3.854,0-5.571,1.715-5.571,3.823,0,5.78,12.976,2.288,12.976,9.965,0,3.1-2.469,5.75-7.858,5.75C525.954,1075.752,522.854,1074.638,521.288,1073.012Z"
                fill="#114684"
              />
              <Path
                data-name="Path 4197"
                d="M544.647,1066.57V1054.5h2.228v11.982c0,5,2.348,7.285,6.443,7.285,4.124,0,6.472-2.288,6.472-7.285V1054.5h2.168v12.072c0,6.051-3.252,9.182-8.64,9.182S544.647,1072.621,544.647,1066.57Z"
                fill="#114684"
              />
              <Path
                data-name="Path 4198"
                d="M589.29,1069.942c0,3.582-2.649,5.63-7.918,5.63h-9.694V1054.5h9.093c4.7,0,7.405,1.987,7.405,5.419a4.891,4.891,0,0,1-3.221,4.786A5.032,5.032,0,0,1,589.29,1069.942Zm-15.384-13.608v7.647h6.713c3.372,0,5.329-1.295,5.329-3.824s-1.957-3.823-5.329-3.823Zm13.157,13.427c0-2.739-2.018-3.944-5.721-3.944h-7.436v7.918h7.436C585.045,1073.735,587.063,1072.531,587.063,1069.761Z"
                fill="#114684"
              />
              <Path
                data-name="Path 4199"
                d="M601.179,1056.425h-7.406V1054.5h17.04v1.927h-7.407v19.147h-2.227Z"
                fill="#114684"
              />
              <Path
                data-name="Path 4200"
                d="M631.763,1075.572l-4.817-6.774a15.044,15.044,0,0,1-1.686.09H619.6v6.684h-2.229V1054.5h7.888c5.359,0,8.61,2.709,8.61,7.225a6.532,6.532,0,0,1-4.816,6.623l5.147,7.226Zm-.12-13.849c0-3.372-2.228-5.3-6.443-5.3h-5.6v10.566h5.6C629.415,1066.991,631.643,1065.034,631.643,1061.723Z"
                fill="#114684"
              />
              <Path
                data-name="Path 4201"
                d="M655.363,1069.942H643.622l-2.529,5.63h-2.317l9.633-21.074h2.2l9.633,21.074h-2.347Zm-.812-1.807-5.059-11.319-5.057,11.319Z"
                fill="#114684"
              />
              <Path
                data-name="Path 4202"
                d="M666.739,1054.5h2.229v21.074h-2.229Z"
                fill="#114684"
              />
              <Path
                data-name="Path 4203"
                d="M678.84,1054.5h8.58c6.744,0,11.29,4.335,11.29,10.536s-4.546,10.538-11.29,10.538h-8.58Zm8.46,19.147c5.6,0,9.213-3.552,9.213-8.611s-3.613-8.609-9.213-8.609h-6.232v17.22Z"
                fill="#114684"
              />
            </G>
          </G>
          <G data-name="Group 16894">
            <G data-name="Group 16892">
              <Path
                data-name="Path 4204"
                d="M502.013,1085.8H433.734v-42.708h68.279Zm-63.779-4.5h59.279v-33.708H438.234Z"
                fill="#114684"
              />
            </G>
            <G data-name="Group 16893">
              <Path
                data-name="Path 4205"
                d="M471.313,1076.159H443.4v-4.4h23.509v-5.082H443.546v-13.891h48.8v4.4H480.71v18.967h-4.4v-18.967H447.946v5.09h23.366Z"
                fill="#114684"
              />
            </G>
          </G>
        </G>
      </Svg>
    </View>
  );

  const PDFFooter = () => (
    <Text
      style={styles.pageNumber}
      render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
      fixed
    />
  );

  const CompanyDetails = () => (
    <View style={styles.companyDetails}>
      <View style={styles.logoTitle}>
        {!!logo && <Image style={styles.companyLogo} src={logo} />}
      </View>
      {contactInfo && <BrokerDetails />}
    </View>
  );

  const SubTitle = () => (
    <View>
      <Text style={styles.subTitle}>{title}</Text>
    </View>
  );

  const BrokerDetails = () => (
    // <View style={styles.brokerContainer}>
    <View style={styles.brokerData}>
      {brokerInfo?.brokerageName && (
        <Text style={{ ...styles.brokerInfo, marginBottom: '4px' }}>
          {brokerInfo?.brokerageName}
        </Text>
      )}

      <Text style={{ ...styles.brokerName, marginBottom: '4px' }}>
        {brokerInfo?.firstName + ' ' + brokerInfo?.lastName}
      </Text>
      <View style={{ ...styles.brokerEmail, marginBottom: '4px' }}>
        <View style={{ marginRight: 5 }}>
          <EmailSVG />
        </View>
        <View>
          <Text style={styles.brokerName}>{brokerInfo?.email}</Text>
        </View>
      </View>
      <View style={{ ...styles.brokerEmail, marginBottom: '4px' }}>
        <View style={{ marginRight: 5 }}>
          <ContactSVG />
        </View>
        <View>
          <Text style={styles.brokerName}>{brokerInfo?.contactNumber}</Text>
        </View>
      </View>
    </View>
    // </View>
  );

  const ContactSVG = () => (
    <Svg fill="currentColor" viewBox="0 0 512 512" height="10" width="10">
      <Path d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"></Path>
    </Svg>
  );

  const EmailSVG = () => (
    <Svg fill="currentColor" viewBox="0 0 24 24" height="12" width="12">
      <Path fill="none" d="M0 0h24v24H0V0z"></Path>
      <Path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z"></Path>
    </Svg>
  );

  const AccountingSystemBankInfo = ({ bankInfo }: any) => {
    return (
      <View
        style={{
          ...styles?.flexColumn,
          borderTop: 'none',
        }}
      >
        <View
          style={{
            ...styles?.flexRowWrap,
            justifyContent: 'space-between',
          }}
        >
          <View
            style={{
              ...styles?.flexRowNoWrap,
              padding: 2,
            }}
          >
            <Text>Name of bank : </Text>
            <Text style={{ flexGrow: 1 }}>{bankInfo?.name ?? 'NA'}</Text>
          </View>
          <View
            style={{
              ...styles?.flexRowNoWrap,
              padding: 2,
            }}
          >
            <Text>Address : </Text>
            <Text>{bankInfo?.address ?? 'NA'}</Text>
          </View>
        </View>
        <View
          style={{
            ...styles?.flexRowWrap,
            justifyContent: 'space-between',
          }}
        >
          <View
            style={{
              ...styles?.flexRowNoWrap,
              padding: 2,
            }}
          >
            <Text style={styles?.AccountingFormLabel}>
              Account manager name :{' '}
            </Text>
            <Text>{bankInfo?.accountManagerName ?? 'NA'}</Text>
          </View>
          <View
            style={{
              ...styles?.flexRowNoWrap,
              padding: 2,
            }}
          >
            <Text style={styles?.AccountingFormLabel}>
              With bank since what year :{' '}
            </Text>{' '}
            <Text> {bankInfo?.withBankSinceYear ?? 'NA'}</Text>
          </View>
          <View
            style={{
              ...styles?.flexRowNoWrap,
              padding: 2,
            }}
          >
            <Text style={styles?.AccountingFormLabel}>Phone number : </Text>
            <Text>{bankInfo?.bankPhoneNumber ?? 'NA'}</Text>
          </View>
          <View
            style={{
              ...styles?.flexRowNoWrap,
              padding: 2,
            }}
          >
            <Text style={styles?.AccountingFormLabel}>
              Authorized line of credit :{' '}
            </Text>
            <Text>{bankInfo?.authorizedLineOfCredit ?? 'NA'}</Text>
          </View>
          <View
            style={{
              ...styles?.flexRowNoWrap,
              padding: 2,
            }}
          >
            <Text style={styles?.AccountingFormLabel}>
              How much of authorized line of credit is currently used? :{' '}
            </Text>
            <Text>{bankInfo?.usedLineOfCredit ?? 'NA'}</Text>
          </View>
          <View
            style={{
              ...styles?.flexRowWrap,
              justifyContent: 'space-between',
              padding: 2,
            }}
          >
            <View
              style={{
                ...styles?.flexRowNoWrap,
                padding: 2,
              }}
            >
              <Text style={styles?.AccountingFormLabel}>
                How is the line of credit secured? :{' '}
              </Text>
              <Text>
                {bankInfo?.creditSecured !== null
                  ? bankInfo?.creditSecured
                    ? 'Secured'
                    : 'Unsecured'
                  : 'NA'}
              </Text>
            </View>
            <View
              style={{
                ...styles?.flexRowNoWrap,
                padding: 2,
              }}
            >
              <Text style={styles?.AccountingFormLabel}>
                Credit secured value :{' '}
              </Text>
              <Text>{bankInfo?.securedVal ?? 'NA'}</Text>
            </View>
            {bankInfo?.securedVal === 'other' && (
              <View
                style={{
                  ...styles?.flexRowNoWrap,
                  padding: 2,
                }}
              >
                <Text style={styles?.AccountingFormLabel}>
                  If Other(Please describe) :{' '}
                </Text>
                <Text>{bankInfo?.otherSecuredVal ?? 'NA'}</Text>
              </View>
            )}
          </View>
        </View>
      </View>
    );
  };

  const AccountingSystem = () => (
    <View break>
      <View style={styles.formHeading}>
        <Text>
          {stepperFormLabel && stepperFormLabel?.generalBanking?.trim() !== ''
            ? stepperFormLabel?.generalBanking
            : 'General Banking'}
        </Text>
      </View>
      <View
        style={{
          border: '1px solid black',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <View
          style={{
            width: '50%',
            flex: '1',
            borderRight: '1px solid black',
          }}
        >
          <View
            style={{
              borderBottom: '1px solid black',
              padding: 2,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <Text style={styles?.AccountingFormLabel}>
              Legal name of company :{' '}
            </Text>
            <Text>{accountingSystem?.legalName ?? 'NA'}</Text>
          </View>
          <View
            style={{
              borderBottom: '1px solid black',
              padding: 2,
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            <Text style={styles?.AccountingFormLabel}>
              Date business established :{' '}
            </Text>
            <Text>
              {accountingSystem?.businessEstablishedDate
                ? moment(accountingSystem?.businessEstablishedDate).format(
                    'MM/DD/yyyy'
                  )
                : 'NA'}
            </Text>
          </View>
          <View
            style={{
              borderBottom: '1px solid black',
              padding: 2,
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            <Text style={styles?.AccountingFormLabel}>Street : </Text>
            <Text>{accountingSystem?.street ?? 'NA'}</Text>
          </View>
          <View
            style={{
              borderBottom: '1px solid black',
              padding: 2,
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            <Text style={styles?.AccountingFormLabel}>Country : </Text>
            <Text>{accountingSystem?.country ?? 'NA'}</Text>
          </View>
          <View
            style={{
              borderBottom: '1px solid black',
              padding: 2,
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            <Text style={styles?.AccountingFormLabel}>City : </Text>
            <Text>{accountingSystem?.city ?? 'NA'}</Text>
          </View>
          <View
            style={{
              // borderBottom: '1px solid black',
              padding: 2,
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            <Text style={styles?.AccountingFormLabel}>
              Date of Fiscal Year End :{' '}
            </Text>
            <Text>
              {accountingSystem?.dateCompleted
                ? moment(accountingSystem?.dateCompleted).format('MM/DD/yyyy')
                : 'NA'}
            </Text>
          </View>
        </View>
        <View
          style={{
            width: '50%',
            flex: '1',
          }}
        >
          <View
            style={{
              borderBottom: '1px solid black',
              // borderRight: '1px solid black',
              padding: 2,
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            <Text style={styles?.AccountingFormLabel}>Website : </Text>
            <Text>{accountingSystem?.website ?? 'NA'}</Text>
          </View>
          <View
            style={{
              borderBottom: '1px solid black',
              // borderRight: '1px solid black',
              padding: 2,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Text style={styles?.AccountingFormLabel}>Email address : </Text>
            <Text>{accountingSystem?.email ?? 'NA'}</Text>
          </View>
          <View
            style={{
              borderBottom: '1px solid black',
              // borderRight: '1px solid black',
              padding: 2,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Text style={styles?.AccountingFormLabel}>
              {`${accountingSystem?.country === 'United States' ? 'State' : 'Province'}`}{' '}
              :{' '}
            </Text>
            <Text>{accountingSystem?.province ?? 'NA'}</Text>
          </View>
          <View
            style={{
              borderBottom: '1px solid black',
              // borderRight: '1px solid black',
              padding: 2,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Text style={styles?.AccountingFormLabel}>
              {`${accountingSystem?.country === 'United States' ? 'Zip code' : 'Postal code'}`}{' '}
              :{' '}
            </Text>
            <Text>{accountingSystem?.postalCode ?? 'NA'}</Text>
          </View>
          <View
            style={{
              borderBottom: '1px solid black',
              // borderRight: '1px solid black',
              padding: 2,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Text style={styles?.AccountingFormLabel}>Telephone : </Text>
            <Text>{accountingSystem?.telephone ?? 'NA'}</Text>
          </View>
          <View
            style={{
              // borderBottom: '1px solid black',
              // borderRight: '1px solid black',
              padding: 2,
            }}
          ></View>
        </View>
      </View>

      <View
        style={{
          border: '1px solid black',
          borderBottom: accountingSystem?.mailingAddressSameAsOffice
            ? '1px solid black'
            : 'none',
          marginTop: 5,
        }}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: 3,
          }}
        >
          <Text style={styles?.AccountingFormLabel}>
            Use the Office address as the mailing address? :{' '}
          </Text>
          <Text>
            {accountingSystem?.mailingAddressSameAsOffice ? 'Yes' : 'No'}
          </Text>
        </View>
      </View>
      {!accountingSystem?.mailingAddressSameAsOffice && (
        <View
          style={{
            border: '1px solid black',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
          }}
        >
          <View
            style={{
              padding: 2,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Text style={styles?.AccountingFormLabel}>Street : </Text>
            <Text>{accountingSystem?.mailingStreet ?? 'NA'}</Text>
          </View>
          <View
            style={{
              padding: 2,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Text style={styles?.AccountingFormLabel}>City : </Text>{' '}
            <Text> {accountingSystem?.mailingCity ?? 'NA'}</Text>
          </View>
          <View
            style={{
              padding: 2,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Text style={styles?.AccountingFormLabel}>Country : </Text>
            <Text>{accountingSystem?.mailingCountry ?? 'NA'}</Text>
          </View>
          <View
            style={{
              padding: 2,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Text style={styles?.AccountingFormLabel}>
              {`${accountingSystem?.country === 'United States' ? 'State' : 'Province'}`}{' '}
              :{' '}
            </Text>
            <Text>{accountingSystem?.mailingProvince ?? 'NA'}</Text>
          </View>
          <View
            style={{
              padding: 2,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Text style={styles?.AccountingFormLabel}>
              {`${accountingSystem?.country === 'United States' ? 'Zip code' : 'Postal code'}`}{' '}
              :{' '}
            </Text>
            <Text>{accountingSystem?.mailingPostalCode ?? 'NA'}</Text>
          </View>
        </View>
      )}

      <View style={{ border: '1px solid black', borderTop: 'none' }}>
        <View
          style={{
            padding: 2,
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Text style={styles?.AccountingFormLabel}>
            Name of Accounting Firm :{' '}
          </Text>
          <Text>{accountingSystem?.accountingFirmName ?? 'NA'}</Text>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <View
            style={{
              padding: 2,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Text style={styles?.AccountingFormLabel}>
              Contact Name at Accounting Firm :{' '}
            </Text>
            <Text>{accountingSystem?.contactName ?? 'NA'}</Text>
          </View>

          <View
            style={{
              padding: 2,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Text style={styles?.AccountingFormLabel}>Phone number : </Text>
            <Text>{accountingSystem?.phoneNumber ?? 'NA'}</Text>
          </View>
        </View>
      </View>

      <View style={{ border: '1px solid black', borderTop: 'none' }}>
        <View style={{ padding: 2, display: 'flex', flexDirection: 'row' }}>
          <Text style={styles?.AccountingFormLabel}>
            What intervals are interim statements prepared? :{' '}
          </Text>
          <Text style={{ textTransform: 'capitalize' }}>
            {accountingSystem?.interimStatementInterval ?? 'NA'}
          </Text>
        </View>
      </View>

      <View>
        {accountingSystem?.bankInfo &&
          accountingSystem?.bankInfo?.map((item: any, index: number) => (
            <AccountingSystemBankInfo bankInfo={item} key={index} />
          ))}
      </View>
    </View>
  );

  const getcheckedValue = (queKey: boolean | null) => {
    return queKey !== null ? (queKey === true ? 'Yes' : 'No') : 'No';
  };

  const CreditBureauCompanies = ({ company }: any) => {
    return (
      <View
        style={{
          ...styles?.flexColumn,
          padding: 2,
          marginBottom: 5,
        }}
      >
        <View style={{ ...styles?.flexRowWrap }}>
          <Text>Name : {company?.name ?? 'NA'}</Text>
          <Text style={{ marginLeft: 30 }}>
            Nature of operations of related company :{' '}
            {company?.natureOfOperations ?? 'NA'}
          </Text>
        </View>
        <View>
          <Text>
            What is the ownership structure of this related company :{' '}
            {company?.ownershipStructure ?? 'NA'}
          </Text>
        </View>
      </View>
    );
  };
  const LifeInsurence = ({ company }: any) => {
    return (
      <View
        style={{
          ...styles?.flexColumn,
          padding: 2,
          marginBottom: 5,
        }}
      >
        <View style={{ ...styles?.flexRowWrap }}>
          <Text>Name : {company?.name ?? 'NA'}</Text>
          <Text style={{ marginLeft: 30 }}>
            Nature of operations of related company :{' '}
            {company?.natureOfOperations ?? 'NA'}
          </Text>
        </View>
        <View>
          <Text>
            What is the ownership structure of this related company :{' '}
            {company?.ownershipStructure ?? 'NA'}
          </Text>
        </View>
      </View>
    );
  };

  const getByLabelText = (key: string) => {
    return (
      <Text
        style={{
          fontWeight: 600,
          color: '#114684',
          fontSize: 10,
          // marginRight: '10px',

          paddingLeft: '5px',
        }}
        wrap={false}
      >
        {key}:
      </Text>
    );
  };

  const formatValue = (value: any) => {
    if ((typeof value === 'number' || Number(value)) && value < 100) {
      return `${value}%`;
    }
    return value;
  };
  const formatNumber = (number: number): string => {
    if (number !== 0 && !number) return '';
    const formattedNumber = number.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return `$ ${formattedNumber}`;
  };

  const getFieldInput = (key: string, value: any) => {
    return (
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          border: '1px solid #000',
          width: '100%',
          flexWrap: 'wrap',
          marginBottom: '1px',
        }}
        wrap={false}
      >
        <View
          style={{
            ...styles?.flexRowNoWrap,
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
          }}
        >
          <View></View>
          {getByLabelText(key)}
        </View>
        <Text
          style={{
            fontSize: 10,
            // padding: '5px',
            flexGrow: 1,
            paddingLeft: '5px',
            // color: '#575555',
          }}
        >
          {key.includes('%')
            ? formatValue(value)
            : key.includes('$')
              ? formatNumber(value)
              : value}
        </Text>
      </View>
    );
  };

  const getFieldInputCommon = (
    key: string,
    value: any,
    maxWidth?: string,
    width?: string
  ) => {
    return (
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '4px',
          width: `${width ?? '100%'}`,
          flexGrow: 1,
          maxWidth: `${maxWidth ?? '100%'}`,
        }}
        wrap={false}
      >
        <View
          style={{
            ...styles?.flexRowNoWrap,
          }}
        >
          <View style={{ ...styles?.dot }}></View>
          {getByLabelText(key)}
        </View>
        <Text
          style={{
            fontSize: 10,
            borderRadius: '5px',
            // border: '1px solid #7f7f7f',
            padding: '5px 2px',
            flexGrow: 1,
          }}
        >
          {key.includes('%')
            ? formatValue(value)
            : key.includes('$')
              ? formatNumber(value)
              : value}
        </Text>
      </View>
    );
  };

  const getFieldInputNew = (label: string, value: any) => {
    return (
      <Text
        style={{
          fontSize: 10,
          paddingVertical: 4,
          paddingHorizontal: 6,
          textAlign: label ? 'left' : 'right',
        }}
      >
        {label ? `${label}: ${value}` : value}
      </Text>
    );
  };

  const getFieldInputSperate = (label: string, value: any) => {
    return (
      <Text style={{ fontSize: 11, paddingVertical: 4, paddingHorizontal: 6 }}>
        {label ? `${label}: ${value}` : value}
      </Text>
    );
  };

  const getInputCapitalized = (key: string, value: any) => {
    return (
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          border: '1px solid black',
          gap: '2px',
          width: '100%',
        }}
        wrap={false}
      >
        <View
          style={{
            ...styles?.flexRowNoWrap,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
          }}
        >
          <View></View>
          {getByLabelText(key)}
        </View>
        <Text
          style={{
            fontSize: 10,
            // padding: '5px',
            textTransform: 'capitalize',
            flexGrow: 1,
            // color: '#575555',
          }}
        >
          {value}
        </Text>
      </View>
    );
  };
  const getPFInput = (key: string, value: any) => {
    return (
      <Text
        style={{
          fontSize: 12,
          paddingVertical: 4,
          paddingHorizontal: 6,
        }}
      >
        {value}
      </Text>
    );
  };

  const getFieldInputCommons = (key: string, value: any) => {
    return (
      <Text
        style={{
          fontSize: 11,
          paddingVertical: 4,
          paddingHorizontal: 6,
          textAlign: 'right',
        }}
      >
        {key.includes('%')
          ? formatValue(value)
          : key.includes('$')
            ? formatNumber(value)
            : value}
      </Text>
    );
  };

  const getAssetPriceInput = (
    key: string,
    value: any,
    isOtherType: boolean
  ) => {
    return (
      <Text
        style={{
          fontSize: 10,
          paddingVertical: 4,
          paddingHorizontal: 6,
          textAlign: isOtherType ? 'center' : 'left',
        }}
      >
        {value}
      </Text>
    );
  };

  const getFieldCheckbox = (key: string, value: boolean) => {
    return (
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '2px',
          width: '100%',
        }}
      >
        <View
          style={{
            ...styles?.flexRowNoWrap,
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
          }}
        >
          {getByLabelText(key)}
        </View>
        <Text
          style={{
            fontSize: 10,
            padding: '5px 10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#575555',
          }}
        >
          {value ? (value === true ? 'Yes' : 'No') : 'No'}
        </Text>
      </View>
    );
  };

  const getFieldCheckboxCredit = (key: string, value: boolean) => {
    return (
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          border: '1px solid black',
          gap: '2px',
          width: '100%',
        }}
        wrap={false}
      >
        <View
          style={{
            ...styles?.flexRowNoWrap,
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
          }}
        >
          {getByLabelText(key)}
        </View>
        <Text
          style={{
            fontSize: 10,
            // padding: '5px',
            flexGrow: 1,
            // color: '#575555',
          }}
        >
          {' '}
          {value ? (value === true ? 'Secured' : 'Unsecured') : 'Unsecured'}
        </Text>
      </View>
    );
  };

  const isShowPFForm = () => {
    const ownerData =
      financialInfo?.ownersFinancialInfo &&
      financialInfo?.ownersFinancialInfo?.length > 0 &&
      financialInfo?.ownersFinancialInfo?.map((owner: any, i: number) => {
        return owner?.ownerInfo?.nameHoldco ||
          owner?.ownerInfo?.dateOfBirth ||
          owner?.ownerInfo?.position ||
          owner?.ownerInfo?.inPositionSince ||
          owner?.ownerInfo?.ownershipPercentage ||
          isPFSection(owner)
          ? true
          : false;
      });

    return ownerData?.includes(true);
  };

  const isShowSurety = () => {
    return surety?.suretyName ||
      surety?.totalWorkOnHand ||
      surety?.singleJob ||
      surety?.reasonChangingSurety ||
      typeof surety?.refusedBond === 'boolean' ||
      (!!surety?.whoSignSeal && !isEveryObjKeyNull(surety?.whoSignSeal))
      ? true
      : false;
  };

  const isShowInsurance = () => {
    return typeof insurance?.keyManLifeInsurance?.hasKeyManLifeInsurance ===
      'boolean' ||
      typeof insurance?.commercialGeneralLiabilityInsurance
        ?.hasCommercialGeneralLiabilityInsurance === 'boolean' ||
      typeof insurance?.environmentalInsurance?.hasEnvironmentalInsurance ===
        'boolean' ||
      typeof insurance?.errorsAndOmissionsInsurance
        ?.hasErrorsAndOmissionsInsurance === 'boolean' ||
      typeof insurance?.fidelityInsurance?.hasFidelityInsurance === 'boolean' ||
      typeof insurance?.equipmentInsurance?.hasEquipmentInsurance ===
        'boolean' ||
      typeof insurance?.otherInsurance?.hasOtherInsurance === 'boolean'
      ? true
      : false;
  };

  const isEveryObjKeyNull = (arr: { [key: string]: any }) => {
    return arr?.every((info: any) =>
      Object.values(info).every(
        (value) => value === null || value === '' || value === 0
      )
    );
  };

  const isEveryObjKeyNullRemoveId = (arr: { [key: string]: any }) => {
    return arr?.every((info: any) => {
      const entries = Object.entries(info).filter(([key]) => key !== 'id');
      return entries.every(
        ([, value]) => value === null || value === '' || value === 0
      );
    });
  };
  const isEveryObjKeyNullAssets = (arr: { [key: string]: any }) => {
    return arr?.every((info: any) => {
      const entries = Object.entries(info).filter(
        ([key]) => key !== 'id' && key !== 'type'
      );
      return entries.every(
        ([, value]) => value === null || value === '' || value === 0
      );
    });
  };

  const getFilteredArray = (arr: { [key: string]: any }) => {
    return arr.filter(
      (info: any) =>
        !Object.values(info).every(
          (value) => value === null || value === '' || value === 0
        )
    );
  };
  const getFilteredArrayAssets = (arr: { [key: string]: any }) => {
    return arr.filter(
      (info: any) =>
        info?.value &&
        !Object.values(info).every(
          (value) => value === null || value === '' || value === 0
        )
    );
  };

  const isPFSection = (owner: any) => {
    return (
      owner?.assetsResidential?.address ||
      owner?.assetsResidential?.value ||
      !isEveryObjKeyNullRemoveId(owner?.assetsRealEstate) ||
      !isEveryObjKeyNullAssets(owner?.assetsPrice) ||
      owner?.liabilitiesResidence?.mortgage ||
      owner?.liabilitiesResidence?.value ||
      !isEveryObjKeyNullRemoveId(owner?.liabilitiesRealEstate) ||
      !isEveryObjKeyNullAssets(owner?.liabilitiesPrice) ||
      !isEveryObjKeyNullRemoveId(owner?.businessInterests) ||
      owner?.totalAssets ||
      owner?.totalLiabilities ||
      owner?.personalNetWorth
    );
  };

  const renderFields = (accountingSystem: any) => {
    // Create an array of field objects
    const fields = [
      { label: 'Legal Name of Company', value: accountingSystem?.legalName },
      {
        label: 'Date Business Established',
        value: moment(accountingSystem?.businessEstablishedDate).format(
          'MM/DD/YYYY'
        ),
      },
      { label: 'Email Address', value: accountingSystem?.email },
      { label: 'Website', value: accountingSystem?.website },
      { label: 'Street', value: accountingSystem?.street },
      { label: 'City', value: accountingSystem?.city },
      {
        label: `${accountingSystem?.country === 'United States' ? 'State' : 'Province'}`,
        value: accountingSystem?.province,
      },
      {
        label: `${accountingSystem?.country === 'United States' ? 'Zip code' : 'Postal code'}`,
        value: accountingSystem?.postalCode,
      },
      { label: 'Country', value: accountingSystem?.country },
      {
        label: 'Use the Office address as the mailing address? ',
        value:
          accountingSystem?.mailingAddressSameAsOffice === true ? 'Yes' : 'No',
      },
    ];

    // Filter out any null or undefined values
    const validFields = fields.filter((field) => field.value);

    // Split the fields into left and right columns
    const leftFields = validFields.slice(0, Math.ceil(validFields.length / 2));
    const rightFields = validFields.slice(Math.ceil(validFields.length / 2));

    return (
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
        }}
      >
        {/* Left Column */}
        <View style={{ flex: 1 }}>
          {leftFields.map((field, index) => (
            <View key={index} style={{ flexWrap: 'wrap' }}>
              {getFieldInput(field.label, field.value)}
            </View>
          ))}
        </View>

        <View
          style={{
            width: '1px',
            backgroundColor: '#eee',
          }}
        />

        {/* Right Column */}
        <View style={{ flex: 1 }}>
          {rightFields.map((field, index) => (
            <View key={index} style={{ flexWrap: 'wrap' }}>
              {getFieldInput(field.label, field.value)}
            </View>
          ))}
        </View>
      </View>
    );
  };

  const renderField = (label: string, value: any, width = '48%') => {
    if (!value) return null;
    return (
      <View style={{ ...styles.fieldContainer, width }}>
        <Text style={styles.label}>{label}</Text>
        <View style={styles.inputBox}>
          <Text style={styles.inputText}>
            {typeof value === 'string' &&
            moment(value, 'YYYY-MM-DD', true).isValid()
              ? moment(value).format('MM/DD/YYYY')
              : value}
          </Text>
        </View>
      </View>
    );
  };

  const renderMailingAddress = (accountingSystem: any) => {
    const mailingFields = [
      { label: 'Street', value: accountingSystem?.mailingStreet },
      { label: 'City', value: accountingSystem?.mailingCity },
      {
        label: `${accountingSystem?.mailingCountry === 'United States' ? 'State' : 'Province'}`,
        value: accountingSystem?.mailingProvince,
      },
      {
        label: `${accountingSystem?.mailingCountry === 'United States' ? 'Zip code' : 'Postal code'}`,
        value: accountingSystem?.mailingPostalCode,
      },
      { label: 'Country', value: accountingSystem?.mailingCountry },
    ];

    const validMailingFields = mailingFields.filter((field) => field.value);
    const leftMailingFields = validMailingFields.slice(
      0,
      Math.ceil(validMailingFields.length / 2)
    );
    const rightMailingFields = validMailingFields.slice(
      Math.ceil(validMailingFields.length / 2)
    );

    return (
      <View style={{ ...styles?.flexRowWrap, width: '100%' }}>
        <View style={{ flex: 1 }}>
          {leftMailingFields.map((field, index) => (
            <View key={index}>{getFieldInput(field?.label, field?.value)}</View>
          ))}
        </View>
        <View
          style={{
            width: '1px',
            backgroundColor: '#eee',
          }}
        />
        <View style={{ flex: 1 }}>
          {rightMailingFields.map((field, index) => (
            <View key={index}>{getFieldInput(field?.label, field?.value)}</View>
          ))}
        </View>
      </View>
    );
  };

  const renderAccountingFields = (accountingSystem: any) => {
    const accountingFields = [
      { label: 'Telephone', value: accountingSystem?.telephone },
      {
        label: 'Name of Accounting Firm',
        value: accountingSystem?.accountingFirmName,
      },
      {
        label: 'Contact Name at Accounting Firm',
        value: accountingSystem?.contactName,
      },
      { label: 'Phone number', value: accountingSystem?.phoneNumber },
      {
        label: 'What intervals are interim statements prepared?',
        value: accountingSystem?.interimStatementInterval,
      },
      {
        label: 'Date of Fiscal Year End',
        value: moment(accountingSystem?.dateCompleted).format('MM/DD/YYYY'),
      },
    ];

    const validAccountingFields = accountingFields.filter(
      (field) => field.value
    );
    const leftAccountingFields = validAccountingFields.slice(
      0,
      Math.ceil(validAccountingFields.length / 2)
    );
    const rightAccountingFields = validAccountingFields.slice(
      Math.ceil(validAccountingFields.length / 2)
    );

    return (
      <View style={{ ...styles?.flexRowWrap, width: '100%' }}>
        <View style={{ flex: 1 }}>
          {leftAccountingFields.map((field, index) => (
            <View key={index}>{getFieldInput(field?.label, field?.value)}</View>
          ))}
        </View>

        <View
          style={{
            width: '1px',
            backgroundColor: '#eee',
          }}
        />
        <View style={{ flex: 1 }}>
          {rightAccountingFields.map((field, index) => (
            <View key={index}>{getFieldInput(field?.label, field?.value)}</View>
          ))}
        </View>
      </View>
    );
  };

  const AccountingSystemNew = () => (
    <View break>
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '15px',
        }}
      >
        <View
          style={{ width: '100%', display: 'flex', flexDirection: 'row' }}
          id="generalBankingPage"
        >
          <Text style={{ ...styles?.formHeading, color: 'black' }}>1. </Text>
          <Text style={{ ...styles?.formHeading, color: '#114684' }}>
            {stepperFormLabel && stepperFormLabel?.generalBanking?.trim() !== ''
              ? stepperFormLabel?.generalBanking
              : 'General & Banking'}
          </Text>
        </View>

        <View>
          <Text
            style={{ margin: '0 0 6px 0', fontWeight: 'bold', fontSize: 12 }}
          >
            Company Details
          </Text>
          <View style={{ border: '1px solid black', borderBottom: 'none' }}>
            <View
              style={{
                ...styles?.rowWithBottomBorder,
              }}
            >
              <Text>Legal Name of Company : </Text>
              <Text style={{ fontWeight: 'semibold' }}>
                {accountingSystem?.legalName}
              </Text>
            </View>
            {accountingSystem?.businessEstablishedDate && (
              <View
                style={{
                  ...styles?.rowWithBottomBorder,
                }}
              >
                <Text>Date Business Established : </Text>
                <Text style={{ fontWeight: 'semibold' }}>
                  {moment(accountingSystem?.businessEstablishedDate).format(
                    'MMMM DD, yyyy'
                  )}
                </Text>
              </View>
            )}
            <View style={{ ...styles?.flexWithBottomBorder }}>
              <View
                style={{
                  ...styles?.simpleCol,
                  width: '50%',
                }}
              >
                <Text>Email Address : </Text>
                <Text style={{ fontWeight: 'semibold' }}>
                  {accountingSystem?.email}
                </Text>
              </View>

              {accountingSystem?.website && (
                <View
                  style={{
                    ...styles?.simpleCol,
                    width: '50%',
                    borderLeft: '1px solid black',
                  }}
                >
                  <Text>Website : </Text>
                  <Text style={{ fontWeight: 'semibold' }}>
                    {accountingSystem?.website}
                  </Text>
                </View>
              )}
            </View>
            <View
              style={{
                fontSize: 10,
                backgroundColor: '#114684',
                color: 'white',
                fontWeight: 'bold',
                padding: '3px',
                textAlign: 'center',
              }}
            >
              <Text>Office Address</Text>
            </View>
            <View
              style={{
                ...styles?.rowWithBottomBorder,
              }}
            >
              <Text>Street : </Text>
              <Text style={{ fontWeight: 'semibold' }}>
                {accountingSystem?.street}
              </Text>
            </View>
            <View style={{ ...styles?.flexWithBottomBorder }}>
              <View
                style={{
                  ...styles?.simpleCol,
                  width: '50%',
                  borderRight: '1px solid black',
                }}
              >
                <Text>City : </Text>
                <Text style={{ fontWeight: 'semibold' }}>
                  {accountingSystem?.city}
                </Text>
              </View>
              <View
                style={{
                  ...styles?.simpleCol,
                  width: '50%',
                }}
              >
                <Text>
                  {accountingSystem?.country === 'United States'
                    ? 'State'
                    : 'Province'}{' '}
                  :{' '}
                </Text>
                <Text style={{ fontWeight: 'semibold' }}>
                  {accountingSystem?.province}
                </Text>
              </View>
            </View>
            <View style={{ ...styles?.flexWithBottomBorder }}>
              <View
                style={{
                  ...styles?.simpleCol,
                  width: '50%',
                  borderRight: '1px solid black',
                }}
              >
                <Text>
                  {accountingSystem?.country === 'United States'
                    ? 'Zip code'
                    : 'Postal code'}{' '}
                  :{' '}
                </Text>
                <Text style={{ fontWeight: 'semibold' }}>
                  {accountingSystem?.postalCode}
                </Text>
              </View>
              <View
                style={{
                  ...styles?.simpleCol,
                  width: '50%',
                }}
              >
                <Text>Country :</Text>
                <Text style={{ fontWeight: 'semibold' }}>
                  {accountingSystem?.country}
                </Text>
              </View>
            </View>
            <View
              style={{
                ...styles?.rowWithBottomBorder,
              }}
            >
              <Text>Use the Office address as the mailing address : </Text>
              <Text style={{ fontWeight: 'semibold' }}>
                {accountingSystem?.mailingAddressSameAsOffice === true
                  ? 'Yes'
                  : 'No'}
              </Text>
            </View>
            {accountingSystem?.mailingAddressSameAsOffice !== null &&
              !accountingSystem?.mailingAddressSameAsOffice && (
                <>
                  <View
                    style={{
                      fontSize: 10,
                      backgroundColor: '#114684',
                      color: 'white',
                      fontWeight: 'bold',
                      padding: '3px',
                      textAlign: 'center',
                    }}
                  >
                    <Text>Mailing Address</Text>
                  </View>
                  <View
                    style={{
                      ...styles?.rowWithBottomBorder,
                    }}
                  >
                    <Text>Street : </Text>
                    <Text style={{ fontWeight: 'semibold' }}>
                      {accountingSystem?.mailingStreet}
                    </Text>
                  </View>
                  <View style={{ ...styles?.flexWithBottomBorder }}>
                    <View
                      style={{
                        ...styles?.simpleCol,
                        width: '50%',
                        borderRight: '1px solid black',
                      }}
                    >
                      <Text>City : </Text>
                      <Text style={{ fontWeight: 'semibold' }}>
                        {accountingSystem?.mailingCity}
                      </Text>
                    </View>
                    <View
                      style={{
                        ...styles?.simpleCol,
                        width: '50%',
                      }}
                    >
                      <Text>
                        {accountingSystem?.mailingCountry === 'United States'
                          ? 'State'
                          : 'Province'}{' '}
                        :{' '}
                      </Text>
                      <Text style={{ fontWeight: 'semibold' }}>
                        {accountingSystem?.mailingProvince}
                      </Text>
                    </View>
                  </View>
                  <View style={{ ...styles?.flexWithBottomBorder }}>
                    <View
                      style={{
                        ...styles?.simpleCol,
                        width: '50%',
                        borderRight: '1px solid black',
                      }}
                    >
                      <Text>
                        {accountingSystem?.mailingCountry === 'United States'
                          ? 'Zip Code'
                          : 'Postal Code'}{' '}
                        :{' '}
                      </Text>
                      <Text style={{ fontWeight: 'semibold' }}>
                        {accountingSystem?.mailingPostalCode}
                      </Text>
                    </View>
                    <View
                      style={{
                        ...styles?.simpleCol,
                        width: '50%',
                      }}
                    >
                      <Text>Country :</Text>
                      <Text style={{ fontWeight: 'semibold' }}>
                        {accountingSystem?.mailingCountry}
                      </Text>
                    </View>
                  </View>
                </>
              )}
          </View>
        </View>

        <View>
          <Text
            style={{ margin: '10px 0 6px 0', fontWeight: 'bold', fontSize: 12 }}
          >
            Accounting Firm
          </Text>
          <View style={{ border: '1px solid black', borderBottom: 'none' }}>
            <View
              style={{
                ...styles?.rowWithBottomBorder,
              }}
            >
              <Text>Name of Accounting Firm : </Text>
              <Text style={{ fontWeight: 'semibold' }}>
                {accountingSystem?.accountingFirmName}
              </Text>
            </View>
            <View style={{ ...styles?.flexWithBottomBorder }}>
              <View
                style={{
                  ...styles?.simpleCol,
                  width: '50%',
                }}
              >
                <Text>Telephone : </Text>
                <Text style={{ fontWeight: 'semibold' }}>
                  {accountingSystem?.telephone}
                </Text>
              </View>
              <View
                style={{
                  ...styles?.simpleCol,
                  width: '50%',
                  borderLeft: '1px solid black',
                }}
              >
                <Text>Phone Number : </Text>
                <Text style={{ fontWeight: 'semibold' }}>
                  {accountingSystem?.phoneNumber}
                </Text>
              </View>
            </View>
            <View style={{ ...styles?.flexWithBottomBorder }}>
              <View
                style={{
                  ...styles?.simpleCol,
                  width: '50%',
                }}
              >
                <Text>Date of Fiscal Year End : </Text>
                <Text style={{ fontWeight: 'semibold' }}>
                  {moment(accountingSystem?.dateCompleted).format(
                    'MMM DD, YYYY'
                  )}
                </Text>
              </View>
              <View
                style={{
                  ...styles?.simpleCol,
                  width: '50%',
                  borderLeft: '1px solid black',
                }}
              >
                <Text>Contact Name at Accounting Firm : </Text>
                <Text style={{ fontWeight: 'semibold', flex: 1 }}>
                  {accountingSystem?.contactName}
                </Text>
              </View>
            </View>
            <View
              style={{
                ...styles?.rowWithBottomBorder,
              }}
            >
              <Text>What intervals are interim statements prepared? : </Text>
              <Text
                style={{ fontWeight: 'semibold', textTransform: 'capitalize' }}
              >
                {accountingSystem?.interimStatementInterval}
              </Text>
            </View>
          </View>
        </View>

        {accountingSystem?.bankInfo &&
          !isEveryObjKeyNull(accountingSystem?.bankInfo) && (
            <View>
              <Text
                style={{
                  margin: '10px 0 6px 0',
                  fontWeight: 'bold',
                  fontSize: 12,
                }}
              >
                Banking Details
              </Text>

              {accountingSystem?.bankInfo &&
                getFilteredArray(accountingSystem?.bankInfo)?.map(
                  (item: any, index: number) => (
                    <View
                      key={index}
                      style={{
                        border: '1px solid black',
                      }}
                    >
                      <View
                        style={{
                          ...styles?.rowWithoutBottomBorder,
                          backgroundColor: '#114684',
                          color: 'white',
                        }}
                      >
                        <Text style={{ fontWeight: 'semibold' }}>
                          Name of Bank :{' '}
                        </Text>
                        <Text style={{ fontWeight: 'bold' }}>
                          {item?.bankName || '-'}
                        </Text>
                      </View>
                      <View
                        style={{
                          padding: '3px',
                          fontSize: 10,
                          backgroundColor: '#edf5ff',
                        }}
                      >
                        {item?.address && (
                          <View
                            style={{
                              ...styles?.rowWithoutBottomBorder,
                              padding: '1px',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Text>Address : </Text>
                            <Text style={{ fontWeight: 'semibold' }}>
                              {item?.address}
                            </Text>
                          </View>
                        )}
                        {item?.bankPhoneNumber && (
                          <View
                            style={{
                              ...styles?.rowWithoutBottomBorder,
                              padding: '1px',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Text>Phone Number : </Text>
                            <Text style={{ fontWeight: 'semibold' }}>
                              {item?.bankPhoneNumber}
                            </Text>
                          </View>
                        )}
                        {item?.accountManagerName && (
                          <View
                            style={{
                              ...styles?.rowWithoutBottomBorder,
                              padding: '1px',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Text>Account Manager Name : </Text>
                            <Text style={{ fontWeight: 'semibold' }}>
                              {item?.accountManagerName}
                            </Text>
                          </View>
                        )}
                        {item?.withBankSinceYear && (
                          <View
                            style={{
                              ...styles?.rowWithoutBottomBorder,
                              padding: '1px',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Text>With bank since what year : </Text>
                            <Text style={{ fontWeight: 'semibold' }}>
                              {item?.withBankSinceYear}
                            </Text>
                          </View>
                        )}
                        {item?.authorizedLineOfCredit && (
                          <View
                            style={{
                              ...styles?.rowWithoutBottomBorder,
                              padding: '1px',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Text>Authorized line of credit : </Text>
                            <Text style={{ fontWeight: 'semibold' }}>
                              {formatNumber(
                                Number(item?.authorizedLineOfCredit)
                              )}
                            </Text>
                          </View>
                        )}
                        {item?.usedLineOfCredit && (
                          <View
                            style={{
                              ...styles?.rowWithoutBottomBorder,
                              padding: '1px',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Text>
                              How much of authorized line of credit is currently
                              used? :{' '}
                            </Text>
                            <Text style={{ fontWeight: 'semibold' }}>
                              {formatNumber(Number(item?.usedLineOfCredit))}
                            </Text>
                          </View>
                        )}
                        {item?.creditSecured !== null && (
                          <View
                            style={{
                              ...styles?.rowWithoutBottomBorder,
                              padding: '1px',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Text>How is the line of credit secured? : </Text>
                            <Text style={{ fontWeight: 'semibold' }}>
                              {item?.creditSecured
                                ? item?.creditSecured === true
                                  ? 'Secured'
                                  : 'Unsecured'
                                : 'Unsecured'}
                              {item?.creditSecured}
                            </Text>
                          </View>
                        )}
                        {item?.creditSecured && item?.securedVal && (
                          <View
                            style={{
                              ...styles?.rowWithoutBottomBorder,
                              padding: '1px',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Text>Credit Secured Value : </Text>
                            <Text style={{ fontWeight: 'semibold' }}>
                              {item?.securedVal}
                            </Text>
                          </View>
                        )}
                        {item?.otherSecuredVal && (
                          <View
                            style={{
                              ...styles?.rowWithoutBottomBorder,
                              padding: '1px',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Text>Other (Please describe) : </Text>
                            <Text style={{ fontWeight: 'semibold' }}>
                              {item?.otherSecuredVal}
                            </Text>
                          </View>
                        )}
                      </View>
                    </View>
                  )
                )}
            </View>
          )}
      </View>
    </View>
  );

  const getQueLabel = (que: string) => {
    return (
      <Text
        style={{
          fontWeight: 600,
          color: '#114684',
          fontSize: 9,
          marginRight: '10px',
        }}
      >
        {que}{' '}
      </Text>
    );
  };

  const creditBureauQue = (
    que: string,
    key: boolean | null,
    detailsQue: string,
    detailsKey: string | null
  ) => {
    return (
      <View
        style={{
          ...styles?.flexColumn,
          gap: '4px',
          margin: '5px 0px',
        }}
        wrap={false}
      >
        <View
          style={{
            ...styles?.flexColumn,
            flexDirection: 'row',
            gap: '2px',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <View
            style={{
              ...styles?.flexRowNoWrap,
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              maxWidth: '90%',
            }}
          >
            <View style={{ ...styles?.dot }}></View>
            {getQueLabel(que)}
          </View>
          <Text
            style={{
              fontWeight: 600,
              fontSize: 10,
              marginLeft: '10px',
              // color: '#575555',
            }}
          >
            {key ? (key === true ? 'Yes' : 'No') : 'No'}
          </Text>
        </View>
        {key && !!detailsKey && (
          <View
            style={{
              ...styles?.flexColumn,
              flexDirection: 'row',
              gap: '2px',
              width: '90%',
              // justifyContent: 'space-between',
            }}
          >
            <View
              style={{
                ...styles?.flexRowNoWrap,
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                maxWidth: '40%',
              }}
            >
              <View style={{ ...styles?.dot }}></View>
              <Text style={{ fontWeight: 600, color: '#114684', fontSize: 10 }}>
                {detailsQue}:{' '}
              </Text>
            </View>
            <View>
              <Text
                style={{
                  fontWeight: 600,
                  fontSize: 10,
                  padding: '5px',
                  flexGrow: 1,
                  color: '#575555',
                  maxWidth: '90%',
                }}
              >
                {detailsKey}
              </Text>
            </View>
          </View>
        )}
      </View>
    );
  };

  const suretyQue = (
    que: string,
    key: boolean | null,
    detailsQue: string,
    detailsKey: string | null
  ) => {
    return (
      typeof key === 'boolean' && (
        <View
          style={{
            ...styles?.flexColumn,
            // backgroundColor: '#f5f5f5',
            // borderRadius: '8px',
            padding: '8px',
            gap: '4px',
          }}
          wrap={false}
        >
          <View
            style={{
              ...styles?.flexColumn,
              flexDirection: 'row',
              gap: '2px',
            }}
          >
            <View
              style={{
                ...styles?.flexRowNoWrap,
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
              }}
            >
              <View style={{ ...styles?.dot }}></View>
              {getQueLabel(que)}
            </View>
            <Text style={{ fontWeight: 600, fontSize: 10, marginLeft: '10px' }}>
              {key ? (key === true ? 'Yes' : 'No') : 'No'}
            </Text>
          </View>
          {key && (
            <View style={{ ...styles?.flexColumn, gap: '2px' }}>
              <View
                style={{
                  ...styles?.flexRowNoWrap,
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                }}
              >
                <View style={{ ...styles?.dot }}></View>
                <Text
                  style={{ fontWeight: 600, color: '#114684', fontSize: 10 }}
                >
                  {detailsQue}{' '}
                </Text>
              </View>
              <View>
                <Text
                  style={{
                    fontWeight: 600,
                    fontSize: 10,
                    border: '1px solid #7f7f7f',
                    padding: '5px 10px',
                    flexGrow: 1,
                  }}
                >
                  {detailsKey}
                </Text>
              </View>
            </View>
          )}
        </View>
      )
    );
  };

  const creditBureaudisputesWithSuppliers = (
    que: string,
    key: boolean | null
  ) => {
    return (
      <View
        style={{
          ...styles?.flexColumn,
          // backgroundColor: '#f5f5f5',
          // borderRadius: '8px',
          padding: '8px',
          gap: '4px',
        }}
        wrap={false}
      >
        <View
          style={{
            ...styles?.flexColumn,
            gap: '2px',
          }}
        >
          <View
            style={{
              ...styles?.flexRowNoWrap,
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
            }}
          >
            <View style={{ ...styles?.dot }}></View>
            {getQueLabel(que)}
          </View>
          <Text style={{ fontWeight: 600, fontSize: 10, marginLeft: '10px' }}>
            {key ? (key === true ? 'Yes' : 'No') : 'No'}
          </Text>
        </View>
        {key && (
          <View
            style={{
              ...styles?.flexRowNoWrap,
              flexDirection: 'column',
              gap: '10px',
            }}
          >
            <View
              style={{
                ...styles?.flexColumn,
                flexDirection: 'row',
                gap: '2px',
                width: '50%',
              }}
            >
              <View
                style={{
                  ...styles?.flexRowNoWrap,
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                }}
              >
                <View style={{ ...styles?.dot }}></View>
                <Text
                  style={{
                    fontWeight: 600,
                    color: '#114684',
                    fontSize: 10,
                    marginRight: '10px',
                  }}
                >
                  Additional Details{' '}
                </Text>
              </View>
              <Text
                style={{
                  fontWeight: 600,
                  fontSize: 10,
                  flexGrow: 1,
                }}
              >
                {creditBureau?.disputesWithSuppliers?.details}
              </Text>
            </View>
            <View
              style={{
                ...styles?.flexColumn,
                flexDirection: 'row',
                gap: '2px',
                width: '100%',
              }}
            >
              <View
                style={{
                  ...styles?.flexRowNoWrap,
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                }}
              >
                <View style={{ ...styles?.dot }}></View>
                <Text
                  style={{
                    fontWeight: 600,
                    color: '#114684',
                    fontSize: 10,
                    marginRight: '10px',
                  }}
                >
                  By what means is it funded? Provide a copy of the
                  agreement.{' '}
                </Text>
              </View>
              <Text
                style={{
                  fontWeight: 600,
                  fontSize: 10,
                  flexGrow: 1,
                }}
              >
                {creditBureau?.disputesWithSuppliers?.fundingMeans}
              </Text>
            </View>
          </View>
        )}
      </View>
    );
  };

  const creditBureauQueChain = (
    que: string,
    key: boolean | null,
    queSecond: string,
    KeySecond: boolean | null
  ) => {
    return (
      <View
        style={{
          ...styles?.flexColumn,
          // backgroundColor: '#f5f5f5',
          // borderRadius: '8px',
          padding: '8px',
          gap: '4px',
        }}
        wrap={false}
      >
        <View
          style={{
            ...styles?.flexColumn,
            flexDirection: 'row',
            gap: '2px',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <View
            style={{
              ...styles?.flexRowNoWrap,
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              maxWidth: '90%',
            }}
          >
            <View style={{ ...styles?.dot }}></View>
            {getQueLabel(que)}
          </View>
          <Text style={{ fontWeight: 600, fontSize: 10, marginLeft: '10px' }}>
            {key ? (key === true ? 'Yes' : 'No') : 'No'}
          </Text>
        </View>
      </View>
    );
  };

  const LegalPageAccordian = ({
    accorddianArray,
    accStateValue,
    isCumulativeSubSectionCount = false,
  }: {
    accorddianArray: Record<string, any>[];
    accStateValue: Record<string, any>;
    isCumulativeSubSectionCount?: boolean;
  }) => {
    let cumulativeSubSectionCount = 0;
    const filterAccData = getNonNullSections(accorddianArray, accStateValue);

    return (
      <View
        style={{
          ...styles.flexColumn,
          // backgroundColor: '#FAFAFA',
          // border: '1px solid black',
          // borderBottom: 'none',
        }}
      >
        {/* Table Header */}
        {/* <View
          wrap={false}
          style={{
            flexDirection: 'row',
            backgroundColor: '#114684',
            padding: '3px',
            color: 'white',
          }}
        >
          <Text style={{ flex: 1, fontWeight: 'bold', fontSize: 10 }}>
            Section Name
          </Text>

          <Text style={{ flex: 1, fontWeight: 'bold', fontSize: 10 }}>
            Value
          </Text>
        </View> */}

        {/* Table Content */}
        {filterAccData?.map((item: any, i: number) => {
          if (item?.subSection) {
            return (
              <View
                wrap={false}
                style={{ border: '1px solid black', borderBottom: 'none' }}
              >
                <Text
                  style={{
                    padding: '3px',
                    backgroundColor: '#114684',
                    fontWeight: 'bold',
                    fontSize: 10,
                    color: 'white',
                    textAlign: 'center',
                  }}
                >
                  {getAccordianSectionName[item?.name]}
                </Text>
                {item?.subSection?.map((subsec: any, index: number) => {
                  const displayIndex = cumulativeSubSectionCount + index + 1;

                  return (
                    <View key={index} style={{ borderTop: '1px solid black' }}>
                      <View
                        wrap={false}
                        style={{
                          flexDirection: 'row',
                          backgroundColor: '#edf5ff',
                          padding: '3px',
                          borderBottom: '1px solid black',
                        }}
                      >
                        <Text
                          style={{ flex: 1, fontWeight: 'bold', fontSize: 10 }}
                        >
                          {isCumulativeSubSectionCount
                            ? displayIndex
                            : index + 1}
                          . {getAccordianSubSectionName[subsec?.name]}
                        </Text>
                        {/* <Text style={{ flex: 1 }}></Text>
                        <Text style={{ flex: 1 }}></Text> */}
                      </View>

                      {/* Subsection Fields */}
                      {subsec?.fields?.map((field: any, fieldIndex: number) => {
                        const keyNames = field?.name?.split('.');
                        const fieldValue = keyNames.reduce(
                          (acc: any, key: any) => acc?.[key],
                          creditBureau
                        );

                        return (
                          !!fieldValue && (
                            <View
                              key={fieldIndex}
                              style={{
                                flexDirection: 'row',
                                padding: '3px',
                                borderBottom: '1px solid black',
                                backgroundColor: '#fff',
                              }}
                            >
                              <Text style={{ flex: 1, fontSize: 10 }}>
                                {field?.label}:
                              </Text>

                              <Text style={{ flex: 1, fontSize: 10 }}>
                                {field?.type === 'date'
                                  ? moment(fieldValue).format('MMM DD, YYYY')
                                  : fieldValue}
                              </Text>
                            </View>
                          )
                        );
                      })}
                    </View>
                  );
                })}
              </View>
            );
          } else {
            return (
              <View
                key={i}
                style={{
                  flexDirection: 'row',
                  padding: '4px',
                  textAlign: 'center',
                  border: '1px solid black',
                  // borderBottom: '1px solid black',
                }}
              >
                <Text style={{ flex: 1, fontWeight: 'bold', fontSize: 11 }}>
                  {getAccordianSectionName[item?.name]}
                </Text>
                <Text style={{ flex: 1 }}></Text>
                <Text style={{ flex: 1 }}></Text>
              </View>
            );
          }
        })}
      </View>
    );
  };

  // const isBankruptcyHistoryTable = (obj: any): boolean => {
  //   // Check if `hasBankruptcyHistory` is null or undefined
  //   if (!obj || !obj.hasBankruptcyHistory) {
  //     return false;
  //   }

  //   const { bankruptcyDetails, insolvencyDetails, liquidationDetails } =
  //     obj.hasBankruptcyHistory;

  //   // Check if all fields in `hasBankruptcyHistory` are null
  //   const allDetailsAreNull =
  //     bankruptcyDetails === null &&
  //     insolvencyDetails === null &&
  //     liquidationDetails === null;

  //   // Return true if any field is not null
  //   return !allDetailsAreNull;
  // };

  const getTableColumns = (
    tableData: Record<string, any>[],
    colName: string
  ) => {
    const baseColumn = {
      name:
        colName === 'Bankruptcy details'
          ? `Name`
          : `Filing ${tableData?.length}`,
    };
    const dynamicColumns = tableData?.map((item: any, i: number) => {
      return { name: `${colName} ${i + 1}` };
    });
    // return [baseColumn, ...dynamicColumns];
    return dynamicColumns;
  };

  // const LegalPageTable = ({
  //   columns,
  //   rows,
  //   tableData,
  // }: {
  //   columns: Record<string, any>[];
  //   rows: Record<string, any>[];
  //   tableData: Record<string, any>[];
  // }) => {
  //   return (
  //     <View style={styles.tableContainer}>
  //       <LegalPageTableHeader columns={columns} />
  //       <LegalPageTableTableBody rows={rows} tableData={tableData} />
  //     </View>
  //   );
  // };

  const chunkArray = (array: any[], size: number) =>
    array.reduce((acc, _, i) => {
      if (i % size === 0) acc.push(array.slice(i, i + size));
      return acc;
    }, [] as any[][]);

  const LegalPageTable = ({
    columns,
    rows,
    tableData,
    baseColumnHeaderName,
    tableName,
  }: {
    columns: Record<string, any>[];
    rows: Record<string, any>[];
    tableData: Record<string, any>[];
    baseColumnHeaderName: string;
    tableName: string;
  }) => {
    const baseColumn = { name: baseColumnHeaderName, key: 'name' };

    // Split columns into chunks of 3 to accommodate the base column, making 4 columns per chunk in total
    const chunkedColumns = [];
    const dataChunkSize = 3;
    for (let i = 0; i < columns.length; i += dataChunkSize) {
      chunkedColumns.push([baseColumn, ...columns.slice(i, i + dataChunkSize)]);
    }

    // Chunk `tableData` into groups of 3 rows
    const chunkedTableData = chunkArray(tableData, dataChunkSize);

    let filterRow: Record<string, any>[] = [];
    if (
      [
        'liensFiledAgainstCompany',
        'stateFederalTax',
        'outstandingJudgments',
        'outstandingSuits',
        'outstandingClaims',
        'liensFiledByCompany',
      ].includes(tableName)
    ) {
      const initialFilterRow = rows?.filter((field) => {
        if (field?.key === 'courtFilings') {
          return false;
        } else if (field?.parentKey) {
          return true;
        } else {
          // Check if there is any valid data for the field key in tableData
          return tableData?.some((detail) => {
            const value = detail[field.key];

            if (Array.isArray(value)) {
              return value.length > 0;
            } else if (typeof value === 'object' && value !== null) {
              return Object.values(value).some((val) => val !== null);
            } else if (typeof value === 'string') {
              return value !== null && value !== '';
            } else if (typeof value === 'boolean') {
              return value !== null;
            } else {
              return false;
            }
          });
        }
      });

      // Now, filter again to ensure no two consecutive objects or last object has parentKey: true
      filterRow = initialFilterRow.filter((field, index, arr) => {
        const isLastElement = index === arr.length - 1;
        const hasParentKey = field?.parentKey;

        // Check if the current item and the next item both have parentKey: true
        const hasNextParentKey =
          !isLastElement && arr[index + 1]?.parentKey === true;

        // Remove if current item is the last with parentKey: true or if consecutive parentKeys are found
        return !(hasParentKey && (hasNextParentKey || isLastElement));
      });
    } else {
      filterRow = rows?.filter((field) => {
        return tableData?.some(
          (detail) => detail[field.key] && detail[field.key] !== null
        );
      });
    }

    console.log('chunkedColumns', chunkedColumns, chunkedTableData, filterRow);

    // <View style={styles.tableContainer}>
    //     <View key={index}>
    //       <LegalPageTableHeader columns={colGroup} />
    //       <LegalPageTableTableBody
    //         rows={filterRow}
    //         tableData={chunkedTableData[index]}
    //       />
    //     </View>
    //   </View>

    return chunkedColumns.map((colGroup, index) => (
      <View wrap style={{ border: '1px solid black', borderBottom: 'none' }}>
        <View key={index} wrap={false}>
          <LegalPageTableHeader columns={colGroup} />
          <LegalPageTableTableBody
            rows={filterRow}
            tableData={chunkedTableData[index]}
          />
        </View>
      </View>
    ));
  };

  const LegalPageTableHeader = ({
    columns,
  }: {
    columns: Record<string, any>[];
  }) => (
    <View
      style={{
        flexDirection: 'row',
        borderBottom: '1px solid black',
        backgroundColor: '#114684',
        color: 'white',
        fontWeight: 'bold',
      }}
    >
      {columns?.map((item: any, i: number) => {
        return (
          <>
            <Text
              style={{
                padding: '3px',
                width: '25%',
                borderRight:
                  columns?.length - 1 === i ? 'none' : '1px solid black',
                flex: 1,
              }}
              // style={
              //   columns?.length - 1 === i
              //     ? [
              //         styles.tableCell,
              //         { ...styles.headerCell, borderTopRightRadius: 1 },
              //         styles.lastCell,
              //       ]
              //     : [
              //         styles.tableCell,
              //         i === 0
              //           ? { ...styles.headerCell, borderTopLeftRadius: 1 }
              //           : styles.headerCell,
              //       ]
              // }
            >
              {item?.name}
            </Text>
          </>
        );
      })}
    </View>
  );

  const LegalPageTableTableBody = ({
    rows,
    tableData,
  }: {
    rows: Record<string, any>[];
    tableData: Record<string, any>[];
  }) => {
    console.log('tableData', tableData, rows);

    return (
      <>
        {rows?.map((item: any, i: number) => {
          return (
            <View
              style={{
                flexDirection: 'row',
                borderBottom: '1px solid black',
                fontSize: 9,
              }}
              key={i}
              wrap={false}
            >
              <Text
                style={
                  rows?.length - 1 === i
                    ? [
                        {
                          borderBottomWidth: 0,
                          padding: '3px',
                          width: '25%',
                          flex: 1,
                          borderRight: '1px solid black',
                          fontWeight: 'bold',
                        },
                      ]
                    : item?.parentKey
                      ? [
                          {
                            textAlign: 'center',
                            borderRight: 'none',
                            backgroundColor: '#edf5ff',
                            fontWeight: 'bold',
                            padding: '3px',
                            fontSize: 10,
                            flex: 1,
                          },
                        ]
                      : [
                          {
                            padding: '3px',
                            width: '25%',
                            flex: 1,
                            fontWeight: 'bold',
                            borderRight: '1px solid black',
                          },
                        ]
                }
                // style={
                //   rows?.length - 1 === i
                //     ? [styles.tableCell, { borderBottomWidth: 0 }]
                //     : item?.parentKey
                //       ? [
                //           styles.tableCell,
                //           styles.lastCell,
                //           {
                //             textAlign: 'left',
                //             paddingLeft: 10,
                //             backgroundColor: '#edf5ff',
                //             fontWeight: 'bold',
                //           },
                //         ]
                //       : [styles.tableCell]
                // }
              >
                {item.name}
              </Text>
              {!item?.parentKey &&
                tableData?.map((data, index) => {
                  const value = data[item?.key];
                  let displayValue = '';
                  if (Array.isArray(value)) {
                    displayValue = value?.length > 0 ? value.join(', ') : 'N/A';
                  } else if (typeof value === 'object' && value !== null) {
                    displayValue = value?.value ? `${value?.value}` : 'N/A';
                  } else if (typeof value === 'boolean') {
                    displayValue = value ? 'Yes' : 'No';
                  } else {
                    displayValue = value ?? 'N/A';
                  }

                  return (
                    <Text
                      key={index}
                      style={
                        rows?.length - 1 === i &&
                        tableData?.length - 1 === index
                          ? [
                              {
                                padding: '3px', //last row, last column
                                width: '25%',
                                flex: 1,
                              },
                            ]
                          : rows?.length - 1 === i &&
                              tableData?.length - 1 !== index
                            ? [
                                {
                                  padding: '3px', //last row, other than last column
                                  width: '25%',
                                  flex: 1,
                                  borderRight: '1px solid black',
                                },
                              ]
                            : tableData?.length - 1 === index
                              ? [{ padding: '3px', width: '25%', flex: 1 }]
                              : [
                                  {
                                    padding: '3px', //other than last row, other than last column
                                    width: '25%',
                                    flex: 1,
                                    borderRight: '1px solid black',
                                  },
                                ]
                      }
                      // style={
                      //   rows?.length - 1 === i &&
                      //   tableData?.length - 1 === index
                      //     ? [
                      //         styles.tableCell,
                      //         styles.lastCell,
                      //         {
                      //           borderBottomWidth: 0,
                      //           backgroundColor: 'yellow',
                      //         },
                      //       ]
                      //     : rows?.length - 1 === i &&
                      //         tableData?.length - 1 !== index
                      //       ? [
                      //           styles.tableCell,
                      //           {
                      //             borderBottomWidth: 0,
                      //             backgroundColor: 'yellow',
                      //           },
                      //         ]
                      //       : tableData?.length - 1 === index
                      //         ? [styles.tableCell, styles.lastCell]
                      //         : [
                      //             styles.tableCell,
                      //             { backgroundColor: 'yellow' },
                      //           ]
                      // }
                    >
                      {displayValue}
                    </Text>
                  );
                })}
            </View>
          );
        })}
      </>
    );
  };

  // const getTableColumnsNew = (
  //   tableData: Record<string, any>[],
  //   colName: string
  // ) => {
  //   // const baseColumn = { name: `Filing ${tableData?.length}` };
  //   const dynamicColumns = tableData?.map((item: any, i: number) => {
  //     return { name: `${colName} ${i + 1}` };
  //   });
  //   // return [baseColumn, ...dynamicColumns];
  //   return dynamicColumns;
  // };

  // const LegalPageTableNew = ({
  //   columns,
  //   rows,
  //   tableData,
  // }: {
  //   columns: Record<string, any>[];
  //   rows: Record<string, any>[];
  //   tableData: Record<string, any>[];
  // }) => {
  //   return (
  //     <View style={styles.tableContainer}>
  //       <LegalPageTableHeaderNew columns={columns} tableData={tableData} />
  //       <LegalPageTableTableBodyNew columns={columns} tableData={tableData} />
  //     </View>
  //   );
  // };

  // const LegalPageTableHeaderNew = ({
  //   columns,
  //   tableData,
  // }: {
  //   columns: Record<string, any>[];
  //   tableData: Record<string, any>[];
  // }) => (
  //   <View style={styles.tableRow}>
  //     <Text style={[styles.tableCell, styles.headerCell]}>
  //       {`Filing ${tableData?.length}`}
  //     </Text>
  //     {/* columns?.filter(col => !col?.parentKey)?.map ///filter parentKey column*/}
  //     {columns
  //       ?.filter((col, _y) => !col?.parentKey && _y <= 3)
  //       ?.map((item: any, i: number) => {
  //         return (
  //           <>
  //             <Text
  //               style={
  //                 columns?.filter((col, _y) => !col?.parentKey && _y <= 3)
  //                   ?.length -
  //                   1 ===
  //                 i
  //                   ? [styles.tableCell, styles.headerCell, styles.lastCell]
  //                   : [styles.tableCell, styles.headerCell]
  //               }
  //             >
  //               {item?.name}
  //             </Text>
  //           </>
  //         );
  //       })}
  //   </View>
  // );

  // const LegalPageTableTableBodyNew = ({
  //   columns,
  //   tableData,
  // }: {
  //   columns: Record<string, any>[];
  //   tableData: Record<string, any>[];
  // }) => {
  //   console.log('tableData', tableData, columns);

  //   return tableData?.map((item, i) => {
  //     return (
  //       <View style={styles.tableRow} key={i}>
  //         <Text style={styles.tableCell}>{`Judgement ${i + 1}`}</Text>
  //         {columns
  //           ?.filter((col, _y) => !col?.parentKey && _y <= 3)
  //           ?.map((item1, index) => {
  //             const value = item1?.parentKey ? '' : item[item1?.key];
  //             let displayValue = '';
  //             if (Array.isArray(value)) {
  //               displayValue = value.join(', ');
  //             } else if (typeof value === 'object' && value !== null) {
  //               displayValue = value?.value ? `${value.value}` : 'N/A';
  //             } else {
  //               displayValue = value ?? 'N/A';
  //             }
  //             return (
  //               <Text
  //                 key={index}
  //                 style={
  //                   columns?.filter((col, _y) => !col?.parentKey && _y <= 3)
  //                     ?.length -
  //                     1 ===
  //                   index
  //                     ? [styles.tableCell, styles.lastCell]
  //                     : [styles.tableCell]
  //                 }
  //               >
  //                 {displayValue}
  //               </Text>
  //             );
  //           })}
  //       </View>
  //     );
  //   });
  // };

  const CreditBureauNew = () => {
    const showRelatedCompanies =
      creditBureau?.relatedCompanies?.hasRelatedCompanies &&
      creditBureau?.relatedCompanies?.companies?.length > 0 &&
      creditBureau?.relatedCompanies?.companies?.some(
        (company: any) =>
          company?.name ||
          company?.natureOfOperations ||
          company?.ownershipStructure
      );
    return (
      <View break>
        <View
          style={{
            ...styles?.flexColumn,
            gap: '15px',
          }}
        >
          <View
            style={{ width: '100%', display: 'flex', flexDirection: 'row' }}
            id="legalPage"
          >
            <Text style={{ ...styles?.formHeading, color: 'black' }}>2. </Text>
            <Text style={{ ...styles?.formHeading, color: '#114684' }}>
              {stepperFormLabel && stepperFormLabel?.legal?.trim() !== ''
                ? stepperFormLabel?.legal
                : 'Legal'}
            </Text>
          </View>

          <View
            style={{
              fontSize: 10,
            }}
          >
            <View>
              <View
                style={{
                  border: '1px solid black',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: '5px',
                  borderBottom:
                    creditBureau?.receivershipOrBankruptcyHistory?.hasHistory &&
                    creditBureau?.receivershipOrBankruptcyHistory
                      ?.hasHistory === true
                      ? 'none'
                      : '1px solid black',
                }}
              >
                <Text
                  wrap
                  style={{
                    flexBasis: '90%',
                    padding: '3px',
                    fontWeight: 'semibold',
                  }}
                >
                  Has the company, any prior company or any of the owners or
                  their spouses or a company owned by any of them ever been
                  placed in receivership or bankruptcy?
                </Text>
                <Text
                  style={{
                    flexBasis: '10%',
                    padding: '3px',
                    fontWeight: 'ultrabold',
                    textAlign: 'right',
                    flex: 1,
                  }}
                >
                  {creditBureau?.receivershipOrBankruptcyHistory?.hasHistory &&
                  creditBureau?.receivershipOrBankruptcyHistory?.hasHistory ===
                    true
                    ? 'Yes'
                    : 'No'}
                </Text>
              </View>
              {/* Bankruptcy */}
              {creditBureau?.receivershipOrBankruptcyHistory?.hasHistory &&
                creditBureau?.hasBankruptcyHistory?.bankruptcyDetails &&
                creditBureau?.hasBankruptcyHistory?.bankruptcyDetails?.length >
                  0 && (
                  <View>
                    <Text
                      style={{
                        fontSize: 12,
                        border: '1px solid black',
                        borderBottom: 'none',
                        borderTop: 'none',
                        fontWeight: 'extrabold',
                        padding: '3px 0',
                        textAlign: 'center',
                      }}
                    >
                      TOTAL BANKRUPTCY (
                      {
                        creditBureau?.hasBankruptcyHistory?.bankruptcyDetails
                          ?.length
                      }
                      )
                    </Text>
                    <LegalPageTable
                      columns={getTableColumns(
                        creditBureau?.hasBankruptcyHistory?.bankruptcyDetails,
                        'Bankruptcy Details'
                      )}
                      rows={hasBankruptcyHistoryTableFields}
                      tableData={
                        creditBureau?.hasBankruptcyHistory?.bankruptcyDetails
                      }
                      baseColumnHeaderName="Name"
                      tableName="Bankruptcy"
                    />
                  </View>
                )}
              {/* Insolvency */}
              {creditBureau?.receivershipOrBankruptcyHistory?.hasHistory &&
                creditBureau?.hasBankruptcyHistory?.insolvencyDetails &&
                creditBureau?.hasBankruptcyHistory?.insolvencyDetails?.length >
                  0 && (
                  <View>
                    <Text
                      style={{
                        fontSize: 12,
                        border: '1px solid black',
                        borderBottom: 'none',
                        borderTop: 'none',
                        fontWeight: 'extrabold',
                        padding: '3px 0',
                        textAlign: 'center',
                      }}
                    >
                      TOTAL INSOLVENCY (
                      {
                        creditBureau?.hasBankruptcyHistory?.insolvencyDetails
                          ?.length
                      }
                      )
                    </Text>
                    <LegalPageTable
                      columns={getTableColumns(
                        creditBureau?.hasBankruptcyHistory?.insolvencyDetails,
                        'Insolvency Details'
                      )}
                      rows={hasBankruptcyHistoryTableFields}
                      tableData={
                        creditBureau?.hasBankruptcyHistory?.insolvencyDetails
                      }
                      baseColumnHeaderName="Name"
                      tableName="Insolvency"
                    />
                  </View>
                )}
              {/* Liquidation */}
              {creditBureau?.receivershipOrBankruptcyHistory?.hasHistory &&
                creditBureau?.hasBankruptcyHistory?.liquidationDetails &&
                creditBureau?.hasBankruptcyHistory?.liquidationDetails?.length >
                  0 && (
                  <View>
                    <Text
                      style={{
                        fontSize: 12,
                        border: '1px solid black',
                        borderBottom: 'none',
                        borderTop: 'none',
                        fontWeight: 'extrabold',
                        padding: '3px 0',
                        textAlign: 'center',
                      }}
                    >
                      TOTAL LIQUIDATION (
                      {
                        creditBureau?.hasBankruptcyHistory?.liquidationDetails
                          ?.length
                      }
                      )
                    </Text>
                    <LegalPageTable
                      columns={getTableColumns(
                        creditBureau?.hasBankruptcyHistory?.liquidationDetails,
                        'Liquidation Details'
                      )}
                      rows={hasBankruptcyHistoryTableFields}
                      tableData={
                        creditBureau?.hasBankruptcyHistory?.liquidationDetails
                      }
                      baseColumnHeaderName="Name"
                      tableName="Liquidation"
                    />
                  </View>
                )}
              {/* Additional detail : Bankruptcy */}
              {creditBureau?.receivershipOrBankruptcyHistory?.details && (
                <View
                  style={{
                    border: '1px solid black',
                    borderTop: 'none',
                    padding: '3px',
                  }}
                >
                  <Text style={{ fontWeight: 'bold' }}>Additional Details</Text>
                  <Text
                    style={{
                      textAlign: 'justify',
                      marginTop: '5px',
                      fontSize: 9,
                    }}
                  >
                    {creditBureau?.receivershipOrBankruptcyHistory?.details}
                  </Text>
                </View>
              )}
            </View>
            {/* Guarantor */}
            <View>
              <View
                style={{
                  border: '1px solid black',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: '5px',
                  borderBottom:
                    creditBureau?.guaranteesForOtherParties?.hasGuarantees &&
                    creditBureau?.guaranteesForOtherParties?.hasGuarantees ===
                      true
                      ? 'none'
                      : '1px solid black',
                }}
              >
                <Text
                  wrap
                  style={{
                    flexBasis: '90%',
                    padding: '3px',
                    fontWeight: 'semibold',
                  }}
                >
                  Has the company, its owners or any of the related companies
                  provided any guarantees for the indebtedness of any other
                  party, outside Corporation or individuals?
                </Text>
                <Text
                  style={{
                    flexBasis: '10%',
                    padding: '3px',
                    fontWeight: 'ultrabold',
                    textAlign: 'right',
                    flex: 1,
                  }}
                >
                  {creditBureau?.guaranteesForOtherParties?.hasGuarantees &&
                  creditBureau?.guaranteesForOtherParties?.hasGuarantees ===
                    true
                    ? 'Yes'
                    : 'No'}
                </Text>
              </View>
              {creditBureau?.guaranteesForOtherParties?.hasGuarantees &&
                IsNonNullOrObject(creditBureau?.hasGuarantees) && (
                  <LegalPageAccordian
                    accorddianArray={guaranteesForOtherPartiesAcc}
                    accStateValue={creditBureau?.hasGuarantees}
                    isCumulativeSubSectionCount={true}
                  />
                )}
              {creditBureau?.guaranteesForOtherParties?.details && (
                <View
                  style={{
                    border: '1px solid black',
                    borderTop: 'none',
                    padding: '3px',
                  }}
                >
                  <Text style={{ fontWeight: 'bold' }}>Additional Details</Text>
                  <Text
                    style={{
                      textAlign: 'justify',
                      marginTop: '5px',
                      fontSize: 9,
                    }}
                  >
                    {creditBureau?.guaranteesForOtherParties?.details}
                  </Text>
                </View>
              )}
            </View>

            {/* Related companies */}
            <View>
              <View
                wrap={false}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: '5px',
                  border: '1px solid black',
                }}
              >
                <Text
                  style={{
                    flexBasis: '90%',
                    padding: '3px',
                    fontWeight: 'semibold',
                  }}
                >
                  Does the company have one or more related companies?
                </Text>
                <Text
                  style={{
                    flexBasis: '10%',
                    padding: '3px',
                    fontWeight: 'ultrabold',
                    textAlign: 'right',
                    flex: 1,
                  }}
                >
                  {creditBureau?.relatedCompanies?.hasRelatedCompanies &&
                  creditBureau?.relatedCompanies?.hasRelatedCompanies === true
                    ? 'Yes'
                    : 'No'}
                </Text>
              </View>
              {creditBureau?.relatedCompanies?.hasRelatedCompanies &&
                IsNonNullOrObject(creditBureau?.hasRelatedCompanies) && (
                  <LegalPageAccordian
                    accorddianArray={relatedCompaniesAcc}
                    accStateValue={creditBureau?.hasRelatedCompanies}
                  />
                )}
              {creditBureau?.relatedCompanies?.companies &&
                creditBureau?.relatedCompanies?.companies.length > 0 &&
                !isEveryObjKeyNull(
                  creditBureau?.relatedCompanies?.companies
                ) && (
                  <View
                    wrap={false}
                    style={{ border: '1px solid black', borderBottom: 'none' }}
                  >
                    <Text
                      style={{
                        padding: '3px',
                        backgroundColor: '#114684',
                        color: 'white',
                        fontWeight: 'bold',
                        fontSize: 10,
                        textAlign: 'center',
                      }}
                    >
                      Other Related Companies
                    </Text>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        borderBottom: '1px solid black',
                      }}
                    >
                      <Text
                        style={{
                          padding: '3px',
                          backgroundColor: '#edf5ff',
                          fontWeight: 'bold',
                          textAlign: 'center',
                          borderRight: '1px solid black',
                          flexBasis: '35%',
                        }}
                      >
                        Name
                      </Text>
                      <Text
                        style={{
                          padding: '3px',
                          backgroundColor: '#edf5ff',
                          fontWeight: 'bold',
                          textAlign: 'center',
                          borderRight: '1px solid black',
                          flexBasis: '35%',
                        }}
                      >
                        Nature of Operations
                      </Text>
                      <Text
                        style={{
                          padding: '3px',
                          backgroundColor: '#edf5ff',
                          fontWeight: 'bold',
                          textAlign: 'center',
                          flexBasis: '30%',
                        }}
                      >
                        Ownership Structure (%)
                      </Text>
                    </View>
                    {creditBureau?.relatedCompanies?.companies?.map(
                      (item: any, index: number) => (
                        <View
                          key={index}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            borderBottom: '1px solid black',
                          }}
                        >
                          <Text
                            style={{
                              padding: '3px',
                              textAlign: 'center',
                              borderRight: '1px solid black',
                              flexBasis: '35%',
                            }}
                          >
                            {item?.name}
                          </Text>
                          <Text
                            style={{
                              padding: '3px',
                              textAlign: 'center',
                              borderRight: '1px solid black',
                              flexBasis: '35%',
                            }}
                          >
                            {item?.natureOfOperations}
                          </Text>
                          <Text
                            style={{
                              padding: '3px',
                              textAlign: 'center',

                              flexBasis: '30%',
                            }}
                          >
                            {item?.ownershipStructure}
                          </Text>
                        </View>
                      )
                    )}
                  </View>
                )}
            </View>

            {/* Change in management */}
            <View>
              <View
                wrap={false}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: '5px',
                  border: '1px solid black',
                  borderBottom:
                    creditBureau?.changesInManagement?.hasChanges &&
                    creditBureau?.changesInManagement?.hasChanges === true
                      ? 'none'
                      : '1px solid black',
                }}
              >
                <Text
                  style={{
                    flexBasis: '90%',
                    padding: '3px',
                    fontWeight: 'semibold',
                  }}
                >
                  Have there been any changes in the control, ownership, or
                  management of the company in the past 3 years or are any such
                  changes impending?
                </Text>
                <Text
                  style={{
                    flexBasis: '10%',
                    padding: '3px',
                    fontWeight: 'ultrabold',
                    textAlign: 'right',
                    flex: 1,
                  }}
                >
                  {creditBureau?.changesInManagement?.hasChanges &&
                  creditBureau?.changesInManagement?.hasChanges === true
                    ? 'Yes'
                    : 'No'}
                </Text>
              </View>
              {creditBureau?.changesInManagement?.hasChanges &&
                IsNonNullOrObject(creditBureau?.hasChangesInManagement) && (
                  <LegalPageAccordian
                    accorddianArray={changesInManagementAcc}
                    accStateValue={creditBureau?.hasChangesInManagement}
                    isCumulativeSubSectionCount={true}
                  />
                )}
              {creditBureau?.changesInManagement?.explanation && (
                <View
                  style={{
                    border: '1px solid black',
                    borderTop: 'none',
                    padding: '3px',
                  }}
                >
                  <Text style={{ fontWeight: 'bold' }}>Additional Details</Text>
                  <Text
                    style={{
                      textAlign: 'justify',
                      marginTop: '5px',
                      fontSize: 9,
                    }}
                  >
                    {creditBureau?.changesInManagement?.explanation}
                  </Text>
                </View>
              )}
            </View>

            {/* liens Filed Against Company */}
            <View
              break={
                creditBureau?.liensFiledAgainstCompany?.hasLiens &&
                creditBureau?.liensFiledAgainstCompany?.hasLiens === true
              }
            >
              <View
                // wrap={false}
                style={{
                  border: '1px solid black',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: '5px',
                  borderBottom:
                    creditBureau?.liensFiledAgainstCompany?.hasLiens &&
                    creditBureau?.liensFiledAgainstCompany?.hasLiens === true
                      ? 'none'
                      : '1px solid black',
                }}
              >
                <Text
                  style={{
                    flexBasis: '90%',
                    padding: '3px',
                    fontWeight: 'semibold',
                  }}
                >
                  Are there any liens for labour or material filed against you
                  by anyone?
                </Text>
                <Text
                  style={{
                    flexBasis: '10%',
                    padding: '3px',
                    fontWeight: 'ultrabold',
                    textAlign: 'right',
                    flex: 1,
                  }}
                >
                  {creditBureau?.liensFiledAgainstCompany?.hasLiens &&
                  creditBureau?.liensFiledAgainstCompany?.hasLiens === true
                    ? 'Yes'
                    : 'No'}
                </Text>
              </View>
              {creditBureau?.liensFiledAgainstCompany?.hasLiens &&
                creditBureau?.hasLiensFiledAgainstCompany &&
                creditBureau?.hasLiensFiledAgainstCompany?.length > 0 && (
                  <View>
                    <LegalPageTable
                      columns={getTableColumns(
                        creditBureau?.hasLiensFiledAgainstCompany,
                        'Lien'
                      )}
                      rows={liensFiledAgainstCompanyTableFields}
                      tableData={creditBureau?.hasLiensFiledAgainstCompany}
                      baseColumnHeaderName={`Total Liens (${creditBureau?.hasLiensFiledAgainstCompany?.length})`}
                      tableName="liensFiledAgainstCompany"
                    />
                  </View>
                )}
              {creditBureau?.liensFiledAgainstCompany?.details && (
                <View
                  style={{
                    border: '1px solid black',
                    borderTop: 'none',
                    padding: '3px',
                  }}
                >
                  <Text style={{ fontWeight: 'bold' }}>Additional Details</Text>
                  <Text
                    style={{
                      textAlign: 'justify',
                      marginTop: '5px',
                      fontSize: 9,
                    }}
                  >
                    {creditBureau?.liensFiledAgainstCompany?.details}
                  </Text>
                </View>
              )}
            </View>

            {/* state Federal Tax */}
            {accountingSystem?.country === 'United States' && (
              <View
                break={
                  creditBureau?.stateFederalTax &&
                  creditBureau?.stateFederalTax === true
                }
              >
                <View
                  // wrap={false}

                  style={{
                    border: '1px solid black',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    gap: '5px',
                    borderBottom:
                      creditBureau?.stateFederalTax &&
                      creditBureau?.stateFederalTax === true
                        ? 'none'
                        : '1px solid black',
                  }}
                >
                  <Text
                    style={{
                      flexBasis: '90%',
                      padding: '3px',
                      fontWeight: 'semibold',
                    }}
                  >
                    Are there any state or federal tax liens that are open?
                  </Text>
                  <Text
                    style={{
                      flexBasis: '10%',
                      padding: '3px',
                      fontWeight: 'ultrabold',
                      textAlign: 'right',
                      flex: 1,
                    }}
                  >
                    {creditBureau?.stateFederalTax &&
                    creditBureau?.stateFederalTax === true
                      ? 'Yes'
                      : 'No'}
                  </Text>
                </View>
                {creditBureau?.stateFederalTax &&
                  creditBureau?.hasStateFederalTax &&
                  creditBureau?.hasStateFederalTax?.length > 0 && (
                    <View>
                      <LegalPageTable
                        columns={getTableColumns(
                          creditBureau?.hasStateFederalTax,
                          'Lien'
                        )}
                        rows={stateFederalTaxTableFields}
                        tableData={creditBureau?.hasStateFederalTax}
                        baseColumnHeaderName={`Total State / Federal Tax (${creditBureau?.hasStateFederalTax?.length})`}
                        tableName="stateFederalTax"
                      />
                    </View>
                  )}
                {creditBureau?.paymentPlan && (
                  <View
                    style={{
                      border: '1px solid black',
                      borderTop: 'none',
                      padding: '3px',
                    }}
                  >
                    <Text style={{ fontWeight: 'bold' }}>
                      Is there a payment plan in place? Provide a copy of the
                      agreement or documentation from the taxing entity.
                    </Text>
                    <Text
                      style={{
                        textAlign: 'justify',
                        marginTop: '5px',
                        fontSize: 9,
                      }}
                    >
                      {creditBureau?.paymentPlan}
                    </Text>
                  </View>
                )}
              </View>
            )}

            {/* judgement,claims,suits */}
            <View
              break={
                creditBureau?.outstandingJudgments?.hasOutstandingJudgments &&
                creditBureau?.outstandingJudgments?.hasOutstandingJudgments ===
                  true
              }
            >
              <View
                // wrap={false}
                style={{
                  border: '1px solid black',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: '5px',
                  borderBottom:
                    creditBureau?.outstandingJudgments
                      ?.hasOutstandingJudgments &&
                    creditBureau?.outstandingJudgments
                      ?.hasOutstandingJudgments === true
                      ? 'none'
                      : '1px solid black',
                }}
              >
                <Text
                  style={{
                    flexBasis: '90%',
                    padding: '3px',
                    fontWeight: 'semibold',
                  }}
                >
                  Are there any judgments, suits or claims outstanding against
                  your company its officers or any company of either?
                </Text>
                <Text
                  style={{
                    flexBasis: '10%',
                    padding: '3px',
                    fontWeight: 'ultrabold',
                    textAlign: 'right',
                    flex: 1,
                  }}
                >
                  {creditBureau?.outstandingJudgments
                    ?.hasOutstandingJudgments &&
                  creditBureau?.outstandingJudgments
                    ?.hasOutstandingJudgments === true
                    ? 'Yes'
                    : 'No'}
                </Text>
              </View>
              {creditBureau?.outstandingJudgments?.hasOutstandingJudgments &&
                creditBureau?.hasOutstandingJudgments &&
                creditBureau?.hasOutstandingJudgments?.length > 0 && (
                  <View>
                    <LegalPageTable
                      columns={getTableColumns(
                        creditBureau?.hasOutstandingJudgments,
                        'Judgement'
                      )}
                      rows={outstandingJudgementsTableFields}
                      tableData={creditBureau?.hasOutstandingJudgments}
                      baseColumnHeaderName={`Total Judgements (${creditBureau?.hasOutstandingJudgments?.length})`}
                      tableName="outstandingJudgments"
                    />
                  </View>
                )}
              {creditBureau?.outstandingJudgments?.hasOutstandingJudgments &&
                creditBureau?.hasOutstandingSuits &&
                creditBureau?.hasOutstandingSuits?.length > 0 && (
                  <View
                    break={
                      creditBureau?.hasOutstandingJudgments &&
                      creditBureau?.hasOutstandingJudgments?.length > 0
                    }
                  >
                    <LegalPageTable
                      columns={getTableColumns(
                        creditBureau?.hasOutstandingSuits,
                        'Suit'
                      )}
                      rows={outstandingSuitsTableFields}
                      tableData={creditBureau?.hasOutstandingSuits}
                      baseColumnHeaderName={`Total Suits (${creditBureau?.hasOutstandingSuits?.length})`}
                      tableName="outstandingSuits"
                    />
                  </View>
                )}
              {creditBureau?.outstandingJudgments?.hasOutstandingJudgments &&
                creditBureau?.hasOutstandingClaims &&
                creditBureau?.hasOutstandingClaims?.length > 0 && (
                  <View
                    break={
                      (creditBureau?.hasOutstandingSuits &&
                        creditBureau?.hasOutstandingSuits?.length > 0) ||
                      (creditBureau?.hasOutstandingJudgments &&
                        creditBureau?.hasOutstandingJudgments?.length > 0)
                    }
                  >
                    <LegalPageTable
                      columns={getTableColumns(
                        creditBureau?.hasOutstandingClaims,
                        'Claim'
                      )}
                      rows={outstandingSuitsTableFields}
                      tableData={creditBureau?.hasOutstandingClaims}
                      baseColumnHeaderName={`Total Claims (${creditBureau?.hasOutstandingClaims?.length})`}
                      tableName="outstandingClaims"
                    />
                  </View>
                )}
              {creditBureau?.outstandingJudgments?.details && (
                <View
                  style={{
                    border: '1px solid black',
                    borderTop: 'none',
                    padding: '3px',
                  }}
                >
                  <Text style={{ fontWeight: 'bold' }}>Additional Details</Text>
                  <Text
                    style={{
                      textAlign: 'justify',
                      marginTop: '5px',
                      fontSize: 9,
                    }}
                  >
                    {creditBureau?.outstandingJudgments?.details}
                  </Text>
                </View>
              )}
            </View>

            {/* surety bond claims */}
            <View>
              <View
                wrap={false}
                style={{
                  border: '1px solid black',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: '5px',
                  borderBottom:
                    creditBureau?.bondClaims &&
                    creditBureau?.bondClaims === true
                      ? 'none'
                      : '1px solid black',
                }}
              >
                <Text
                  style={{
                    flexBasis: '90%',
                    padding: '3px',
                    fontWeight: 'semibold',
                  }}
                >
                  Are there any claims made against a surety bond?
                </Text>
                <Text
                  style={{
                    flexBasis: '10%',
                    padding: '3px',
                    fontWeight: 'ultrabold',
                    textAlign: 'right',
                    flex: 1,
                  }}
                >
                  {creditBureau?.bondClaims && creditBureau?.bondClaims === true
                    ? 'Yes'
                    : 'No'}
                </Text>
              </View>
              {creditBureau?.bondClaims && (
                <View
                  wrap={false}
                  style={{
                    border: '1px solid black',
                    borderTop: 'none',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    gap: '5px',
                    borderBottom:
                      creditBureau?.claimsPaid &&
                      creditBureau?.claimsPaid === true
                        ? 'none'
                        : '1px solid black',
                  }}
                >
                  <Text
                    style={{
                      flexBasis: '90%',
                      padding: '3px',
                      fontWeight: 'semibold',
                    }}
                  >
                    Were the claims paid?
                  </Text>
                  <Text
                    style={{
                      flexBasis: '10%',
                      padding: '3px',
                      fontWeight: 'ultrabold',
                      textAlign: 'right',
                      flex: 1,
                    }}
                  >
                    {creditBureau?.claimsPaid &&
                    creditBureau?.claimsPaid === true
                      ? 'Yes'
                      : 'No'}
                  </Text>
                </View>
              )}
              {creditBureau?.bondClaims && creditBureau?.claimsPaid && (
                <View
                  wrap={false}
                  style={{
                    border: '1px solid black',
                    borderTop: 'none',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    gap: '5px',
                    borderBottom:
                      creditBureau?.suretyMade &&
                      creditBureau?.suretyMade === true
                        ? 'none'
                        : '1px solid black',
                  }}
                >
                  <Text
                    style={{
                      flexBasis: '90%',
                      padding: '3px',
                      fontWeight: 'semibold',
                    }}
                  >
                    Was the surety made whole?
                  </Text>
                  <Text
                    style={{
                      flexBasis: '10%',
                      padding: '3px',
                      fontWeight: 'ultrabold',
                      textAlign: 'right',
                      flex: 1,
                    }}
                  >
                    {creditBureau?.suretyMade &&
                    creditBureau?.suretyMade === true
                      ? 'Yes'
                      : 'No'}
                  </Text>
                </View>
              )}

              {creditBureau?.bondClaims &&
                creditBureau?.claimsPaid &&
                creditBureau?.suretyMade === false &&
                IsNonNullOrObject(creditBureau?.hasClaimsSuretybond) && (
                  <LegalPageAccordian
                    accorddianArray={bondClaimsAcc}
                    accStateValue={creditBureau?.hasClaimsSuretybond}
                  />
                )}
              {creditBureau?.bondClaims &&
                creditBureau?.claimsPaid &&
                creditBureau?.suretyMade === false && (
                  <View
                    style={{
                      border: '1px solid black',
                      borderTop: 'none',
                      padding: '3px',
                    }}
                  >
                    <Text style={{ fontWeight: 'bold' }}>
                      Additional Details
                    </Text>
                    <Text
                      style={{
                        textAlign: 'justify',
                        marginTop: '5px',
                        fontSize: 9,
                      }}
                    >
                      {creditBureau?.whyNotMade}
                    </Text>
                  </View>
                )}
            </View>

            {/* disputes */}
            <View>
              <View
                wrap={false}
                style={{
                  border: '1px solid black',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: '5px',
                  borderBottom:
                    creditBureau?.disputesWithOthers?.hasDisputes &&
                    creditBureau?.disputesWithOthers?.hasDisputes === true
                      ? 'none'
                      : '1px solid black',
                }}
              >
                <Text
                  style={{
                    flexBasis: '90%',
                    padding: '3px',
                    fontWeight: 'semibold',
                  }}
                >
                  Are others disputing any work which you did or failed to do or
                  any account which you presented to them?
                </Text>
                <Text
                  style={{
                    flexBasis: '10%',
                    padding: '3px',
                    fontWeight: 'ultrabold',
                    textAlign: 'right',
                    flex: 1,
                  }}
                >
                  {creditBureau?.disputesWithOthers?.hasDisputes &&
                  creditBureau?.disputesWithOthers?.hasDisputes === true
                    ? 'Yes'
                    : 'No'}
                </Text>
              </View>
              {creditBureau?.disputesWithOthers?.details && (
                <View
                  style={{
                    border: '1px solid black',
                    borderTop: 'none',
                    padding: '3px',
                  }}
                >
                  <Text style={{ fontWeight: 'bold' }}>Additional Details</Text>
                  <Text
                    style={{
                      textAlign: 'justify',
                      marginTop: '5px',
                      fontSize: 9,
                    }}
                  >
                    {creditBureau?.disputesWithOthers?.details}
                  </Text>
                </View>
              )}
            </View>

            {/* liens by 3rd party */}
            <View
              break={
                creditBureau?.liensFiledByCompany?.hasLiens &&
                creditBureau?.liensFiledByCompany?.hasLiens === true
              }
            >
              <View
                // wrap={false}
                style={{
                  border: '1px solid black',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: '5px',
                  borderBottom:
                    creditBureau?.liensFiledByCompany?.hasLiens &&
                    creditBureau?.liensFiledByCompany?.hasLiens === true
                      ? 'none'
                      : '1px solid black',
                }}
              >
                <Text
                  style={{
                    flexBasis: '90%',
                    padding: '3px',
                    fontWeight: 'semibold',
                  }}
                >
                  Are there any liens for labour or material filed by you
                  against a third party?
                </Text>
                <Text
                  style={{
                    flexBasis: '10%',
                    padding: '3px',
                    fontWeight: 'ultrabold',
                    textAlign: 'right',
                    flex: 1,
                  }}
                >
                  {creditBureau?.liensFiledByCompany?.hasLiens &&
                  creditBureau?.liensFiledByCompany?.hasLiens === true
                    ? 'Yes'
                    : 'No'}
                </Text>
              </View>
              {creditBureau?.liensFiledByCompany?.hasLiens &&
                creditBureau?.hasLiensFiledByCompany &&
                creditBureau?.hasLiensFiledByCompany?.length > 0 && (
                  <View>
                    <LegalPageTable
                      columns={getTableColumns(
                        creditBureau?.hasLiensFiledByCompany,
                        'Lien'
                      )}
                      rows={liensFiledAgainstCompanyTableFields}
                      tableData={creditBureau?.hasLiensFiledByCompany}
                      baseColumnHeaderName={`Total Liens (${creditBureau?.hasLiensFiledByCompany?.length})`}
                      tableName="liensFiledByCompany"
                    />
                  </View>
                )}
              {creditBureau?.liensFiledByCompany?.details && (
                <View
                  style={{
                    border: '1px solid black',
                    borderTop: 'none',
                    padding: '3px',
                  }}
                >
                  <Text style={{ fontWeight: 'bold' }}>Additional Details</Text>
                  <Text
                    style={{
                      textAlign: 'justify',
                      marginTop: '5px',
                      fontSize: 9,
                    }}
                  >
                    {creditBureau?.liensFiledByCompany?.details}
                  </Text>
                </View>
              )}
            </View>

            {/* dispute with supplier */}
            <View>
              <View
                wrap={false}
                style={{
                  border: '1px solid black',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: '5px',
                  borderBottom:
                    creditBureau?.disputesWithSuppliers?.hasDisputes &&
                    creditBureau?.disputesWithSuppliers?.hasDisputes === true
                      ? 'none'
                      : '1px solid black',
                }}
              >
                <Text
                  style={{
                    flexBasis: '90%',
                    padding: '3px',
                    fontWeight: 'semibold',
                  }}
                >
                  Is the company presently engaged in any dispute with suppliers
                  or sub trades relative to their work or materials?
                </Text>
                <Text
                  style={{
                    flexBasis: '10%',
                    padding: '3px',
                    fontWeight: 'ultrabold',
                    textAlign: 'right',
                    flex: 1,
                  }}
                >
                  {creditBureau?.disputesWithSuppliers?.hasDisputes &&
                  creditBureau?.disputesWithSuppliers?.hasDisputes === true
                    ? 'Yes'
                    : 'No'}
                </Text>
              </View>
              {creditBureau?.disputesWithSuppliers?.hasDisputes &&
                creditBureau?.disputesWithSuppliers?.hasDisputes === true && (
                  <View
                    style={{
                      border: '1px solid black',
                      borderTop: 'none',
                      padding: '3px',
                    }}
                  >
                    <Text style={{ fontWeight: 'bold' }}>
                      Additional Details
                    </Text>
                    <Text
                      style={{
                        textAlign: 'justify',
                        marginTop: '5px',
                        fontSize: 9,
                      }}
                    >
                      {creditBureau?.disputesWithSuppliers?.details}
                    </Text>
                    <Text style={{ fontWeight: 'bold', marginTop: '5px' }}>
                      By what means is it funded? Provide a copy of the
                      agreement.
                    </Text>
                    <Text
                      style={{
                        textAlign: 'justify',
                        marginTop: '5px',
                        fontSize: 9,
                      }}
                    >
                      {creditBureau?.disputesWithSuppliers?.fundingMeans}
                    </Text>
                  </View>
                )}
            </View>

            {/* buy/sell agreement */}
            <View>
              <View
                wrap={false}
                style={{
                  border: '1px solid black',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: '5px',
                  borderBottom:
                    creditBureau?.buySellAgreement &&
                    creditBureau?.buySellAgreement === true
                      ? 'none'
                      : '1px solid black',
                }}
              >
                <Text
                  style={{
                    flexBasis: '90%',
                    padding: '3px',
                    fontWeight: 'semibold',
                  }}
                >
                  Is there a formal Buy/Sell agreement?
                </Text>
                <Text
                  style={{
                    flexBasis: '10%',
                    padding: '3px',
                    fontWeight: 'ultrabold',
                    textAlign: 'right',
                    flex: 1,
                  }}
                >
                  {creditBureau?.buySellAgreement &&
                  creditBureau?.buySellAgreement === true
                    ? 'Yes'
                    : 'No'}
                </Text>
              </View>
              {creditBureau?.buySellAgreement &&
                IsNonNullOrObject(creditBureau?.hasBuySellAgreement) && (
                  <LegalPageAccordian
                    accorddianArray={buySellAgreementAcc}
                    accStateValue={creditBureau?.hasBuySellAgreement}
                  />
                )}
              {creditBureau?.agreementDetails && (
                <View
                  style={{
                    border: '1px solid black',
                    borderTop: 'none',
                    padding: '3px',
                  }}
                >
                  <Text style={{ fontWeight: 'bold' }}>
                    Provide further details regarding the agreement
                  </Text>
                  <Text
                    style={{
                      textAlign: 'justify',
                      marginTop: '5px',
                      fontSize: 9,
                    }}
                  >
                    {creditBureau?.agreementDetails}
                  </Text>
                </View>
              )}
            </View>
          </View>

          {/* <View
            style={{
              ...styles?.flexColumn,
              
              padding: '10px 0',
            }}
          >
            
          </View> */}
        </View>
      </View>
    );
  };

  const PresonalFinancial = () => (
    <View break>
      {financialInfo?.ownersFinancialInfo &&
        financialInfo?.ownersFinancialInfo?.length > 0 && (
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '15px',
            }}
          >
            {/* Section Header */}
            <View
              style={{ display: 'flex', flexDirection: 'row' }}
              id="personalFinancial"
            >
              <Text style={{ ...styles?.formHeading, color: 'black' }}>
                3.{' '}
              </Text>
              <Text style={{ ...styles?.formHeading, color: '#114684' }}>
                {stepperFormLabel &&
                stepperFormLabel?.personalFinancial?.trim() !== ''
                  ? stepperFormLabel?.personalFinancial
                  : 'Personal Financial'}
              </Text>
            </View>

            {financialInfo?.ownersFinancialInfo?.map(
              (owner: any, i: number) => {
                return (
                  <View key={i}>
                    {(owner?.ownerInfo?.nameHoldco ||
                      owner?.ownerInfo?.dateOfBirth ||
                      owner?.ownerInfo?.position ||
                      owner?.ownerInfo?.inPositionSince ||
                      owner?.ownerInfo?.ownershipPercentage ||
                      isPFSection(owner)) && (
                      <View wrap={false}>
                        <View
                          style={{
                            fontSize: 14,
                            fontWeight: 'bold',
                            marginBottom: '10px',
                          }}
                        >
                          {/* {owner?.ownerInfo?.nameHoldco && ( */}
                          <Text id={`personalFinancialPage-${i + 1}`}>
                            {`${toRoman(i + 1)})`}{' '}
                            {owner?.ownerInfo?.nameHoldco
                              ? owner?.ownerInfo?.nameHoldco
                              : `Owner ${i + 1}`}
                          </Text>
                          {/*  )} */}
                        </View>

                        <View
                          style={{
                            border: '1px solid black',
                            borderBottom: 'none',
                            borderTop: 'none',
                          }}
                        >
                          <View
                            style={{
                              fontSize: 12,
                              backgroundColor: '#114684',
                              color: 'white',
                              fontWeight: 'bold',
                              padding: '3px 0',
                              textAlign: 'center',
                            }}
                          >
                            <Text>Owner Info</Text>
                          </View>
                          {/* Owner Details */}

                          <View
                            style={{
                              padding: '3px',
                              borderBottom: '1px solid black',
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              gap: '5px',
                            }}
                          >
                            <Text>Name / Holdco</Text>
                            <Text
                              style={{
                                fontWeight: 'bold',
                                flex: 1,
                                textAlign: 'right',
                              }}
                            >
                              {owner?.ownerInfo?.nameHoldco
                                ? owner?.ownerInfo?.nameHoldco
                                : `Owner ${i + 1}`}
                            </Text>
                          </View>

                          {owner?.ownerInfo?.dateOfBirth && (
                            <View
                              style={{
                                padding: '3px',
                                borderBottom: '1px solid black',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                gap: '5px',
                              }}
                            >
                              <Text>Date of Birth</Text>
                              <Text
                                style={{
                                  fontWeight: 'bold',
                                  flex: 1,
                                  textAlign: 'right',
                                }}
                              >
                                {moment(owner?.ownerInfo?.dateOfBirth).format(
                                  'MMM DD, YYYY'
                                )}
                              </Text>
                            </View>
                          )}

                          {owner?.ownerInfo?.position && (
                            <View
                              style={{
                                padding: '3px',
                                borderBottom: '1px solid black',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                gap: '5px',
                              }}
                            >
                              <Text>Position at {qboCompanyName || '-'}</Text>
                              <Text
                                style={{
                                  fontWeight: 'bold',
                                  flex: 1,
                                  textAlign: 'right',
                                }}
                              >
                                {owner?.ownerInfo?.position}
                              </Text>
                            </View>
                          )}

                          {owner?.ownerInfo?.inPositionSince && (
                            <View
                              style={{
                                padding: '3px',
                                borderBottom: '1px solid black',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                gap: '5px',
                              }}
                            >
                              <Text>In Position Since</Text>
                              <Text
                                style={{
                                  fontWeight: 'bold',
                                  flex: 1,
                                  textAlign: 'right',
                                }}
                              >
                                {moment(
                                  owner?.ownerInfo?.inPositionSince
                                ).format('MMM DD, YYYY')}
                              </Text>
                            </View>
                          )}

                          {owner?.ownerInfo?.ownershipPercentage && (
                            <View
                              style={{
                                padding: '3px',
                                borderBottom: '1px solid black',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                gap: '5px',
                              }}
                            >
                              <Text>
                                % Ownership of {qboCompanyName || '-'}
                              </Text>
                              <Text
                                style={{
                                  fontWeight: 'bold',
                                  flex: 1,
                                  textAlign: 'right',
                                }}
                              >
                                {owner?.ownerInfo?.ownershipPercentage} %
                              </Text>
                            </View>
                          )}
                        </View>
                      </View>
                    )}

                    {isPFSection(owner) && (
                      <View style={{ margin: '15px 0' }}>
                        <View>
                          <Text
                            style={{
                              fontWeight: 'extrabold',
                              color: '#114684',
                              fontSize: 11,
                              textAlign: 'center',
                              marginBottom: '8px',
                            }}
                          >
                            FINANCIAL INFORMATION
                          </Text>
                          <Text
                            style={{
                              fontWeight: 'semibold',
                              color: 'black',
                              fontSize: 9,
                              textAlign: 'justify',
                              marginBottom: '8px',
                            }}
                          >
                            Supporting documents such as RRSP statements, tax
                            assessments, company financial statements, bank
                            statements etc. should accompany the information,
                            otherwise they will be discounted from the total
                            assets
                          </Text>
                        </View>

                        {(owner?.assetsResidential?.address ||
                          owner?.assetsResidential?.value ||
                          !isEveryObjKeyNullRemoveId(owner?.assetsRealEstate) ||
                          !isEveryObjKeyNullAssets(owner?.assetsPrice)) && (
                          <View
                            style={{
                              border: '1px solid black',
                            }}
                          >
                            <View
                              style={{
                                fontSize: 12,
                                // backgroundColor: '#114684',
                                // color: 'white',
                                fontWeight: 'extrabold',
                                padding: '3px 0',
                                textAlign: 'center',
                                borderBottom: '1px solid black',
                              }}
                            >
                              <Text>ASSETS</Text>
                            </View>
                            {(owner?.assetsResidential?.address ||
                              owner?.assetsResidential?.value) && (
                              <View
                                style={{
                                  padding: '3px',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  backgroundColor: '#edf5ff',
                                }}
                              >
                                <View style={{ flexBasis: '50%' }}>
                                  <Text style={{ fontWeight: 'bold' }}>
                                    Personal Residential Address
                                  </Text>
                                  <Text style={{ fontSize: 10 }}>
                                    {owner?.assetsResidential?.address || '-'}
                                  </Text>
                                </View>
                                <View
                                  style={{
                                    flexBasis: '50%',
                                    textAlign: 'right',
                                  }}
                                >
                                  <Text style={{ fontWeight: 'bold' }}>
                                    Market Value
                                  </Text>
                                  <Text style={{ fontSize: 10 }}>
                                    {owner?.assetsResidential?.value
                                      ? formatNumber(
                                          owner?.assetsResidential?.value
                                        )
                                      : '-'}
                                  </Text>
                                </View>
                              </View>
                            )}

                            {!isEveryObjKeyNullRemoveId(
                              owner?.assetsRealEstate
                            ) && (
                              <View>
                                <View
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    backgroundColor: '#114684',
                                    color: 'white',
                                    fontWeight: 'bold',
                                    padding: '3px',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <Text style={{ flexBasis: '50%' }}>
                                    Other Real Estate Address
                                  </Text>
                                  <Text
                                    style={{
                                      flexBasis: '50%',
                                      textAlign: 'right',
                                    }}
                                  >
                                    Market Value
                                  </Text>
                                </View>

                                {getFilteredArray(owner?.assetsRealEstate)?.map(
                                  (assets: any, index: number) => (
                                    <View
                                      key={index}
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        backgroundColor:
                                          index % 2 === 0 ? 'white' : '#edf5ff',
                                        fontSize: 10,
                                        padding: '3px',
                                        justifyContent: 'space-between',
                                      }}
                                    >
                                      <Text style={{ flexBasis: '50%' }}>
                                        {assets?.address || '-'}
                                      </Text>
                                      <Text
                                        style={{
                                          flexBasis: '50%',
                                          textAlign: 'right',
                                        }}
                                      >
                                        {formatNumber(assets?.value)}
                                      </Text>
                                    </View>
                                  )
                                )}
                              </View>
                            )}

                            {!isEveryObjKeyNullAssets(owner?.assetsPrice) && (
                              <View>
                                <View
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    backgroundColor: '#114684',
                                    color: 'white',
                                    fontWeight: 'bold',
                                    padding: '3px',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <Text style={{ flexBasis: '50%' }}>
                                    Asset Type
                                  </Text>
                                  <Text
                                    style={{
                                      flexBasis: '50%',
                                      textAlign: 'right',
                                    }}
                                  >
                                    Value
                                  </Text>
                                </View>

                                {getFilteredArrayAssets(
                                  owner?.assetsPrice
                                )?.map((assets: any, index: number) => (
                                  <View
                                    key={index}
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      backgroundColor:
                                        index % 2 === 0 ? 'white' : '#edf5ff',
                                      fontSize: 10,
                                      padding: '3px',
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    <Text style={{ flexBasis: '50%' }}>
                                      {assets?.type === 'Others'
                                        ? assets?.otherAssetType || '-'
                                        : assets?.type || '-'}
                                    </Text>
                                    <Text
                                      style={{
                                        flexBasis: '50%',
                                        textAlign: 'right',
                                      }}
                                    >
                                      {assets?.value
                                        ? formatNumber(assets?.value)
                                        : '-'}
                                    </Text>
                                  </View>
                                ))}
                              </View>
                            )}
                          </View>
                        )}

                        {(owner?.liabilitiesResidence?.mortgage ||
                          owner?.liabilitiesResidence?.value ||
                          !isEveryObjKeyNullRemoveId(
                            owner?.liabilitiesRealEstate
                          ) ||
                          !isEveryObjKeyNullAssets(
                            owner?.liabilitiesPrice
                          )) && (
                          <View
                            style={{
                              border: '1px solid black',
                              // marginTop: '10px',
                            }}
                            wrap={false}
                          >
                            <View
                              style={{
                                fontSize: 12,
                                // backgroundColor: '#114684',
                                // color: 'white',
                                fontWeight: 'extrabold',
                                padding: '3px 0',
                                textAlign: 'center',
                                borderBottom: '1px solid black',
                              }}
                            >
                              <Text>LIABILATIES</Text>
                            </View>

                            {(owner?.liabilitiesResidence?.mortgage ||
                              owner?.liabilitiesResidence?.value) && (
                              <View
                                style={{
                                  padding: '3px',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  backgroundColor: '#edf5ff',
                                }}
                              >
                                <View style={{ flexBasis: '50%' }}>
                                  <Text style={{ fontWeight: 'bold' }}>
                                    Personal Residence Mortgage
                                  </Text>
                                  <Text style={{ fontSize: 10 }}>
                                    {owner?.liabilitiesResidence?.mortgage ||
                                      '-'}
                                  </Text>
                                </View>
                                <View
                                  style={{
                                    flexBasis: '50%',
                                    textAlign: 'right',
                                  }}
                                >
                                  <Text style={{ fontWeight: 'bold' }}>
                                    Mortgage Value
                                  </Text>
                                  <Text style={{ fontSize: 10 }}>
                                    {owner?.liabilitiesResidence?.value
                                      ? formatNumber(
                                          owner?.liabilitiesResidence?.value
                                        )
                                      : '-'}
                                  </Text>
                                </View>
                              </View>
                            )}

                            {!isEveryObjKeyNullRemoveId(
                              owner?.liabilitiesRealEstate
                            ) && (
                              <View>
                                <View
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    backgroundColor: '#114684',
                                    color: 'white',
                                    fontWeight: 'bold',
                                    padding: '3px',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <Text style={{ flexBasis: '50%' }}>
                                    Other Real Estate Mortgage
                                  </Text>
                                  <Text
                                    style={{
                                      flexBasis: '50%',
                                      textAlign: 'right',
                                    }}
                                  >
                                    Mortgage Value
                                  </Text>
                                </View>

                                {getFilteredArray(
                                  owner?.liabilitiesRealEstate
                                )?.map((assets: any, index: number) => (
                                  <View
                                    key={index}
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      backgroundColor:
                                        index % 2 === 0 ? 'white' : '#edf5ff',
                                      fontSize: 10,
                                      padding: '3px',
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    <Text style={{ flexBasis: '50%' }}>
                                      {assets?.mortgage || '-'}
                                    </Text>
                                    <Text
                                      style={{
                                        flexBasis: '50%',
                                        textAlign: 'right',
                                      }}
                                    >
                                      {assets?.value
                                        ? formatNumber(assets?.value)
                                        : '-'}
                                    </Text>
                                  </View>
                                ))}
                              </View>
                            )}

                            {!isEveryObjKeyNullAssets(
                              owner?.liabilitiesPrice
                            ) && (
                              <View>
                                <View
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    backgroundColor: '#114684',
                                    color: 'white',
                                    fontWeight: 'bold',
                                    padding: '3px',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <Text style={{ flexBasis: '50%' }}>
                                    Liability Type
                                  </Text>
                                  <Text
                                    style={{
                                      flexBasis: '50%',
                                      textAlign: 'right',
                                    }}
                                  >
                                    Value
                                  </Text>
                                </View>

                                {getFilteredArrayAssets(
                                  owner?.liabilitiesPrice
                                )?.map((assets: any, index: number) => (
                                  <View
                                    key={index}
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      backgroundColor:
                                        index % 2 === 0 ? 'white' : '#edf5ff',
                                      fontSize: 10,
                                      padding: '3px',
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    <Text style={{ flexBasis: '50%' }}>
                                      {assets?.type === 'Others'
                                        ? assets?.otherLiabilityType || '-'
                                        : assets?.type || '-'}
                                    </Text>
                                    <Text
                                      style={{
                                        flexBasis: '50%',
                                        textAlign: 'right',
                                      }}
                                    >
                                      {assets?.value
                                        ? formatNumber(assets?.value)
                                        : '-'}
                                    </Text>
                                  </View>
                                ))}
                              </View>
                            )}
                          </View>
                        )}

                        {!isEveryObjKeyNullRemoveId(
                          owner?.businessInterests
                        ) && (
                          <View
                            wrap={false}
                            style={{
                              border: '1px solid black',
                            }}
                          >
                            <View
                              style={{
                                fontSize: 12,

                                fontWeight: 'extrabold',
                                padding: '3px 0',
                                textAlign: 'center',
                                borderBottom: '1px solid black',
                              }}
                            >
                              <Text>BUSINESS INTERESTS</Text>
                            </View>
                            <View>
                              <View
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  backgroundColor: '#114684',
                                  color: 'white',
                                  fontWeight: 'bold',
                                  padding: '3px',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <Text style={{ flexBasis: '40%' }}>
                                  Business Name
                                </Text>
                                <Text
                                  style={{
                                    flexBasis: '35%',
                                    textAlign: 'right',
                                  }}
                                >
                                  Business Value
                                </Text>
                                <Text
                                  style={{
                                    flexBasis: '25%',
                                    textAlign: 'right',
                                  }}
                                >
                                  Ownership %
                                </Text>
                              </View>

                              {getFilteredArray(owner?.businessInterests)?.map(
                                (item: any, index: number) => (
                                  <View
                                    key={index}
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      backgroundColor:
                                        index % 2 === 0 ? 'white' : '#edf5ff',
                                      fontSize: 10,
                                      padding: '3px',
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    <Text style={{ flexBasis: '40%' }}>
                                      {item?.legalName || '-'}
                                    </Text>
                                    <Text
                                      style={{
                                        flexBasis: '35%',
                                        textAlign: 'right',
                                      }}
                                    >
                                      {item?.value
                                        ? formatNumber(item?.value)
                                        : '-'}
                                    </Text>
                                    <Text
                                      style={{
                                        flexBasis: '25%',
                                        textAlign: 'right',
                                      }}
                                    >
                                      {item?.ownershipPercentage || '-'}
                                    </Text>
                                  </View>
                                )
                              )}
                            </View>
                          </View>
                        )}

                        <View
                          wrap={false}
                          style={{
                            marginTop: '20px',
                            border: '1px solid black',
                            borderTop: 'none',
                          }}
                        >
                          <View
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              backgroundColor: '#114684',
                              color: 'white',
                              fontWeight: 'bold',
                              padding: '3px',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Text style={{ flexBasis: '50%' }}>Category</Text>
                            <Text
                              style={{ flexBasis: '50%', textAlign: 'right' }}
                            >
                              Value
                            </Text>
                          </View>
                          <View
                            style={{
                              display: 'flex',
                              flexDirection: 'row',

                              padding: '3px',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Text
                              style={{
                                flexBasis: '50%',
                                fontWeight: 'semibold',
                              }}
                            >
                              Total Assets
                            </Text>
                            <Text
                              style={{
                                flexBasis: '50%',
                                textAlign: 'right',
                                fontWeight: 'bold',
                              }}
                            >
                              {owner?.totalAssets
                                ? formatNumber(owner?.totalAssets)
                                : '-'}
                            </Text>
                          </View>
                          <View
                            style={{
                              display: 'flex',
                              flexDirection: 'row',

                              padding: '3px',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Text
                              style={{
                                flexBasis: '50%',
                                fontWeight: 'semibold',
                              }}
                            >
                              Total Liabilities
                            </Text>
                            <Text
                              style={{
                                flexBasis: '50%',
                                textAlign: 'right',
                                fontWeight: 'bold',
                              }}
                            >
                              {owner?.totalLiabilities
                                ? formatNumber(owner?.totalLiabilities)
                                : '-'}
                            </Text>
                          </View>

                          {owner?.personalNetWorth && (
                            <View
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                borderTop: '1px solid black',
                                padding: '3px',
                                justifyContent: 'space-between',
                                fontWeight: 'ultrabold',
                              }}
                            >
                              <Text style={{ flexBasis: '50%' }}>
                                Personal Net Worth
                              </Text>
                              <Text
                                style={{ flexBasis: '50%', textAlign: 'right' }}
                              >
                                {formatNumber(owner?.personalNetWorth)}
                              </Text>
                            </View>
                          )}
                        </View>
                      </View>
                    )}
                  </View>
                );
              }
            )}
          </View>
        )}
    </View>
  );

  const InsurenceLimitProvider = ({
    mainQuestion,
    mainAnswer,
    subAnswer,
  }: {
    mainQuestion: string;
    mainAnswer: boolean | null;
    subAnswer: {
      limit: any;
      provider: any;
      repBrokerage: any;
    };
  }) => {
    return (
      <>
        {typeof mainAnswer === 'boolean' && (
          <View
            style={{
              ...styles?.flexColumn,
              // backgroundColor: '#f5f5f5',
              // borderRadius: '8px',
              padding: '8px',
              gap: '4px',
            }}
            wrap={false}
          >
            <View
              style={{
                ...styles?.flexColumn,
                gap: '2px',
              }}
            >
              <View
                style={{
                  ...styles?.flexRowNoWrap,
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                }}
              >
                <View style={{ ...styles?.dot }}></View>
                {getQueLabel(mainQuestion)}
              </View>
              <Text
                style={{
                  fontWeight: 600,
                  fontSize: 10,
                  marginLeft: '10px',
                }}
              >
                {mainAnswer === true ? 'Yes' : 'No'}
              </Text>
            </View>
            {mainAnswer && (
              <View style={{ ...styles?.flexColumn, gap: '4px' }}>
                <View
                  style={{
                    ...styles?.flexRowWrap,
                    gap: '8px',
                  }}
                >
                  {subAnswer?.limit &&
                    getFieldInputCommon(
                      'What are the limit(s)',
                      subAnswer?.limit ?? <> &nbsp;</>
                    )}
                  {subAnswer.provider &&
                    getFieldInputCommon(
                      'Who is the insurer?',
                      subAnswer.provider ?? <> &nbsp;</>
                    )}
                  {subAnswer.repBrokerage &&
                    getFieldInputCommon(
                      'Who is your representing brokerage?',
                      subAnswer.repBrokerage ?? <> &nbsp;</>
                    )}
                </View>
              </View>
            )}
          </View>
        )}
      </>
    );
  };

  const InsuranceNew = () => {
    const insuranceData = [
      {
        question: 'Is there Key Man Life Insurance?',
        answer: insurance?.keyManLifeInsurance?.hasKeyManLifeInsurance,
        details: insurance?.keyManLifeInsurance?.details || [],
      },
      {
        question: 'Do you have Commercial General Liability Insurance?',
        answer:
          insurance?.commercialGeneralLiabilityInsurance
            ?.hasCommercialGeneralLiabilityInsurance,
        details: [
          {
            repBrokerage:
              insurance?.commercialGeneralLiabilityInsurance?.repBrokerage,
            amount: insurance?.commercialGeneralLiabilityInsurance?.limit,
            provider: insurance?.commercialGeneralLiabilityInsurance?.provider,
          },
        ],
      },
      {
        question: 'Do you have Environmental (Pollution) Insurance?',
        answer: insurance?.environmentalInsurance?.hasEnvironmentalInsurance,
        details: [
          {
            repBrokerage: insurance?.environmentalInsurance?.repBrokerage,
            amount: insurance?.environmentalInsurance?.limit,
            provider: insurance?.environmentalInsurance?.provider,
          },
        ],
      },
      {
        question: 'Do you have Errors & Omissions Insurance?',
        answer:
          insurance?.errorsAndOmissionsInsurance
            ?.hasErrorsAndOmissionsInsurance,
        details: [
          {
            repBrokerage: insurance?.errorsAndOmissionsInsurance?.repBrokerage,
            amount: insurance?.errorsAndOmissionsInsurance?.limit,
            provider: insurance?.errorsAndOmissionsInsurance?.provider,
          },
        ],
      },
      {
        question: 'Do you have Crime (Fidelity) Insurance?',
        answer: insurance?.fidelityInsurance?.hasFidelityInsurance,
        details: [
          {
            repBrokerage: insurance?.fidelityInsurance?.repBrokerage,
            amount: insurance?.fidelityInsurance?.limit,
            provider: insurance?.fidelityInsurance?.provider,
          },
        ],
      },
      {
        question: 'Do you have Equipment Insurance?',
        answer: insurance?.equipmentInsurance?.hasEquipmentInsurance,
        details: [
          {
            repBrokerage: insurance?.equipmentInsurance?.repBrokerage,
            amount: insurance?.equipmentInsurance?.limit,
            provider: insurance?.equipmentInsurance?.provider,
          },
        ],
      },
      {
        question: 'Do you have Cyber Insurance?',
        answer: insurance?.cyberInsurance?.hasCyberInsurance,
        details: [
          {
            repBrokerage: insurance?.cyberInsurance?.repBrokerage,
            amount: insurance?.cyberInsurance?.limit,
            provider: insurance?.cyberInsurance?.provider,
          },
        ],
      },
      {
        question: 'Do you have any other type of insurance?',
        answer: insurance?.otherInsurance?.hasOtherInsurance,
        details: [
          {
            repBrokerage: insurance?.otherInsurance?.details[0]?.brokerage,
            amount: insurance?.otherInsurance?.details[0]?.limit,
            provider: insurance?.otherInsurance?.details[0]?.provider,
            type: insurance?.otherInsurance?.details[0]?.type,
          },
        ],
      },
    ];

    const keyManLifeInsurance = insurance?.keyManLifeInsurance;
    const otherInsurance = insuranceData.filter(
      (item) => item.question !== 'Is there Key Man Life Insurance?'
    );

    return (
      <View break style={styles.table} wrap={false} id="insurancePage">
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Text style={{ ...styles?.formHeading, color: 'black' }}>6. </Text>
          <Text
            style={{
              ...styles?.formHeading,
              color: '#114684',
            }}
          >
            Insurance
          </Text>
        </View>

        {/* Table for Key Man Life Insurance */}
        {keyManLifeInsurance && keyManLifeInsurance.hasKeyManLifeInsurance && (
          <View>
            <Text style={{ margin: '10px 0px', fontWeight: 'bold' }}>
              Key Man Life Insurance
            </Text>
            <View style={{ border: '1px solid black', borderBottom: 'none' }}>
              <View
                style={{
                  fontSize: 10,
                  display: 'flex',
                  flexDirection: 'row',
                  backgroundColor: '#114684',
                  color: 'white',
                  fontWeight: 'bold',
                }}
              >
                <Text
                  style={{ padding: '4px', width: '34%', textAlign: 'center' }}
                >
                  Key Person
                </Text>
                <Text
                  style={{ padding: '4px', width: '34%', textAlign: 'center' }}
                >
                  Amount
                </Text>
                <Text
                  style={{ padding: '4px', width: '32%', textAlign: 'center' }}
                >
                  Beneficiary
                </Text>
              </View>
              {keyManLifeInsurance?.details.length > 0 ? (
                keyManLifeInsurance?.details.map(
                  (keyInsurance: any, index: number) => (
                    <View
                      key={index}
                      style={{
                        fontSize: 10,
                        display: 'flex',
                        flexDirection: 'row',

                        borderBottom: '1px solid black',
                      }}
                    >
                      <Text
                        style={{
                          padding: '4px',
                          width: '34%',
                          textAlign: 'center',
                          borderRight: '1px solid black',
                        }}
                      >
                        {keyInsurance?.keyPerson || '-'}
                      </Text>
                      <Text
                        style={{
                          padding: '4px',
                          width: '34%',
                          textAlign: 'right',
                          borderRight: '1px solid black',
                        }}
                      >
                        {keyInsurance?.amount
                          ? formatNumber(keyInsurance?.amount)
                          : '-'}
                      </Text>
                      <Text
                        style={{
                          padding: '4px',
                          width: '32%',
                          textAlign: 'center',
                        }}
                      >
                        {keyInsurance?.beneficiary || '-'}
                      </Text>
                    </View>
                  )
                )
              ) : (
                <View
                  style={{
                    fontSize: 10,
                    display: 'flex',
                    flexDirection: 'row',

                    borderBottom: '1px solid black',
                  }}
                >
                  <Text
                    style={{
                      padding: '4px',
                      width: '34%',
                      textAlign: 'center',
                      borderRight: '1px solid black',
                    }}
                  >
                    -
                  </Text>
                  <Text
                    style={{
                      padding: '4px',
                      width: '34%',
                      textAlign: 'right',
                      borderRight: '1px solid black',
                    }}
                  >
                    -
                  </Text>
                  <Text
                    style={{ padding: '4px', width: '32%', textAlign: 'right' }}
                  >
                    -
                  </Text>
                </View>
              )}
            </View>
          </View>
        )}

        {otherInsurance.length > 0 && (
          <View style={{ marginTop: '5px' }}>
            <Text style={{ margin: '10px 0px', fontWeight: 'bold' }}>
              Other Insurances
            </Text>
            <View style={{ border: '1px solid black', borderBottom: 'none' }}>
              <View
                style={{
                  fontSize: 10,
                  display: 'flex',
                  flexDirection: 'row',
                  backgroundColor: '#114684',
                  color: 'white',
                  fontWeight: 'bold',
                }}
              >
                <Text
                  style={{ padding: '4px', width: '27%', textAlign: 'center' }}
                >
                  Insurance Type
                </Text>
                <Text
                  style={{ padding: '4px', width: '19%', textAlign: 'center' }}
                >
                  Limit(s)
                </Text>
                <Text
                  style={{ padding: '4px', width: '27%', textAlign: 'center' }}
                >
                  Insurer
                </Text>
                <Text
                  style={{ padding: '4px', width: '27%', textAlign: 'center' }}
                >
                  Brokerage
                </Text>
              </View>
              {otherInsurance.map((item, index) => (
                <View
                  key={index}
                  style={{
                    fontSize: 10,
                    display: 'flex',
                    flexDirection: 'row',
                    borderBottom: '1px solid black',
                  }}
                >
                  <View
                    style={{
                      padding: '4px',
                      width: '27%',
                      textAlign: 'justify',
                      borderRight: '1px solid black',
                    }}
                  >
                    <Text style={{ fontSize: 9 }}>
                      {item.question} -{' '}
                      <Text style={{ fontWeight: 'bold', fontSize: 10 }}>
                        {' '}
                        {item?.answer ? 'Yes' : 'No'}
                      </Text>
                    </Text>
                    {item?.details[0]?.type && (
                      <Text
                        style={{ marginTop: '2px', fontWeight: 'semibold' }}
                      >
                        {item?.details[0].type}
                      </Text>
                    )}
                  </View>

                  <Text
                    style={{
                      padding: '4px',
                      width: '19%',
                      textAlign: 'right',
                      borderRight: '1px solid black',
                    }}
                  >
                    {item.details[0]?.amount
                      ? formatNumber(Number(item.details[0]?.amount))
                      : '-'}
                  </Text>
                  <Text
                    style={{
                      padding: '4px',
                      width: '27%',
                      textAlign: 'center',
                      borderRight: '1px solid black',
                    }}
                  >
                    {item.details[0]?.provider || '-'}
                  </Text>
                  <Text
                    style={{
                      padding: '4px',
                      width: '27%',
                      textAlign: 'center',
                    }}
                  >
                    {item.details[0]?.repBrokerage || '-'}
                  </Text>
                </View>
              ))}
            </View>
          </View>
        )}

        {/* Combined Table for Other Insurances */}
        {/* {otherInsurance.length > 0 && (
          <View>
            <Text style={{ margin: '10px 0px' }}>Other Insurances</Text>

            
            <View
              style={{
                ...styles.row,
                ...styles.header,
                border: '1px solid #ddd',
              }}
            >
              <Text style={styles.cells}>Insurance Type</Text>
              <Text style={{ ...styles.cellData, textAlign: 'center' }}>
                Limit(s)
              </Text>
              <Text style={{ ...styles.cellData, textAlign: 'center' }}>
                Insurer
              </Text>
              <Text
                style={{
                  ...styles.cellData,
                  textAlign: 'center',
                  borderRightWidth: 0,
                }}
              >
                Brokerage
              </Text>
            </View>

            
            <View style={{ border: '1px solid #ddd' }}>
              {otherInsurance.map((item, index) => (
                <View style={styles.row} key={index}>
                  <Text style={styles.cells}>
                    {item.question} - {item?.answer ? 'Yes' : 'No'}
                  </Text>
                  <Text style={{ ...styles.cellData, textAlign: 'center' }}>
                    {item.details[0]?.amount || '-'}
                  </Text>
                  <Text style={{ ...styles.cellData, textAlign: 'center' }}>
                    {item.details[0]?.provider || '-'}
                  </Text>
                  <Text
                    style={{
                      ...styles.cellData,
                      textAlign: 'center',
                      borderRightWidth: 0,
                    }}
                  >
                    {item.details[0]?.repBrokerage || '-'}
                  </Text>
                </View>
              ))}
            </View>
          </View>
        )} */}
      </View>
    );
  };

  const SuretyNew = () => (
    <View break>
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '15px',
        }}
        id="suretyPage"
      >
        {/* Section Header */}
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <Text style={{ ...styles?.formHeading, color: 'black' }}>5. </Text>
          <Text style={{ ...styles?.formHeading, color: '#114684' }}>
            {stepperFormLabel?.surety?.trim() || 'Surety'}
          </Text>
        </View>

        {/* Surety Information */}
        {(surety?.suretyName ||
          surety?.totalWorkOnHand ||
          surety?.singleJob) && (
          <View style={{ border: '1px solid black', borderBottom: 'none' }}>
            {surety?.suretyName && (
              <View
                style={{
                  ...styles?.rowWithBottomBorder,
                }}
              >
                <Text>Surety Name : </Text>
                <Text style={{ fontWeight: 'semibold' }}>
                  {surety?.suretyName}
                </Text>
              </View>
            )}
            {(surety?.totalWorkOnHand || surety?.singleJob) && (
              <View style={{ ...styles?.flexWithBottomBorder }}>
                {surety?.totalWorkOnHand && (
                  <View
                    style={{
                      ...styles?.simpleCol,
                      width: '50%',
                      borderRight: '1px solid black',
                    }}
                  >
                    <Text>Total Work On Hand : </Text>
                    <Text style={{ fontWeight: 'semibold' }}>
                      {formatNumber(surety?.totalWorkOnHand)}
                    </Text>
                  </View>
                )}
                {surety?.singleJob && (
                  <View
                    style={{
                      ...styles?.simpleCol,
                      width: '50%',
                    }}
                  >
                    <Text>Single Job Limit : </Text>
                    <Text style={{ fontWeight: 'semibold' }}>
                      {formatNumber(surety?.singleJob)}
                    </Text>
                  </View>
                )}
              </View>
            )}
            {surety?.reasonChangingSurety && (
              <View style={{ ...styles?.colWithBottomBorder }}>
                <Text style={{ fontWeight: 'bold' }}>
                  Reason for Changing Surety
                </Text>
                <Text style={{ textAlign: 'justify' }} wrap>
                  {surety?.reasonChangingSurety}
                </Text>
              </View>
            )}
            {surety?.refusedBond && (
              <View
                style={
                  surety?.refusedBondExplanation
                    ? styles.rowWithoutBottomBorder
                    : styles?.rowWithBottomBorder
                }
              >
                <Text style={{ fontWeight: 'bold' }}>
                  Have you ever been refused a bond?{' '}
                </Text>
                <Text>{surety?.refusedBond === true ? 'Yes' : 'No'}</Text>
              </View>
            )}
            {surety?.refusedBondExplanation && (
              <View style={{ ...styles?.colWithBottomBorder }}>
                <Text style={{ fontWeight: 'bold' }}>Provide Detail</Text>
                <Text style={{ textAlign: 'justify' }}>
                  {surety?.refusedBondExplanation}
                </Text>
              </View>
            )}
            {surety?.whoSignSeal?.length > 0 &&
              !isEveryObjKeyNull(surety?.whoSignSeal) && (
                <View style={{ borderBottom: '1px solid black' }}>
                  <Text
                    style={{
                      fontWeight: 'bold',
                      textAlign: 'center',
                      fontSize: 10,
                      padding: '3px',
                    }}
                  >
                    Who is authorized to sign and seal documents?
                  </Text>
                  <View
                    style={{
                      fontSize: 10,
                      display: 'flex',
                      flexDirection: 'row',
                      backgroundColor: '#114684',
                      color: 'white',
                      fontWeight: 'bold',
                    }}
                  >
                    <Text style={{ padding: '4px', width: '34%' }}>
                      First Name
                    </Text>
                    <Text style={{ padding: '4px', width: '34%' }}>
                      Last Name
                    </Text>
                    <Text style={{ padding: '4px', width: '32%' }}>
                      Position
                    </Text>
                  </View>
                  {surety?.whoSignSeal?.map((item: any, i: number) => (
                    <View
                      key={i}
                      style={{
                        fontSize: 10,
                        display: 'flex',
                        flexDirection: 'row',
                        backgroundColor: i % 2 === 0 ? '#fff' : '#edf5ff',
                      }}
                    >
                      <Text
                        style={{
                          padding: '4px',
                          width: '34%',
                          textAlign: 'justify',
                        }}
                      >
                        {item?.firstName || '-'}
                      </Text>
                      <Text
                        style={{
                          padding: '4px',
                          width: '34%',
                          textAlign: 'justify',
                        }}
                      >
                        {item?.lastName || '-'}
                      </Text>
                      <Text
                        style={{
                          padding: '4px',
                          width: '32%',
                          textAlign: 'justify',
                        }}
                      >
                        {item?.position || '-'}
                      </Text>
                    </View>
                  ))}
                </View>
              )}
          </View>
        )}
      </View>
    </View>
  );

  const findOtherContractType = () => {
    let constructionType = cms?.contractors.find(
      (obj: any) => obj.type === 'Others'
    );
    if (constructionType === undefined) return 'Other';
    else return constructionType?.otherContractorType || 'Other';
  };

  const AboutNew = () => (
    <View break>
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '15px',
        }}
      >
        <View
          style={{ width: '100%', display: 'flex', flexDirection: 'row' }}
          id="aboutPage"
        >
          <Text style={{ ...styles?.formHeading, color: 'black' }}>4. </Text>
          <Text style={{ ...styles?.formHeading, color: '#114684' }}>
            {stepperFormLabel && stepperFormLabel?.about?.trim() !== ''
              ? stepperFormLabel?.about
              : 'About'}
          </Text>
        </View>
        <View style={{ borderTop: '1px solid black' }}>
          {/* style={{ border: '1px solid black', borderBottom: 'none' }} */}
          {cms?.name && (
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                padding: '3px',
                borderLeft: '1px solid black',
                borderRight: '1px solid black',
                borderBottom: '1px solid black',
              }}
            >
              <Text>Name </Text>
              <Text
                style={{
                  flex: 1,
                  textAlign: 'right',
                  paddingLeft: '5px',
                  fontWeight: 'bold',
                }}
              >
                {cms?.name}
              </Text>
            </View>
          )}
          {cms?.position && (
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                padding: '3px',
                borderBottom: '1px solid black',
                borderLeft: '1px solid black',
                borderRight: '1px solid black',
              }}
            >
              <Text>Position </Text>
              <Text
                style={{
                  flex: 1,
                  textAlign: 'right',
                  paddingLeft: '5px',
                  fontWeight: 'bold',
                }}
              >
                {cms?.position}
              </Text>
            </View>
          )}
          {cms?.hiringDate && (
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                padding: '3px',
                borderBottom: '1px solid black',
                borderLeft: '1px solid black',
                borderRight: '1px solid black',
              }}
            >
              <Text>Hiring Date </Text>
              <Text
                style={{
                  flex: 1,
                  textAlign: 'right',
                  paddingLeft: '5px',
                  fontWeight: 'bold',
                }}
              >
                {moment(cms.hiringDate).format('MMM DD, YYYY')}
              </Text>
            </View>
          )}
          {cms?.geoArea && (
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                padding: '3px',
                borderLeft: '1px solid black',
                borderRight: '1px solid black',
                borderBottom: '1px solid black',
              }}
            >
              <Text>Geographic Area </Text>
              <Text
                style={{
                  flex: 1,
                  textAlign: 'right',
                  paddingLeft: '5px',
                  fontWeight: 'bold',
                }}
              >
                {cms?.geoArea}
              </Text>
            </View>
          )}
          {cms?.workOutsideDetails && (
            <View
              style={{
                borderBottom: '1px solid black',
                borderLeft: '1px solid black',
                borderRight: '1px solid black',
              }}
            >
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  padding: '3px',
                }}
              >
                <Text>Do you ever work outside? </Text>
                <Text
                  style={{
                    flex: 1,
                    textAlign: 'right',
                    paddingLeft: '5px',
                    fontWeight: 'bold',
                  }}
                >
                  {cms?.workOutsideDetails?.workOutside ? 'Yes' : 'No'}
                </Text>
              </View>
              {cms?.workOutsideDetails?.workOutside && (
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: '3px',
                    gap: '5px',
                    fontSize: 10,
                  }}
                >
                  {cms.workOutsideDetails.where && (
                    <View style={{ flexBasis: '50%' }}>
                      <Text
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        Where?
                      </Text>
                      <Text style={{ textAlign: 'justify' }}>
                        {cms.workOutsideDetails.where}
                      </Text>
                    </View>
                  )}

                  {cms.workOutsideDetails.howOften && (
                    <View style={{ flexBasis: '50%' }}>
                      <Text
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        How Often?
                      </Text>
                      <Text>{cms.workOutsideDetails.howOften}</Text>
                    </View>
                  )}
                </View>
              )}
            </View>
          )}
          {cms?.fixedContract?.length > 0 && (
            <View
              style={{
                borderLeft: '1px solid black',
                borderRight: '1px solid black',
              }}
            >
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  backgroundColor: '#114684',

                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: 9,
                }}
              >
                <Text
                  style={{
                    width: '25%',
                    borderRight: '1px solid black',
                    padding: '3px',
                  }}
                >
                  Type of Construction
                </Text>
                <Text
                  style={{
                    width: '25%',
                    borderRight: '1px solid black',
                    padding: '3px',
                  }}
                >
                  Largest fixed price contract in the last 3 years for this type
                  of construction
                </Text>
                <Text
                  style={{
                    width: '25%',
                    borderRight: '1px solid black',
                    padding: '3px',
                  }}
                >
                  Location of largest fixed price contract in the last 3 years
                  for this type of construction
                </Text>
                <Text style={{ width: '25%', padding: '3px' }}>
                  % of annual sales related to this type of construction
                </Text>
              </View>

              {cms?.fixedContract?.map((contract: any, index: number) => (
                <View
                  key={index}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',

                    fontSize: 10,
                    borderBottom: '1px solid black',
                  }}
                >
                  <Text
                    style={{
                      width: '25%',
                      textAlign: 'justify',
                      padding: '3px',
                      borderRight: '1px solid black',
                    }}
                  >
                    {contract?.contractorType === 'Others'
                      ? findOtherContractType()
                      : contract.contractorType}
                  </Text>
                  <Text
                    style={{
                      width: '25%',
                      textAlign: 'right',
                      padding: '3px',
                      borderRight: '1px solid black',
                    }}
                  >
                    {contract?.contractPrice
                      ? formatNumber(contract?.contractPrice)
                      : '-'}
                  </Text>
                  <Text
                    style={{
                      width: '25%',
                      textAlign: 'justify',
                      padding: '3px',
                      borderRight: '1px solid black',
                    }}
                  >
                    {contract?.location || '-'}
                  </Text>
                  <Text
                    style={{ width: '25%', textAlign: 'right', padding: '3px' }}
                  >
                    {contract?.annualSales || '-'}
                  </Text>
                </View>
              ))}
            </View>
          )}
          <View
            wrap={false}
            style={{
              borderBottom: '1px solid black',
              fontSize: 10,
              borderLeft: '1px solid black',
              borderRight: '1px solid black',
            }}
          >
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                padding: '3px',
                gap: '5px',
                justifyContent: 'space-between',
              }}
            >
              <Text style={{ fontWeight: 'semibold' }}>
                What was the largest $ amount of work on hand at any one time in
                the past 5 years?
              </Text>
              <Text
                style={{ fontWeight: 'ultrabold', textAlign: 'right', flex: 1 }}
              >
                {formatNumber(Number(cms?.largestWorkAmount))}
              </Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                padding: '3px',
                gap: '5px',
                justifyContent: 'space-between',
              }}
            >
              <Text style={{ fontWeight: 'semibold' }}>
                How many contracts did it consist of?
              </Text>
              <Text style={{ fontWeight: 'ultrabold', textAlign: 'right' }}>
                {cms?.numberOfContracts}
              </Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                padding: '3px',
                gap: '5px',
                justifyContent: 'space-between',
              }}
            >
              <Text style={{ fontWeight: 'semibold' }}>
                What % of work is usually sublet to others?
              </Text>
              <Text style={{ fontWeight: 'ultrabold', textAlign: 'right' }}>
                {cms?.workSubletPercentage} %
              </Text>
            </View>
          </View>
          <View
            style={{
              borderBottom: '1px solid black',
              fontSize: 10,
              padding: '3px',
              borderLeft: '1px solid black',
              borderRight: '1px solid black',
            }}
          >
            <Text style={{ fontWeight: 'semibold' }}>
              What type of work is usually undertaken with own forces?
            </Text>
            <Text style={{ textAlign: 'justify', marginTop: '5px' }}>
              {cms?.workUndertakenOwnForces}
            </Text>
          </View>

          {cms?.managementControl && cms?.managementControl?.length > 0 && (
            <View
              style={{
                borderBottom: '1px solid black',
                borderLeft: '1px solid black',
                borderRight: '1px solid black',
                fontSize: 10,
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Text
                style={{
                  flexBasis: '50%',
                  padding: '3px',
                  borderRight: '1px solid black',
                }}
              >
                To what extent does management control and supervise individual
                jobs?
              </Text>
              <Text
                style={{
                  flexBasis: '50%',
                  padding: '3px',
                  fontWeight: 'semibold',
                }}
              >
                {cms?.managementControl?.map((jobs: any, index: number) => {
                  return index === cms?.managementControl.length - 1
                    ? jobs?.jobType === 'Other'
                      ? jobs?.otherJobType
                      : jobs?.jobType
                    : (jobs?.jobType === 'Other'
                        ? jobs?.otherJobType
                        : jobs?.jobType) + ', ';
                })}
              </Text>
            </View>
          )}

          {cms?.workSublet && cms?.workSublet?.length > 0 && (
            <View
              style={{
                borderBottom: '1px solid black',
                borderLeft: '1px solid black',
                borderRight: '1px solid black',
                fontSize: 10,
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Text
                style={{
                  flexBasis: '50%',
                  padding: '3px',
                  borderRight: '1px solid black',
                }}
              >
                What type of work is usually sublet?
              </Text>
              <Text
                style={{
                  flexBasis: '50%',
                  padding: '3px',
                  fontWeight: 'semibold',
                }}
              >
                {cms?.workSublet?.map((sublet: any, index: number) => {
                  return index === cms?.workSublet.length - 1
                    ? sublet?.type === 'Others'
                      ? sublet?.otherSublet
                      : sublet?.type
                    : (sublet?.type === 'Others'
                        ? sublet?.otherSublet
                        : sublet?.type) + ', ';
                })}
              </Text>
            </View>
          )}
          {cms?.totalWorkProgramOneTime && (
            <View
              style={{
                borderBottom: '1px solid black',
                borderLeft: '1px solid black',
                borderRight: '1px solid black',
                fontSize: 10,
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Text
                wrap
                style={{
                  flexBasis: '70%',
                  padding: '3px',
                  fontWeight: 'semibold',
                }}
              >
                What is the total value of projects ($) that your organization
                can manage simultaneously?
              </Text>
              <Text
                style={{
                  flexBasis: '30%',
                  padding: '3px',
                  fontWeight: 'ultrabold',
                  textAlign: 'right',
                }}
              >
                {formatNumber(Number(cms?.totalWorkProgramOneTime))}
              </Text>
            </View>
          )}
          {cms?.totalWorkProgram12Months && (
            <View
              style={{
                borderBottom: '1px solid black',
                borderLeft: '1px solid black',
                borderRight: '1px solid black',
                fontSize: 10,
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Text
                style={{
                  flexBasis: '70%',
                  padding: '3px',
                  fontWeight: 'semibold',
                }}
              >
                What is the total value of projects ($) that your organization
                can manage over the next 12 months?
              </Text>
              <Text
                style={{
                  flexBasis: '30%',
                  padding: '3px',
                  fontWeight: 'ultrabold',
                  textAlign: 'right',
                }}
              >
                {formatNumber(Number(cms?.totalWorkProgram12Months))}
              </Text>
            </View>
          )}
          {cms?.obtainBondsFromSubcontractors && (
            <View
              style={{
                borderBottom: '1px solid black',
                fontSize: 10,
                borderLeft: '1px solid black',
                borderRight: '1px solid black',
              }}
            >
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  padding: '3px',
                  gap: '5px',
                  justifyContent: 'space-between',
                }}
              >
                <Text style={{ fontWeight: 'semibold' }}>
                  Do you obtain bonds from some of your subcontractors?
                </Text>
                <Text
                  style={{
                    fontWeight: 'ultrabold',
                    textAlign: 'right',
                    flex: 1,
                  }}
                >
                  {cms?.obtainBondsFromSubcontractors?.hasBonds &&
                  cms?.obtainBondsFromSubcontractors?.hasBonds === true
                    ? 'Yes'
                    : 'No'}
                </Text>
              </View>
              {cms?.obtainBondsFromSubcontractors?.hasBonds &&
                cms?.obtainBondsFromSubcontractors?.hasBonds === true && (
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      padding: '3px',
                      gap: '5px',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Text style={{ fontWeight: 'semibold' }}>
                      What is the minimum contract $ value that you require a
                      bond from a subcontractor?
                    </Text>
                    <Text
                      style={{
                        fontWeight: 'ultrabold',
                        textAlign: 'right',
                        flex: 1,
                      }}
                    >
                      {formatNumber(
                        Number(
                          cms?.obtainBondsFromSubcontractors?.minimumContract
                        )
                      )}
                    </Text>
                  </View>
                )}
            </View>
          )}
          {cms?.salesPercentageByOwnerType &&
            cms?.salesPercentageByOwnerType?.length > 0 && (
              <View
                wrap={false}
                style={{
                  borderBottom: '1px solid black',
                  fontSize: 10,
                  borderLeft: '1px solid black',
                  borderRight: '1px solid black',
                }}
              >
                <Text
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    padding: '3px',
                  }}
                >
                  List the approximate % of your annual sales for each type of
                  owner for whom you performed work
                </Text>
                <View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      backgroundColor: '#114684',
                      color: 'white',
                      fontWeight: 'bold',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Text
                      style={{
                        padding: '3px',

                        flexBasis: '70%',
                      }}
                    >
                      Owner Type
                    </Text>
                    <Text style={{ padding: '3px' }}>Sales %</Text>
                  </View>
                  {cms?.salesPercentageByOwnerType?.map(
                    (owner: any, index: number) => (
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          backgroundColor:
                            index % 2 === 0 ? 'white' : '#edf5ff',

                          fontWeight: 'bold',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Text
                          style={{
                            padding: '3px',
                            flexBasis: '70%',
                          }}
                        >
                          {owner?.ownerType === 'Others'
                            ? owner?.otherOwnerType || 'Others'
                            : owner?.ownerType}
                        </Text>
                        <Text style={{ padding: '3px', textAlign: 'right' }}>
                          {owner?.salesPercentage} %
                        </Text>
                      </View>
                    )
                  )}
                </View>
              </View>
            )}
          {cms?.workOutsideCanada && (
            <View
              wrap={false}
              style={{
                borderBottom: '1px solid black',
                fontSize: 10,
                borderLeft: '1px solid black',
                borderRight: '1px solid black',
              }}
            >
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Text
                  style={{
                    padding: '3px',
                    fontWeight: 'semibold',
                  }}
                >
                  Do you perform any work outside of Canada?
                </Text>
                <Text
                  style={{
                    padding: '3px',
                    fontWeight: 'ultrabold',
                  }}
                >
                  {cms?.workOutsideCanada?.performWorkOutside &&
                  cms?.workOutsideCanada?.performWorkOutside === true
                    ? 'Yes'
                    : 'No'}
                </Text>
              </View>
              {cms?.workOutsideCanada?.performWorkOutside &&
                cms?.workOutsideCanada?.performWorkOutside === true &&
                cms?.workOutsideCanada?.annualSalesPercentage?.length > 0 && (
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      backgroundColor: '#114684',
                      color: 'white',
                      fontWeight: 'bold',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Text
                      style={{
                        padding: '3px',

                        flexBasis: '60%',
                      }}
                    >
                      Country
                    </Text>
                    <Text style={{ padding: '3px', flex: 1 }}>
                      % of Annual Sales Account (excluding Canada)
                    </Text>
                  </View>
                )}
              {cms?.workOutsideCanada?.performWorkOutside &&
                cms?.workOutsideCanada?.performWorkOutside === true &&
                cms?.workOutsideCanada?.annualSalesPercentage?.map(
                  (sales: any, index: number) => (
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        backgroundColor: index % 2 === 0 ? 'white' : '#edf5ff',

                        fontWeight: 'bold',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Text
                        style={{
                          padding: '3px',
                          flexBasis: '60%',
                        }}
                      >
                        {sales?.country || '-'}
                      </Text>
                      <Text style={{ padding: '3px', textAlign: 'right' }}>
                        {sales?.percentage || '-'}{' '}
                        {sales?.percentage ? '%' : ''}
                      </Text>
                    </View>
                  )
                )}
            </View>
          )}
          {(cms.employeesUnionized !== null ||
            cms.payUnionScaleWages !== null) && (
            <View
              style={{
                borderBottom: '1px solid black',
                borderLeft: '1px solid black',
                borderRight: '1px solid black',
                fontSize: 10,
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <View
                style={{
                  ...styles.rowWithoutBottomBorder,
                  flexBasis: '50%',
                  borderRight: '1px solid black',
                }}
              >
                <Text style={{ fontWeight: 'semibold' }}>
                  Are your employees unionized?
                </Text>
                <Text style={{ fontWeight: 'ultrabold', paddingLeft: '5px' }}>
                  {cms?.employeesUnionized && cms?.employeesUnionized === true
                    ? 'Yes'
                    : 'No'}
                </Text>
              </View>

              <View
                style={{ ...styles.rowWithoutBottomBorder, flexBasis: '50%' }}
              >
                <Text style={{ fontWeight: 'semibold' }}>
                  Do you pay union scale wages?
                </Text>
                <Text style={{ fontWeight: 'ultrabold' }}>
                  {cms?.payUnionScaleWages && cms?.payUnionScaleWages === true
                    ? 'Yes'
                    : 'No'}
                </Text>
              </View>
            </View>
          )}
          {cms?.keyPersonnel &&
          cms.keyPersonnel.length > 0 &&
          cms.keyPersonnel.some(
            (person: any) => person.name || person.position || person.hiringDate
          ) ? (
            <View
              wrap={false}
              style={{
                // borderBottom: '1px solid black',
                border: '1px solid black',
              }}
            >
              <View
                style={{
                  fontSize: 12,

                  fontWeight: 'extrabold',
                  padding: '3px 0',
                  textAlign: 'center',
                }}
              >
                <Text>KEY PERSONNEL</Text>
              </View>
              <View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    backgroundColor: '#114684',
                    color: 'white',
                    fontWeight: 'bold',
                    padding: '3px',
                    justifyContent: 'space-between',
                  }}
                >
                  <Text style={{ flexBasis: '40%' }}>Name</Text>
                  <Text
                    style={{
                      flexBasis: '35%',
                      textAlign: 'center',
                    }}
                  >
                    Position
                  </Text>
                  <Text
                    style={{
                      flexBasis: '25%',
                      textAlign: 'center',
                    }}
                  >
                    Hiring Date
                  </Text>
                </View>

                {cms?.keyPersonnel?.map((item: any, index: number) => (
                  <View
                    key={index}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      backgroundColor: index % 2 === 0 ? 'white' : '#edf5ff',
                      fontSize: 10,
                      padding: '3px',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Text style={{ flexBasis: '40%' }}>
                      {item?.name || '-'}
                    </Text>
                    <Text
                      style={{
                        flexBasis: '35%',
                        textAlign: 'center',
                      }}
                    >
                      {item?.position || '-'}
                    </Text>
                    <Text
                      style={{
                        flexBasis: '25%',
                        textAlign: 'center',
                      }}
                    >
                      {item?.hiringDate
                        ? moment(item?.hiringDate).format('MMM DD, YYYY')
                        : '—'}
                    </Text>
                  </View>
                ))}
              </View>
            </View>
          ) : null}
        </View>
      </View>
    </View>
  );

  const getIndex = () => {
    return isShowPFForm() ? 4 : 3;
  };

  const toRoman = (num: number): string => {
    const romanNumeralMap: [string, number][] = [
      ['M', 1000],
      ['CM', 900],
      ['D', 500],
      ['CD', 400],
      ['C', 100],
      ['XC', 90],
      ['L', 50],
      ['XL', 40],
      ['X', 10],
      ['IX', 9],
      ['V', 5],
      ['IV', 4],
      ['I', 1],
    ];
    let result = '';
    for (const [roman, n] of romanNumeralMap) {
      while (num >= n) {
        result += roman;
        num -= n;
      }
    }
    return result;
  };

  const TOC = () => (
    <View break>
      <Text style={{ ...styles?.formHeading, textAlign: 'center' }}>
        Table of Contents
      </Text>
      <View
        style={{
          ...styles?.flexColumn,
          gap: '8px',
          marginTop: 40,
          marginLeft: 10,
          fontSize: 14,
        }}
      >
        <View style={{ ...styles?.flexRowNoWrap, gap: '2px' }}>
          <Text>1.</Text>
          <Link
            src="#generalBankingPage"
            style={{
              textDecoration: 'none',
              color: '#114684',
              fontWeight: 'bold',
            }}
          >
            {stepperFormLabel && stepperFormLabel?.generalBanking?.trim() !== ''
              ? stepperFormLabel?.generalBanking
              : 'General & Banking'}
          </Link>
        </View>
        <View style={{ ...styles?.flexRowNoWrap, gap: '2px' }}>
          <Text>2.</Text>
          <Link
            src="#legalPage"
            style={{
              textDecoration: 'none',
              color: '#114684',
              fontWeight: 'bold',
            }}
          >
            {stepperFormLabel && stepperFormLabel?.legal?.trim() !== ''
              ? stepperFormLabel?.legal
              : 'Legal'}
          </Link>
        </View>
        {isShowPFForm() && (
          <View style={{ ...styles?.flexRowNoWrap, gap: '2px' }}>
            <Text>3.</Text>
            <Link
              src="#personalFinancial"
              style={{
                textDecoration: 'none',
                color: '#114684',
                fontWeight: 'bold',
              }}
            >
              {stepperFormLabel &&
              stepperFormLabel?.personalFinancial?.trim() !== ''
                ? stepperFormLabel?.personalFinancial
                : 'Personal Financial'}
            </Link>
          </View>
        )}
        {isShowPFForm() &&
          financialInfo?.ownersFinancialInfo?.map((owner: any, i: number) => {
            if (
              owner?.ownerInfo?.nameHoldco ||
              owner?.ownerInfo?.dateOfBirth ||
              owner?.ownerInfo?.position ||
              owner?.ownerInfo?.inPositionSince ||
              owner?.ownerInfo?.ownershipPercentage ||
              isPFSection(owner)
            ) {
              return (
                <View
                  style={{
                    ...styles?.flexRowNoWrap,
                    gap: '2px',
                    paddingLeft: 13,
                  }}
                  key={i}
                >
                  {/* <Text>{`${i + 1})`}</Text> */}
                  <Text style={{ fontSize: 12 }}>{`${toRoman(i + 1)})`}</Text>
                  <Link
                    src={`#personalFinancialPage-${i + 1}`}
                    style={{
                      textDecoration: 'none',
                      fontSize: 10,
                      color: 'black',
                      fontWeight: 'bold',
                    }}
                  >
                    {owner?.ownerInfo?.nameHoldco
                      ? owner?.ownerInfo?.nameHoldco
                      : `Owner ${i + 1}`}
                  </Link>
                </View>
              );
            }
          })}
        <View style={{ ...styles?.flexRowNoWrap, gap: '2px' }}>
          <Text>{getIndex()}.</Text>
          <Link
            src="#aboutPage"
            style={{
              textDecoration: 'none',
              color: '#114684',
              fontWeight: 'bold',
            }}
          >
            {stepperFormLabel && stepperFormLabel?.about?.trim() !== ''
              ? stepperFormLabel?.about
              : 'About'}
          </Link>
        </View>
        {isShowSurety() && (
          <View style={{ ...styles?.flexRowNoWrap, gap: '2px' }}>
            <Text>{getIndex() + 1}.</Text>
            <Link
              src="#suretyPage"
              style={{
                textDecoration: 'none',
                color: '#114684',
                fontWeight: 'bold',
              }}
            >
              {stepperFormLabel && stepperFormLabel?.surety?.trim() !== ''
                ? stepperFormLabel?.surety
                : 'Surety'}
            </Link>
          </View>
        )}
        {isShowInsurance() && (
          <View style={{ ...styles?.flexRowNoWrap, gap: '2px' }}>
            <Text>{getIndex() + (isShowSurety() ? 2 : 1)}.</Text>
            <Link
              src="#insurancePage"
              style={{
                textDecoration: 'none',
                color: '#114684',
                fontWeight: 'bold',
              }}
            >
              {stepperFormLabel && stepperFormLabel?.insurance?.trim() !== ''
                ? stepperFormLabel?.insurance
                : 'Insurance'}
            </Link>
          </View>
        )}
      </View>
    </View>
  );

  return (
    <Document style={{ fontFamily: 'Nunito' }}>
      <Page size="A4" style={styles.page}>
        <PDFHeader />

        <View>
          {heading ? <PDFHeading /> : <CompanyName />}
          {contactInfo && <CompanyDetails />}
          {title && <SubTitle />}
          {summary && <Address />}
        </View>

        <TOC />
        <AccountingSystemNew />
        <CreditBureauNew />
        {isShowPFForm() && <PresonalFinancial />}
        <AboutNew />
        {(surety?.suretyName ||
          surety?.totalWorkOnHand ||
          surety?.singleJob ||
          surety?.reasonChangingSurety ||
          typeof surety?.refusedBond === 'boolean' ||
          (!!surety?.whoSignSeal &&
            !isEveryObjKeyNull(surety?.whoSignSeal))) && <SuretyNew />}
        {(typeof insurance?.keyManLifeInsurance?.hasKeyManLifeInsurance ===
          'boolean' ||
          typeof insurance?.commercialGeneralLiabilityInsurance
            ?.hasCommercialGeneralLiabilityInsurance === 'boolean' ||
          typeof insurance?.environmentalInsurance
            ?.hasEnvironmentalInsurance === 'boolean' ||
          typeof insurance?.errorsAndOmissionsInsurance
            ?.hasErrorsAndOmissionsInsurance === 'boolean' ||
          typeof insurance?.fidelityInsurance?.hasFidelityInsurance ===
            'boolean' ||
          typeof insurance?.equipmentInsurance?.hasEquipmentInsurance ===
            'boolean' ||
          typeof insurance?.otherInsurance?.hasOtherInsurance ===
            'boolean') && <InsuranceNew />}

        {/* <UserAddress /> */}
        {/* <TableHead />
        <TableBody />
        <TableTotal /> */}
        <PDFFooter />
      </Page>
    </Document>
  );
};

export default ApplicationPDF;
