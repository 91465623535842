import { HStack, Tooltip } from '@chakra-ui/react';
import { Box, Flex, MultiSelect, Text, TextInput } from '@mantine/core';
import type React from 'react';
import { useMemo } from 'react';
import { HiMiniInformationCircle } from 'react-icons/hi2';
import { useEfficiencyContext } from 'src/context/EfficiencyRatioContext';
import {
  CalculationFields,
  calculateTotalWthPLBS,
  getOptionWithPLBSReports,
  getStyles,
} from '../../data';

interface CalculationDisplayUTNProps {
  label: string;
  calculationFields: CalculationFields;
}

export const CalculationDisplayUTN: React.FC<CalculationDisplayUTNProps> = ({
  label,
  calculationFields,
}) => {
  const {
    costsInExcessOfBillings,
    setCostsInExcessOfBillings,
    reportReferenceData,
    tangibleNetworth,
    historyData,
  } = useEfficiencyContext();

  const isCostsInExcessOfBillingsOption =
    !!calculationFields?.costsInExcessOfBillings?.fields?.length;
  const isCostsInExcessOfBillingsValue = !!calculationFields
    ?.costsInExcessOfBillings?.value
    ? calculationFields?.costsInExcessOfBillings?.value
    : 0;

  const totalCostsInExcessOfBillingsSelectedFieldSumInitial = useMemo(() => {
    return calculateTotalWthPLBS(
      costsInExcessOfBillings,
      calculationFields?.costsInExcessOfBillings,
      reportReferenceData
    );
  }, []);
  const costsInExcessOfBillingsTotalInitialValue =
    isCostsInExcessOfBillingsValue -
    totalCostsInExcessOfBillingsSelectedFieldSumInitial;

  const costsInExcessOfBillingsTotal = useMemo(() => {
    const totalSumSelectedFields = calculateTotalWthPLBS(
      costsInExcessOfBillings,
      calculationFields?.costsInExcessOfBillings,
      reportReferenceData
    );
    return Number(
      Number(
        totalSumSelectedFields + costsInExcessOfBillingsTotalInitialValue
      )?.toFixed(2)
    );
  }, [costsInExcessOfBillings, calculationFields?.costsInExcessOfBillings]);

  // const isTangibleNetworthOption =
  //   !!calculationFields?.tangibleNetworth?.fields?.length;
  // const isTangibleNetworthValue = !!calculationFields?.tangibleNetworth?.value
  //   ? calculationFields?.tangibleNetworth?.value
  //   : 0;

  // const costsInExcessOfBillingsTotal = useMemo(() => {
  //   const totalSum =
  //     calculateTotalWthPLBS(
  //       costsInExcessOfBillings,
  //       calculationFields?.costsInExcessOfBillings,
  //       reportReferenceData
  //     ) +
  //     (isCostsInExcessOfBillingsOption ? 0 : isCostsInExcessOfBillingsValue);
  //   return Number(totalSum?.toFixed(2));
  // }, [costsInExcessOfBillings, calculationFields?.costsInExcessOfBillings]);

  // const tangibleNetworthTotal = useMemo(() => {
  //   const totalSum =
  //     calculateTotalWthPLBS(
  //       tangibleNetworth,
  //       calculationFields?.tangibleNetworth,
  //       reportReferenceData
  //     ) + (isTangibleNetworthOption ? 0 : isTangibleNetworthValue);
  //   return Number(totalSum?.toFixed(2));
  // }, [tangibleNetworth, calculationFields?.tangibleNetworth]);

  const result = useMemo(() => {
    const answer = Number(
      (costsInExcessOfBillingsTotal / tangibleNetworth).toFixed(2)
    );
    return !!tangibleNetworth ? answer : 'N/A';
  }, [costsInExcessOfBillingsTotal, tangibleNetworth]);

  return (
    <Box w={'100%'}>
      <HStack gap={2} mb="md">
        <Text size="lg" weight={700}>
          {label}
        </Text>
        {!!historyData?.underBillingsToTangibleNetWorth && (
          <Tooltip
            label={`${label} (${historyData?.underBillingsToTangibleNetWorth?.value}) is updated by ${historyData?.underBillingsToTangibleNetWorth?.updatedBy}`}
            placement="right"
            hasArrow
          >
            <Box>
              <HiMiniInformationCircle
                size={18}
                color="black"
                cursor="pointer"
              />
            </Box>
          </Tooltip>
        )}
      </HStack>
      <Flex direction="column" align="stretch" gap="md">
        <Flex wrap={'wrap'} align="center" justify={'flex-start'} gap="xs">
          <MultiSelect
            label={`Costs in Excess of Billings (${costsInExcessOfBillingsTotal})`}
            data={getOptionWithPLBSReports(
              calculationFields?.costsInExcessOfBillings?.fields!,
              reportReferenceData!
            )}
            value={costsInExcessOfBillings}
            onChange={setCostsInExcessOfBillings}
            placeholder="Select Costs in Excess of Billings items"
            searchable
            className="legalform_pdfPreference"
            maxDropdownHeight={400}
            sx={{ flex: 1 }}
            styles={getStyles(
              calculationFields?.costsInExcessOfBillings?.fields!
            )}
            readOnly
          />
          <Text size="xl" weight={700} mt={'xs'}>
            ÷
          </Text>
          {/* <MultiSelect
            label={`Tangible Networth (${tangibleNetworthTotal})`}
            data={getOptionWithPLBSReports(
              calculationFields?.tangibleNetworth?.fields!,
              reportReferenceData!
            )}
            value={tangibleNetworth}
            onChange={setTangibleNetworth}
            placeholder="Select Tangible Networth items"
            searchable
            className="legalform_pdfPreference"
            maxDropdownHeight={400}
            sx={{ flex: 1 }}
          /> */}

          <TextInput
            label="Tangible Networth"
            value={tangibleNetworth?.toFixed(2) ?? 0}
            disabled
            styles={{
              input: {
                textAlign: 'right',
                '&:disabled': {
                  color: 'black',
                  fontWeight: 'bold',
                },
              },
            }}
            sx={{ flex: 1 }}
          />
        </Flex>
        <Flex align="center" justify="flex-start" wrap={'wrap'} gap={'xs'}>
          {/* Equals Sign */}
          <Text size="xl" weight={700} mt={'xs'}>
            =
          </Text>
          {/* Result */}
          <TextInput
            label="Result"
            value={result ?? 0}
            disabled
            styles={{
              input: {
                textAlign: 'right',
                '&:disabled': {
                  color: 'black',
                  fontWeight: 'bold',
                },
              },
            }}
            maw={'50%'}
            w={'100%'}
          />
        </Flex>
      </Flex>
    </Box>
  );
};
