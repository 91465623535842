import {
  Box,
  Button,
  Flex,
  Grid,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Modal, Title } from '@mantine/core';
import { useFormik } from 'formik';
import { MRT_Row, MRT_TableInstance } from 'mantine-react-table';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useAppToast } from 'src/hooks';
import { updateARRecord } from 'src/Redux/Broker/Reports/APAgingSummary/slice';
import { updateOcrReportRecord } from 'src/Redux/OcrReports/slice';
import {
  ADARow,
  AdjustedTotalRow,
  OCRhistoryInfo,
} from 'src/Redux/OcrReports/state';
import { useAppDispatch, useAppSelector } from 'src/Redux/Store';
import {
  getDateFormat,
  getRandomUUID,
  preventAlphabetsTyping,
  removeFields,
  trimStringValues,
} from 'src/utils/helpers';
import { ARSchemaEdit } from 'src/ValidationSchema/Pages/Index';
import { TAccountReceivableRow } from '../AccountReceivableTable';
import {
  calculateEachRowTotal,
  updateAPARLastTotalRow,
  updateAPARReportConsecutiveTotals,
} from '../utils';

interface EditARReportProps {
  row: MRT_Row<Record<string, any>> | null;
  table: MRT_TableInstance<Record<string, any>> | null;
  modalOpen: boolean;
  onClose: () => void;
  isEditable: boolean;
  tableData: Record<string, any>[];
  ocrReportId: number | null;
}

const EditAccountReceivable = ({
  table,
  row,
  modalOpen,
  onClose,
  isEditable,
  tableData,
  ocrReportId,
}: EditARReportProps) => {
  const [recordId, setRecordId] = useState<number | null>(null);
  const [btnLoader, setBtnLoader] = useState<boolean>(false);
  const [formSubmit, setFormSubmit] = useState<boolean>(false);
  const accountPayableReport = useAppSelector((state) => state.accountPayable);
  const dispatch = useAppDispatch();
  const toast = useAppToast();
  const userId = localStorage.getItem('userID');
  const userType = localStorage.getItem('userType');
  const brokerSlice = useAppSelector((state) => state.broker.brokerProfileData);
  const applicantSlice = useAppSelector((state) => state.applicantProfile);
  const userName =
    userType === 'applicant'
      ? applicantSlice?.user?.data?.companyName
      : userType === 'broker'
        ? brokerSlice?.data?.firstName + ' ' + brokerSlice?.data?.lastName
        : '';
  const ocrReportSlice = useAppSelector((state) => state.ocrReportSlice);

  interface EditARFormValues {
    question: string;
    holdbackRetention: number | null;
    retentionValue: number | null;
    tableRow: Record<string, any> | null;
    id: number;
  }

  const initialValues: EditARFormValues = {
    question: 'Amount',
    holdbackRetention: null,
    retentionValue: null,
    tableRow: row && row?.original,
    id: row && row?.original && row?.original?.id,
  };

  useEffect(() => {
    console.log('tableRow', formik.values.tableRow, row, table);
    formik.setValues({
      question: row?.original?.question ?? 'Amount',
      holdbackRetention: row?.original?.holdbackRetention,
      retentionValue: row?.original?.retentionValue,
      tableRow: row && row?.original,
      id: row && row?.original && row?.original?.id,
    });
  }, [row?.original]);

  const submitARHandler = async (values: EditARFormValues) => {
    try {
      console.log('submited', values);
      setFormSubmit(true);
      const updatedARData = trimStringValues(
        removeFields(values, ['tableRow'])
      );
      let payload = {
        id: recordId,
        updatedData: Array(updatedARData),
      };
      console.log('payload', payload);
      if (isEditable) {
        if (!!tableData?.length && !!ocrReportId) {
          const updatedTData = tableData?.map((el, index) =>
            el?.id === values?.id
              ? {
                  ...el,
                  holdbackRetention: updatedARData?.holdbackRetention,
                  retentionValue: updatedARData?.retentionValue,
                  question: updatedARData?.question,
                  id: values?.id,
                }
              : el
          );
          const totalLabel = Object.keys(updatedTData[0]).find((el) =>
            el?.toLocaleLowerCase().startsWith('t')
          );
          console.log('totalLabel', totalLabel);

          const eachRowTotalUpdate = calculateEachRowTotal(
            updatedTData,
            totalLabel
          );
          const updateConsecutiveTotals =
            updateAPARReportConsecutiveTotals(eachRowTotalUpdate);
          const removeAdaAdjustedTotalRow = updateConsecutiveTotals?.filter(
            (item) =>
              ![AdjustedTotalRow.AdjustedTotal, ADARow.ADA].includes(
                item?.Label
              )
          );

          const finalValue = updateAPARLastTotalRow(removeAdaAdjustedTotalRow);
          console.log(
            'final',
            eachRowTotalUpdate,
            updateConsecutiveTotals,
            finalValue
          );

          const currentDateTime = moment(new Date()).toISOString();
          const msgFormatDate = getDateFormat(
            currentDateTime,
            'MMM DD,YYYY hh:mm A'
          );
          console.log('row?.original', row);

          let cols = Object.keys(row?.original!);
          const changes: any[] = [];
          cols.forEach((obj) => {
            if (obj !== 'id') {
              if (
                row?.original[obj as keyof TAccountReceivableRow] !==
                row?._valuesCache[obj]
              )
                changes.push({
                  columnName: obj !== 'Label' ? obj : 'Customer',
                  oldvalue: row?.original[obj as keyof TAccountReceivableRow],
                  newValue: row?._valuesCache[obj],
                });
            }
          });
          console.log('row_id=', Object.keys(tableData[0]));
          console.log('col=', changes);
          const history: OCRhistoryInfo = {
            id: getRandomUUID(),
            message: `Row ${row?.original?.Label} updated on ${msgFormatDate} by ${userName}`,
            user_id: Number(userId),
            row_id: row?.original?.id!,
            date: currentDateTime,
            changes: changes,
          };

          dispatch(
            updateOcrReportRecord({
              id: ocrReportId,
              ocrReportData: finalValue,
              history,
            })
          );
        }
      } else {
        //OBO
        await dispatch(updateARRecord({ payload }) as any);
      }
    } catch (err) {
      console.log('error', err);
      setFormSubmit(false);
    }
  };
  const formik = useFormik({
    initialValues,
    validationSchema: ARSchemaEdit(isEditable),
    onSubmit: submitARHandler,
  });

  useEffect(() => {
    return () => {
      if (!modalOpen) {
        setFormSubmit(false);
      }
    };
  }, [modalOpen]);

  useEffect(() => {
    const { status, type, error, msg } = ocrReportSlice;
    switch (status) {
      case 'loading':
        {
          if (type === 'UPDATE_OCR_REPORT') {
            setBtnLoader(true);
          }
        }
        break;
      case 'succeed':
        {
          if (type === 'UPDATE_OCR_REPORT') {
            setBtnLoader(false);
            onClose();
          }
        }
        break;
      case 'failed':
        {
          if (type === 'UPDATE_OCR_REPORT') {
            setBtnLoader(false);
            onClose();
          }
        }
        break;
      default:
        break;
    }
  }, [ocrReportSlice.status]);

  useEffect(() => {
    switch (accountPayableReport.status) {
      case 'succeed': {
        if (accountPayableReport.type === 'GET_ACCOUNT_RECEIVABLE_DATA') {
          if ((accountPayableReport?.data?.reports as any)?.id) {
            setRecordId(
              (accountPayableReport?.data?.reports as any)?.id as any
            );
          }
        } else if (
          accountPayableReport.type === 'UPDATE_ACCOUNT_RECEIVABLE_RECORD' &&
          formSubmit
        ) {
          setBtnLoader(false);
          toast({
            title: 'Success',
            status: 'success',
          });
          onClose();
        }
        return;
      }
      case 'loading': {
        if (
          accountPayableReport.type === 'UPDATE_ACCOUNT_RECEIVABLE_RECORD' &&
          formSubmit
        ) {
          setBtnLoader(true);
        }
        return;
      }
      case 'failed': {
        if (
          accountPayableReport.type === 'UPDATE_ACCOUNT_RECEIVABLE_RECORD' &&
          formSubmit
        ) {
          setBtnLoader(false);
          toast({
            title: accountPayableReport.error,
            status: 'error',
          });
          onClose();
        }
        return;
      }
    }
  }, [accountPayableReport.status]);

  useEffect(() => {
    console.log('formik', formik);

    if (!!formik.values.holdbackRetention) {
      const retentionValue =
        (formik.values?.holdbackRetention *
          formik?.values?.tableRow?.[
            isEditable ? '91 AND OVER' : '91 and over'
          ]) /
        100;
      if (formik.values.question === 'Percentage') {
        formik.setFieldValue('retentionValue', retentionValue);
      } else {
        formik.setFieldValue(
          'retentionValue',
          formik.values?.holdbackRetention
        );
      }
    } else {
      formik.setFieldValue('retentionValue', null);
    }
  }, [formik.values.holdbackRetention]);

  useEffect(() => {
    if (formik.values.question === (row?.original?.question || 'Amount')) {
      formik.setFieldValue(
        'holdbackRetention',
        row?.original?.holdbackRetention
      );
      formik.setFieldValue('retentionValue', row?.original?.retentionValue);
    }
  }, [formik.values.question]);

  const { values, handleChange, setFieldValue, errors, resetForm } = formik;
  console.log('formik', formik, recordId);

  const getRetention = (number: number | null) => {
    return number ? number?.toFixed(2) : null;
  };

  return (
    <Modal opened={modalOpen} onClose={onClose} withCloseButton={true}>
      <form onSubmit={formik.handleSubmit}>
        <Stack>
          <Title order={3}>Edit Data</Title>
          <Grid gridTemplateColumns={'1fr'} gap={4}>
            <Box>
              <Text
                fontWeight="600"
                display="flex"
                justifyContent="start"
                fontSize={13}
                flexDir={'column'}
              >
                How do you want to add Holdback / Retention?
              </Text>
              <RadioGroup
                value={values?.question ?? ''}
                onChange={(value) => {
                  setFieldValue('question', value);
                  setFieldValue('holdbackRetention', null);
                  setFieldValue('retentionValue', null);
                }}
              >
                <Stack
                  direction="row"
                  display={'flex'}
                  flexWrap={'wrap'}
                  mt={2}
                >
                  <Radio value="Amount" size="sm">
                    Amount
                  </Radio>
                  <Radio value="Percentage" size="sm">
                    Percentage
                  </Radio>
                </Stack>
              </RadioGroup>
            </Box>
            <Box display={'flex'} flexDir={'column'} gap={1}>
              <Text
                fontWeight="600"
                display="flex"
                justifyContent="start"
                fontSize={13}
                flexDir={'column'}
              >
                {values?.question === 'Amount' ? '$' : ''} Add{' '}
                {values?.question}{' '}
                {values?.question === 'Percentage' ? '(%)' : ''}
              </Text>
              <Input
                fontSize={'13px'}
                w="100%"
                type="number"
                placeholder={`${values?.question === 'Amount' ? '$' : ''} Add ${values?.question} ${values?.question === 'Percentage' ? '(%)' : ''}`}
                name="holdbackRetention"
                value={values?.holdbackRetention ?? ''}
                onChange={handleChange}
                onWheel={(e) => (e.target as HTMLInputElement).blur()}
                onKeyDown={preventAlphabetsTyping}
              />
              {errors.holdbackRetention && (
                <div
                  style={{
                    color: 'red',
                    fontSize: '13px',
                    textAlign: 'start',
                    fontWeight: '500',
                  }}
                >
                  {errors.holdbackRetention}
                </div>
              )}
            </Box>
            {values?.question !== 'Amount' && (
              <Box display={'flex'} flexDir={'column'} gap={1}>
                <Text
                  fontWeight="600"
                  display="flex"
                  justifyContent="start"
                  fontSize={13}
                  flexDir={'column'}
                >
                  Holdback / Retention
                </Text>
                <Input
                  fontSize={'13px'}
                  w="100%"
                  type="number"
                  placeholder={`Holdback / Retention`}
                  name="retentionValue"
                  // value={values?.retentionValue ?? ''}
                  value={getRetention(values?.retentionValue) ?? ''}
                  onChange={handleChange}
                  onWheel={(e) => (e.target as HTMLInputElement).blur()}
                  onKeyDown={preventAlphabetsTyping}
                  isDisabled={true}
                  _disabled={{
                    opacity: 1,
                  }}
                />
              </Box>
            )}
          </Grid>
          <Flex justify="flex-end" mt="xl">
            <Button type="button" variant="white" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" isLoading={btnLoader}>
              Submit
            </Button>
            {/* <MRT_EditActionButtons variant="text" table={table} row={row} /> */}
          </Flex>
        </Stack>
      </form>
    </Modal>
  );
};

export default EditAccountReceivable;
