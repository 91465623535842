import { IOcrReportIssueList } from 'src/Redux/Admin/OcrReportIssues/state';

export interface ocrIssueTicketStatusOptions {
  value: 'in-progress' | 'completed' | 'open';
  label: string;
}
export interface OCRIssueStatusOptionsCompletedStatus {
  value: 'reopened' | 'closed';
  label: string;
}

export interface supportTicketStateSchema {
  ocrIssueStatus: string;
  comment: string;
}

export const ocrIssueTicketStatusOptions: ocrIssueTicketStatusOptions[] = [
  {
    value: 'open',
    label: 'Open',
  },
  {
    value: 'in-progress',
    label: 'In Progress',
  },
  {
    value: 'completed',
    label: 'Resolved',
  },
];

export const OCRIssueStatusOptionsCompletedStatus: OCRIssueStatusOptionsCompletedStatus[] =
  [
    {
      value: 'reopened',
      label: 'Reopened',
    },
    {
      value: 'closed',
      label: 'Closed',
    },
  ];

export const getOCRIssueStatusOptions = (userType: string, status: string) => {
  if (userType === 'broker' && status === 'completed') {
    return OCRIssueStatusOptionsCompletedStatus;
  } else {
    return ocrIssueTicketStatusOptions;
  }
};

export const getOcrReportIssueTableData = (data: IOcrReportIssueList[]) => {
  const tData = data?.map((item) => ({
    id: item.id,
    reportId: item.reportId,
    reason: item.reason,
    comment: item.comment,
    status: item.status,
    uniqueId: item.uniqueId,
    ageing: item.ageing,
    applicantName: item.applicantName,
    brokerName: item.brokerName,
    brokerEmail: item.brokerEmail,
    applicantEmail: item.applicantEmail,
    createdAt: item.createdAt,
    updatedAt: item.updatedAt,
    report: {
      id: item.report.id,
      applicantName: item.report.applicantName,
      brokerName: item.report.brokerName,
      applicantId: item.report.applicantId,
      reportInfo: {
        type: item.report.reportInfo.type,
        endDate: item.report.reportInfo.endDate,
        startDate: item.report.reportInfo.startDate,
        timeFrame: item.report.reportInfo.timeFrame,
        reportName: item.report.reportInfo.reportName,
        financialPeriod: item.report.reportInfo.financialPeriod,
        fiscalYearEndPeriodDate: item.report.reportInfo.fiscalYearEndPeriodDate,
        fiscalYearStartPeriodDate:
          item.report.reportInfo.fiscalYearStartPeriodDate,
      },
      reportLinks: item.report.reportLinks,
      reportUploadedBy: item.report.reportUploadedBy,
      hasDeletedOcrReports: item.report.hasDeletedOcrReports,
      reportStatus: item.report.reportStatus,
      reasonForOcrFailure: item.report.reasonForOcrFailure,
    },
    history: item?.history,
  }));
  return tData;
};

export const getData = (data: IOcrReportIssueList) => {
  const rowData = {
    id: data.id,
    reportId: data.reportId,
    reason: data.reason,
    comment: data.comment,
    status: data.status,
    ageing: data.ageing,
    uniqueId: data.uniqueId,
    applicantName: data.applicantName,
    brokerName: data.brokerName,
    createdAt: data.createdAt,
    updatedAt: data.updatedAt,
    report: {
      id: data.report.id,
      applicantName: data.report.applicantName,
      brokerName: data.report.brokerName,
      applicantId: data.report.applicantId,
      reportInfo: {
        type: data.report.reportInfo.type,
        endDate: data.report.reportInfo.endDate,
        startDate: data.report.reportInfo.startDate,
        timeFrame: data.report.reportInfo.timeFrame,
        reportName: data.report.reportInfo.reportName,
        financialPeriod: data.report.reportInfo.financialPeriod,
        fiscalYearEndPeriodDate: data.report.reportInfo.fiscalYearEndPeriodDate,
        fiscalYearStartPeriodDate:
          data.report.reportInfo.fiscalYearStartPeriodDate,
      },
      reportLinks: data.report.reportLinks,
      reportUploadedBy: data.report.reportUploadedBy,
      hasDeletedOcrReports: data.report.hasDeletedOcrReports,
      reportStatus: data.report.reportStatus,
      reasonForOcrFailure: data.report.reasonForOcrFailure,
    },
    history: data?.history,
  };
  return rowData;
};

export const getIssueCurrentStatus = (status: string) => {
  let bgColor = '';
  let textColor = '';
  let updatedStatus = status;
  switch (status) {
    case 'open':
      bgColor = 'orange.100';
      textColor = 'orange.800';
      break;
    case 'completed':
      bgColor = 'green.100';
      textColor = 'green.800';
      updatedStatus = 'Resolved';
      break;
    case 'in-progress':
      bgColor = 'blue.100';
      textColor = 'blue.800';
      break;
    case 'reopened':
      bgColor = 'purple.100';
      textColor = 'purple.800';
      break;
    case 'closed':
      bgColor = 'red.100';
      textColor = 'red.800';
      break;
    default:
      bgColor = 'gray.300';
      textColor = 'gray.800';
  }
  return {
    bgColor,
    textColor,
    updatedStatus,
  };
};

export const initialSupportTicketState: supportTicketStateSchema = {
  ocrIssueStatus: '',
  comment: '',
};
