import { Box, Flex } from '@chakra-ui/react';
import {
  ActionIcon,
  Button,
  Group,
  Modal,
  Text,
  TextInput,
} from '@mantine/core';
import type React from 'react';
import { useState } from 'react';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';

interface PasswordPromptProps {
  isOpen: boolean;
  onClose: () => void;
  formik: Record<string, any>;
}

export const PasswordPrompt: React.FC<PasswordPromptProps> = ({
  isOpen,
  onClose,
  formik,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      title="Authentication Required"
      closeOnClickOutside={false}
      closeOnEscape={false}
      withCloseButton={false}
    >
      <Text size="sm" mb="md">
        Please enter the password to access this website:
      </Text>
      <form onSubmit={formik.handleSubmit}>
        <Flex flexDir={'column'} gap={2}>
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            border={'1px solid #ccc'}
            borderRadius={'6px'}
            px={'5px'}
          >
            <TextInput
              placeholder="Enter password"
              value={formik.values.password}
              onChange={formik.handleChange}
              name="password"
              type={showPassword ? 'text' : 'password'}
              style={{ flex: 1 }}
              styles={{
                input: {
                  border: 'none',
                },
              }}
            />

            <ActionIcon
              onClick={handleTogglePassword}
              variant="transparent"
              size="sm"
              aria-label={showPassword ? 'Hide password' : 'Show password'}
            >
              {showPassword ? (
                <MdVisibility size={25} />
              ) : (
                <MdVisibilityOff size={25} />
              )}
            </ActionIcon>
          </Box>
          {formik.touched.password && formik.errors.password && (
            <div
              style={{
                color: 'red',
                fontSize: '13px',
                textAlign: 'start',
                fontWeight: '500',
              }}
            >
              {formik.errors.password}
            </div>
          )}
        </Flex>
        <Group position="right" mt="md">
          <Button type="submit">Submit</Button>
        </Group>
      </form>
    </Modal>
  );
};
