import { HStack, Tooltip } from '@chakra-ui/react';
import { Box, Flex, MultiSelect, Text, TextInput } from '@mantine/core';
import type React from 'react';
import { useMemo } from 'react';
import { HiMiniInformationCircle } from 'react-icons/hi2';
import { useLiquidityContext } from 'src/context/LiquidityRatioContext';
import {
  CalculationFields,
  calculateTotalWthPLBS,
  getBSAssetsOptions,
  getOptionWithPLBSReports,
  getStyles,
} from '../../data';

interface CalculationDisplayOverbillingsToNetCashProps {
  label: string;
  calculationFields: CalculationFields;
}

export const CalculationDisplayOverbillingsToNetCash: React.FC<
  CalculationDisplayOverbillingsToNetCashProps
> = ({ label, calculationFields }) => {
  const {
    billingInExcessOfCosts,
    setBillingInExcessOfCosts,
    totalCashAndCashEquivalents,
    setTotalCashAndCashEquivalents,
    reportReferenceData,
    historyData,
  } = useLiquidityContext();

  const isBillingsInExcessOfCostsOption =
    !!calculationFields?.billingInExcessOfCosts?.fields?.length;
  const isBillingsInExcessOfCostsValue = !!calculationFields
    ?.billingInExcessOfCosts?.value
    ? calculationFields?.billingInExcessOfCosts?.value
    : 0;
  const isCashAndCashEquivalentsOption =
    !!calculationFields?.totalCashAndCashEquivalents?.fields?.length;
  const isCashAndCashEquivalentsValue = !!calculationFields
    ?.totalCashAndCashEquivalents?.value
    ? calculationFields?.totalCashAndCashEquivalents?.value
    : 0;

  const totalBillingsInExcessOfCostsSelectedFieldSumInitial = useMemo(() => {
    return calculateTotalWthPLBS(
      billingInExcessOfCosts,
      calculationFields?.billingInExcessOfCosts,
      reportReferenceData
    );
  }, []);
  const billingsInExcessOfCostsTotalInitialValue =
    isBillingsInExcessOfCostsValue -
    totalBillingsInExcessOfCostsSelectedFieldSumInitial;

  const totalCashEquivalentsSelectedFieldSumInitial = useMemo(() => {
    return calculateTotalWthPLBS(
      totalCashAndCashEquivalents,
      calculationFields?.totalCashAndCashEquivalents,
      reportReferenceData
    );
  }, []);
  const cashEquivalentsTotalInitialValue =
    isCashAndCashEquivalentsValue - totalCashEquivalentsSelectedFieldSumInitial;

  const billingsInExcessOfCostsTotal = useMemo(() => {
    const totalSumSelectedFields = calculateTotalWthPLBS(
      billingInExcessOfCosts,
      calculationFields?.billingInExcessOfCosts,
      reportReferenceData
    );
    return Number(
      Number(
        totalSumSelectedFields + billingsInExcessOfCostsTotalInitialValue
      )?.toFixed(2)
    );
  }, [billingInExcessOfCosts, calculationFields?.billingInExcessOfCosts]);

  const cashAndCashEquivalentsTotal = useMemo(() => {
    const totalSumSelectedFields = calculateTotalWthPLBS(
      totalCashAndCashEquivalents,
      calculationFields?.totalCashAndCashEquivalents,
      reportReferenceData
    );
    return Number(
      Number(
        totalSumSelectedFields + cashEquivalentsTotalInitialValue
      )?.toFixed(2)
    );
  }, [
    totalCashAndCashEquivalents,
    calculationFields?.totalCashAndCashEquivalents,
  ]);

  // const billingsInExcessOfCostsTotal = useMemo(() => {
  //   const totalSum =
  //     calculateTotalWthPLBS(
  //       billingInExcessOfCosts,
  //       calculationFields.billingInExcessOfCosts,
  //       reportReferenceData
  //     ) +
  //     (isBillingsInExcessOfCostsOption ? 0 : isBillingsInExcessOfCostsValue);
  //   return Number(totalSum?.toFixed(2));
  // }, [billingInExcessOfCosts, calculationFields.billingInExcessOfCosts]);

  // const cashAndCashEquivalentsTotal = useMemo(() => {
  //   const totalSum =
  //     calculateTotalWthPLBS(
  //       totalCashAndCashEquivalents,
  //       calculationFields.totalCashAndCashEquivalents,
  //       reportReferenceData
  //     ) + (isCashAndCashEquivalentsOption ? 0 : isCashAndCashEquivalentsValue);
  //   return Number(totalSum?.toFixed(2));
  // }, [totalCashAndCashEquivalents, calculationFields.totalCashAndCashEquivalents]);

  const result = useMemo(() => {
    const answer = Number(
      (billingsInExcessOfCostsTotal / cashAndCashEquivalentsTotal).toFixed(2)
    );
    return !!cashAndCashEquivalentsTotal ? answer : 'N/A';
  }, [billingsInExcessOfCostsTotal, cashAndCashEquivalentsTotal]);

  return (
    <Box w={'100%'}>
      <HStack gap={2} mb="md">
        <Text size="lg" weight={700}>
          {label}
        </Text>
        {!!historyData?.overBillingsToNetCash && (
          <Tooltip
            label={`${label} (${historyData?.overBillingsToNetCash?.value}) is updated by ${historyData?.overBillingsToNetCash?.updatedBy}`}
            placement="right"
            hasArrow
          >
            <Box>
              <HiMiniInformationCircle
                size={18}
                color="black"
                cursor="pointer"
              />
            </Box>
          </Tooltip>
        )}
      </HStack>
      <Flex direction="column" align="stretch" gap="md">
        <Flex wrap={'wrap'} align="center" justify={'flex-start'} gap="xs">
          <MultiSelect
            label={`Billings in Excess of Costs (${billingsInExcessOfCostsTotal})`}
            data={getOptionWithPLBSReports(
              calculationFields?.billingInExcessOfCosts?.fields!,
              reportReferenceData!
            )}
            value={billingInExcessOfCosts}
            onChange={setBillingInExcessOfCosts}
            placeholder="Select Billings in Excess of Costs items"
            searchable
            className="legalform_pdfPreference"
            maxDropdownHeight={400}
            sx={{ flex: 1 }}
            styles={getStyles(
              calculationFields?.billingInExcessOfCosts?.fields!
            )}
            readOnly
          />
          <Text size="xl" weight={700} mt={'xs'}>
            ÷
          </Text>
          <MultiSelect
            label={`Cash and Cash Equivalents (${cashAndCashEquivalentsTotal})`}
            data={getBSAssetsOptions(
              calculationFields?.totalCashAndCashEquivalents?.fields!,
              reportReferenceData!
            )}
            value={totalCashAndCashEquivalents}
            onChange={setTotalCashAndCashEquivalents}
            placeholder="Select Cash and Cash Equivalents items"
            searchable
            className="legalform_pdfPreference"
            maxDropdownHeight={400}
            sx={{ flex: 1 }}
          />
        </Flex>
        <Flex align="center" justify="flex-start" wrap={'wrap'} gap={'xs'}>
          {/* Equals Sign */}
          <Text size="xl" weight={700} mt={'xs'}>
            =
          </Text>
          {/* Result */}
          <TextInput
            label="Result"
            value={result ?? 0}
            disabled
            styles={{
              input: {
                textAlign: 'right',
                '&:disabled': {
                  color: 'black',
                  fontWeight: 'bold',
                },
              },
            }}
            maw={'50%'}
            w={'100%'}
          />
        </Flex>
      </Flex>
    </Box>
  );
};
