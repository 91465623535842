import { Box, Flex, Heading, Select, Text } from '@chakra-ui/react';
import { Tabs } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApplicantBrokerage } from 'src/Redux/Broker/Dashboard/BrokerageDashboard';
import { IApplicantData } from 'src/Redux/Broker/Reports/WorkInProgress/state';
import { useAppDispatch, useAppSelector } from 'src/Redux/Store';
import BondActivityReport from './BondActivityReport';
import ClientProfileReport from './ClientProfileReport';

const BondFacilityTracker = () => {
  const dispatch = useAppDispatch();
  const brokerageDashboard = useAppSelector(
    (state) => state.brokerageDashboard
  );
  const applicantUser = useAppSelector((state) => state.applicantProfile?.user);
  const [applicantOptions, setApplicantOptions] = useState<IApplicantData[]>(
    []
  );
  const userType = localStorage.getItem('userType');
  const navigate = useNavigate();
  const [selectedApplicant, setSelectedApplicant] = useState('');
  const [bondActivityEditMode, setBondActivityEditMode] = useState(false);
  const [clientProfileEditMode, setClientProfileEditMode] = useState(false);
  const [disableApplicant, setDisableApplicant] = useState(false);
  const [activeTab, setActiveTab] = useState<string | null>('bond');

  useEffect(() => {
    if (!brokerageDashboard.applicantsByBrokrageData) {
      dispatch(ApplicantBrokerage());
    } else {
      setApplicantOptions(brokerageDashboard.applicantsByBrokrageData);
    }
    if (userType === 'applicant') {
      // if (
      //   applicantUser?.data?.activeSuretyPlan &&
      //   applicantUser?.data?.isBondApplicationVisible
      // ) {
      setSelectedApplicant(applicantUser?.data?.applicantId);
      // } else navigate('/applicant/surety-hub');
    }
  }, [brokerageDashboard.applicantsByBrokrageData, applicantUser]);

  /**
   * Called when selected applicant changed from dropdown
   * @param event
   */
  const handleApplicantChange = (
    event: React.ChangeEvent<HTMLSelectElement> | string
  ) => {
    const selectedId = typeof event === 'string' ? event : event.target.value;
    setSelectedApplicant(selectedId);
  };

  /**
   * Called when Bond activity is in edit mode
   * @param editMode
   */
  const handleEditModeBA = (editMode: boolean) => {
    setBondActivityEditMode(editMode);
    setDisableApplicant(editMode);
  };

  /**
   * Called when Client profile is in edit mode
   * @param editMode
   */
  const handleEditModeCP = (editMode: boolean) => {
    setClientProfileEditMode(editMode);
    setDisableApplicant(editMode);
  };

  /**
   * Called when tab changes
   * @param tab
   */
  const handleTabChange = (tab: any) => {
    if (tab === 'client' && !bondActivityEditMode) setActiveTab(tab);
    else if (tab === 'bond' && !clientProfileEditMode) setActiveTab(tab);
  };

  return (
    <Flex direction={'column'} gap={5} p={5}>
      <Heading fontSize={'24px'} fontWeight={'600'}>
        Bond Facility Tracker
      </Heading>
      {userType === 'broker' && (
        <Box maxW="max-content" display={'flex'}>
          <Select
            w="100%"
            name="applicantId"
            borderRadius="5px"
            bg={'#114684'}
            color={'white'}
            textAlign={'center'}
            onChange={handleApplicantChange}
            isDisabled={disableApplicant}
          >
            <option
              value={''}
              style={{ color: 'black' }}
              label="Select Applicant"
            />
            {Array.isArray(applicantOptions) &&
              applicantOptions?.map((user?: any, index?: number) => (
                <option
                  style={{ color: 'black' }}
                  key={index}
                  value={user?.applicantId ?? ''}
                  label={user?.companyName ?? ''}
                />
              ))}
          </Select>
        </Box>
      )}

      {userType === 'broker' && selectedApplicant === '' && (
        <Text size={'xs'} fontWeight={'600'}>
          Choose an applicant to view its Bond Activity & Client Profile Report
        </Text>
      )}
      {selectedApplicant && (
        <Tabs
          defaultValue="bond"
          value={activeTab}
          onTabChange={(e) => {
            handleTabChange(e);
          }}
        >
          <Tabs.List>
            <Tabs.Tab value="bond">Bond Activity Report</Tabs.Tab>
            <Tabs.Tab value="client">Client Profile Report</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="bond">
            <Box p={'2em 0'}>
              <BondActivityReport
                applicantId={selectedApplicant}
                onEditMode={handleEditModeBA}
              ></BondActivityReport>
            </Box>
          </Tabs.Panel>
          <Tabs.Panel value="client">
            <Box p={'2em 0'}>
              <ClientProfileReport
                applicantId={selectedApplicant}
                onEditMode={handleEditModeCP}
              ></ClientProfileReport>
            </Box>
          </Tabs.Panel>
        </Tabs>
      )}
    </Flex>
  );
};

export default BondFacilityTracker;
