export type AnalyticalReportRatioData =
  | 'Profitability'
  | 'Liquidity'
  | 'Efficiency'
  | 'Leverage';

export const analyticalReportRatioOptions: {
  value: AnalyticalReportRatioData;
  label: string;
}[] = [
  {
    value: 'Profitability',
    label: 'Profitability Ratio',
  },
  {
    value: 'Liquidity',
    label: 'Liquidity Ratio',
  },
  {
    value: 'Efficiency',
    label: 'Efficiency Ratio',
  },
  {
    value: 'Leverage',
    label: 'Leverage Ratio',
  },
  // {
  //   value: 'YearOverYearGrowth',
  //   label: 'YoY growth',
  // },
];

export type AnalyticalReportPeriodData =
  | 'Monthly'
  | 'Quarterly'
  | 'Semi Annually'
  | 'Year-to-Date'
  | 'Annual Statements';

export const analyticalReportPeriodOptionsInitial: {
  value: AnalyticalReportPeriodData;
  label: string;
}[] = [
  {
    value: 'Annual Statements',
    label: 'Annual Statements',
  },
  // {
  //   value: 'Semi Annually',
  //   label: 'Semi Annually',
  // },
  // {
  //   value: 'Quarterly',
  //   label: 'Quarterly',
  // },
  // {
  //   value: 'Monthly',
  //   label: 'Monthly',
  // },
  // {
  //   value: 'Year-to-Date',
  //   label: 'YTD',
  // },
];

export const analyticalReportPeriodOptionsOrder = [
  'Semi Annually',
  'Quarterly',
  'Monthly',
  'YTD',
];

export const analyticalReportsCalculationFieldsLabel: {
  [key: string]: string;
} = {
  TotalRevenue: 'Total Revenue',
  CoGS: 'Cost of Goods Sold',
  TotalOperatingExpenses: 'Total Operating Expenses',
  TotalOtherIncome: 'Total Other Income',
  TotalOtherExpenses: 'Total Other Expenses',
  TotalIncomeTaxes: 'Total Income Taxes',
  TotalCurrentAssets: 'Total Current Assets',
  TotalLongTermAssets: 'Total Long Term Assets',
  TotalAssets: 'Total Assets',
  TotalCurrentLiabilities: 'Total Current Liabilities',
  TotalLongTermLiabilities: 'Total Long Term Liabilities',
  TotalLiabilities: 'Total Liabilities',
  TotalEquity: 'Total Equity',
};

export const updateSelectedItems = (
  data: any,
  setters: Record<string, Function>
) => {
  Object.keys(data).forEach((key) => {
    if (data[key]?.fields) {
      // Filter only selected items
      const selectedItems = data[key].fields.filter(
        (item: any) => item.isSelected
      );

      const selectedItemsIds = selectedItems.map((item: any) => item.id);

      // Update corresponding state dynamically
      if (setters[key]) {
        setters[key](selectedItemsIds);
      }
    }
  });
};

//old function without PL/BS report options render
// export const updateDataWithSelectedItemsAndSum = (
//   data: any,
//   selectedItemsMap: Record<string, string[]>
// ) => {
//   return Object.keys(data).reduce((acc: any, key) => {
//     const updatedFields = data[key]?.fields?.map((field: any) => ({
//       ...field,
//       isSelected: selectedItemsMap[key]?.includes(field.id) || false,
//     }));

//     // Calculate sum of selected items' values
//     const selectedSum = updatedFields
//       ? updatedFields
//         .filter((field: any) => field.isSelected)
//         .reduce((sum: number, field: any) => sum + field.value, 0)
//       : data[key]?.value || 0;

//     acc[key] = {
//       ...data[key],
//       ...(updatedFields ? { fields: updatedFields } : {}),
//       value: selectedSum, // Update value with sum of selected fields
//     };

//     return acc;
//   }, {});
// };

//new function with PL/BS report options render
export const updateDataWithSelectedItemsAndSum = (
  calculationReferenceFields: CalculationFields,
  selectedItemsMap: Record<string, string[]>, // Selected IDs for each field
  allOptionsbinded: optionsFields[],
  initialCalculationReferenceFields: CalculationFields
): CalculationFields => {
  let updatedFields = { ...calculationReferenceFields };

  Object.keys(updatedFields).forEach((key) => {
    if (!updatedFields[key]?.fields) return;

    // Get selected IDs for the current field
    const selectedIds = selectedItemsMap[key] || [];

    // Update isSelected for existing fields
    // updatedFields[key].fields = updatedFields?.[key]?.fields?.map((item) => ({
    //   ...item,
    //   isSelected: selectedIds?.includes(item.id),
    // }));

    updatedFields[key] = {
      ...updatedFields[key],
      fields: updatedFields?.[key]?.fields?.map((item) => ({
        ...item,
        isSelected: !!selectedItemsMap[key]
          ? selectedIds?.includes(item.id)
          : item?.isSelected,
      })),
    };

    // Add new selected items from allOptionsbinded that are not already in fields

    // const filteredOptions = allOptionsbinded.filter((item) => !selectedIds?.includes(item.id));
    const newSelectedItems = allOptionsbinded
      .filter(
        (item) =>
          selectedIds?.includes(item.id) &&
          !updatedFields?.[key]?.fields?.some((f) => f.id === item.id)
      )
      .map((item) => ({ ...item, isSelected: true }));

    console.log('newSelectedItems', newSelectedItems);

    updatedFields[key]?.fields?.push(...newSelectedItems);

    // Calculate total sum of selected fields
    // Ensure value is always a number (default to 0 if no selected items exist)
    const initialValue = initialCalculationReferenceFields?.[key]?.value ?? 0;
    const initialSelectedFieldsTotal = initialCalculationReferenceFields?.[
      key
    ]?.fields
      ?.filter((item) => item.isSelected)
      .reduce((sum, item) => sum + (item.Total ?? 0), 0);
    const selectedFieldsTotal = updatedFields?.[key]?.fields
      ?.filter((item) => item.isSelected)
      .reduce((sum, item) => sum + (item.Total ?? 0), 0);

    console.log(
      'updatedFields',
      key,
      updatedFields,
      Number(
        (
          initialValue -
          Number(initialSelectedFieldsTotal) +
          Number(selectedFieldsTotal)
        ).toFixed(2)
      )
    );
    updatedFields[key] = {
      ...updatedFields[key],
      value: Number(
        (
          initialValue -
          Number(initialSelectedFieldsTotal) +
          Number(selectedFieldsTotal)
        ).toFixed(2)
      )!, // Ensures value is always a number
    };
  });

  return updatedFields;
};

export interface CalculationFields {
  [key: string]: CalculationField;
}

export interface optionsFields {
  Label: string;
  Total: number;
  id: string;
  isSelected: boolean;
  groupBy: 'self' | 'Profit and Loss' | 'Balance Sheet';
}

export interface CalculationField {
  value: number;
  fields?: optionsFields[];
}

export const getOptions = (
  field: Array<{
    Label: string;
    Total: number;
    id: string;
    isSelected: boolean;
  }>
) =>
  field?.map((item) => ({
    value: item.id,
    label: `${item.Label}: ${!!item.Total ? item.Total : 0}`,
  })) || [];

export const calculateTotal = (
  selectedIds: string[],
  field: CalculationField
) =>
  field?.fields
    ?.filter((item) => selectedIds.includes(item.id))
    .reduce((sum, item) => sum + (!!item.Total ? item.Total : 0), 0) || 0;

export const calculateTotalWthPLBS = (
  selectedIds: string[],
  field: CalculationField,
  reportReferenceData: {
    profitAndLossData: { [key: string]: optionsFields[] };
    balanceSheetData: { [key: string]: optionsFields[] };
  }
) => {
  const fieldAllIds = field?.fields?.map((item) => item.id);
  // const PLReportOptions = Object.values(
  //   reportReferenceData.profitAndLossData
  // )?.flat();
  // const BSReportOptions = Object.values(
  //   reportReferenceData.balanceSheetData
  // )?.flat();
  const PLReportOptions = [
    ...(reportReferenceData?.profitAndLossData?.expenses ?? []),
  ];
  const BSReportOptions = [
    ...(reportReferenceData?.balanceSheetData?.assets ?? []),
    ...(reportReferenceData?.balanceSheetData?.liabilities ?? []),
  ];

  const PLUpdatedData = PLReportOptions?.filter(
    (el) => !fieldAllIds?.includes(el?.id)
  );
  const BSUpdatedData = BSReportOptions?.filter(
    (el) => !fieldAllIds?.includes(el?.id)
  );
  const allOptionsbinded = [
    ...field?.fields!,
    ...PLUpdatedData,
    ...BSUpdatedData,
  ];
  return (
    allOptionsbinded
      ?.filter((item) => selectedIds.includes(item.id))
      .reduce(
        (sum, item) => sum + (!!item.Total ? Number(item.Total) : 0),
        0
      ) || 0
  );
};

export const getOptionWithPLBSReports = (
  field: optionsFields[],
  reportReferenceData: {
    profitAndLossData: { [key: string]: optionsFields[] };
    balanceSheetData: { [key: string]: optionsFields[] };
  }
) => {
  const fieldAllIds = field?.map((item) => item.id);
  // const PLReportOptions = Object.values(
  //   reportReferenceData.profitAndLossData
  // )?.flat();
  // const BSReportOptions = Object.values(
  //   reportReferenceData.balanceSheetData
  // )?.flat();
  const PLReportOptions = [
    ...(reportReferenceData?.profitAndLossData?.expenses ?? []),
  ];
  const BSReportOptions = [
    ...(reportReferenceData?.balanceSheetData?.assets ?? []),
    ...(reportReferenceData?.balanceSheetData?.liabilities ?? []),
  ];
  const PLUpdatedData = PLReportOptions?.filter(
    (el) => !fieldAllIds.includes(el?.id)
  );
  const BSUpdatedData = BSReportOptions?.filter(
    (el) => !fieldAllIds.includes(el?.id)
  );
  const allOptionsbinded = [...field, ...PLUpdatedData, ...BSUpdatedData];

  return (
    allOptionsbinded?.map((item) => ({
      value: item.id,
      label: `${item.Label}: ${!!item.Total ? item.Total : 0}`,
      group: item.groupBy,
    })) || []
  );
};

export const monthLabels: Record<string, string> = {
  M1: 'Jan',
  M2: 'Feb',
  M3: 'Mar',
  M4: 'Apr',
  M5: 'May',
  M6: 'Jun',
  M7: 'Jul',
  M8: 'Aug',
  M9: 'Sep',
  M10: 'Oct',
  M11: 'Nov',
  M12: 'Dec',
};

export const getBSAssetsOptions = (
  field: optionsFields[],
  reportReferenceData: {
    profitAndLossData: { [key: string]: optionsFields[] };
    balanceSheetData: { [key: string]: optionsFields[] };
  }
) => {
  const fieldAllIds = field?.map((item) => item.id);
  const BSReportOptions = reportReferenceData.balanceSheetData?.assets;
  const BSUpdatedData = BSReportOptions?.filter(
    (el) => !fieldAllIds?.includes(el?.id)
  );
  const allOptionsbinded = [...field, ...BSUpdatedData];

  return (
    allOptionsbinded?.map((item) => ({
      value: item.id,
      label: `${item.Label}: ${!!item.Total ? item.Total : 0}`,
      group: item.groupBy,
    })) || []
  );
};

export const getPLExpensesOptions = (
  field: optionsFields[],
  reportReferenceData: {
    profitAndLossData: { [key: string]: optionsFields[] };
    balanceSheetData: { [key: string]: optionsFields[] };
  }
) => {
  const fieldAllIds = field?.map((item) => item.id);
  const PLReportOptions = reportReferenceData.profitAndLossData?.expenses;
  const PLUpdatedData = PLReportOptions?.filter(
    (el) => !fieldAllIds?.includes(el?.id)
  );
  const allOptionsbinded = [...field, ...PLUpdatedData];

  return (
    allOptionsbinded?.map((item) => ({
      value: item.id,
      label: `${item.Label}: ${!!item.Total ? item.Total : 0}`,
      group: item.groupBy,
    })) || []
  );
};

export const liquidityHistoryDataKeys: string[] = [
  'daysOfCash',
  'tangibleNetworth',
  'backlogToTangibleNetWorth',
  'overBillingsToNetCash',
];

const calculateLiquidityRatioValues = (data: Record<string, any>) => {
  const denominator =
    (data.totalExpenses.value - data.nonCashItems.value) / 365;
  const daysOfCash = !!denominator
    ? Number(
        (data?.totalCashAndCashEquivalents?.value / denominator).toFixed(2)
      )
    : 0;

  const tangibleNetworth = Number(
    (
      data.totalAssets.value -
      data.totalLiabilities.value -
      data.inTangibleAssets.value
    ).toFixed(2)
  );

  const backlogToTangibleNetWorth = !!tangibleNetworth
    ? Number((data.backlog.value / tangibleNetworth).toFixed(2))
    : 0;

  const overBillingsToNetCash = !!data.totalCashAndCashEquivalents.value
    ? Number(
        (
          data.billingInExcessOfCosts.value /
          data.totalCashAndCashEquivalents.value
        ).toFixed(2)
      )
    : 0;

  return {
    daysOfCash,
    tangibleNetworth,
    backlogToTangibleNetWorth,
    overBillingsToNetCash,
  };
};

export const historyDataOfLiquidityRatio = (
  updatedData: Record<string, any>,
  initialData: Record<string, any>,
  keysToCheck: string[],
  historyData: {
    [key: string]: {
      value: number;
      updatedBy: string;
    };
  }
) => {
  const updatedValues: Record<string, any> =
    calculateLiquidityRatioValues(updatedData);
  const initialValues: Record<string, any> =
    calculateLiquidityRatioValues(initialData);

  const cleanedHistoryData = Object.fromEntries(
    Object.entries(historyData ?? {}).map(([key, { value }]) => [
      key,
      { value },
    ])
  );

  let history: any = cleanedHistoryData;

  keysToCheck.forEach((key) => {
    if (updatedValues[key] !== initialValues[key]) {
      history[key] = { value: updatedValues[key] };
    }
  });

  return Object.keys(history).length ? history : null;
};

export const efficiencyHistoryDataKeys: string[] = [
  'tangibleNetworth',
  'underBillingsToTangibleNetWorth',
  'revenueToTangibleNetWorth',
];

const calculateEfficiencyRatioValues = (data: Record<string, any>) => {
  const tangibleNetworth = Number(
    (
      data.totalAssets.value -
      data.totalLiabilities.value -
      data.inTangibleAssets.value
    ).toFixed(2)
  );

  const underBillingsToTangibleNetWorth = !!tangibleNetworth
    ? Number((data.costsInExcessOfBillings.value / tangibleNetworth).toFixed(2))
    : 0;

  const revenueToTangibleNetWorth = !!tangibleNetworth
    ? Number((data.totalIncome.value / tangibleNetworth).toFixed(2))
    : 0;

  return {
    tangibleNetworth,
    underBillingsToTangibleNetWorth,
    revenueToTangibleNetWorth,
  };
};

export const historyDataOfEfficiencyRatio = (
  updatedData: Record<string, any>,
  initialData: Record<string, any>,
  keysToCheck: string[],
  historyData: {
    [key: string]: {
      value: number;
      updatedBy: string;
    };
  }
) => {
  const updatedValues: Record<string, any> =
    calculateEfficiencyRatioValues(updatedData);
  const initialValues: Record<string, any> =
    calculateEfficiencyRatioValues(initialData);

  const cleanedHistoryData = Object.fromEntries(
    Object.entries(historyData ?? {}).map(([key, { value }]) => [
      key,
      { value },
    ])
  );

  let history: any = cleanedHistoryData;

  keysToCheck.forEach((key) => {
    if (updatedValues[key] !== initialValues[key]) {
      history[key] = { value: updatedValues[key] };
    }
  });

  return Object.keys(history).length ? history : null;
};

export const leverageHistoryDataKeys: string[] = [
  'tangibleNetworth',
  'longTermAssetsToTangibleNetWorth',
  'totalLiabilitiesToTangibleNetWorth',
];

const calculateLeverageRatioValues = (data: Record<string, any>) => {
  const tangibleNetworth = Number(
    (
      data.totalAssets?.value -
      data.totalLiabilities?.value -
      data.inTangibleAssets?.value
    ).toFixed(2)
  );

  const longTermAssetsToTangibleNetWorth = !!tangibleNetworth
    ? Number((data.totalLongTermAssets?.value / tangibleNetworth).toFixed(2))
    : 0;

  const totalLiabilitiesToTangibleNetWorth = !!tangibleNetworth
    ? Number((data.totalLiabilities?.value / tangibleNetworth).toFixed(2))
    : 0;

  return {
    tangibleNetworth,
    longTermAssetsToTangibleNetWorth,
    totalLiabilitiesToTangibleNetWorth,
  };
};

export const historyDataOfLeverageRatio = (
  updatedData: Record<string, any>,
  initialData: Record<string, any>,
  keysToCheck: string[],
  historyData: {
    [key: string]: {
      value: number;
      updatedBy: string;
    };
  }
) => {
  const updatedValues: Record<string, any> =
    calculateLeverageRatioValues(updatedData);
  const initialValues: Record<string, any> =
    calculateLeverageRatioValues(initialData);

  const cleanedHistoryData = Object.fromEntries(
    Object.entries(historyData ?? {}).map(([key, { value }]) => [
      key,
      { value },
    ])
  );

  let history: any = cleanedHistoryData;

  keysToCheck.forEach((key) => {
    if (updatedValues[key] !== initialValues[key]) {
      history = {
        ...history,
        [key]: { value: updatedValues[key] },
      };
    }
  });

  return Object.keys(history).length ? history : null;
};

export const semiAnuallyEachYearLabels: Record<string, string> = {
  s1: 'H2',
  s2: 'H1',
  s3: 'H2',
  s4: 'H1',
  s5: 'H2',
  s6: 'H1',
  s7: 'H2',
  s8: 'H1',
};

export const quarterlyEachYearLabels: Record<string, string> = {
  q1: 'Q4',
  q2: 'Q3',
  q3: 'Q2',
  q4: 'Q1',
  q5: 'Q4',
  q6: 'Q3',
  q7: 'Q2',
  q8: 'Q1',
};

export const getStyles = (fields: optionsFields[]) => {
  return {
    defaultValueLabel: {
      color: 'black',
      fw: 'bold',
    },
    value: {
      cursor: 'not-allowed',
    },
    input: {
      cursor: !!fields?.length ? 'initial' : 'not-allowed',
      '&:read-only': {
        border: '1px solid #ced4da',
        backgroundColor: '#f1f3f5',
        opacity: 0.6,
      },
    },
  };
};

export function compareAnalyticalReportData(
  initialData: Record<string, any>,
  updatedData: Record<string, any>
) {
  const result: Record<string, any> = {};

  for (const key in updatedData) {
    const initialFields: any = initialData[key]?.fields || [];
    const updatedFields: any = updatedData[key]?.fields || [];

    const initialMap: any = new Map(
      initialFields.map((item: any) => [item.Label, item])
    );
    const updatedMap: any = new Map(
      updatedFields.map((item: any) => [item.Label, item])
    );

    const fields = [];
    for (const updatedItem of updatedFields) {
      const initialItem = initialMap.get(updatedItem.Label);
      const group = updatedItem.groupBy;
      const isSelectedChanged =
        initialItem && initialItem.isSelected !== updatedItem.isSelected;
      const isNewItem = !initialItem;

      if (
        (group === 'self' && isSelectedChanged) ||
        (group === 'Profit and Loss' && (isNewItem || isSelectedChanged)) ||
        (group === 'Balance Sheet' && (isNewItem || isSelectedChanged))
      ) {
        fields.push(updatedItem);
      }
    }

    if (fields.length > 0) {
      result[key] = { value: null, fields };
    }
  }

  return result;
}

export const extractHeaders = (columns: any[]): string[] => {
  return columns.flatMap(
    (col) =>
      Array.isArray(col.columns)
        ? extractHeaders(col.columns) // Recursively get headers for grouped columns
        : col.accessorKey // Return header for data columns
  );
};

// const extractHeaders = (columns: any[]): string[] => {
//   return columns.reduce<string[]>((acc: string[], col) => {
//     if (col.columnDefType === 'group' && Array.isArray(col.columns)) {
//       acc.push(...extractHeaders(col.columns)); // Recursively extract headers for grouped columns
//     } else if (col.columnDefType === 'data') {
//       acc.push(col.accessorKey); // Only push accessorKey for data columns
//     }
//     return acc;
//   }, []);
// };
