import { StepsTheme as Steps } from 'chakra-ui-steps';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import './index.css';
import { store } from './Redux/Store';

import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import PasswordProtection from './Component/Authentication/PasswordProtection';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const theme = extendTheme({
  colors: {
    brand: {
      yellow: '#ffa011',
      blue: '#114684',
    },
  },
  fonts: {
    body: '"Montserrat", sans-se"',
    heading: '"Montserrat", sans-se"',
  },
  components: {
    Steps,
  },
});

root.render(
  <Provider store={store}>
    {/* <React.StrictMode> */}
    <ChakraProvider theme={theme}>
      <PasswordProtection password={process.env.REACT_APP_AUTH_PW!}>
        <App />
      </PasswordProtection>
    </ChakraProvider>
    {/* </React.StrictMode> */}
  </Provider>
);
