import { Box, Flex, MultiSelect, Text, TextInput } from '@mantine/core';
import React from 'react';
import { getOptions, getStyles, optionsFields } from '../../data';

interface CalculationDisplayProps {
  label: string;
  inputs: {
    label: string;
    value: number | null | 'N/A';
    fields?: optionsFields[];
    isDisplayTextInput?: boolean;
  }[];
  operators: string[];
  result: number | null | 'N/A';
  isPercentage?: boolean;
}

export const CalculationDisplay: React.FC<CalculationDisplayProps> = ({
  label,
  inputs,
  operators,
  result,
  isPercentage = false,
}) => {
  return (
    <Box w={'100%'}>
      {/* Label Row */}
      <Text size="lg" weight={700} align="left" mb="xs">
        {label}
      </Text>

      <Flex direction="column" align="stretch" gap="md">
        {/* Calculation Row */}
        <Flex wrap="wrap" align="center" justify={'flex-start'} gap="xs">
          {inputs.map((input, index) => (
            <div
              key={index}
              style={{
                width: '48%',
                minWidth: '250px',
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
              }}
            >
              {!!input?.isDisplayTextInput ? (
                <TextInput
                  label={input.label}
                  value={input.value ?? 0}
                  disabled
                  sx={{ flex: 1 }}
                  styles={{
                    input: {
                      textAlign: 'right',
                      '&:disabled': {
                        color: 'black',
                        fontWeight: 'bold',
                      },
                    },
                  }}
                />
              ) : (
                <MultiSelect
                  key={index.toString()}
                  label={`${input.label} (${input.value ?? 0})`}
                  data={getOptions(input?.fields!)}
                  value={input?.fields
                    ?.filter((item) => item?.isSelected)
                    ?.map((item) => item?.id)}
                  placeholder={`Select ${input.label} items`}
                  searchable
                  className="legalform_pdfPreference"
                  maxDropdownHeight={400}
                  sx={{ flex: 1 }}
                  styles={getStyles(input?.fields!)}
                  readOnly
                />
              )}
              {index < operators.length && (
                <Text size="xl" weight={700}>
                  {operators[index]}
                </Text>
              )}
            </div>
          ))}

          {isPercentage && (
            <Text size="md" style={{ pointerEvents: 'none' }}>
              * 100
            </Text>
          )}
        </Flex>
        <Flex align="center" justify="flex-start" wrap={'wrap'} gap={'xs'}>
          {/* Equals Sign */}
          <Text size="xl" weight={700}>
            =
          </Text>
          {/* Result */}
          <TextInput
            label="Result"
            value={
              result?.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) ?? 0
            }
            disabled
            styles={{
              input: {
                textAlign: 'right',
                '&:disabled': {
                  color: 'black',
                  fontWeight: 'bold',
                },
              },
            }}
            maw={'50%'}
            w={'100%'}
          />
        </Flex>
      </Flex>
    </Box>
  );
};
