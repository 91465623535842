import {
  Box,
  Button,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Switch,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { ActionIcon, Tooltip } from '@mantine/core';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { FaTrashRestore } from 'react-icons/fa';
import { HiOutlineDownload } from 'react-icons/hi';
import { LuView } from 'react-icons/lu';
import { RiFileDownloadFill } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { useZipFromLinks } from 'src/hooks';
import { formatNumber } from 'src/Pages/Reports/components/MantineTable';
import {
  DeleteBondActivity,
  GetBondActivity,
  GetDeletedBondActivity,
  RestoreBondActivity,
  UpdateBondActivity,
} from 'src/Redux/Broker/BondFacilityTracker/BondActivity';
import { useAppDispatch, useAppSelector } from 'src/Redux/Store';
import Swal from 'sweetalert2';
import BondActivityAdd from './BondActivityAdd';
import ManageBonds from './ManageBonds';
import { BondActivity, BondActivityBonds } from './model';

const BondActivityReport = ({
  applicantId,
  onEditMode,
}: {
  applicantId: string;
  onEditMode: any;
}) => {
  const userBroker = useAppSelector((state) => state.broker.brokerProfileData);
  const [addMode, setAddMode] = useState(false);
  const dispatch = useAppDispatch();
  const [bondActivityData, setBondActivityData] = useState<BondActivity[]>([]);
  const [selectedBondActivity, setSelectedBondActivity] =
    useState<BondActivity | null>(null);
  const { createZipFromLinks, loading, error } = useZipFromLinks();
  const [isOpen, setIsOpen] = useState(false);
  const [queryFiles, setQueryFiles] = useState([]);
  const [isDeletedBonds, setIsDeletedBonds] = useState(false);
  const bondFacilityTrackerSlice = useAppSelector(
    (state) => state.bondFacilityTrackerSlice
  );
  const [singleContractLimit, setSingleContractLimit] = useState<number>(0);
  const {
    isOpen: isOpenManageBonds,
    onOpen: openManageBonds,
    onClose: closeManageBonds,
  } = useDisclosure();
  const Toast = useToast();
  const navigate = useNavigate();

  const [rowProject, setRowProject] = useState<BondActivity>();
  const {
    isOpen: isOpenViewBonds,
    onOpen: openViewBonds,
    onClose: closeViewBonds,
  } = useDisclosure();

  /**
   * Modal to view and download uploaded files of 'Status Query Form Files'
   */
  const ViewFileModal = () => {
    return (
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Status Query Form Files</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex flexDirection={'column'} pb={4}>
              {queryFiles.map((item: any, index) => (
                <Flex
                  p={'5px 0'}
                  key={index}
                  borderBottom={
                    index < queryFiles.length - 1
                      ? '1px solid rgb(210, 213, 218)'
                      : undefined
                  }
                  alignItems={'center'}
                >
                  <Text fontSize={11}>{item?.fileName}</Text>
                  <Spacer></Spacer>
                  <Tooltip label="Download">
                    <IconButton
                      isRound={true}
                      variant="solid"
                      fontSize={'18px'}
                      aria-label="Exit"
                      icon={<HiOutlineDownload style={{ color: '#114684' }} />}
                      onClick={() =>
                        handleDownload(item, item?.fileName.split('.')[0])
                      }
                    />
                  </Tooltip>
                </Flex>
              ))}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  };

  /**
   * View Bond numbers of a project
   */
  const ViewBondsModal = () => {
    return (
      <Modal isOpen={isOpenViewBonds} onClose={closeViewBonds}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{rowProject?.projectName}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <Flex
                border={'1px solid rgb(221 228 237)'}
                borderRadius={'6px 6px 0 0'}
              >
                <Text
                  textAlign={'center'}
                  fontWeight="600"
                  fontSize={13}
                  pb="5px"
                  width={'20%'}
                >
                  Bond Numbers
                </Text>
                <Text
                  width={'80%'}
                  textAlign={'center'}
                  fontWeight="600"
                  fontSize={13}
                  pb="5px"
                >
                  Bond Types
                </Text>
              </Flex>
              <Box
                border={'1px solid rgb(221 228 237)'}
                borderRadius={'0 0 6px 6px'}
                maxH={'300px'}
                overflowY={'auto'}
                borderTop={'none'}
                height={'88%'}
              >
                {rowProject?.bondType &&
                  rowProject?.bondType.map((bonds, index) => (
                    <Flex
                      gap={5}
                      key={index}
                      borderBottom={
                        index < 4 ? '1px solid rgb(221 228 237)' : undefined
                      }
                      alignItems={'center'}
                    >
                      <Box width={'20%'} padding={'5px'}>
                        <Text
                          color={'#114684'}
                          fontWeight={'600'}
                          fontSize={'13px'}
                        >
                          {bonds.number}
                        </Text>
                      </Box>
                      <Box
                        width={'80%'}
                        padding={'5px'}
                        borderLeft={'1px solid rgb(221 228 237)'}
                      >
                        <Flex gap={2} wrap={'wrap'}>
                          {bonds.bonds &&
                            bonds.bonds.map((bondtype, index2) => (
                              <Text
                                style={{
                                  backgroundColor: '#ededed',
                                  padding: '1px 10px 4px 10px',
                                  borderRadius: '20px',
                                  fontSize: '13px',

                                  color: '#646464',
                                }}
                                key={index2}
                              >
                                {bondtype.type}
                              </Text>
                            ))}
                        </Flex>
                        {bonds.bonds.length === 0 && (
                          <Text
                            color={'gray.300'}
                            fontSize={13}
                            fontWeight={'600'}
                            padding={'10px'}
                          >
                            No Bond Type
                          </Text>
                        )}
                      </Box>
                    </Flex>
                  ))}

                {rowProject?.bondType && rowProject?.bondType.length === 0 && (
                  <Text
                    color={'gray.300'}
                    fontSize={13}
                    textAlign={'center'}
                    fontWeight={'600'}
                    padding={'10px'}
                  >
                    No Records
                  </Text>
                )}
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  };

  //columns configuration for table data
  const columns = useMemo(
    () => [
      {
        accessorKey: 'projectName',
        header: 'Project Name',
      },
      {
        accessorKey: 'projectLocation',
        header: 'Project Location',
      },
      {
        accessorKey: 'obligeeName',
        header: 'Obligee Name',
      },
      {
        accessorKey: 'projectDescription',
        header: 'Project Description',
      },
      {
        accessorKey: 'projectStartDate',
        header: 'Project Start Date',
        Cell: ({ cell, row }: { cell: any; row: any }) => {
          const { projectStartDate } = row?.original;
          return projectStartDate
            ? moment(projectStartDate).format('DD-MM-YYYY')
            : '';
        },
      },
      {
        accessorKey: 'projectCompletionDate',
        header: 'Project Completion Date',
        Cell: ({ cell, row }: { cell: any; row: any }) => {
          const { projectCompletionDate } = row?.original;
          return projectCompletionDate
            ? moment(projectCompletionDate).format('DD-MM-YYYY')
            : '';
        },
      },
      {
        accessorKey: 'initialTotalContractValue',
        header: 'Initial Total Contract Value',
        Cell: ({ cell }) => <>{formatNumber(cell.getValue() as number)}</>,
      },
      {
        accessorKey: 'currentTotalContractValue',
        header: 'Current Total Contract Value',
        Cell: ({ cell }) => <>{formatNumber(cell.getValue() as number)}</>,
      },
      {
        accessorKey: 'singleContractLimit',
        header: 'Overage of Single Contract Limit',
        Cell: ({ cell, row }: { cell: any; row: any }) => {
          const { currentTotalContractValue } = row?.original;
          const overageSCL =
            Number(singleContractLimit) - Number(currentTotalContractValue);
          return overageSCL < 0 ? 'N/A' : formatNumber(overageSCL);
        },
      },
      {
        accessorKey: 'bondType',
        header: 'Bond',
        Cell: ({ cell, row }) => (
          <Flex justifyContent={'center'}>
            <Button
              backgroundColor={'#d8eaff'}
              color={'#114684'}
              onClick={() =>
                isDeletedBonds || userBroker === null
                  ? viewBonds(row?.original)
                  : handleManageBonds(row?.original)
              }
              _hover={{ opacity: 0.8, border: '1px solid #a2bad6' }}
            >
              {isDeletedBonds || userBroker === null
                ? 'View Bonds'
                : 'Manage Bonds'}
            </Button>
          </Flex>
        ),
      },

      {
        accessorKey: 'issueDate',
        header: 'Issue Date',
        Cell: ({ cell, row }: { cell: any; row: any }) => {
          const { issueDate } = row?.original;
          return issueDate ? moment(issueDate).format('DD-MM-YYYY') : '';
        },
      },

      {
        accessorKey: 'bondStatus',
        header: 'Bond Status',
      },
      {
        accessorKey: 'indemnityAndSecurityConditions',
        header: 'Indemnity & Security Conditions',
        Cell: ({ cell, row }) => (
          <Flex gap={5}>
            <>
              {cell
                .getValue()
                .filter((ele: string) => ele !== 'Other')
                .join(',')}
            </>
            <Spacer></Spacer>
            {row?.original?.attachments &&
              row?.original?.attachments.length > 0 && (
                <>
                  <Tooltip label="Download Files">
                    <ActionIcon
                      color="teal"
                      onClick={() =>
                        handleDownload(
                          row?.original.attachments,
                          row?.original?.projectName
                        )
                      }
                      size={'md'}
                    >
                      <RiFileDownloadFill style={{ fontSize: '20px' }} />
                    </ActionIcon>
                  </Tooltip>
                </>
              )}
          </Flex>
        ),
      },
      {
        accessorKey: 'statusQueryFormProvided',
        header: 'Status Query Form Provided',
        Cell: ({ cell, row }) => (
          <Flex gap={5}>
            <>{cell.getValue() ? 'Yes' : 'No'}</>
            <Spacer></Spacer>
            {row?.original?.queryFormAttachments &&
              row?.original?.queryFormAttachments.length > 0 && (
                <>
                  <Tooltip label="View Files">
                    <ActionIcon
                      style={{ color: '#3b82f6' }}
                      onClick={() => {
                        setIsOpen(true);
                        setQueryFiles(row?.original.queryFormAttachments);
                      }}
                      size={'md'}
                    >
                      <LuView style={{ fontSize: '20px' }} />
                    </ActionIcon>
                  </Tooltip>
                </>
              )}
          </Flex>
        ),
      },
      {
        accessorKey: 'suretyBondFormsAttachments',
        header: 'Surety Bond Forms',
        Cell: ({ cell, row }) => (
          <Flex justifyContent={'center'}>
            {row?.original?.suretyBondFormsAttachments &&
              row?.original?.suretyBondFormsAttachments.length > 0 && (
                <>
                  <Tooltip label="Download Files">
                    <ActionIcon
                      color="teal"
                      onClick={() =>
                        handleDownload(
                          row?.original.suretyBondFormsAttachments,
                          row?.original?.projectName + '_Surety_Bond_Forms'
                        )
                      }
                      size={'md'}
                    >
                      <RiFileDownloadFill style={{ fontSize: '20px' }} />
                    </ActionIcon>
                  </Tooltip>
                </>
              )}
          </Flex>
        ),
      },
    ],
    [singleContractLimit, isDeletedBonds]
  );

  /**
   * Function to display modal of bond numbers list in deleted mode
   * @param selectedRow
   */
  const viewBonds = (selectedRow: any) => {
    setRowProject(selectedRow);
    openViewBonds();
  };

  /**
   * Function to display modal of Manage Bonds(add/edit/delete bond numbers)
   * @param selectedRow
   */
  const handleManageBonds = (selectedRow: any) => {
    setRowProject(selectedRow);
    openManageBonds();
  };

  /**
   * Download files as zipped format
   * @param fileInfo
   * @param zipName
   */
  const handleDownload = (fileInfo: any, zipName: string) => {
    if (Array.isArray(fileInfo)) {
      createZipFromLinks({
        links: (fileInfo as any[])?.map((item: any) => item?.link),
        zipName: `${zipName}`,
      });
    } else {
      const file = [];
      file.push(fileInfo.link);
      createZipFromLinks({
        links: file,
        zipName: `${zipName}`,
      });
    }
  };

  /**
   * Get Bond Activity data
   */
  const getBondActivity = async () => {
    const result = await dispatch(GetBondActivity(applicantId));
    setBondActivityData(result.payload?.data);
  };

  useEffect(() => {
    //Get bond activity data based on Show Deleted is set
    isDeletedBonds ? getDeletedBondActivity() : getBondActivity();
  }, [applicantId]);

  useEffect(() => {
    const { status, type } = bondFacilityTrackerSlice;
    switch (status) {
      case 'succeed':
        if (type === 'GET_CLIENT_PROFILE_DETAILS') {
          if (bondFacilityTrackerSlice.clientProfileDetails) {
            let profiles = [...bondFacilityTrackerSlice.clientProfileDetails];
            const latestProfile =
              profiles.length > 1
                ? profiles.sort(
                    (a, b) =>
                      new Date(b.updatedAt!).getTime() -
                      new Date(a.updatedAt!).getTime()
                  )[0]
                : profiles[0];

            //set 'Overage of Single Contract Value' field based on latest Client Profile data
            setSingleContractLimit(Number(latestProfile?.singleContractLimit));
          } else setSingleContractLimit(0);
        }

        break;

      default:
        break;
    }
  }, [
    applicantId,
    bondFacilityTrackerSlice.status,
    bondFacilityTrackerSlice.type,
    ,
  ]);

  /**
   * Delete(soft delete) a bond activity
   * @param bond : Bond activity object to delete
   */
  const handleDelete = (bond: any) => {
    Swal.fire({
      title:
        "Do you want to delete bond activity record of '" +
        bond.projectName +
        "' ?",
      icon: 'error',
      showCancelButton: true,
      confirmButtonColor: '#DC3741',
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (result.isConfirmed === true) {
        dispatch(DeleteBondActivity(bond.id))
          .then((res: any) => {
            if (res.meta.requestStatus === 'fulfilled') {
              setBondActivityData((current) =>
                current.filter((obj) => obj.id !== bond.id)
              );
              Swal.fire({
                title: 'Deleted Successfully.',
                icon: 'success',
                showConfirmButton: false,
                timer: 2000,
              });
            }
          })
          .catch((err: any) => {});
      }
    });
  };

  /**
   * Get deleted bond activity data
   */
  const getDeletedBondActivity = async () => {
    const result = await dispatch(GetDeletedBondActivity(applicantId));
    setBondActivityData(result.payload?.data);
  };

  /**
   * Restore soft deleted bond activity project
   * @param proj
   */
  const restoreBondProject = (proj: any) => {
    Swal.fire({
      title:
        "Do you want to restore bond activity record of '" +
        proj.projectName +
        "' ?",
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#DC3741',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed === true) {
        dispatch(RestoreBondActivity(proj.id))
          .then((res: any) => {
            if (res?.payload?.status === 200) {
              setBondActivityData((current) =>
                current.filter((obj) => obj.id !== proj.id)
              );
              Swal.fire({
                title: 'Project restored successfully.',
                icon: 'success',
                showConfirmButton: false,
                timer: 2000,
              });
            } else {
              Swal.fire({
                title: res?.payload?.message || 'Error occurred!',
                icon: 'error',
                showConfirmButton: false,
                timer: 2000,
              });
            }
          })
          .catch((err: any) => {
            Swal.fire({
              title: 'Error occurred!',
              icon: 'error',
              showConfirmButton: false,
              timer: 2000,
            });
          });
      }
    });
  };

  /**
   * Show deleted bond activity projects list
   * @param e: event object
   */
  const showDeletedBonds = async (e: any) => {
    setIsDeletedBonds(e.target.checked);
    if (e.target.checked) {
      getDeletedBondActivity();
    } else getBondActivity();
  };

  /**
   * Mantine Table configuration
   */
  const tableBond = useMantineReactTable({
    columns,

    data: bondActivityData as any,
    renderTopToolbarCustomActions: ({ table }) => (
      <Flex gap={5}>
        {userBroker && (
          <Button
            onClick={handleAddActivity}
            bg={'#114684'}
            color={'#ffffff'}
            _hover={{ opacity: 0.8 }}
          >
            Add Bond Activity
          </Button>
        )}
        <Flex gap={2} alignItems={'center'}>
          <Text>Show Deleted</Text>
          <Switch id="showDeleted" onChange={showDeletedBonds} />
        </Flex>
      </Flex>
    ),
    enableColumnPinning: true,
    initialState: {
      columnPinning: { left: ['mrt-row-actions', 'projectName'] },
    },
    state: { columnVisibility: { 'mrt-row-actions': userBroker !== null } },
    enableRowActions: true,
    editDisplayMode: 'custom',
    mantineTableHeadProps: {
      opacity: isDeletedBonds ? 0.6 : 1,
    },
    renderRowActions: ({ row }: any) => (
      <Flex gap="5" justifyContent={'center'}>
        {!isDeletedBonds && (
          <Flex gap={5}>
            <ActionIcon
              onClick={() => {
                setSelectedBondActivity(row?.original);
                setAddMode(true);
                onEditMode(true);
              }}
              size={'sm'}
            >
              <IconEdit />
            </ActionIcon>

            <ActionIcon
              color="red"
              onClick={() => handleDelete(row?.original)}
              size={'sm'}
            >
              <IconTrash />
            </ActionIcon>
          </Flex>
        )}

        {isDeletedBonds && (
          <Tooltip label="Restore">
            <ActionIcon
              color="orange"
              onClick={() => {
                restoreBondProject(row?.original);
              }}
              size={'sm'}
            >
              <FaTrashRestore />
            </ActionIcon>
          </Tooltip>
        )}
      </Flex>
    ),
  });

  /**
   * Switch to add bond activity mode
   */
  const handleAddActivity = () => {
    setAddMode(true);
  };

  /**
   * Exit from Add/Edit mode
   * @param data
   */
  const handleExit = async (data: boolean) => {
    if (selectedBondActivity) onEditMode(false);

    setAddMode(data);
    setSelectedBondActivity(null);

    if (!data) {
      getBondActivity();
    }
  };

  /**
   * Update bond numbers data
   * @param newdata
   * @param removedBonds
   * @param save
   */
  const handleManageBondsClose = async (
    newdata: BondActivityBonds[],
    removedBonds: string[],
    save: boolean
  ) => {
    if (save) {
      try {
        let res = '';
        const existingBonds = [...(rowProject?.bondType || [])];
        //remove bonds
        if (removedBonds.length > 0) {
          removedBonds.forEach((obj) => {
            const idx = existingBonds.findIndex((itm) => itm.number === obj);
            if (idx !== -1) existingBonds.splice(idx, 1);
          });
        }

        //set bonds on top with empty bond types
        if (existingBonds.length > 0) {
          existingBonds.forEach((obj) => {
            if (
              newdata.find((item) => item.number === obj.number) === undefined
            ) {
              newdata.unshift(obj);
            }
          });
        }

        newdata.forEach((obj: any) => {
          obj.bonds.forEach((items: any) => delete items?.disabledExpiry);
        });

        const payload: any = rowProject?.linkedByProjectId
          ? {
              bondType: newdata,
              createdByProjectId: rowProject.createdByProjectId,
              createdBySource: rowProject.createdBySource,
              linkedByProjectId: rowProject.linkedByProjectId,
              linkedByProjectName: rowProject.linkedByProjectName,
            }
          : {
              bondType: newdata,
              createdByProjectId: rowProject?.createdByProjectId,
              createdBySource: rowProject?.createdBySource,
            };

        const actionResult = await dispatch(
          UpdateBondActivity({
            ...payload,
            id: rowProject?.id,
          }) as any
        );

        const response = actionResult?.payload;
        res = response?.msg;
        const resStatus = response?.status;

        if (resStatus === 200) {
          let bondedRow = bondActivityData.find(
            (obj) => obj.id === rowProject?.id
          );
          if (bondedRow !== undefined) {
            setBondActivityData((prev) =>
              prev.map((item) =>
                item.id === rowProject?.id
                  ? { ...item, bondType: newdata }
                  : item
              )
            );
          }

          Toast({
            title: res || 'Project Bonds successfully updated',
            status: 'success',
            isClosable: true,
            duration: 1000,
            position: 'top-right',
          });
        } else {
          if (resStatus !== 401) {
            Toast({
              title: res || 'Error occurred!',
              status: 'error',
              isClosable: true,
              duration: 3000,
              position: 'top-right',
            });
          } else {
            navigate('/login');
          }
        }
      } catch (err: any) {
        Toast({
          title: 'Error occurred!',
          status: 'error',
          isClosable: true,
          duration: 3000,
          position: 'top-right',
        });
      }
    }
  };

  return (
    <Box>
      {!addMode && <MantineReactTable table={tableBond} />}
      {addMode && (
        <BondActivityAdd
          selectedBondActivity={selectedBondActivity}
          addMode={handleExit}
          applicantId={applicantId}
        ></BondActivityAdd>
      )}
      <ViewFileModal></ViewFileModal>
      <ManageBonds
        isOpen={isOpenManageBonds}
        onClose={closeManageBonds}
        bondsList={rowProject?.bondType || []}
        onSubmit={handleManageBondsClose}
        projectEndDate={
          rowProject?.projectCompletionDate
            ? new Date(rowProject.projectCompletionDate)
            : null
        }
      ></ManageBonds>
      <ViewBondsModal></ViewBondsModal>
    </Box>
  );
};

export default BondActivityReport;
