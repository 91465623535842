import {
  Badge,
  Box,
  Button,
  HStack,
  Input,
  Link,
  Select,
  Text,
  Textarea,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { Group, LoadingOverlay, Modal } from '@mantine/core';
import { useFormik } from 'formik';
import { MRT_Row } from 'mantine-react-table';
import { useEffect, useState } from 'react';
import { LuExternalLink } from 'react-icons/lu';
import { useNavigate } from 'react-router-dom';
import { useAppToast } from 'src/hooks';
import { OCRIssueUpdateStatus } from 'src/Redux/Admin/OcrReportIssues/slice';
import { IOcrReportIssueList } from 'src/Redux/Admin/OcrReportIssues/state';
import { useAppDispatch, useAppSelector } from 'src/Redux/Store';
import {
  getDateFormat,
  getFileNameFromURL,
  getInputBg,
} from 'src/utils/helpers';
import { supportTicketYupSchema } from 'src/ValidationSchema/Pages/Index';
import {
  getData,
  getIssueCurrentStatus,
  getOCRIssueStatusOptions,
  initialSupportTicketState,
  supportTicketStateSchema,
} from './data';
import IssueLog from './IssueLog';

const ViewOcrIssueModal = ({
  isOpen,
  onClose,
  row,
  submitLoader,
  isEditMode,
}: {
  isOpen: boolean;
  onClose: () => void;
  row: MRT_Row<IOcrReportIssueList>;
  submitLoader: boolean;
  isEditMode: boolean;
}) => {
  const dispatch = useAppDispatch();
  const toast = useAppToast();
  const navigate = useNavigate();
  const ocrReportIssueSlice = useAppSelector(
    (state) => state.OcrReportIssueSlice
  );
  const [ocrReportInfo, setOcrReportInfo] =
    useState<IOcrReportIssueList | null>(null);
  const [modalContentLoader, setModalContentLoader] = useState<boolean>(false);
  const userType = localStorage.getItem('userType');
  const navigatePath =
    userType === 'broker' ? '/broker/upload/report-issues' : '/report-issues';
  // useEffect(() => {
  //   if (isOpen) {
  //     setOcrReportInfo(row?.original);
  //   }
  // }, [isOpen]);

  useEffect(() => {
    return () => {
      formik.resetForm();
    };
  }, [isOpen]);

  useEffect(() => {
    const { status, type, error, ocrIssueInfo } = ocrReportIssueSlice;
    switch (status) {
      case 'loading': {
        if (type === 'GET_OCR_REPORT_ISSUE_BY_ID') {
          setModalContentLoader(true);
        }
        break;
      }
      case 'succeed': {
        if (type === 'GET_OCR_REPORT_ISSUE_BY_ID') {
          const rowData: any = getData(ocrIssueInfo!);
          setOcrReportInfo(rowData);
          setModalContentLoader(false);
        }
        break;
      }
      case 'failed': {
        if (type === 'GET_OCR_REPORT_ISSUE_BY_ID') {
          toast({
            title: error,
            status: 'error',
          });
          setModalContentLoader(false);
        }
        break;
      }

      default:
        break;
    }
  }, [ocrReportIssueSlice?.status, ocrReportIssueSlice?.type]);

  const submitOCRIssueHandler = (values: supportTicketStateSchema) => {
    const { status, id } = ocrReportInfo!;
    try {
      if (values?.ocrIssueStatus !== status?.[status?.length - 1]) {
        let payload = {
          reportId: id,
          status: values?.ocrIssueStatus,
          [userType === 'broker' ? 'reason' : 'comment']: values?.comment,
        };
        dispatch(OCRIssueUpdateStatus(payload));
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const formik = useFormik({
    initialValues: initialSupportTicketState,
    validationSchema: supportTicketYupSchema,
    onSubmit: submitOCRIssueHandler,
  });

  return (
    <Modal
      opened={isOpen}
      onClose={() => {
        onClose();
        formik.resetForm();
        navigate(navigatePath);
      }}
      title={`Ticket Number #${row?.original?.uniqueId ?? ''}`}
      overlayProps={{
        opacity: 0.1,
        blur: 3,
      }}
      size={'625px'}
      className="modal-style"
      classNames={{
        content: 'support-ticket-modal-content',
      }}
    >
      <Box pos={'relative'}>
        <LoadingOverlay
          visible={modalContentLoader}
          zIndex={1000}
          overlayBlur={0.5}
          loaderProps={{ size: 'sm' }}
        />
        <form onSubmit={formik.handleSubmit}>
          <Box display={'flex'} flexDirection={'column'} gap={3}>
            <Box display={'flex'} flexDirection={'column'} mt={2}>
              <VStack spacing={3} align="flex-start">
                <HStack w={'100%'}>
                  <Box w={'100%'}>
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                      px={1}
                    >
                      Applicant Name
                    </Text>
                    <Input
                      mt={2}
                      flex="1"
                      fontSize={'13px'}
                      type="text"
                      placeholder="Applicant Name"
                      borderRadius="5px"
                      focusBorderColor="#7f7f7f"
                      borderColor={'#7f7f7f'}
                      _hover={{ borderColor: '#7f7f7f' }}
                      cursor={'not-allowed'}
                      value={ocrReportInfo?.applicantName ?? ''}
                      name="applicantName"
                      readOnly
                      bgColor={getInputBg({ isDisable: true })}
                    />
                  </Box>
                  <Box w={'100%'}>
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                      px={1}
                    >
                      Broker Name
                    </Text>
                    <Input
                      mt={2}
                      flex="1"
                      fontSize={'13px'}
                      type="text"
                      placeholder="Broker Name"
                      borderRadius="5px"
                      focusBorderColor="#7f7f7f"
                      borderColor={'#7f7f7f'}
                      _hover={{ borderColor: '#7f7f7f' }}
                      cursor={'not-allowed'}
                      value={ocrReportInfo?.brokerName ?? ''}
                      name="brokerName"
                      readOnly
                      bgColor={getInputBg({ isDisable: true })}
                    />
                  </Box>
                </HStack>
                {/* <Text fontSize="16px">
                <Text as={'span'} fontWeight={600}>
                  File Description:
                </Text>{' '}
                {ocrReportInfo?.report?.reportLinks[0]?.comment ?? 'N/A'}
              </Text> */}
                {/* <Text fontSize="16px">
                <Text as={'span'} fontWeight={600}>
                  Error Log:
                </Text>{' '}
                Failure reason
              </Text>
              <Text fontSize="16px">
                <Text as={'span'} fontWeight={600}>
                  Broker Brief Description:
                </Text>{' '}
                {ocrReportInfo?.reason}
              </Text> */}
                <HStack w={'100%'}>
                  <Box w={'100%'}>
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                      px={1}
                    >
                      Error Log
                    </Text>
                    <Tooltip
                      label={ocrReportInfo?.report?.reasonForOcrFailure}
                      placement="bottom"
                      hasArrow
                    >
                      <Input
                        mt={2}
                        flex="1"
                        fontSize={'13px'}
                        type="text"
                        placeholder="Error Log"
                        borderRadius="5px"
                        focusBorderColor="#7f7f7f"
                        borderColor={'#7f7f7f'}
                        _hover={{ borderColor: '#7f7f7f' }}
                        cursor={'not-allowed'}
                        value={ocrReportInfo?.report?.reasonForOcrFailure ?? ''}
                        name="errorLog"
                        readOnly
                        bgColor={getInputBg({ isDisable: true })}
                        whiteSpace={'nowrap'}
                        overflow={'hidden'}
                        textOverflow={'ellipsis'}
                      />
                    </Tooltip>
                  </Box>
                  <Box w={'100%'}>
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                      px={1}
                    >
                      Broker Brief Description
                    </Text>
                    <Input
                      mt={2}
                      flex="1"
                      fontSize={'13px'}
                      type="text"
                      placeholder="Broker Brief Description"
                      borderRadius="5px"
                      focusBorderColor="#7f7f7f"
                      borderColor={'#7f7f7f'}
                      _hover={{ borderColor: '#7f7f7f' }}
                      cursor={'not-allowed'}
                      value={ocrReportInfo?.reason?.[0] ?? ''}
                      name="reason"
                      readOnly
                      bgColor={getInputBg({ isDisable: true })}
                    />
                  </Box>
                </HStack>
                <HStack w={'100%'} alignItems={'flex-start'}>
                  <VStack
                    w={'100%'}
                    justifyContent={'flex-start'}
                    alignItems={'flex-start'}
                  >
                    <Box>
                      <Text
                        fontWeight="600"
                        color="#114684"
                        textAlign="start"
                        fontSize={13}
                        px={1}
                        as={'span'}
                      >
                        Created Date:{' '}
                      </Text>{' '}
                      <Text
                        display={'inline-flex'}
                        fontSize={13}
                        fontWeight="600"
                      >
                        {getDateFormat(
                          ocrReportInfo?.createdAt!,
                          'MMMM DD, YYYY'
                        )}
                      </Text>
                    </Box>
                    <Box>
                      <Text
                        fontWeight="600"
                        color="#114684"
                        textAlign="start"
                        fontSize={13}
                        px={1}
                        as={'span'}
                      >
                        Current Status:{' '}
                      </Text>{' '}
                      <Badge
                        bg={
                          getIssueCurrentStatus(
                            ocrReportInfo?.status?.[
                              ocrReportInfo?.status?.length - 1
                            ]!
                          )?.bgColor
                        }
                        color={
                          getIssueCurrentStatus(
                            ocrReportInfo?.status?.[
                              ocrReportInfo?.status?.length - 1
                            ]!
                          )?.textColor
                        }
                        px="2"
                        py="1"
                        borderRadius="md"
                      >
                        {
                          getIssueCurrentStatus(
                            ocrReportInfo?.status?.[
                              ocrReportInfo?.status?.length - 1
                            ]!
                          )?.updatedStatus
                        }
                      </Badge>
                    </Box>
                    <Text fontSize="16px" textTransform="capitalize">
                      <Text
                        fontWeight="600"
                        color="#114684"
                        textAlign="start"
                        fontSize={13}
                        px={1}
                        as={'span'}
                      >
                        File attachment:
                      </Text>{' '}
                      <Link
                        href={ocrReportInfo?.report?.reportLinks[0]?.link!}
                        target="_blank"
                        as={'a'}
                        display={'inline-flex'}
                        flexWrap={'nowrap'}
                        alignItems={'center'}
                        gap={1}
                        fontSize={13}
                        fontWeight="600"
                        textDecoration={'underline'}
                      >
                        <Text as="span">
                          {getFileNameFromURL(
                            ocrReportInfo?.report?.reportLinks[0]?.link!
                          ) ?? ''}
                        </Text>
                        <Box>
                          <LuExternalLink />
                        </Box>
                      </Link>
                    </Text>
                  </VStack>
                  {!!isEditMode && (
                    <Box
                      w={'100%'}
                      display={'flex'}
                      flexDirection={'column'}
                      mt={2}
                    >
                      <Text
                        fontWeight="600"
                        color="#114684"
                        textAlign="start"
                        fontSize={13}
                        px={1}
                      >
                        Status
                      </Text>
                      <Select
                        value={formik.values.ocrIssueStatus ?? ''}
                        name="ocrIssueStatus"
                        onChange={formik.handleChange}
                        fontSize={'13px'}
                        _disabled={{ opacity: 1 }}
                        w="100%"
                        borderRadius="5px"
                        focusBorderColor="#7f7f7f"
                        borderColor={'#7f7f7f'}
                        _hover={{ borderColor: '#7f7f7f' }}
                      >
                        <option
                          value={''}
                          style={{ color: 'black' }}
                          label="Select Option"
                        />
                        {getOCRIssueStatusOptions(
                          userType!,
                          ocrReportInfo?.status?.[
                            ocrReportInfo?.status?.length - 1
                          ]!
                        )?.map((item: any, index: number) => (
                          <option
                            style={{ color: 'black' }}
                            key={index}
                            value={item.value}
                            label={item.label}
                          />
                        ))}
                      </Select>
                      {formik?.touched.ocrIssueStatus &&
                        formik.errors.ocrIssueStatus && (
                          <div
                            style={{
                              color: 'red',
                              fontSize: '13px',
                              textAlign: 'start',
                              fontWeight: '500',
                            }}
                          >
                            {formik?.errors.ocrIssueStatus}
                          </div>
                        )}
                    </Box>
                  )}
                </HStack>
                {!!isEditMode && (
                  <Box
                    w={'100%'}
                    display={'flex'}
                    flexDirection={'column'}
                    mt={2}
                  >
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                      px={1}
                    >
                      Comment
                    </Text>
                    <Textarea
                      fontSize={'13px'}
                      w="100%"
                      placeholder="Comment"
                      borderRadius="5px"
                      focusBorderColor="#7f7f7f"
                      borderColor={'#7f7f7f'}
                      _hover={{ borderColor: '#7f7f7f' }}
                      onChange={formik.handleChange}
                      value={formik.values.comment ?? ''}
                      name="comment"
                      resize={'none'}
                    />
                    {formik?.touched.comment && formik.errors.comment && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          textAlign: 'start',
                          fontWeight: '500',
                        }}
                      >
                        {formik?.errors.comment}
                      </div>
                    )}
                  </Box>
                )}
              </VStack>
            </Box>
            {!isEditMode && (
              <>
                <Text fontSize={16} fontWeight="600">
                  History of Ticket
                </Text>
                <VStack
                  spacing={2}
                  align={'flex-start'}
                  maxH={'calc(100vh - 500px)'}
                  overflow={'auto'}
                >
                  {ocrReportInfo?.history &&
                    ocrReportInfo?.history?.length > 0 &&
                    ocrReportInfo?.history?.map((item, index) => (
                      <IssueLog
                        cardDetails={item}
                        index={index}
                        prevCard={
                          index === 0 ? null : ocrReportInfo?.history[index - 1]
                        }
                      />
                    ))}
                </VStack>
              </>
            )}
            {!!isEditMode && (
              <Group position="right">
                <Button
                  type="button"
                  onClick={() => {
                    onClose();
                    formik.resetForm();
                    navigate(navigatePath);
                  }}
                  //   isDisabled={isRegenerateOCR}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  colorScheme="blue"
                  loadingText="Submit"
                  isDisabled={
                    formik?.values?.ocrIssueStatus ===
                    ocrReportInfo?.status[ocrReportInfo?.status?.length - 1]
                  }
                  isLoading={submitLoader}
                  minW={'80px'}
                >
                  Submit
                </Button>
              </Group>
            )}
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default ViewOcrIssueModal;
