import React from 'react';
import { AnalyticalReportDetails } from 'src/Redux/Broker/AnalyticalReports/state';
import { useAnalyticalReportContext } from '../..';
import EfficiencyRatiosTable from '../EfficiencyRatio/EfficiencyRatiosTable';
import LeverageRatiosTable from '../LeverageRatio/LeverageRatiosTable';
import LiquidityRatiosTable from '../LiquidityRatio/LiquidityRatiosTable';
import ProfitabilityRatiosTable from '../ProfitabilityRatio/ProfitabilityRatiosTable';
import YearOverYearGrowthTable from '../YearOverYearGrowth/YearOverYearGrowthTable';

const AnalyticalTable: React.FC<{
  tableInfo: AnalyticalReportDetails;
}> = ({ tableInfo }) => {
  const { analyticalReportRatio } = useAnalyticalReportContext();
  if (!!tableInfo) {
    switch (analyticalReportRatio) {
      case 'Profitability':
        return <ProfitabilityRatiosTable TData={tableInfo!} />;
      case 'Liquidity':
        return <LiquidityRatiosTable TData={tableInfo!} />;
      case 'Efficiency':
        return <EfficiencyRatiosTable TData={tableInfo!} />;
      case 'Leverage':
        return <LeverageRatiosTable TData={tableInfo!} />;
      default:
        return <YearOverYearGrowthTable TData={tableInfo!} />;
    }
  }
};

export default AnalyticalTable;
