import {
  Box,
  Button,
  Flex,
  Grid,
  Select,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import {
  Divider,
  FileInput,
  List,
  Modal,
  Progress,
  Textarea,
  Title,
} from '@mantine/core';
import { IconDownload, IconFileCv } from '@tabler/icons-react';
import { useFormik } from 'formik';
import {
  MRT_GlobalFilterTextInput,
  MRT_Row,
  MRT_TableInstance,
  MRT_TablePagination,
  MRT_ToolbarInternalButtons,
} from 'mantine-react-table';
import { useEffect, useState } from 'react';
import { CgComment, CgTrash } from 'react-icons/cg';
import {
  exportDocumentDownloadOptions,
  financialOption,
  financialStatementOption,
} from 'src/Pages/Upload/data';
import { getSecondDropDownOption } from 'src/Pages/Upload/utils';
import { useAppDispatch } from 'src/Redux/Store';
import { updateReports } from 'src/Redux/UploadReports/slice';
import {
  IReportLinks,
  IUploadReportsData,
} from 'src/Redux/UploadReports/state';
import { UploadSchemaEdit } from 'src/ValidationSchema/Pages/Index';
import {
  MAX_UPLOAD_SIZE,
  PDF_CSV_EXECLE_TYPES,
} from 'src/constants/UploadDocuments';
import { useAppToast, useS3FileUpload } from 'src/hooks';
import { APIStatus } from 'src/types/unions';
import { isFileTypeAllowed } from 'src/utils/helpers';
import { uploadTable } from '../UploadFilesTable';

export const renderTopToolbar = ({
  table,
  isTableLoading,
  handleExportData,
  isExportDisabled,
}: {
  table: MRT_TableInstance<uploadTable>;
  isTableLoading: boolean;
  handleExportData: () => void;
  isExportDisabled: boolean;
}) => (
  <Flex direction={'column'}>
    <Flex
      justifyContent={'space-between'}
      alignItems={'center'}
      flexWrap={'wrap'}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '5px',
          padding: '12px',
          flexWrap: 'wrap',
        }}
      >
        <Select placeholder="Select option" maxW={'max-content'}>
          {exportDocumentDownloadOptions?.map((item: any) => (
            <option value={item?.value}>{item?.label}</option>
          ))}
        </Select>
        <Button
          color="lightblue"
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={handleExportData}
          leftIcon={<IconDownload />}
          variant="filled"
          className="primary-btn"
          disabled={!isExportDisabled}
        >
          Export All Documents
        </Button>
      </Box>
      <Flex py={1} alignItems={'center'} flexWrap={'wrap'}>
        <Flex alignItems={'center'} gap={4} p={3}>
          <MRT_GlobalFilterTextInput table={table} />
          <MRT_ToolbarInternalButtons table={table} />
        </Flex>
        <Divider
          size="md"
          orientation="vertical"
          h={40}
          style={{ alignSelf: 'center' }}
        />
        <Box className="pagination-wrapper">
          <MRT_TablePagination position="top" table={table} />
        </Box>
      </Flex>
    </Flex>
    {isTableLoading && <Progress value={100} animate={true} />}
  </Flex>
);

export const HandleEditRowManually = ({
  table,
  row,
  applicantReportsData,
  isEditSaving = false,
  modalOpen = false,
  onClose,
  status,
}: {
  row: MRT_Row<uploadTable>;
  applicantReportsData: IUploadReportsData[] | null | undefined;
  isEditSaving: boolean;
  table: MRT_TableInstance<uploadTable>;
  modalOpen: boolean;
  onClose: () => void;
  status: APIStatus | null;
}) => {
  // states
  const [fileLinks, setFileLinks] = useState<{
    newFiles: { file: File; comment?: string | null }[];
    oldFiles: IReportLinks[];
  }>({
    newFiles: [],
    oldFiles: [],
  });
  const [currentUpdateRowId, setCurrentUpdateRowId] = useState<number | null>(
    null
  );
  // hooks
  const toast = useAppToast();
  const { S3UploadError, S3UploadLoading, S3UploadResponse, uploadFilesInS3 } =
    useS3FileUpload();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (applicantReportsData) {
      const { reportInfo, reportLinks, id } = applicantReportsData[row.index];

      setFieldValue('financial', reportInfo.type);
      setFieldValue(
        'financialYear',
        reportInfo.type === 'Financial Statement'
          ? reportInfo.timeFrame
          : reportInfo.reportName
      );
      setFieldValue('financialStatement', reportInfo.reportName);
      setFieldValue('financialFileWithComment.oldFiles', reportLinks);
    }

    return () => {
      resetForm();
    };
  }, [modalOpen]);

  useEffect(() => {
    if (S3UploadResponse) {
      dispatch(
        updateReports({
          id: Number(currentUpdateRowId),
          reportLinks: [
            ...(S3UploadResponse as any)?.data?.map(
              (value: any, index: number) => ({
                link: value?.location,
                urlKey: value?.key,
                comment: value.comment ?? null,
              })
            ),
            ...fileLinks.oldFiles.map((file) => ({
              link: file.link,
              urlKey: file.urlKey,
              comment: file.comment,
            })),
          ],
        })
      );
    }
    return () => {};
  }, [S3UploadResponse]);

  const toggleCommentBox = (
    index: number,
    val: string | null,
    type: 'oldFiles' | 'newFiles'
  ) => {
    if (val !== null) {
      setFieldValue(
        `financialFileWithComment[${type}][${index}].comment`,
        null
      );
    } else
      setFieldValue(`financialFileWithComment[${type}][${index}].comment`, '');
  };

  const submitReportsHandler = async (values: EditReportValues) => {
    try {
      const localFiles = values.financialFileWithComment?.newFiles.map(
        (data) => data.file
      );
      values.financialFileWithComment &&
        setFileLinks(values.financialFileWithComment);
      applicantReportsData &&
        setCurrentUpdateRowId(applicantReportsData?.[row.index].id!);
      if (localFiles?.length) {
        const formData = new FormData();
        localFiles.forEach((file, index) => {
          formData.append(`files`, file);
        });

        await uploadFilesInS3(formData);

        if (S3UploadError) {
          toast({
            title: 'Error in file uploading!!',
            status: 'error',
          });
          return;
        }
      } else if (
        JSON.stringify(values.financialFileWithComment?.oldFiles) !==
        JSON.stringify(applicantReportsData?.[row.index].reportLinks)
      ) {
        if (values.financialFileWithComment?.oldFiles.length === 0) {
          toast({
            status: 'error',
            title: 'Please upload atleast one file',
          });
          return;
        }
        dispatch(
          updateReports({
            id: Number(applicantReportsData?.[row.index].id!),
            reportLinks: [
              ...values.financialFileWithComment?.oldFiles.map((file) => ({
                link: file.link,
                urlKey: file.urlKey,
                comment: file.comment,
              }))!,
            ],
          })
        );
      } else {
        onClose();
      }
    } catch (error) {
      console.log('submitReportsHandler', error);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: UploadSchemaEdit,
    onSubmit: submitReportsHandler,
  });

  const { values, handleChange, setFieldValue, errors, resetForm } = formik;

  const handleFileInputUpload = (payload: File | File[]) => {
    const files = Array.isArray(payload) ? payload : [payload];

    const isValid = files.map((file) => ({
      typeError: !isFileTypeAllowed(file.name, PDF_CSV_EXECLE_TYPES),
      sizeError: file.size > MAX_UPLOAD_SIZE,
    }));

    if (isValid.some((error) => error.typeError)) {
      toast({
        title: 'Invalid file type!',
        status: 'error',
      });
      return;
    }

    // if (isValid.some((error) => error.sizeError)) {
    //   toast({
    //     title: `Max file size allowed is ${MAX_UPLOAD_SIZE / 1024 ** 2} MB!`,
    //     status: 'error',
    //   });
    //   return;
    // }

    const newFiles = files.map((file) => ({
      file,
      comment: null,
    }));
    setFieldValue('financialFileWithComment.newFiles', newFiles);
    if (values.financial === 'Financial Statement') {
      setFieldValue('financialFileWithComment.oldFiles', []);
    }
  };

  return (
    <Modal opened={modalOpen} onClose={onClose} withCloseButton={false}>
      <form onSubmit={formik.handleSubmit}>
        <Stack>
          <Title order={3}>Edit Data</Title>
          <Grid gridTemplateColumns={'1fr'} gap={4}>
            <Box>
              <Box>
                <Select
                  disabled
                  w={'100%'}
                  borderRadius="5px"
                  bg={'#114684'}
                  color={'white'}
                  textAlign={'center'}
                  onChange={(e) => {
                    formik.setFieldValue('financial', e.target.value);
                    formik.setFieldValue('financialYear', null);
                    formik.setFieldValue('financialStatement', null);
                  }}
                  value={formik?.values?.financial ?? ''}
                  name="financial"
                >
                  <option
                    value={''}
                    style={{ color: 'black' }}
                    label="Select Option"
                  />
                  {Array.isArray(financialOption) &&
                    financialOption.map((item: any, index: number) => (
                      <option
                        style={{ color: 'black' }}
                        key={index}
                        value={item.value}
                        label={item.label}
                      />
                    ))}
                </Select>
              </Box>
              {formik.touched.financial && errors.financial && (
                <div
                  style={{
                    color: 'red',
                    fontSize: '13px',
                    textAlign: 'start',
                    fontWeight: '500',
                  }}
                >
                  {errors.financial}
                </div>
              )}
            </Box>
            <Box>
              <Box>
                <Select
                  disabled
                  borderRadius="5px"
                  bg={'#114684'}
                  color={'white'}
                  textAlign={'center'}
                  onChange={handleChange}
                  value={formik?.values?.financialYear ?? ''}
                  name="financialYear"
                >
                  <option
                    value={''}
                    style={{ color: 'black' }}
                    label="Select Option"
                  />
                  {getSecondDropDownOption(formik?.values?.financial).map(
                    (item: any, index: number) => (
                      <option
                        style={{ color: 'black' }}
                        key={index}
                        value={item.value}
                        label={item.label}
                      />
                    )
                  )}
                </Select>
              </Box>
              {formik.touched.financialYear && errors.financialYear && (
                <div
                  style={{
                    color: 'red',
                    fontSize: '13px',
                    textAlign: 'start',
                    fontWeight: '500',
                  }}
                >
                  {errors.financialYear}
                </div>
              )}
            </Box>
            {formik?.values?.financial === 'Financial Statement' && (
              <Box>
                <Box>
                  <Select
                    disabled
                    borderRadius="5px"
                    bg={'#114684'}
                    color={'white'}
                    textAlign={'center'}
                    onChange={handleChange}
                    value={formik?.values?.financialStatement ?? ''}
                    name="financialStatement"
                  >
                    <option
                      value={''}
                      style={{ color: 'black' }}
                      label="Select Option"
                    />
                    {Array.isArray(financialStatementOption) &&
                      financialStatementOption.map(
                        (item: any, index: number) => (
                          <option
                            style={{ color: 'black' }}
                            key={index}
                            value={item.value}
                            label={item.label}
                          />
                        )
                      )}
                  </Select>
                </Box>
                {formik.touched.financialStatement &&
                  errors.financialStatement && (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        textAlign: 'start',
                        fontWeight: '500',
                      }}
                    >
                      {errors.financialStatement}
                    </div>
                  )}
              </Box>
            )}
            <Box>
              <Box mb={5}>
                <Flex flexWrap={'wrap'} gap={3} alignItems={'flex-end'}>
                  <FileInput
                    icon={
                      <IconFileCv
                        style={{ width: 18, height: 18 }}
                        stroke={1.5}
                      />
                    }
                    styles={{
                      input: {
                        maxWidth: '350px',
                        border: '1px solid #114684',
                      },
                    }}
                    // @ts-ignore
                    placeholder="Upload files"
                    label="Upload files"
                    multiple={formik.values.financial !== 'Financial Statement'}
                    onChange={(payload: File[]) =>
                      handleFileInputUpload(payload)
                    }
                    value={
                      values.financialFileWithComment?.newFiles?.map(
                        (file) => file.file
                      ) ?? []
                    }
                    description="The file format should be - xlsx, xls, csv, pdf, doc, docx"
                    withAsterisk
                    variant="filled"
                    accept=".pdf, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  />
                  <Button
                    disabled={
                      !values.financialFileWithComment?.newFiles?.length
                    }
                    color="red"
                    onClick={(e) =>
                      setFieldValue('financialFileWithComment.newFiles', [])
                    }
                  >
                    Reset
                  </Button>
                </Flex>
                {formik.touched.financialFileWithComment &&
                  errors.financialFileWithComment && (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '13px',
                        textAlign: 'start',
                        fontWeight: '500',
                      }}
                    >
                      {errors.financialFileWithComment}
                    </div>
                  )}
              </Box>
              {values.financialFileWithComment?.newFiles &&
                !!values.financialFileWithComment?.newFiles?.length && (
                  <List
                    spacing="xs"
                    size="sm"
                    center
                    type="ordered"
                    mb={13}
                    listStyleType="none"
                  >
                    {values.financialFileWithComment?.newFiles?.map(
                      (data, index) => (
                        <List.Item className="file-item">
                          <Box w={'100%'}>
                            <Flex gap={2} mb={1}>
                              <Text>
                                {index + 1}. {data.file.name}
                              </Text>
                              <Tooltip
                                label="Delete"
                                placement={'top'}
                                hasArrow
                              >
                                <Box>
                                  <CgTrash
                                    style={{
                                      cursor: 'pointer',
                                    }}
                                    className="file-item-trash"
                                    onClick={() => {
                                      const newData =
                                        values.financialFileWithComment?.newFiles.filter(
                                          (_, i) => i !== index
                                        );

                                      setFieldValue(
                                        `financialFileWithComment.newFiles`,
                                        newData
                                      );
                                    }}
                                  />
                                </Box>
                              </Tooltip>
                              <Tooltip
                                label="Additional Info"
                                placement={'top'}
                                hasArrow
                              >
                                <Box>
                                  <CgComment
                                    style={{
                                      cursor: 'pointer',
                                    }}
                                    className="file-item-trash"
                                    onClick={() =>
                                      toggleCommentBox(
                                        index,
                                        data.comment!,
                                        'newFiles'
                                      )
                                    }
                                  />
                                </Box>
                              </Tooltip>
                            </Flex>
                            {data.comment !== null && (
                              <Textarea
                                placeholder="comment"
                                label="Comment"
                                autosize
                                minRows={2}
                                maxRows={4}
                                value={data.comment ?? ''}
                                onChange={(event) =>
                                  setFieldValue(
                                    `financialFileWithComment.newFiles[${index}].comment`,
                                    event.currentTarget.value
                                  )
                                }
                                styles={{
                                  wrapper: {
                                    marginBottom: 16,
                                  },
                                }}
                              />
                            )}
                          </Box>
                        </List.Item>
                      )
                    )}
                  </List>
                )}
              {values.financialFileWithComment?.oldFiles &&
                !!values.financialFileWithComment?.oldFiles?.length && (
                  <List
                    spacing="xs"
                    size="sm"
                    center
                    type="ordered"
                    mb={16}
                    listStyleType="none"
                  >
                    {values.financialFileWithComment?.oldFiles.map(
                      (data, index) => (
                        <List.Item className="file-item">
                          <Box w={'100%'}>
                            <Flex gap={2} mb={1}>
                              <Text>
                                {values.financialFileWithComment?.newFiles
                                  .length! +
                                  1 +
                                  index}
                                . {data.urlKey}
                              </Text>
                              <Tooltip
                                label="Delete"
                                placement={'top'}
                                hasArrow
                              >
                                <Box>
                                  <CgTrash
                                    style={{
                                      cursor: 'pointer',
                                    }}
                                    className="file-item-trash"
                                    onClick={() => {
                                      const newData =
                                        values.financialFileWithComment?.oldFiles?.filter(
                                          (_, i) => i !== index
                                        );
                                      setFieldValue(
                                        `financialFileWithComment.oldFiles`,
                                        newData
                                      );
                                    }}
                                  />
                                </Box>
                              </Tooltip>
                              <Tooltip
                                label="Additional Info"
                                placement={'top'}
                                hasArrow
                              >
                                <Box>
                                  <CgComment
                                    style={{
                                      cursor: 'pointer',
                                    }}
                                    className="file-item-trash"
                                    onClick={() =>
                                      toggleCommentBox(
                                        index,
                                        data.comment!,
                                        'oldFiles'
                                      )
                                    }
                                  />
                                </Box>
                              </Tooltip>
                            </Flex>
                            {data.comment !== null && (
                              <Textarea
                                placeholder="comment"
                                label="Comment"
                                autosize
                                minRows={2}
                                maxRows={4}
                                value={data.comment ?? ''}
                                onChange={(event) =>
                                  setFieldValue(
                                    `financialFileWithComment.oldFiles[${index}].comment`,
                                    event.currentTarget.value
                                  )
                                }
                                styles={{
                                  wrapper: {
                                    marginBottom: 16,
                                  },
                                }}
                              />
                            )}
                          </Box>
                        </List.Item>
                      )
                    )}
                  </List>
                )}
            </Box>
          </Grid>
          <Flex justify="flex-end" mt="xl">
            <Button type="button" variant="white" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" isLoading={status === 'loading'}>
              Submit
            </Button>
            {/* <MRT_EditActionButtons variant="text" table={table} row={row} /> */}
          </Flex>
        </Stack>
      </form>
    </Modal>
  );
};

export interface EditReportValues {
  financial: string;
  financialYear: string;
  financialStatement: string;
  financialFileWithComment: {
    newFiles: { file: File; comment?: string | null }[];
    oldFiles: IReportLinks[];
  } | null;
}
const initialValues: EditReportValues = {
  financial: '',
  financialYear: '',
  financialStatement: '',
  financialFileWithComment: {
    newFiles: [],
    oldFiles: [],
  },
};

export const FileInfoModal = ({
  isOpen,
  onClose,
  filesInfo,
}: FileInfoModalProps) => {
  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      size={'lg'}
      title={'Files Information'}
      className="modal-style"
      overlayProps={{
        opacity: 0.1,
        blur: 3,
      }}
      // styles={{
      //   content: {
      //     border: '1px solid black',
      //     borderRadius: '12px',
      //   },
      //   header: {
      //     h2: {
      //       fontSize: '22px',
      //       fontWeight: 'bold',
      //       color: '#114684',
      //     },
      //   },
      // }}
    >
      <Stack>
        {/* <Title order={3}>Files Informations</Title> */}
        <Flex justifyContent="space-between" w="100%">
          <Text fontWeight="semibold" w="50%" wordBreak="break-word">
            File Name
          </Text>
          <Text fontWeight="semibold" w="50%" wordBreak="break-word">
            Comment
          </Text>
        </Flex>

        <Flex w="100%" flexDirection="column">
          {filesInfo.map((fileInfo, index) => (
            <Flex key={index} justifyContent="space-between" w="100%">
              <Text w="50%" wordBreak="break-word">
                {fileInfo.urlKey}
              </Text>
              Upload Documents
              <Text w="50%" wordBreak="break-word">
                {fileInfo.comment || '-'}
              </Text>
            </Flex>
          ))}
        </Flex>
      </Stack>
    </Modal>
  );
};

type FileInfoModalProps = {
  isOpen: boolean;
  onClose: () => void;
  filesInfo: IReportLinks[];
};
