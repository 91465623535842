import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import callAPI from 'src/utils/callAPI';
import { isRejectedActionWithPayload } from '../Broker/Documents/slice';
import initialState, { OCRhistoryInfo } from './state';

export const getOcrScanReports = createAsyncThunk(
  'ocrReportStore/getOcrScanReports',
  async (
    payload: {
      applicantId?: number;
      applicationId?: number;
      type?: string;
      reportName?: string;
      timeFrame?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const apiPayload = !!Object.keys(payload)?.length ? payload : null;
      const res = await callAPI(`ocr/report/list`, 'POST', apiPayload, true);

      return res.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);
export const getOCRReportById = createAsyncThunk(
  'ocrReportStore/getOCRReportById',
  async ({ id }: { id: number }, { rejectWithValue }) => {
    try {
      const res = await callAPI(`ocr/report/getbyid/${id}`, 'GET', null, true);

      return res.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);
export const updateOcrReportRecord = createAsyncThunk(
  'ocrReportStore/updateOcrReportRecord',
  async (
    {
      id,
      ocrReportData,
      history,
      adaData,
      adjustedTotal,
    }: {
      id: number;
      ocrReportData: Record<string, any>[];
      history: OCRhistoryInfo;
      adaData?: Record<string, any>;
      adjustedTotal?: Record<string, any>;
    },
    { rejectWithValue }
  ) => {
    try {
      let payload: Record<string, any> = { ocrReportData, history };
      if (adaData && adjustedTotal) {
        payload = { ocrReportData, history, adaData, adjustedTotal };
      }
      const res = await callAPI(
        `ocr/report/update/${id}`,
        'PUT',
        payload,
        true
      );

      return res.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const AddOcrReportRecord = createAsyncThunk(
  'ocrReportStore/AddOcrReportRecord',
  async (
    { id, ocrReportData }: { id: number; ocrReportData: Record<string, any>[] },
    { rejectWithValue }
  ) => {
    try {
      const res = await callAPI(
        `ocr/report/add/data`,
        'POST',
        { id, ocrReportData },
        true
      );

      return res.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const deleteOcrReportRecord = createAsyncThunk(
  'ocrReportStore/deleteOcrReportRecord',
  async (
    { ocrReportId, recordId }: { ocrReportId: number; recordId: string },
    { rejectWithValue }
  ) => {
    try {
      const res = await callAPI(
        `ocr/report/delete/data`,
        'DELETE',
        {
          id: ocrReportId,
          reportDataId: recordId,
        },
        true
      );

      return res.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const deleteOCRReport = createAsyncThunk(
  'ocrReportStore/deleteOCRReport',
  async ({ ocrReportId }: { ocrReportId: number }, { rejectWithValue }) => {
    try {
      const res = await callAPI(
        `ocr/report/delete/${ocrReportId}`,
        'DELETE',
        null,
        true
      );

      return res.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const ocrReportValidate = createAsyncThunk(
  'ocrReportStore/ocrReportValidate',
  async (
    payload: {
      ocrReportId: number;
      applicantId: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const res = await callAPI(`ocr/report/validate`, 'POST', payload, true);

      return res.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const ocrReportAssign = createAsyncThunk(
  'ocrReportStore/ocrReportAssign',
  async (
    {
      ocrReportId,
      applicantId,
    }: {
      ocrReportId: number;
      applicantId: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const res = await callAPI(
        `ocr/report/assignToApplicant/${ocrReportId}`,
        'PUT',
        { applicantId },
        true
      );

      return res.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const getOcrHistory = createAsyncThunk(
  'ocrReportStore/getOcrHistory',
  async (
    {
      ocrReportId,
    }: {
      ocrReportId: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const res = await callAPI(
        `ocr/report/getocrhistory/${ocrReportId}`,
        'GET',
        null,
        true
      );

      return res.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const getOCRReportCount = createAsyncThunk(
  'ocrReportStore/getOCRReportCount',
  async (_, { rejectWithValue }) => {
    try {
      const res = await callAPI(
        '/ocr/report/getReportCount',
        'GET',
        null,
        true
      );
      return res.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const ocrReportStore = createSlice({
  name: 'ocrReportStore',
  initialState,
  reducers: {
    removeOcrReportSliceType(state, action) {
      state.type = null;
      state.ocrReportCount = 0;
    },
  },
  extraReducers: (builder) => {
    // get application by status
    builder
      .addCase(getOcrScanReports.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.type = 'GET_OCR_REPORT_LIST';
      })
      .addCase(getOcrScanReports.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'GET_OCR_REPORT_LIST';
        state.ocrReportList = action.payload.data;
      })
      .addCase(getOcrScanReports.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'GET_OCR_REPORT_LIST';
        state.error = isRejectedActionWithPayload(action);
      })
      .addCase(getOCRReportById.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.type = 'GET_OCR_REPORT_BY_ID';
      })
      .addCase(getOCRReportById.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'GET_OCR_REPORT_BY_ID';
        state.data = action.payload.data;
      })
      .addCase(getOCRReportById.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'GET_OCR_REPORT_BY_ID';
        state.error = isRejectedActionWithPayload(action);
      })

      //update record ocrReport
      .addCase(updateOcrReportRecord.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.type = 'UPDATE_OCR_REPORT';
      })
      .addCase(updateOcrReportRecord.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'UPDATE_OCR_REPORT';
        state.msg = action?.payload?.data;
      })
      .addCase(updateOcrReportRecord.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'UPDATE_OCR_REPORT';
        state.error = isRejectedActionWithPayload(action);
      })

      //add record ocr report
      .addCase(AddOcrReportRecord.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.type = 'ADD_OCR_REPORT';
      })
      .addCase(AddOcrReportRecord.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'ADD_OCR_REPORT';
        state.msg = action?.payload?.message;
      })
      .addCase(AddOcrReportRecord.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'ADD_OCR_REPORT';
        state.error = isRejectedActionWithPayload(action);
      })

      //delete ocr report data(record)
      .addCase(deleteOcrReportRecord.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.type = 'DELETE_OCR_REPORT';
      })
      .addCase(deleteOcrReportRecord.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'DELETE_OCR_REPORT';
        state.msg = action?.payload?.message;
      })
      .addCase(deleteOcrReportRecord.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'DELETE_OCR_REPORT';
        state.error = isRejectedActionWithPayload(action);
      })
      //delete ocr report
      .addCase(deleteOCRReport.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.type = 'DELETE_OCR';
      })
      .addCase(deleteOCRReport.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'DELETE_OCR';
        state.msg = action?.payload?.data;
      })
      .addCase(deleteOCRReport.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'DELETE_OCR';
        state.error = isRejectedActionWithPayload(action);
      })

      //check BS report with AP/AR report
      .addCase(ocrReportValidate.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.type = 'VALIDATE_OCR_REPORT';
      })
      .addCase(ocrReportValidate.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'VALIDATE_OCR_REPORT';
        state.msg = action?.payload?.message;
      })
      .addCase(ocrReportValidate.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'VALIDATE_OCR_REPORT';
        state.error = isRejectedActionWithPayload(action);
      })

      //ocr report assign to applicant
      .addCase(ocrReportAssign.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.type = 'ASSIGN_OCR_REPORT';
      })
      .addCase(ocrReportAssign.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'ASSIGN_OCR_REPORT';
        state.msg = action?.payload?.msg;
      })
      .addCase(ocrReportAssign.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'ASSIGN_OCR_REPORT';
        state.error = isRejectedActionWithPayload(action);
      })

      //ocr report history get
      .addCase(getOcrHistory.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.type = 'GET_OCR_REPORT_HISTORY';
      })
      .addCase(getOcrHistory.fulfilled, (state, action) => {
        console.log('action', action);

        state.status = 'succeed';
        state.type = 'GET_OCR_REPORT_HISTORY';
        state.ocrHistoryData = action?.payload?.reporthistory;
      })
      .addCase(getOcrHistory.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'GET_OCR_REPORT_HISTORY';
        state.error = isRejectedActionWithPayload(action);
      })

      //get ocr report count
      .addCase(getOCRReportCount.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.type = 'GET_NON_VALIDATED_OCR_REPORT_COUNT';
      })
      .addCase(getOCRReportCount.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'GET_NON_VALIDATED_OCR_REPORT_COUNT';
        state.ocrReportCount = action?.payload?.data?.ocrCount;
      })
      .addCase(getOCRReportCount.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'GET_NON_VALIDATED_OCR_REPORT_COUNT';
        state.error = isRejectedActionWithPayload(action);
      });
  },
});

const ocrReportSlice = ocrReportStore.reducer;

export default ocrReportSlice;
export const { removeOcrReportSliceType } = ocrReportStore.actions;
