import { MdDriveFileRenameOutline, MdEmail } from 'react-icons/md';

import {
  Box,
  Button,
  Checkbox,
  Container,
  Input,
  Text,
} from '@chakra-ui/react';
import { Anchor } from '@mantine/core';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppToast } from 'src/hooks';
import { inviteApplicantUser, resetAuthStatus } from 'src/Redux/Auth/Auth';
import { useAppSelector } from 'src/Redux/Store';
import { InviteApplicantSchema } from 'src/ValidationSchema/Auth/Index';
import { initialInviteApplicantState, InviteApplicantAttributes } from './data';

const InviteApplicant = () => {
  const dispatch = useDispatch();
  const auth = useAppSelector((state) => state.auth);
  const toast = useAppToast();
  const navigate = useNavigate();
  const [loader, setLoader] = useState<{ btnLoader: boolean }>({
    btnLoader: false,
  });

  useEffect(() => {
    const { status, type, user, error } = auth;
    switch (status) {
      case 'loading': {
        if (type === 'INVITE_APPLICANT_USER') {
          setLoader((prev) => ({ ...prev, btnLoader: true }));
        }
        break;
      }
      case 'succeed': {
        if (type === 'INVITE_APPLICANT_USER') {
          setLoader((prev) => ({ ...prev, btnLoader: false }));
          toast({
            title: user?.msg,
            status: 'success',
          });
          dispatch(resetAuthStatus());
          navigate('/');
        }
        break;
      }
      case 'failed':
        if (type === 'INVITE_APPLICANT_USER') {
          setLoader((prev) => ({ ...prev, btnLoader: false }));
          toast({
            title: error?.msg || error?.message || 'Error occurred!',
            status: 'error',
          });
          dispatch(resetAuthStatus());
        }
        break;

      default:
        break;
    }
  }, [auth.status, auth.type]);

  const formik = useFormik({
    initialValues: initialInviteApplicantState,
    validationSchema: InviteApplicantSchema,
    onSubmit: async (values: InviteApplicantAttributes) => {
      try {
        dispatch(inviteApplicantUser(values) as any);
      } catch (error) {
        console.log('error', error);
      }
    },
  });

  const { values, handleChange, errors, touched, handleBlur, setFieldValue } =
    formik;

  return (
    <Container
      boxShadow=" rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 1.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset"
      h="auto"
      p={['20px', '10px', '10px', '20px']}
      borderRadius="20px"
      w={'100%'}
      marginTop={10}
    >
      <form
        onSubmit={formik.handleSubmit}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <Box mb={'20px'}>
          <Text
            fontWeight="bold"
            color="#114684"
            textAlign="center"
            fontSize={20}
          >
            Create Applicant Account
          </Text>
        </Box>

        <Box
          display={'flex'}
          flexDirection={'column'}
          gap={['5px', '4px', '7px', '20px']}
        >
          <Box display="flex" flexDirection={'column'}>
            <Box display="flex">
              <Box
                fontSize={25}
                color="#114684"
                borderRadius="5px 0px 0px 5px"
                height="auto"
                backgroundColor="#f0f5f9"
                pl={2}
                pr={2}
                pt={1.5}
                bg={'#f0f5f9'}
              >
                <MdDriveFileRenameOutline />
              </Box>
              <Input
                _hover={{ borderColor: '#114684' }}
                marginLeft={'-2px'}
                border="none"
                backgroundColor="#f0f5f9"
                focusBorderColor="transparent"
                type="text"
                placeholder="Applicant Company Name"
                borderRadius="0px 5px 5px 0px"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.companyName ?? ''}
                name="companyName"
              />
            </Box>
            {touched?.companyName && errors?.companyName ? (
              <div
                style={{
                  color: 'red',
                  fontSize: '15px',
                  textAlign: 'start',
                  fontWeight: '500',
                }}
              >
                {errors?.companyName}
              </div>
            ) : null}
          </Box>

          <Box display="flex" flexDirection={'column'}>
            <Box display="flex">
              <Box
                fontSize={25}
                color="#114684"
                borderRadius="5px 0px 0px 5px"
                height="auto"
                backgroundColor="#f0f5f9"
                pl={2}
                pr={2}
                pt={1}
              >
                <MdEmail />
              </Box>

              <Input
                _hover={{ borderColor: '#114684' }}
                marginLeft={'-2px'}
                bg={'#f0f5f9'}
                border="none"
                focusBorderColor="transparent"
                placeholder="Applicant Email Address"
                borderRadius="0px 5px 5px 0px"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={values?.email ?? ''}
                name="email"
                autoComplete="userData.email"
              />
            </Box>
            {touched?.email && errors?.email ? (
              <div
                style={{
                  color: 'red',
                  fontSize: '15px',
                  textAlign: 'start',
                  fontWeight: '500',
                }}
              >
                {errors.email}
              </div>
            ) : null}
          </Box>
          <Box>
            <Button
              isLoading={loader?.btnLoader}
              loadingText="Create Applicant"
              bg="#114684"
              color="white"
              w="100%"
              _hover={{ color: 'white' }}
              type="submit"
            >
              Create Applicant
            </Button>
          </Box>
        </Box>
      </form>
    </Container>
  );
};

export default InviteApplicant;
