import { HStack, Tooltip } from '@chakra-ui/react';
import { Box, Flex, MultiSelect, Text, TextInput } from '@mantine/core';
import type React from 'react';
import { useMemo } from 'react';
import { HiMiniInformationCircle } from 'react-icons/hi2';
import { useEfficiencyContext } from 'src/context/EfficiencyRatioContext';
import {
  CalculationFields,
  calculateTotalWthPLBS,
  getOptionWithPLBSReports,
  getStyles,
} from '../../data';

interface CalculationDisplayRTNrops {
  label: string;
  calculationFields: CalculationFields;
}

export const CalculationDisplayRTN: React.FC<CalculationDisplayRTNrops> = ({
  label,
  calculationFields,
}) => {
  const {
    totalIncome,
    setTotalIncome,
    tangibleNetworth,
    reportReferenceData,
    historyData,
  } = useEfficiencyContext();

  const isTotalRevenueOption = !!calculationFields?.totalIncome?.fields?.length;
  const isTotalRevenueValue = !!calculationFields?.totalIncome?.value
    ? calculationFields?.totalIncome?.value
    : 0;

  const totalRevenueSelectedFieldSumInitial = useMemo(() => {
    return calculateTotalWthPLBS(
      totalIncome,
      calculationFields?.totalIncome,
      reportReferenceData
    );
  }, []);
  const revenueTotalInitialValue =
    isTotalRevenueValue - totalRevenueSelectedFieldSumInitial;

  const revenueTotal = useMemo(() => {
    const totalSumSelectedFields = calculateTotalWthPLBS(
      totalIncome,
      calculationFields?.totalIncome,
      reportReferenceData
    );
    return Number(
      Number(totalSumSelectedFields + revenueTotalInitialValue).toFixed(2)
    );
  }, [totalIncome, calculationFields?.totalIncome]);

  // const isRevenueTangibleNetworthOption =
  //   !!calculationFields?.revenueTangibleNetworth?.fields?.length;
  // const isRevenueTangibleNetworthValue = !!calculationFields
  //   ?.revenueTangibleNetworth?.value
  //   ? calculationFields?.revenueTangibleNetworth?.value
  //   : 0;

  // const revenueTotal = useMemo(() => {
  //   const totalSum =
  //     calculateTotalWthPLBS(
  //       totalIncome,
  //       calculationFields?.totalIncome,
  //       reportReferenceData
  //     ) + (isTotalRevenueOption ? 0 : isTotalRevenueValue);
  //   return Number(totalSum?.toFixed(2));
  // }, [totalIncome, calculationFields?.totalIncome]);

  // const revenueTangibleNetworthTotal = useMemo(() => {
  //   const totalSum =
  //     calculateTotalWthPLBS(
  //       revenueTangibleNetworth,
  //       calculationFields?.tangibleNetworth,
  //       reportReferenceData
  //     ) +
  //     (isRevenueTangibleNetworthOption ? 0 : isRevenueTangibleNetworthValue);
  //   return Number(totalSum?.toFixed(2));
  // }, [revenueTangibleNetworth, calculationFields?.tangibleNetworth]);

  const result = useMemo(() => {
    const answer = Number((revenueTotal / tangibleNetworth).toFixed(2));
    return !!tangibleNetworth ? answer : 'N/A';
  }, [revenueTotal, tangibleNetworth]);

  return (
    <Box w={'100%'}>
      <HStack gap={2} mb="md">
        <Text size="lg" weight={700}>
          {label}
        </Text>
        {!!historyData?.revenueToTangibleNetWorth && (
          <Tooltip
            label={`${label} (${historyData?.revenueToTangibleNetWorth?.value}) is updated by ${historyData?.revenueToTangibleNetWorth?.updatedBy}`}
            placement="right"
            hasArrow
          >
            <Box>
              <HiMiniInformationCircle
                size={18}
                color="black"
                cursor="pointer"
              />
            </Box>
          </Tooltip>
        )}
      </HStack>
      <Flex direction="column" align="stretch" gap="md">
        <Flex wrap={'wrap'} align="center" justify={'flex-start'} gap="xs">
          <MultiSelect
            label={`Revenue ${revenueTotal}`}
            data={getOptionWithPLBSReports(
              calculationFields?.totalIncome?.fields!,
              reportReferenceData!
            )}
            value={totalIncome}
            onChange={setTotalIncome}
            placeholder="Select Revenue items"
            searchable
            className="legalform_pdfPreference"
            maxDropdownHeight={400}
            sx={{ flex: 1 }}
            styles={getStyles(calculationFields?.totalIncome?.fields!)}
            readOnly
          />
          <Text size="xl" weight={700} mt={'xs'}>
            ÷
          </Text>
          {/* <MultiSelect
            label={`Tangible Networth (${revenueTangibleNetworthTotal})`}
            data={getOptionWithPLBSReports(
              calculationFields?.tangibleNetworth?.fields!,
              reportReferenceData!
            )}
            value={revenueTangibleNetworth}
            onChange={setRevenueTangibleNetworth}
            placeholder="Select Tangible Networth items"
            searchable
            className="legalform_pdfPreference"
            maxDropdownHeight={400}
            sx={{ flex: 1 }}
          /> */}
          <TextInput
            label="Tangible Networth"
            value={tangibleNetworth?.toFixed(2) ?? 0}
            disabled
            styles={{
              input: {
                textAlign: 'right',
                '&:disabled': {
                  color: 'black',
                  fontWeight: 'bold',
                },
              },
            }}
            sx={{ flex: 1 }}
          />
        </Flex>
        <Flex align="center" justify="flex-start" wrap={'wrap'} gap={'xs'}>
          {/* Equals Sign */}
          <Text size="xl" weight={700} mt={'xs'}>
            =
          </Text>
          {/* Result */}
          <TextInput
            label="Result"
            value={result ?? 0}
            disabled
            styles={{
              input: {
                textAlign: 'right',
                '&:disabled': {
                  color: 'black',
                  fontWeight: 'bold',
                },
              },
            }}
            maw={'50%'}
            w={'100%'}
          />
        </Flex>
      </Flex>
    </Box>
  );
};
